import React from "react";
import { Header } from "semantic-ui-react";
import { isString, startCase } from "lodash";
import { CompanyType } from "../../company/types";

const InvoiceComponentCompanyHeader = ({ company }) => (
    <Header>
        {startCase(company.name)}
        <Header.Subheader>{startCase(company.address)}</Header.Subheader>
        <Header.Subheader>
            {[company.city, company.state, company.zip].filter(isString).join(", ")}
        </Header.Subheader>
        <Header sub>
            <b>TABC ID #{company.tabc_id}</b>
        </Header>
    </Header>
);

InvoiceComponentCompanyHeader.propTypes = {
    company: CompanyType.isRequired,
};

export default InvoiceComponentCompanyHeader;
