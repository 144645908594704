import {
    COMPANY_NOTIFICATION_TOGGLE_DATA,
    COMPANY_NOTIFICATION_TOGGLE_ERROR,
    COMPANY_NOTIFICATION_DATA,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    email_notification: "",
    app_notification: "",
    error: null,
};

const setNotificationDataData = (state, data) => ({
    ...state,
    email_notification: data.email_notification,
    app_notification: data.app_notification,
    error: null,
});

const setNotificationToggleError = (state, error) => ({
    ...state,
    isLoading: false,
    error,
});

const setNotificationToggleData = (state, data) => ({
    ...state,
    email_notification: data.email_notification,
    app_notification: data.app_notification,
    error: null,
});

const reducers = {
    [COMPANY_NOTIFICATION_DATA]: setNotificationDataData,
    [COMPANY_NOTIFICATION_TOGGLE_DATA]: setNotificationToggleData,
    [COMPANY_NOTIFICATION_TOGGLE_ERROR]: setNotificationToggleError,
};

export default generateReducer(reducers, initialState);
