import { get } from "lodash";
import { toast } from "react-semantic-toasts";

import {
    INVOICE_DOCUMENT_DATA,
    INVOICE_DOCUMENT_ERROR,
    INVOICE_DOCUMENT_LOADING,
    INVOICE_DOCUMENT_UPLOADING,
    INVOICE_DOCUMENT_UPLOAD_ERROR,
    INVOICE_DOCUMENT_UPLOAD_SUCCESS,
    INVOICE_DOCUMENT_REMOVING,
    INVOICE_DOCUMENT_REMOVE_ERROR,
    INVOICE_DOCUMENT_REMOVE_SUCCESS,
    INVOICE_DOCUMENT_UPLOAD_WEBCAM,
    INVOICE_DOCUMENT_EDITOR_IS_SAVING,
    INVOICE_DOCUMENT_EDITOR_ID,
} from "../constants";

import { makePrivateApiCall } from "utilities/dist/api";

export const loadUploadedDocuments = invoiceId => dispatch => {
    dispatch({
        type: INVOICE_DOCUMENT_LOADING,
    });

    return makePrivateApiCall({
        url: `invoice/${invoiceId}/document`,
    })
        .then(res => {
            dispatch({
                type: INVOICE_DOCUMENT_DATA,
                payload: res.data,
            });
        })
        .catch(err => {
            dispatch({
                type: INVOICE_DOCUMENT_ERROR,
                payload: err.message,
            });
        });
};

export const uploadDocuments =
    (file, invoiceId, cameraImage = "") =>
    dispatch => {
        let imageFormObj = new FormData();

        if (cameraImage) {
            imageFormObj.append("invoice_document", file, cameraImage);
        } else {
            imageFormObj.append("invoice_document", file);
        }

        dispatch({
            type: INVOICE_DOCUMENT_UPLOADING,
            payload: URL.createObjectURL(file),
        });

        makePrivateApiCall({
            method: "POST",
            data: imageFormObj,
            url: `invoice/${invoiceId}/document`,
        })
            .then(res => {
                dispatch({
                    type: INVOICE_DOCUMENT_UPLOAD_SUCCESS,
                    payload: res.data,
                });
            })
            .catch(err => {
                const message = get(err, "response.data.message", err.message);
                dispatch({
                    type: INVOICE_DOCUMENT_UPLOAD_ERROR,
                    payload: message,
                });
            });
    };

export const webCamUpload = data => dispatch => {
    dispatch({
        type: INVOICE_DOCUMENT_UPLOAD_WEBCAM,
        payload: data,
    });
};

export const setDocumentEditorId = fileId => dispatch =>
    dispatch({
        type: INVOICE_DOCUMENT_EDITOR_ID,
        payload: fileId,
    });

export const updateDocumentName = (fileId, originalname) => dispatch => {
    dispatch({
        type: INVOICE_DOCUMENT_EDITOR_IS_SAVING,
        payload: true,
    });

    return makePrivateApiCall({
        method: "PUT",
        url: `invoice/document/${fileId}`,
        data: { originalname },
    })
        .then(res => {
            dispatch({
                type: INVOICE_DOCUMENT_EDITOR_ID,
                payload: null,
            });
            dispatch({
                type: INVOICE_DOCUMENT_EDITOR_IS_SAVING,
                payload: { editorIsSaving: false, updatedDocument: res.data.document },
            });

            if (res.data?.success) {
                toast({
                    time: 15000,
                    type: "success",
                    icon: "file",
                    title: "File updated",
                    description: `Name changed to ${originalname}`,
                });
            }
        })
        .catch(err => {
            dispatch({
                type: INVOICE_DOCUMENT_EDITOR_IS_SAVING,
                payload: { editorIsSaving: false },
            });
            toast({
                time: 15000,
                type: "error",
                icon: "file",
                title: "Error updating file",
                description: err?.response?.data?.message || err.message,
            });
        });
};

export const removeDocument = fileId => dispatch => {
    dispatch({
        type: INVOICE_DOCUMENT_REMOVING,
        payload: fileId,
    });

    makePrivateApiCall({
        method: "DELETE",
        url: `invoice/document/${fileId}`,
    })
        .then(() => {
            dispatch({
                type: INVOICE_DOCUMENT_REMOVE_SUCCESS,
                payload: fileId,
            });
        })
        .catch(err => {
            dispatch({
                type: INVOICE_DOCUMENT_REMOVE_ERROR,
                payload: err.message,
            });
        });
};
