import React from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";

import TopNav from "../containers/top-nav";
import CompanyNotificationContainer from "../../company/notification/index.container";

const NotificationPreferencePage = () => {
    return (
        <Grid>
            <TopNav
                headerIcon="user"
                headerContent="User Notification"
                subheader="Manage your preferences"
            />
            <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={8} className="bp-15">
                    <CompanyNotificationContainer />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
});

export default connect(mapStateToProps)(NotificationPreferencePage);
