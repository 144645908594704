import ReactGA from "react-ga";
import { history } from "../store";

export const initializeTrackingForAnalytics = () => {
    if (process.env.REACT_APP_DISABLE_GOOGLE_ANALYTICS) return;

    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE, {
        debug: process.env.NODE_ENV !== "production",
        titleCase: false,
    });

    history.listen(location => {
        ReactGA.pageview(location.pathname);
    });
};

export const setUserDetailsForAnalytics = (user = {}) => {
    if (process.env.REACT_APP_DISABLE_GOOGLE_ANALYTICS) return;

    ReactGA.set({ userId: user?.id, companyId: user?.company_id });
};

export const trackEventForAnalytics = (data = {}) => {
    if (process.env.REACT_APP_DISABLE_GOOGLE_ANALYTICS) return;
    ReactGA.event(data);
};

export const trackSaveStampRollEvent = () => {
    trackEventForAnalytics({
        category: "stamp_roll",
        action: "created new stamp roll",
    });
};

export const trackDownloadPdfEvent = (number = "", options = {}) => {
    let action = `creating pdf of invoice`;
    if (options.success) action = "pdf created for invoice";
    if (options.error) action = `error ${action}`;

    trackEventForAnalytics({
        action,
        value: number,
        category: "invoice_pdf",
    });
};

export const trackInvoiceCreateEvent = (invoice = {}, options = {}) => {
    let action = `creating new invoice`;
    if (options.success) action = "new invoice created";
    if (options.error) action = `error ${action}`;

    trackEventForAnalytics({
        action,
        value: invoice.id,
        category: "invoice_create",
    });
};

export const trackInvoiceEditEvent = (invoice = {}, options = {}) => {
    let action = `updating existing invoice`;
    if (options.success) action = "existing invoice updated";
    if (options.error) action = `error ${action}`;

    trackEventForAnalytics({
        action,
        value: invoice.id,
        category: "invoice_update",
    });
};

export const trackCustomerCreateEvent = (customer = {}, options = {}) => {
    let action = `saving ${customer.id ? "existing" : "new"} customer`;
    if (options.success)
        action = `${customer.id ? "existing customer updated" : "new customer saved"}`;
    if (options.error) action = `error ${action}`;

    trackEventForAnalytics({
        action,
        value: customer.id,
        category: `${customer.id ? "customer_update" : "customer_create"}`,
    });
};

export const trackCustomerRemoveEvent = (customerId, options = {}) => {
    let action = `removing customer`;
    if (options.success)
        action = "customer removed";
    if (options.error) action = `error ${action}`;

    trackEventForAnalytics({
        action,
        value: customerId,
        category: "customer_remove",
    });
};

export const trackProductCreateEvent = (product = {}, options = {}) => {
    let action = `creating new product`;
    if (options.success) action = "new product created";
    if (options.error) action = `error ${action}`;

    trackEventForAnalytics({
        action,
        value: product.id,
        category: "product_create",
    });
};

export const trackSendEmailEvent = (emails = {}, options = {}) => {
    let action = `sending email`;
    if (options.success) action = "email has been sent";
    if (options.error) action = `error ${action}`;

    trackEventForAnalytics({
        action,
        value: emails.id,
        category: "email",
    });
};

export const trackActivateFeatureEvent = (confirming = {}, options = {}, isActive) => {
    let action = `activating ${confirming.id}`;
    if (options.success) action = `${confirming.id} is ${!isActive ? "activated" : "deactivated"}`;
    if (options.error) action = `error ${action}`;

    trackEventForAnalytics({
        action,
        value: confirming.id,
        category: "feature_activation",
    });
};
