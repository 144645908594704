import React, { useState, useEffect, useCallback } from "react";
import { makePrivateApiCall } from "utilities/dist/api";
import { Button, Segment, Header, Message, Divider } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { CloverComponentFailedInvoices } from "../components/failed-invoices";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { DateTimeFormat } from "utilities/dist/invoice/constants";

export const CloverContainerHistory = () => {
    const [history, setHistory] = useState({ isLoading: true, data: {}, error: "" });
    //     Since we want to keep the sync endpoint open for manual use, companyId needs to be manually injected into the sync request.
    const company_id = useSelector(({ auth }) => auth?.user?.company_id);

    const syncHistory = useCallback(() => {
        makePrivateApiCall({ url: "clover/sync/history", method: "GET" })
            .then(res => {
                setHistory({ isLoading: false, data: res.data, error: "" });
            })
            .catch(err => {
                setHistory({ isLoading: false, data: {}, error: err.message });
            });
    }, []);
    const syncCloverSales = useCallback(() => {
        setHistory({ isLoading: true, data: {}, error: "" });
        makePrivateApiCall({
            url: "clover/sync-invoices",
            method: "GET",
            params: { company_id },
        }).then(syncHistory);
    }, []);
    const syncCloverItems = useCallback(() => {
        setHistory({ isLoading: true, data: {}, error: "" });
        makePrivateApiCall({
            url: "clover/sync-products",
            method: "GET",
            params: { company_id },
        }).then(syncHistory);
    }, [company_id]);

    useEffect(() => {
        syncHistory();
    }, []);

    return (
        <Segment loading={history.isLoading}>
            <Header content="Sync History" />
            {history.error && (
                <Message
                    error
                    icon="warning sign"
                    content={history.error}
                    header="Error loading sync history!"
                />
            )}

            {history.data?.lastSync === null && (
                <Message
                    info
                    icon="calendar"
                    header="No invoice sync history found!"
                    content={`Clover sales sync never ran`}
                />
            )}

            {history.data?.lastSync && (
                <Message
                    info
                    icon="calendar"
                    content={`Last synced invoice on: ${history.data.lastSync.createdAt}`}
                />
            )}

            {!!history.data?.failedInvoices?.length && (
                <>
                    <Header content="Failed Invoice Syncs" dividing />
                    <CloverComponentFailedInvoices invoices={history.data?.failedInvoices} />
                </>
            )}

            {!!history.data?.failedProductSyncLog?.length &&
                history.data?.failedProductSyncLog.map(failedProductSync => (
                    <Message
                        warning
                        icon="martini glass"
                        key={`${failedProductSync.sync_id}`}
                        list={[
                            () => (
                                <Link to={`/clover/${failedProductSync.sync_id}`}>
                                    Product sync failed on:{" "}
                                    {format(new Date(failedProductSync.createdAt), DateTimeFormat)}
                                </Link>
                            ),
                        ]}
                    />
                ))}

            <Divider />
            <Button icon="newspaper" content="Sync Recent Invoices" onClick={syncCloverSales} />
            <Button icon="martini glass" content="Sync Products" onClick={syncCloverItems} />
        </Segment>
    );
};
