import React from "react";

import { formatAmount } from "utilities/dist/helpers";

const InvoiceComponentTotals = ({ totals }) => {
    return (
        <>
            SubTotal: <b>{formatAmount(totals.subTotal)}</b>
            <br />
            {totals.taxTotal > 0 && <span className="text-red">*</span>}Tax:{" "}
            <b>{formatAmount(totals.taxTotal)}</b>
            <br />
            Total: <b>{formatAmount(totals.total)}</b>
        </>
    );
};

export default InvoiceComponentTotals;
