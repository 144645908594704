"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makePublicApiCall = exports.makePrivateApiCall = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _config = _interopRequireDefault(require("./config"));

var _helpers = require("./auth/helpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const API_URL = _config.default.api_url;
/**
 *
 * @typedef ApiRequestConfig
 *
 * @property baseURL {string}
 * @property responseType {string}
 * @property method {string}
 * @property url {string}}}
 * @property headers {{Authorization: string}}
 */

/**
 *
 * @param {ApiRequestConfig} config
 * @return {ApiRequestConfig}
 */

const buildApiCallParams = config => {
  return {
    url: "/",
    method: "get",
    baseURL: API_URL + "/v1",
    responseType: "json",
    ...config
  };
};
/**
 * make private api call
 * @async
 * @function makePrivateApiCall
 *
 * @param {Object} config
 * @param {String} config.url	    Url to send the request
 * @param {String} config.method	    Method type eg: GET, POST
 * @param {String} config.responseType	    Data type of the response
 * @param {String} config.baseURL	    Base url to start the request with
 * @param {Object} config.headers     Add props to request headers
 *
 * @returns {Promise} Promise to return responded data
 */


const makePrivateApiCall = config => {
  const params = {
    headers: {
      Authorization: (0, _helpers.getAuthToken)()
    },
    ...buildApiCallParams(config)
  };
  return (0, _axios.default)(params);
};

exports.makePrivateApiCall = makePrivateApiCall;

const makePublicApiCall = config => {
  return (0, _axios.default)(buildApiCallParams(config));
};

exports.makePublicApiCall = makePublicApiCall;