import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { Grid, Image } from "semantic-ui-react";

import SigninForm from "../containers/signin";
import SignupForm from "../containers/signup";
import ResetPasswordForm from "../containers/reset-password";

import { authValidate } from "../actions";

class AuthMainPage extends React.Component {
    // if on page load, user is signing in, issue a token validation
    // this will make sure that user gets redirected to homepage if user already has a valid token
    componentDidMount() {
        if (this.props.isSigningIn) {
            this.props.authValidate();
        }
    }

    render() {
        const {
            user,
            match: { params },
        } = this.props;

        if (user) {
            return <Redirect to="/" />;
        }

        const containerMap = {
            signin: SigninForm,
            signup: SignupForm,
            "reset-password": ResetPasswordForm,
        };

        const AuthContainer = containerMap[params.section || "signin"];

        return (
            <div className="auth-page">
                {/*
            Heads up! The styles below are necessary for the correct render of this example.
            You can do same with CSS, the main idea is that all the elements up to the `Grid`
            below must have a height of 100%.
            */}
                <style>
                    {`
                body > div,
                body > div > div,
                body > div > div > div.auth-page,
                body > div > div > div.auth-page > .ui.grid {
                    height: 100%;
                }
            `}
                </style>
                <Grid textAlign="center" verticalAlign="middle">
                    <Grid.Column style={{ maxWidth: 450, textAlign: "center" }}>
                        <Image src="/images/logo-full.svg" style={{ margin: "0 auto" }} />

                        <AuthContainer />
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
    isSigningIn: auth.signingIn,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            authValidate,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AuthMainPage);
