import React from "react";
import { Confirm, Button } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

import { isFeatureActive, paidFeatureIds } from "./helpers";
import { toggleCompanyFeature, showCompanyFeatureToggleConfirmation } from "./actions";
import { trackActivateFeatureEvent } from "../../common/analytics";

const CompanyFeatureContainerConfirmToggle = () => {
    const {
        confirming,
        company,
        isLoading,
        // TODO: SHOW ERROR MESSAGE IF THERE IS ANY WHEN ACTIVATING
        // error
    } = useSelector(({ companyFeature, auth }) => ({
        ...companyFeature,
        company: auth.user.company,
    }));
    const isActive = isFeatureActive(company, confirming);

    const dispatch = useDispatch();
    const hideConfirm = () => dispatch(showCompanyFeatureToggleConfirmation(false));
    const toggleFeature = () => {
        dispatch(
            toggleCompanyFeature({
                name: confirming.id,
                active: !isActive,
            })
        );

        trackActivateFeatureEvent(confirming, { success: true }, isActive);
    };

    const requestAccessProp = {
        header: isActive
            ? `Deactive ${confirming.title}`
            : `Request Access to ${confirming.title}`,
        content: isActive
            ? confirming.request_access?.description2
            : confirming.request_access?.description1,
        cancelButton: <Button negative loading={isLoading} content="Cancel" />,
        confirmButton: isActive ? (
            <Button
                color="red"
                loading={isLoading}
                content="Deactivate"
                data-cy="feature-enable-confirm"
            />
        ) : null,
        onCancel: hideConfirm,
        onConfirm: toggleFeature,
        open: !!confirming,
    };

    const confirmProp = {
        header: `${isActive ? "Deactivate" : "Activate"} ${confirming.title}`,
        content: confirming.description,
        cancelButton: <Button negative loading={isLoading} content="Cancel" />,
        confirmButton: (
            <Button
                color="green"
                loading={isLoading}
                content={isActive ? "Deactivate" : "Activate"}
                data-cy="feature-enable-confirm"
            />
        ),
        onConfirm: toggleFeature,
        onCancel: hideConfirm,
        open: !!confirming,
    };
    const isPaidFeature = paidFeatureIds.includes(confirming.id);
    const propsToPass = !isPaidFeature ? confirmProp : requestAccessProp;

    return <Confirm {...propsToPass} />;
};

export default CompanyFeatureContainerConfirmToggle;
