import {
    INVOICE_EMAIL_GRID_DATA,
    INVOICE_EMAIL_GRID_ERROR,
    INVOICE_EMAIL_GRID_LOADING,
    INVOICE_EMAIL_GRID_PAGE_CHANGE,
    INVOICE_EMAIL_GRID_STATE_CHANGE,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    error: null,
    emails: [],
    isLoading: false,
    currentPage: 1,
    lastRow: null,
    pageSize: 25,
    sortModel: [
        {
            colId: "created_at",
            sort: "desc",
        },
    ],
    filterModel: {},
};

const setGridData = (state, { emails, lastRow }) => ({
    ...state,
    error: null,
    isLoading: false,
    lastRow,
    emails,
});

const changeGridPage = (state, currentPage) => ({ ...state, currentPage });
const changeGridState = (state, payload) => ({ ...state, ...payload });
const setGridLoading = state => ({ ...state, isLoading: true });
const setGridError = (state, error) => ({ ...state, isLoading: false, error });

const reducers = {
    [INVOICE_EMAIL_GRID_DATA]: setGridData,
    [INVOICE_EMAIL_GRID_ERROR]: setGridError,
    [INVOICE_EMAIL_GRID_LOADING]: setGridLoading,

    [INVOICE_EMAIL_GRID_PAGE_CHANGE]: changeGridPage,
    [INVOICE_EMAIL_GRID_STATE_CHANGE]: changeGridState,
};

export default generateReducer(reducers, initialState);
