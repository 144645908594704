import React from "react";
import { Grid } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import { CloverContainerSyncLog } from "../containers/sync-log";

const CloverPageSyncLog = ({ computedMatch }) => {
    return (
        <Grid>
            <TopNav headerIcon="leaf" subheader="Clover Sync Log" headerContent="Clover" />

            <Grid.Column mobile={16} computer={16}>
                <CloverContainerSyncLog syncId={computedMatch.params.syncId} />
            </Grid.Column>
        </Grid>
    );
};

export default CloverPageSyncLog;
