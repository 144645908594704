import { io } from "socket.io-client";
import { toast } from "react-semantic-toasts";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import config from "utilities/dist/config";
import { updatePlacedOrderCount } from "./helpers";
import { SOCKET_EVENTS } from "utilities/dist/socket-events";

const getGlobalSocket = () => window["ynvoiceSocket"];
const setGlobalSocket = socket => (window["ynvoiceSocket"] = socket);
const removeGlobalSocket = () => delete window["ynvoiceSocket"];

export const useOrderUpdateWithSocket = notification => {
    const history = useHistory();

    const [placedOrderCount, setPlacedOrderCount] = useState([]);

    const token = localStorage.getItem("auth.token");

    const clickNotification = number => history.push(`/order/${number}`);
    const { app_notification } = notification || {};

    // const addUpdatedProduct = useProductStore(state => state.addUpdatedProduct);

    useEffect(() => {
        if (getGlobalSocket()) {
            getGlobalSocket().disconnect();
        }
        // Get initial placed order count
        updatePlacedOrderCount(setPlacedOrderCount);

        const socket = io(config.websocket_url, {
            transports: ["websocket"],
            auth: {
                token,
            },
        });

        socket.on("connect_error", details => {
            // eslint-disable-next-line no-console
            console.warn(details);
        });
        socket.on(SOCKET_EVENTS.ORDER_INVOICED, () => {
            updatePlacedOrderCount(setPlacedOrderCount);
        });
        socket.on(SOCKET_EVENTS.BOTTLE_POS_INVOICE_SYNC_ERROR, data => {
            toast({
                time: 30000,
                type: "error",
                icon: "sync",
                title: "Bottle POS Sync Failed",
                list: [
                    `Failed to sync invoice to Bottle POS!`,
                    `Please note necessarily mean your changes were not saved on our system.`,
                    `Details: ${data.error}.`,
                    `Click to see more details.`,
                ],
                onClick: () => history.push(`/bottle-pos/${data.syncId}`),
            });
        });
        socket.on(SOCKET_EVENTS.CLOVER_INVENTORY_PUSH_ERROR, data => {
            toast({
                time: 30000,
                type: "error",
                icon: "sync",
                title: "Clover Sync Failed",
                list: [
                    `Failed to sync invoice to Clover!`,
                    `Please note necessarily mean your changes were not saved on our system.`,
                    `Details: ${data.error}.`,
                    `Click to see more details.`,
                ],
                onClick: () => history.push(`/clover/${data.syncId}`),
            });
        });

        // we need this globally so that when re-executed, we can disconnect first before reconnecting
        setGlobalSocket(socket);

        socket.on(SOCKET_EVENTS.ORDER_PLACED, data => {
            updatePlacedOrderCount(setPlacedOrderCount);

            if (history.location.pathname === "/order") {
                history.push({
                    pathname: history.location.pathname,
                    search: "?force-update",
                });
            }
            if (!app_notification || !data?.order_number) return;

            toast({
                description: `You got a new order #${data.order_number}`,
                time: 30000,
                type: "success",
                icon: "checkmark",
                title: "New order arrived",
                onClick: () => clickNotification(data.order_number),
            });
        });

        return () => {
            socket.disconnect();
            removeGlobalSocket();
        };
    }, [app_notification]);

    return {
        placedOrderCount,
    };
};
