import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { startCase, isString } from "lodash";
import { Dropdown, Header } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { loadCustomerList } from "../actions";

const CustomerContainerPicker = ({ dropdownProps = {}, selectedCustomerId, onSelect }) => {
    const { data, isLoading } = useSelector(({ customerList }) => customerList);

    const dispatch = useDispatch();
    const loadCustomers = () => {
        if (!isLoading && data.length < 1 && !data.error) {
            dispatch(loadCustomerList());
        }
    };
    const handleChange = (e, { value }) => {
        if (value) {
            onSelect(data.find(({ id }) => id === value));
        } else {
            onSelect({});
        }
    };

    const handleAddCustomer = () => {
        dispatch(push("/customer/create"));
    };

    useEffect(loadCustomers, []);
    const options = data.map(customer => ({
        key: customer.id,
        value: customer.id,
        text: `${customer.name}`,
        content: (
            <Header as="h4" data-cy={`customer_option_${customer.id}`}>
                {startCase(customer.name)}
                <Header.Subheader>{startCase(customer.address)}</Header.Subheader>
                <Header.Subheader>
                    {[customer.city, customer.state, customer.zip].filter(isString).join(", ")}
                </Header.Subheader>
            </Header>
        ),
    }));

    return (
        <Dropdown
            fluid
            search
            selection
            allowAdditions
            options={options}
            minCharacters={3}
            autoComplete="off"
            loading={isLoading}
            selectOnBlur={false}
            onChange={handleChange}
            value={selectedCustomerId}
            onAddItem={handleAddCustomer}
            additionLabel="Create New Customer "
            data-cy="customer-picker-dropdown"
            placeholder={`Start typing to see suggestions...`}
            {...dropdownProps}
        />
    );
};

CustomerContainerPicker.propTypes = {
    dropdownProps: PropTypes.object,
    onSelect: PropTypes.func.isRequired,
    selectedCustomerId: PropTypes.number,
};

export default CustomerContainerPicker;
