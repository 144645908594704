import { isArray, isString, cloneDeep } from "lodash";

import {
    PRODUCT_PRICE_TIER_INPUT,
    PRODUCT_PRICE_TIER_ERROR,
    PRODUCT_PRICE_TIER_SAVING,
    PRODUCT_PRICE_TIER_SAVED,
    PRODUCT_PRICE_TIER_MODAL_TOGGLE,
    PRODUCT_PRICE_TIER_REMOVED,
    PRODUCT_PRICE_TIER_REMOVING,
    PRODUCT_PRICE_TIER_REMOVE_ERROR,
    PRODUCT_PRICE_TIER_REMOVE_MODAL_TOGGLE,
} from "./constants";
import { generateReducer } from "../../common/helpers";

const defaultInputs = {
    percentage: "10",
    name: "Tier 1",
};

const initialState = {
    inputs: cloneDeep(defaultInputs),
    showModal: false,
    isSaving: false,
    errors: [],

    removingId: 0,
    isRemoving: false,
    removeErrors: [],
};

const setError = (state, error) => {
    let { errors } = state;

    if (isString(error)) {
        errors.push(error);
    }

    if (isArray(error)) {
        errors = error;
    }

    return { ...state, isSaving: false, errors };
};
const setSaving = state => ({ ...state, isSaving: true, errors: [] });
const resetState = () => initialState;

const setInput = (state, inputs = {}) => {
    return { ...state, inputs: { ...state.inputs, ...inputs } };
};

const toggleModal = (state, showModal) => {
    const newState = {
        ...state,
        showModal,
    };

    // Reset the editor id when hiding the modal so that on we get fresh data for create/edit
    if (showModal === false && state.inputs.id) {
        newState.inputs = cloneDeep(defaultInputs);
    }

    return newState;
};

const toggleRemoveModal = (state, removingId) => ({
    ...state,
    removingId,
});

const setRemoved = state => ({
    ...state,
    isRemoving: false,
    removingId: 0,
    removeErrors: [],
});

const setRemoving = state => ({
    ...state,
    isRemoving: true,
    removeErrors: [],
});

const setRemoveError = (state, removeErrors) => ({
    ...state,
    isRemoving: false,
    removeErrors,
});

const reducers = {
    [PRODUCT_PRICE_TIER_SAVED]: resetState,
    [PRODUCT_PRICE_TIER_SAVING]: setSaving,
    [PRODUCT_PRICE_TIER_INPUT]: setInput,
    [PRODUCT_PRICE_TIER_ERROR]: setError,

    [PRODUCT_PRICE_TIER_REMOVED]: setRemoved,
    [PRODUCT_PRICE_TIER_REMOVING]: setRemoving,
    [PRODUCT_PRICE_TIER_REMOVE_ERROR]: setRemoveError,

    [PRODUCT_PRICE_TIER_MODAL_TOGGLE]: toggleModal,
    [PRODUCT_PRICE_TIER_REMOVE_MODAL_TOGGLE]: toggleRemoveModal,
};

export default generateReducer(reducers, initialState);
