import React, { useState } from "react";
import { isEmpty, pick } from "lodash";
import { Form, Button } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

import CustomerContainerPicker from "../../customer/containers/picker";
import InvoiceComponentCustomerHeader from "../components/customer-header";
import {
    setInvoiceBuilderCustomer,
    resetInvoiceBuilder,
    updateInvoiceBuilderCustomer,
} from "./actions";
import { clearProductSearch } from "../../product/search/actions";
import { resetItemLog } from "../item-log/actions";
import { isCompanyFeatureActive } from "utilities/dist/company/helpers";
import { TieredPricing } from "../../company/feature/helpers";

const InvoiceBuilderContainerCustomer = () => {
    const dispatch = useDispatch();
    const resetInvoice = () => {
        dispatch(resetInvoiceBuilder());
        dispatch(resetItemLog());
        dispatch(clearProductSearch());
    };
    const handleCustomerChange = cust => {
        const changeInvoiceBuilderCustomer = invoice
            ? updateInvoiceBuilderCustomer
            : setInvoiceBuilderCustomer;
        dispatch(changeInvoiceBuilderCustomer(cust));
    };
    const { customer, invoice, order_number, isTieredPricingActive, productPriceTiers } =
        useSelector(({ invoiceBuilder, auth }) => ({
            ...pick(invoiceBuilder, ["customer", "invoice", "order_number"]),
            isTieredPricingActive: isCompanyFeatureActive(auth.user.company, TieredPricing.id),
            productPriceTiers: auth.user.company.productPriceTiers,
        }));

    const [editCustomer, setEditCustomer] = useState(false);

    if (isEmpty(customer) || editCustomer) {
        return (
            <Form>
                <Form.Field>
                    <label data-cy="select_customer">Select Customer</label>
                    <CustomerContainerPicker
                        onSelect={handleCustomerChange}
                        selectedCustomerId={!isEmpty(customer) ? customer.id : null}
                    />
                </Form.Field>
            </Form>
        );
    }

    const customerPriceTier = isTieredPricingActive
        ? productPriceTiers?.find(({ id }) => customer?.product_price_tier_id === id)
        : null;

    return (
        <>
            <InvoiceComponentCustomerHeader customer={customer} priceTier={customerPriceTier} />

            {!invoice && !order_number && (
                <Button
                    compact
                    size="mini"
                    icon="pencil"
                    floated="right"
                    content="Change"
                    labelPosition="left"
                    data-cy="change"
                    onClick={resetInvoice}
                />
            )}
            {invoice && (
                <Button
                    compact
                    size="mini"
                    icon="pencil"
                    floated="right"
                    content="Edit"
                    labelPosition="left"
                    onClick={() => setEditCustomer(true)}
                />
            )}
        </>
    );
};

export default InvoiceBuilderContainerCustomer;
