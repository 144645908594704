import React from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";

import CompanyFeatureContainerList from "../../company/feature/list.container";
import TopNav from "../containers/top-nav";

const Features = () => {
    return (
        <Grid>
            <TopNav
                headerIcon="object group icon"
                headerContent="Feature Extensions"
                subheader="Manage extension features to supercharge your invoicing experience"
            />
            <Grid.Row>
                <Grid.Column mobile={16} tablet={16} className="bp-15">
                    <CompanyFeatureContainerList />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
});

export default connect(mapStateToProps)(Features);
