import { debounce, get, isArray } from "lodash";

import {
    PRODUCT_SEARCH_SELECT,
    PRODUCT_SEARCH_CHANGE_QUERY,
    PRODUCT_SEARCH_SUGGESTIONS_DATA,
    PRODUCT_SEARCH_SUGGESTIONS_ERROR,
    PRODUCT_SEARCH_SUGGESTIONS_LOADING,
    PRODUCT_SEARCH_CLEAR,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";
import { selectInvoiceBuilderProduct } from "../../invoice/builder/actions";
import { transformProductToItem } from "../helpers";
import { isCompanyFeatureActive } from "utilities/dist/company/helpers";
import { TieredPricing } from "../../company/feature/helpers";

const getProductSearchSuggestions = debounce(
    (
        query,
        customerId,
        excludingSourceProductIds,
        distributor,
        isTieredPricingActive,
        customerPriceTier,
        dispatch
    ) => {
        dispatch({
            type: PRODUCT_SEARCH_SUGGESTIONS_LOADING,
        });

        makePrivateApiCall({
            url: "product/suggestions",
            params: { query, customerId, excludingSourceProductIds, distributor },
        })
            .then(res => {
                if (res.data && isArray(res.data)) {
                    if (res.data.length === 1 && /^[0-9]+$/.test(query.trim())) {
                        const product = { ...res.data[0], scanned: true, scannedUpc: query.trim() };
                        dispatch(selectProductSearch(product));
                        dispatch(
                            selectInvoiceBuilderProduct(
                                transformProductToItem(product, {
                                    customerPriceTier,
                                    isTieredPricingActive,
                                })
                            )
                        );
                    }
                    dispatch({
                        type: PRODUCT_SEARCH_SUGGESTIONS_DATA,
                        payload: res.data,
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: PRODUCT_SEARCH_SUGGESTIONS_ERROR,
                    payload: get(err, "response.data.message", null) || err.message,
                });
            });
    },
    300
);

export const changeProductSearchQuery = (query, customerId) => (dispatch, getState) => {
    if (query.length > 2) {
        const { invoiceBuilder, inventoryRestock, auth } = getState();
        const excludingSourceProductIds = Object.values(invoiceBuilder.items).map(
            i => i.product.ydk_product_id || i.product.product_id
        );
        // If no customer id is set, no point looking for tiered pricing since the feature only applies to customer specific pricing
        const isTieredPricingActive = customerId
            ? isCompanyFeatureActive(auth.user?.company || {}, TieredPricing.id)
            : false;
        const customerPriceTier = isTieredPricingActive
            ? invoiceBuilder.customer.productPriceTier
            : null;
        getProductSearchSuggestions(
            query,
            customerId,
            excludingSourceProductIds,
            inventoryRestock.distributor,
            isTieredPricingActive,
            customerPriceTier,
            dispatch
        );
    }

    return dispatch({
        type: PRODUCT_SEARCH_CHANGE_QUERY,
        payload: query,
    });
};

export const selectProductSearch = product => ({
    type: PRODUCT_SEARCH_SELECT,
    payload: product,
});

export const clearProductSearch = product => ({
    type: PRODUCT_SEARCH_CLEAR,
    payload: product,
});
