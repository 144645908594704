import React, { useEffect, useState } from "react";
import { Message } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import { uploadDocuments, loadUploadedDocuments, removeDocument, webCamUpload } from "./actions";
import WebcamUpload from "./webcam/webcam-upload";
import { InvoiceDocumentTable } from "./table.component";

const DocumentContainer = ({ invoiceId }) => {
    const [attemptedLoading, setAttemptedLoading] = useState(false);
    const dispatch = useDispatch();
    const {
        uploadError,
        openWebCam,
        uploadingDocument,
        documents,
        removeError,
        removingDocumentsId,
        isLoading,
        error,
    } = useSelector(({ invoiceDocument }) => invoiceDocument);

    useEffect(() => {
        if (invoiceId && !isLoading && !attemptedLoading) {
            setAttemptedLoading(true);
            dispatch(loadUploadedDocuments(invoiceId));
        }
    }, [invoiceId, isLoading, attemptedLoading, dispatch]);

    const removeDocumentWhenConfirmed = id => dispatch(removeDocument(id));

    const errorMessage = (errorState, errorType) =>
        !isLoading &&
        errorState && (
            <Message error content={errorState} header={`Error ${errorType} Documents`} />
        );

    return (
        <>
            {errorMessage(uploadError, "Uploading")}
            {errorMessage(removeError, "Removing")}
            {errorMessage(error, "Loading")}
            {!!openWebCam && <WebcamUpload />}
            <InvoiceDocumentTable
                isLoading={isLoading}
                documents={documents}
                uploadingDocument={uploadingDocument}
                removingDocumentsId={removingDocumentsId}
                removeDocumentWhenConfirmed={removeDocumentWhenConfirmed}
                handleFileUploadClick={e => {
                    dispatch(uploadDocuments(e.target.files[0], invoiceId));
                }}
                handleCameraUploadClick={() =>
                    dispatch(webCamUpload({ openWebCam: true, invoiceId }))
                }
            />
        </>
    );
};

export default DocumentContainer;
