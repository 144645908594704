import { format } from "date-fns";
import { isArray, isString, pick, cloneDeep } from "lodash";

import {
    PRODUCT_EDITOR_INPUT,
    PRODUCT_EDITOR_ERROR,
    PRODUCT_EDITOR_SAVING,
    PRODUCT_EDITOR_SAVED,
    PRODUCT_EDITOR_PRODUCT_ERROR,
    PRODUCT_EDITOR_PRODUCT_LOADING,
    PRODUCT_EDITOR_PRODUCT_DATA,
    PRODUCT_EDITOR_PRODUCT_RESET,
    PRODUCT_EDITOR_MODAL_TOGGLE,
    PRODUCT_EDITOR_ARCHIVE_LOADING,
    PRODUCT_EDITOR_ARCHIVE_SUCCESS,
    PRODUCT_EDITOR_ARCHIVE_ERROR,
    PRODUCT_EDITOR_DELETE_LOADING,
    PRODUCT_EDITOR_DELETE_SUCCESS,
    PRODUCT_EDITOR_DELETE_ERROR,
    PRODUCT_EDITOR_DISTRIBUTOR_LIST_LOADING,
    PRODUCT_EDITOR_DISTRIBUTOR_LIST_SUCCESS,
    PRODUCT_EDITOR_DISTRIBUTOR_LIST_ERROR,
    PRODUCT_EDITOR_DISTRIBUTOR_LIST_ADD,
} from "../constants";
import { generateReducer } from "../../common/helpers";
import { DateFormat } from "utilities/dist/invoice/constants";
import { getUpdatedDateFromArrayOfObj } from "../helpers";

const defaultInputs = {
    upc: "",
    internal_sku: "",
    product: "",
    product_size: "",
    product_type: "",
    product_price: "0.00",
    last_purchase_price: "0.00",

    effective_date: format(new Date(), DateFormat),

    // For inventory feature only
    distributor: "",
    reorder_level: 0,
    reorder_qty: 0,
    reorder_time_in_days: 0,
};

const initialState = {
    inputs: cloneDeep(defaultInputs),
    showModal: false,
    isSaving: false,
    errors: [],

    product: null,
    productError: null,
    isLoadingProduct: false,

    isDeleting: false,
    isArchiving: false,

    isScanned: false,

    distributorList: [],
    isLoadingDistributorList: false,
    distributorListError: null,
};

const setError = (state, error) => {
    let { errors } = state;

    if (isString(error)) {
        errors.push(error);
    }

    if (isArray(error)) {
        errors = error;
    }

    return { ...state, isSaving: false, errors };
};
const setSaving = state => ({ ...state, isSaving: true });
const resetState = () => initialState;

const setInput = (state, inputs = {}) => {
    return { ...state, inputs: { ...state.inputs, ...inputs } };
};

const setProductLoading = state => ({
    ...state,
    isLoadingProduct: true,
    productError: null,
});
const resetProduct = state => ({
    ...state,
    product: null,
    productError: null,
    errors: [],
    isLoadingProduct: false,
    inputs: cloneDeep(defaultInputs),
});
const setProductData = (state, product) => {
    const inputs = pick(product, Object.keys(defaultInputs));
    inputs.id = product.id;
    inputs.ydk_product_id = product.ydk_product_id;

    if (product.priceChanges?.length) {
        const lastCustomerProductPrice = getUpdatedDateFromArrayOfObj(
            product.priceChanges,
            "created_at"
        );
        inputs.product_price = lastCustomerProductPrice.new_price;
    }

    if (!!product.inventory?.id) {
        inputs.distributor = product.inventory.distributor;
        inputs.reorder_qty = product.inventory.reorder_qty;
        inputs.reorder_level = product.inventory.reorder_level;
        inputs.reorder_time_in_days = product.inventory.reorder_time_in_days;
    }

    return {
        ...state,
        isLoadingProduct: false,
        product,
        inputs,
        productError: null,
    };
};
const setProductError = (state, err) => ({
    ...state,
    isLoadingProduct: false,
    product: null,
    productError: err,
});

const toggleModal = (state, showModal) => ({
    ...state,
    showModal,
});

const setProductArchiveLoading = state => ({ ...state, isArchiving: true });
const setProductArchiveError = state => ({ ...state, isArchiving: false });
const setProductArchiveSuccess = state => ({
    ...state,
    isArchiving: false,
    showModal: false,
});

const setProductDeleteSuccess = state => ({ ...state, isDeleting: false });
const setProductDeleteError = state => ({ ...state, isDeleting: false });
const setProductDeleteLoading = state => ({
    ...state,
    isDeleting: true,
    showModal: false,
});

const setDistributorListSuccess = (state, distributorList = []) => ({
    ...state,
    distributorList,
    isLoadingDistributorList: false,
    distributorListError: null,
});
const setDistributorListError = (state, err) => ({
    ...state,
    isLoadingDistributorList: false,
    distributorListError: err,
});
const setDistributorListLoading = state => ({
    ...state,
    isLoadingDistributorList: true,
});
const addCustomDistributor = (state, distributor) => ({
    ...state,
    distributorList: [...state.distributorList, { distributor }],
});

const reducers = {
    [PRODUCT_EDITOR_PRODUCT_LOADING]: setProductLoading,
    [PRODUCT_EDITOR_PRODUCT_ERROR]: setProductError,
    [PRODUCT_EDITOR_PRODUCT_DATA]: setProductData,
    [PRODUCT_EDITOR_PRODUCT_RESET]: resetProduct,

    [PRODUCT_EDITOR_ARCHIVE_LOADING]: setProductArchiveLoading,
    [PRODUCT_EDITOR_ARCHIVE_SUCCESS]: setProductArchiveSuccess,
    [PRODUCT_EDITOR_ARCHIVE_ERROR]: setProductArchiveError,
    [PRODUCT_EDITOR_DELETE_LOADING]: setProductDeleteLoading,
    [PRODUCT_EDITOR_DELETE_SUCCESS]: setProductDeleteSuccess,
    [PRODUCT_EDITOR_DELETE_ERROR]: setProductDeleteError,

    [PRODUCT_EDITOR_SAVED]: resetState,
    [PRODUCT_EDITOR_SAVING]: setSaving,
    [PRODUCT_EDITOR_INPUT]: setInput,
    [PRODUCT_EDITOR_ERROR]: setError,

    [PRODUCT_EDITOR_MODAL_TOGGLE]: toggleModal,

    [PRODUCT_EDITOR_DISTRIBUTOR_LIST_LOADING]: setDistributorListLoading,
    [PRODUCT_EDITOR_DISTRIBUTOR_LIST_SUCCESS]: setDistributorListSuccess,
    [PRODUCT_EDITOR_DISTRIBUTOR_LIST_ERROR]: setDistributorListError,
    [PRODUCT_EDITOR_DISTRIBUTOR_LIST_ADD]: addCustomDistributor,
};

export default generateReducer(reducers, initialState);
