import { format } from "date-fns";
import { isString, isArray, pick } from "lodash";

import {
    STAMP_ROLL_DATA,
    STAMP_ROLL_ERROR,
    STAMP_ROLL_LOADING,
    STAMP_ROLL_SAVED,
    STAMP_ROLL_SAVING,
    STAMP_ROLL_UPDATED,
    STAMP_ROLL_INPUT_ERROR,
    STAMP_ROLL_INPUT_CHANGE,
} from "../constants";
import { DateFormat } from "utilities/dist/invoice/constants";
import { generateReducer } from "../../common/helpers";

const defaultInputs = {
    to: 0,
    id: "",
    from: 0,
    prefix: "",
    received_at: format(new Date(), DateFormat),
};

const initialState = {
    data: [],
    error: null,
    isLoading: false,

    inputErrors: [],
    isSaving: false,
    inputs: { ...defaultInputs },
};

const setLoading = state => ({ ...state, isLoading: true });
const setError = (state, error) => ({ ...state, isLoading: false, error });
const setData = (state, data) => ({
    ...state,
    data,
    error: null,
    isLoading: false,
});

const setInputError = (state, error) => {
    let { inputErrors } = state;

    if (isString(error)) {
        inputErrors.push(error);
    }

    if (isArray(error)) {
        inputErrors = error;
    }

    return { ...state, isSaving: false, inputErrors };
};
const setSaving = state => ({ ...state, isSaving: true });
const addNew = state => ({
    ...state,
    inputErrors: [],
    isSaving: false,
    inputs: { ...defaultInputs },
});
const update = (state, stamp) => ({
    ...state,
    data: state.data.map(st => (st.id === stamp.id ? { ...st, ...stamp } : st)),
});

const setInput = (state, inputs = {}) => {
    // make sure only the allowed fields are added to inputs by only picking the keys from defaultInputs
    return {
        ...state,
        inputs: { ...state.inputs, ...pick(inputs, Object.keys(defaultInputs)) },
    };
};

const reducers = {
    [STAMP_ROLL_DATA]: setData,
    [STAMP_ROLL_ERROR]: setError,
    [STAMP_ROLL_LOADING]: setLoading,

    [STAMP_ROLL_SAVED]: addNew,
    [STAMP_ROLL_UPDATED]: update,
    [STAMP_ROLL_SAVING]: setSaving,
    [STAMP_ROLL_INPUT_CHANGE]: setInput,
    [STAMP_ROLL_INPUT_ERROR]: setInputError,
};

export default generateReducer(reducers, initialState);
