import pluralize from "pluralize";

export const DateFormat = "dd MMM, yyyy";

export const formatItemWithUnits = ({ data: order }) => {
    if (!order) return null;
    const qtyCount = order.orderItems.reduce((count, { qty }) => count + parseInt(qty), 0);
    return `${pluralize("unit", qtyCount, true)} of ${pluralize(
        "item",
        order.orderItems.length,
        true
    )}`;
};
