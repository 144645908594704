import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AgGridReact } from "ag-grid-react";
import { isEmpty, isEqual, get } from "lodash";
import { Header, Button } from "semantic-ui-react";

import { loadStampGridPage, changeStampGridPage, changeStampGridState } from "./actions";
import StampRollContainerPicker from "../roll/containers/picker";
import GridPageSizePicker from "../../common/components/grid-page-size-picker";
import { InvoiceLinkRenderer } from "../../invoice/grid/cellRenderers.component";

const NoStampsOverlay = () => {
    return <Header>No stamp history found.</Header>;
};

const frameworkComponents = {
    noStampsOverlay: NoStampsOverlay,
    invoiceLinkRenderer: InvoiceLinkRenderer,
};

class DataGrid extends React.Component {
    grid = null;
    gridDataSource = {
        getRows: params => {
            this.props.loadStampGridPage(this.props, params);
        },
    };

    componentWillUnmount() {
        this.grid = null;
    }

    onGridReady = grid => {
        this.grid = grid;

        setTimeout(() => this.grid && this.grid.api.sizeColumnsToFit(), 500);

        // this.grid.api.paginationGoToPage(this.props.currentPage);
        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.setDatasource(this.gridDataSource);

        this.grid.api.addEventListener("sortChanged", () => {
            this.props.changeStampGridState({
                sortModel: this.grid.api.getSortModel(),
            });
        });

        this.grid.api.addEventListener("filterChanged", () => {
            this.props.changeStampGridState({
                filterModel: this.grid.api.getFilterModel(),
            });
        });
    };

    reloadGridData = () => {
        this.grid && this.grid.api.refreshInfiniteCache();
    };

    changePage = () => {
        if (this.grid) {
            const page = this.grid.api.paginationGetCurrentPage() + 1;

            if (page === this.props.currentPage) return;

            this.props.changeStampGridPage(page);
        }
    };

    componentDidUpdate(prevProps) {
        if (!this.grid) return;

        if (prevProps.isLoading && !this.props.isLoading) {
            this.grid.api.hideOverlay();
        }

        if (!prevProps.isLoading && this.props.isLoading) {
            this.grid.api.showLoadingOverlay();
        }

        if (!this.props.isLoading && this.props.stamps.length < 1) {
            this.grid.api.showNoRowsOverlay();
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.grid.api.paginationSetPageSize(this.props.pageSize);
        }

        if (!isEqual(prevProps.filterModel, this.props.filterModel)) {
            this.grid.api.setFilterModel(this.props.filterModel);
        }
    }

    render() {
        const { currentStampRoll, pageSize, filterModel } = this.props;

        return (
            <>
                <div className="clear bm-10">
                    {!isEmpty(currentStampRoll) && (
                        <p className="fl">
                            Received stamps {currentStampRoll.received_at} |{" "}
                            <b>{currentStampRoll.to - currentStampRoll.from + 1}</b> Stamps{" "}
                            <b>
                                {currentStampRoll.prefix || ""}
                                {currentStampRoll.from} - {currentStampRoll.prefix || ""}
                                {currentStampRoll.to}
                            </b>
                        </p>
                    )}

                    <GridPageSizePicker
                        button
                        compact
                        pointing="top"
                        pageSize={pageSize}
                        text={`${pageSize}/page`}
                        className="right floated basic"
                        onChange={this.props.changeStampGridState}
                    />

                    <StampRollContainerPicker
                        button
                        compact
                        clearable
                        pointing="top"
                        className="right floated basic"
                        value={get(filterModel, "roll_id.filter")}
                        onChange={(e, data) => {
                            const filterModel = this.grid.api.getFilterInstance("roll_id");
                            filterModel.setModel(
                                data.value
                                    ? {
                                          type: "equals",
                                          filter: data.value,
                                      }
                                    : null
                            );
                            filterModel.applyModel();
                            this.grid.api.onFilterChanged();
                        }}
                    />

                    <Button
                        size="mini"
                        icon="download"
                        floated="right"
                        content="Export"
                        onClick={() =>
                            this.grid.api.exportDataAsCsv({
                                fileName: `tabc_stamps_${Date.now()}`,
                                // columnKeys: without(shownColumns, 'actions')
                            })
                        }
                    />
                </div>
                <div className="ag-theme-balham bm-10">
                    <AgGridReact
                        pagination
                        rowHeight={50}
                        reactNext={true}
                        floatingFilter={true}
                        rowSelection="single"
                        domLayout="autoHeight"
                        rowModelType="infinite"
                        onGridReady={this.onGridReady}
                        columnDefs={this.props.columnDefs}
                        cacheBlockSize={this.props.pageSize}
                        onPaginationChanged={this.changePage}
                        frameworkComponents={frameworkComponents}
                        noRowsOverlayComponent="noStampsOverlay"
                        defaultColDef={{ resizable: true, sortable: true, filter: true }}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ stampGrid, stampWarning }) => ({
    stamps: stampGrid.stamps,
    rollId: stampGrid.rollId,
    pageSize: stampGrid.pageSize,
    isLoading: stampGrid.isLoading,
    sortModel: stampGrid.sortModel,
    columnDefs: stampGrid.columnDefs,
    filterModel: stampGrid.filterModel,
    currentPage: stampGrid.currentPage,

    currentStampRoll: stampWarning.data.currentRoll,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadStampGridPage,
            changeStampGridState,
            changeStampGridPage: page => dispatch(changeStampGridPage(page)),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DataGrid);
