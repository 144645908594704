import { useMemo } from "react";
import { isBeer } from "../helpers";

const ProductStockStatuses = {
    OK: "OK",
    LOW: "LOW",
    ZERO: "ZERO",
    NEGATIVE: "NEGATIVE",
};

const getProductStockStatus = (inventoryQty, inventoryReorderLevel, qty = 0) => {
    // the value may be 0 in which case, we want to compare against total invoiced item count
    if (inventoryQty === undefined || !qty) return ProductStockStatuses.OK;
    if (inventoryQty === qty) return ProductStockStatuses.ZERO;
    if (inventoryQty < qty) return ProductStockStatuses.NEGATIVE;
    if (inventoryQty - qty <= inventoryReorderLevel) return ProductStockStatuses.LOW;
};

const doesProductTypeMismatchWithInvoice = (totalItems = 0, hasBeer = false, item = {}) => {
    if (!totalItems) return false;
    const addingOthersWithBeer = hasBeer && !isBeer(item);
    const addingBeerWithOthers = !hasBeer && isBeer(item);

    return !!item.product && (addingOthersWithBeer || addingBeerWithOthers);
};

export const useProductEditorErrors = ({
    isInventoryActive,
    item = {},
    hasBeer = false,
    totalItems,
    invoiceId,
    isInvoiceBeer = false,
}) => {
    const { qty: inventoryQty, reorder_level: inventoryReorderLevel } =
        item.product?.inventory || {};
    const productStockStatus = useMemo(() => {
        if (!isInventoryActive) return {};
        return getProductStockStatus(inventoryQty, inventoryReorderLevel, item.qty);
    }, [isInventoryActive, inventoryQty, inventoryReorderLevel, item.qty]);

    //     Only show when editing an invoice and the product type is different from the rest of the invoice
    const showTypeMisMatchError = useMemo(() => {
        return invoiceId && doesProductTypeMismatchWithInvoice(totalItems, hasBeer, item);
    }, [totalItems, hasBeer, item?.product?.product_type]);

    const errors = [];

    if (productStockStatus === ProductStockStatuses.NEGATIVE) {
        errors.push(
            `There are only ${inventoryQty} items of ${item.product?.product}(${item.product?.product_size}) available in stock. Invoicing with ${item.qty} items will result in negative inventory.`
        );
    }

    if (productStockStatus === ProductStockStatuses.ZERO) {
        errors.push(
            `There are only ${inventoryQty} items of ${item.product?.product}(${item.product?.product_size}) available in stock. Invoicing with ${item.qty} items will result in zero inventory.`
        );
    }

    if (productStockStatus === ProductStockStatuses.LOW) {
        errors.push(
            `There are only ${inventoryQty} items of ${item.product?.product}(${
                item.product?.product_size
            }) available in stock. You will need to reorder ${
                item.product?.inventory?.reorder_time_in_days
                    ? item.product?.inventory?.reorder_time_in_days + " days"
                    : "soon"
            }.`
        );
    }

    if (showTypeMisMatchError) {
        errors.push(
            `You can't add ${item.product?.product_type?.toLowerCase()} as it must be on a separate invoice from ${
                isInvoiceBeer ? "beer" : "liquor & wine"
            }.`
        );
    }

    return {
        showLowInStockError: productStockStatus !== ProductStockStatuses.OK,
        showTypeMisMatchError,
        errors,
    };
};
