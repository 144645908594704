import { startOfMonth, subMonths } from "date-fns";

export const dateRanges = [
    {
        name: "This Month",
        from: startOfMonth(new Date()),
        to: new Date(),
    },
    {
        name: "Last Month",
        from: subMonths(startOfMonth(new Date()), 1),
        to: startOfMonth(new Date()),
    },
    {
        name: "Last 3 Months",
        from: subMonths(new Date(), 3),
        to: new Date(),
    },
];

export const dateRangesDropdownOptions = dateRanges.map(range => ({
    key: range.name,
    text: range.name,
    value: range.name,
}));
