import {
    INVOICE_BUILDER_CHANGE_PRICE,
    INVOICE_BUILDER_CHANGE_QTY,
    INVOICE_BUILDER_CLONE,
    INVOICE_BUILDER_SELECT_PRODUCT,
    INVOICE_BUILDER_TOGGLE_TAXABLE,
    ITEM_LOG_ADD_PRODUCT,
    ITEM_LOG_CHANGE_INLINE_EDITOR,
    ITEM_LOG_HIDE_INLINE_EDITOR,
    ITEM_LOG_REMOVE_ITEM,
    ITEM_LOG_SHOW_INLINE_EDITOR,
    RESET_ITEM_LOG,
} from "../constants";
import { generateReducer, parseCurrency } from "../../common/helpers";
import { buildProductIdKey } from "../../product/helpers";
import { isQtyComputable } from "../helpers";
import { isEmpty } from "lodash";
import { computeTaxedTotal } from "utilities/dist/invoice/helpers";

const initialState = {
    items: {},
    editor: {},
    errors: [],
    inlineEditorFields: [],
    inlineEditorItemId: "",
    isSaving: false,
    isLoading: false,
};

const itemLogData = (state, invoice) => {
    const newState = {
        ...state,
        items: {},
        order_number: invoice?.order_number,
    };
    invoice.items.forEach(item => {
        newState.items[buildProductIdKey(item.product, item.ydk_product)] = item;
    });
    return newState;
};

const updateItemInline = (state, { field, value }) => {
    if (state.inlineEditorFields.length < 1) return state;

    const newState = { ...state },
        item = newState.items[state.inlineEditorItemId];
    if (field === "actions") {
        item.rejectionNote = value;
    } else if (field === "price") {
        item.newPrice = parseFloat(value);
    } else if (field === "qty") {
        item.newQty = isQtyComputable(value) ? Number(value) : value;
    } else if (field === "price_change_note") {
        item.priceChangeNote = value;
    } else if (field === "qty_change_note") {
        item.qtyChangeNote = value;
    }

    newState.items[state.inlineEditorItemId] = item;

    return newState;
};
const showInlineEditor = (state, { item, inlineEditorFields }) => {
    return {
        ...state,
        inlineEditorFields,
        inlineEditorItemId: buildProductIdKey(item.product, item.ydk_product),
    };
};

const addProduct = state => {
    const item = state.editor;
    const key = buildProductIdKey(item.product);
    const items = { ...state.items, [key]: item };

    let newState = {
        ...state,
        items,
        editor: {},
    };

    return newState;
};

const selectProduct = (state, product) => {
    let newState = { ...state, editor: {} };

    if (!isEmpty(product)) {
        newState.editor = {
            product,
            qty: product.qty,
            price: product.price,
            taxable: !!product.taxable,
            total: product.qty * product.price,
            scanned: !!product.scanned,
            isAdded: true,
        };

        newState.editor.total += computeTaxedTotal(newState.editor);
    }

    return newState;
};

const removeItem = (state, item) => {
    const buildItem = buildProductIdKey(item.product, item.ydk_product);
    const newState = { ...state },
        removedItem = newState.items[state.inlineEditorItemId];
    if (state.inlineEditorItemId === buildItem) {
        removedItem.isRejected = true;
    }

    newState.items[state.inlineEditorItemId] = removedItem;

    return newState;
};

const changeItemPrice = (state, { price, id }) => {
    const newState = { ...state };
    let product = newState.editor;

    if (id) {
        product = { ...newState.products[id] };
    }

    product.price = parseFloat(price);
    product.total = parseCurrency(product.qty * product.price);
    product.total += computeTaxedTotal(product);

    if (id) {
        newState.products[id] = product;
    }

    return newState;
};

const changeItemQty = (state, { qty, id }) => {
    const newState = { ...state };
    let product = newState.editor;

    if (id) {
        product = { ...newState.products[id] };
    }

    product.qty = isQtyComputable(qty) ? Number(qty) : qty;
    const multipliableQty = isQtyComputable(qty) ? product.qty : 0;
    product.total = parseCurrency(product.price * multipliableQty);
    product.total += computeTaxedTotal(product);

    if (id) {
        newState.products[id] = product;
    }

    return newState;
};

const toggleItemTaxable = (state, id) => {
    const newState = { ...state };
    let product = newState.editor;

    if (id) {
        product = { ...newState.products[id] };
    }

    product.taxable = !product.taxable;
    product.total = parseCurrency(product.price * product.qty);
    product.total += computeTaxedTotal(product);

    if (id) {
        newState.products[id] = product;
    }

    return newState;
};

const hideInlineEditor = (state, field) => {
    const newState = {
        ...state,
        inlineEditorFields: state.inlineEditorFields.filter(f => f !== field),
    };

    if (newState.inlineEditorFields.length < 1) {
        newState.inlineEditorItemId = "";
    }

    return newState;
};

const reset = () => ({
    ...initialState,
});

const reducers = {
    [INVOICE_BUILDER_CLONE]: itemLogData,
    [ITEM_LOG_SHOW_INLINE_EDITOR]: showInlineEditor,
    [INVOICE_BUILDER_SELECT_PRODUCT]: selectProduct,
    [INVOICE_BUILDER_TOGGLE_TAXABLE]: toggleItemTaxable,
    [INVOICE_BUILDER_CHANGE_PRICE]: changeItemPrice,
    [INVOICE_BUILDER_CHANGE_QTY]: changeItemQty,
    [ITEM_LOG_CHANGE_INLINE_EDITOR]: updateItemInline,
    [ITEM_LOG_HIDE_INLINE_EDITOR]: hideInlineEditor,
    [ITEM_LOG_REMOVE_ITEM]: removeItem,
    [ITEM_LOG_ADD_PRODUCT]: addProduct,
    [RESET_ITEM_LOG]: reset,
};

export default generateReducer(reducers, initialState);
