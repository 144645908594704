import React from "react";

export const paidFeatureIds = ["order", "bottle_pos", "inventory", "clover", "tiered_pricing"];

const ContactToEnableFeature = () => (
    <span className="content">
        Please contact the yDrink team to activate this feature by sending us an email at{" "}
        <a href="mailto:dev@ydrink.net">dev@ydrink.net</a> or give us a call{" "}
        <a href="tel:1-888-yDrink-1">1-888-yDrink-1</a>
    </span>
);

export const PaymentFeatureDetails = {
    id: "payment",
    pricing: "Free",
    comingSoon: false,
    title: "Payment Management",
    description: "Keep track of payments on invoices. Auto track payment due dates and much more.",
};

export const OrderFeature = {
    id: "order",
    pricing: "Paid",
    comingSoon: false,
    title: "Online Order System",
    description:
        "Give your customers a seamless online ordering system and make your life easier by creating invoices directly from the orders.",
    request_access: {
        description1: ContactToEnableFeature,
        description2: () => (
            <span className="content">
                Do you really want to de-active the establishment app ? <br /> Once you de-activate
                to activate the feature again you have to contact with the yDrink team.
            </span>
        ),
    },
};

export const TieredPricing = {
    id: "tiered_pricing",
    pricing: "Paid",
    comingSoon: false,
    title: "Tiered Pricing",
    description: "Use tiered pricing to easily manage product pricing for customer groups",
    request_access: {
        description1: ContactToEnableFeature,
        description2: () => (
            <span className="content">
                Do you really want to de-active tiered pricing ? <br /> Once you de-activate to
                activate the feature again you have to contact with the yDrink team.
            </span>
        ),
    },
};

export const InternalSkuFeature = {
    id: "internal_sku",
    pricing: "Free",
    comingSoon: false,
    title: "Internal Sku",
    description: "Activate internal SKU to maintain you own product.",
};

export const EmailFeatureDetails = {
    id: "email",
    pricing: "Free",
    comingSoon: false,
    title: "Email Invoice",
    description: "Send invoices to the customer via email.",
};

export const CloverFeatureDetails = {
    id: "clover",
    pricing: "Paid",
    comingSoon: false,
    title: "Clover Product",
    description: "Sync your product inventory with clover.",
    request_access: {
        description1: ContactToEnableFeature,
        description2: () => (
            <span className="content">
                Do you really want to de-active the bottle clover integration? <br />
                Once you de-activate to activate the feature again you have to contact with the
                yDrink team.
            </span>
        ),
    },
};

export const BottlePosFeatureDetails = {
    id: "bottle_pos",
    pricing: "Paid",
    comingSoon: false,
    title: "Bottle POS integration",
    description: "Sync your product inventory with bottle POS.",
    request_access: {
        description1: ContactToEnableFeature,
        description2: () => (
            <span className="content">
                Do you really want to de-active the bottle POS sync? <br />
                Once you de-activate to activate the feature again you have to contact with the
                yDrink team.
            </span>
        ),
    },
};

export const InventoryFeatureDetails = {
    id: "inventory",
    pricing: "Paid",
    comingSoon: false,
    title: "Inventory Management",
    description: "Manage and track your inventory.",
    request_access: {
        description1: ContactToEnableFeature,
        description2: () => (
            <span className="content">
                Do you really want to de-active the bottle POS sync? <br />
                Once you de-activate to activate the feature again you have to contact with the
                yDrink team.
            </span>
        ),
    },
};

export const features = [
    PaymentFeatureDetails,
    OrderFeature,
    InternalSkuFeature,
    EmailFeatureDetails,
    CloverFeatureDetails,
    BottlePosFeatureDetails,
    InventoryFeatureDetails,
    TieredPricing,
];

export const isFeatureActive = (company, feature) => {
    return (
        company &&
        company.companyFeatures &&
        !!company.companyFeatures.find(f => f.name === feature.id && !!f.active)
    );
};
