import {
    COMPANY_STATS_DATA,
    COMPANY_STATS_ERROR,
    COMPANY_STATS_LOADING,
    COMPANY_STATS_CHANGE_DATERANGE,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";
import { dateRanges } from "./helpers";

export const setCompanyStatDateRange = range => ({
    type: COMPANY_STATS_CHANGE_DATERANGE,
    payload: range,
});

export const loadCompanyStats = dateRange => dispatch => {
    dispatch({
        type: COMPANY_STATS_LOADING,
    });

    const range = dateRanges.find(dr => dr.name === dateRange);

    return makePrivateApiCall({
        url: "/company/stats",
        method: "get",
        params: range,
    })
        .then(res => {
            if (res.data) {
                dispatch({
                    type: COMPANY_STATS_DATA,
                    payload: res.data,
                });
            }
        })
        .catch(err => {
            dispatch({
                type: COMPANY_STATS_ERROR,
                payload: err.message,
            });
        });
};
