import { debounce, get } from "lodash";

import {
    COMPANY_QUICK_SEARCH_CHANGE_QUERY,
    COMPANY_QUICK_SEARCH_SUGGESTIONS_DATA,
    COMPANY_QUICK_SEARCH_SUGGESTIONS_ERROR,
    COMPANY_QUICK_SEARCH_SUGGESTIONS_LOADING,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";

const getQuickSearchSuggestions = debounce((query, dispatch) => {
    dispatch({
        type: COMPANY_QUICK_SEARCH_SUGGESTIONS_LOADING,
    });

    makePrivateApiCall({
        url: "company/quick-search",
        params: { query },
    })
        .then(res => {
            if (res.data) {
                dispatch({
                    type: COMPANY_QUICK_SEARCH_SUGGESTIONS_DATA,
                    payload: res.data,
                });
            }
        })
        .catch(err => {
            dispatch({
                type: COMPANY_QUICK_SEARCH_SUGGESTIONS_ERROR,
                payload: get(err, "response.data.message", null) || err.message,
            });
        });
}, 300);

export const changeQuickSearchQuery = query => dispatch => {
    if (query.length > 2) {
        getQuickSearchSuggestions(query, dispatch);
    }

    return dispatch({
        type: COMPANY_QUICK_SEARCH_CHANGE_QUERY,
        payload: query,
    });
};
