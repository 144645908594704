import React, { useState, useEffect, useCallback } from "react";
import { makePrivateApiCall } from "utilities/dist/api";
import { Segment, Header, Table } from "semantic-ui-react";

export const BottlePosContainerSyncLog = ({ syncId }) => {
    const [syncLog, setSyncLog] = useState({ isLoading: true, data: [], error: "" });
    //     Since we want to keep the sync endpoint open for manual use, companyId needs to be manually injected into the sync request.

    const syncSyncLog = useCallback(() => {
        makePrivateApiCall({ url: `bottle-pos/sync-log/${syncId}`, method: "GET" })
            .then(res => {
                setSyncLog({ isLoading: false, data: res.data, error: "" });
            })
            .catch(err => {
                setSyncLog({ isLoading: false, data: [], error: err.message });
            });
    }, []);

    useEffect(() => {
        syncSyncLog();
    }, []);

    return (
        <Segment loading={syncLog.isLoading}>
            <Header content="Sync Log" />

            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Time</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Data</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {syncLog.data?.map(log => {
                        const data = JSON.parse(log.error_log || log.success_log);
                        return (
                            <Table.Row
                                key={`sync_log_${log.id}_${log.sync_id}`}
                                error={!!log.error_log}
                            >
                                <Table.Cell>{log.createdAt}</Table.Cell>
                                <Table.Cell>
                                    {log.success_log === "sync-invoices" ? "Invoice" : "Product"}
                                </Table.Cell>
                                <Table.Cell>
                                    {data?.info?.message || data?.message}
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </Segment>
    );
};
