export const CUSTOMER_EDITOR_CUSTOMER_LOADING = "CUSTOMER_EDITOR_CUSTOMER_LOADING";
export const CUSTOMER_EDITOR_CUSTOMER_RESET = "CUSTOMER_EDITOR_CUSTOMER_RESET";
export const CUSTOMER_EDITOR_CUSTOMER_ERROR = "CUSTOMER_EDITOR_CUSTOMER_ERROR";
export const CUSTOMER_EDITOR_CUSTOMER_DATA = "CUSTOMER_EDITOR_CUSTOMER_DATA";
export const CUSTOMER_EDITOR_CONFIRMING = "CUSTOMER_EDITOR_CONFIRMING";
export const CUSTOMER_EDITOR_SAVING = "CUSTOMER_EDITOR_SAVING";
export const CUSTOMER_EDITOR_INPUT = "CUSTOMER_EDITOR_INPUT";
export const CUSTOMER_EDITOR_ERROR = "CUSTOMER_EDITOR_ERROR";
export const CUSTOMER_EDITOR_SAVED = "CUSTOMER_EDITOR_SAVED";
export const CUSTOMER_EDITOR_REMOVING = "CUSTOMER_EDITOR_REMOVING";
export const CUSTOMER_EDITOR_REMOVED = "CUSTOMER_EDITOR_REMOVED";
export const CUSTOMER_EDITOR_ERROR_REMOVING = "CUSTOMER_EDITOR_ERROR_REMOVING";

export const CUSTOMER_LIST_LOADING = "CUSTOMER_LIST_LOADING";
export const CUSTOMER_LIST_DATA = "CUSTOMER_LIST_DATA";
export const CUSTOMER_LIST_ERROR = "CUSTOMER_LIST_ERROR";

export const CUSTOMER_GRID_STATE_CHANGE = "CUSTOMER_GRID_STATE_CHANGE";
