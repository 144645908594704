import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
    STAMP_LAST_DATA,
    STAMP_LAST_ERROR,
    STAMP_LAST_LOADING,
    STAMP_HIDE_LOW_WARNING,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    error: null,
    isLoading: false,
    hideWarning: false,
    data: {
        availableStampCount: null,
        firstAvailableStamp: null,
        totalStampCount: null,
        currentRoll: null,
    },
};

const setLoading = state => ({ ...state, isLoading: true });
const setHide = state => ({ ...state, hideWarning: true });
const setError = (state, error) => ({ ...state, isLoading: false, error });
const setData = (state, data) => ({
    ...state,
    data,
    isLoading: false,
    error: null,
});

const reducers = {
    [STAMP_LAST_DATA]: setData,
    [STAMP_LAST_ERROR]: setError,
    [STAMP_LAST_LOADING]: setLoading,
    [STAMP_HIDE_LOW_WARNING]: setHide,
};

const stampWarning = generateReducer(reducers, initialState);

export default persistReducer(
    {
        key: "stampWarning",
        storage,
        whitelist: ["hideWarning"],
    },
    stampWarning
);
