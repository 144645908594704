import { get } from "lodash";

import {
    CUSTOMER_EDITOR_INPUT,
    CUSTOMER_EDITOR_ERROR,
    CUSTOMER_EDITOR_SAVING,
    CUSTOMER_EDITOR_CONFIRMING,
    CUSTOMER_EDITOR_SAVED,
    CUSTOMER_EDITOR_CUSTOMER_LOADING,
    CUSTOMER_EDITOR_CUSTOMER_DATA,
    CUSTOMER_EDITOR_CUSTOMER_ERROR,
    CUSTOMER_EDITOR_CUSTOMER_RESET,
    CUSTOMER_EDITOR_REMOVING,
    CUSTOMER_EDITOR_REMOVED,
    CUSTOMER_EDITOR_ERROR_REMOVING,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";
import { trackCustomerCreateEvent, trackCustomerRemoveEvent } from "../../common/analytics";

export const requestSaveCustomer =
    (customer = {}) =>
    dispatch => {
        return dispatch({
            type: CUSTOMER_EDITOR_CONFIRMING,
            payload: customer,
        });
    };

export const saveCustomer =
    (customer = {}, onComplete) =>
    dispatch => {
        dispatch({
            type: CUSTOMER_EDITOR_SAVING,
            payload: true,
        });

        const request = {
            url: `/customer`,
            method: "post",
            data: customer,
        };

        if (customer.id) {
            request.url = `/customer/${customer.id}`;
            request.method = "put";
        }

        trackCustomerCreateEvent(customer, {});

        makePrivateApiCall(request)
            .then(res => {
                trackCustomerCreateEvent(customer, { success: true });
                if (res.data) {
                    dispatch({ type: CUSTOMER_EDITOR_SAVED, payload: res.data });
                    onComplete(res.data, { isUpdate: !!customer.id });
                }
            })
            .catch(err => {
                trackCustomerCreateEvent(customer, { error: true });
                dispatch(setCustomerEditorConfirming(false));

                let messages = [];
                const data = get(err, "response.data", null);

                if (!data) {
                    onComplete(null);
                    messages.push(err.message);
                } else if (data.details) {
                    onComplete(data.details);
                    data.details.forEach(({ message }) => messages.push(message));
                } else if (data.message) {
                    onComplete(null);
                    messages.push(data.message);
                }

                dispatch(setCustomerEditorError(messages));
            });
    };

export const setCustomerEditorInput =
    (inputs = {}) =>
    dispatch => {
        return dispatch({
            type: CUSTOMER_EDITOR_INPUT,
            payload: inputs,
        });
    };

export const setCustomerEditorConfirming =
    (confirming = false) =>
    dispatch => {
        return dispatch({
            type: CUSTOMER_EDITOR_CONFIRMING,
            payload: !!confirming,
        });
    };

export const setCustomerEditorError = error => dispatch => {
    return dispatch({
        type: CUSTOMER_EDITOR_ERROR,
        payload: error,
    });
};

export const setLoadingCustomerForEdit = payload => dispatch =>
    dispatch({
        type: CUSTOMER_EDITOR_CUSTOMER_LOADING,
        payload,
    });

export const loadCustomerForEdit = customerId => dispatch => {
    dispatch(setLoadingCustomerForEdit(true));

    makePrivateApiCall({
        method: "get",
        url: `/customer/${customerId}`,
    })
        .then(res => {
            if (res.data) {
                dispatch({
                    type: CUSTOMER_EDITOR_CUSTOMER_DATA,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: CUSTOMER_EDITOR_CUSTOMER_ERROR,
                    payload: "Customer Not Found",
                });
            }
        })
        .catch(err => {
            dispatch({
                type: CUSTOMER_EDITOR_CUSTOMER_ERROR,
                payload: err.message,
            });
        });
};

export const resetEditingCustomer = () => dispatch =>
    dispatch({
        type: CUSTOMER_EDITOR_CUSTOMER_RESET,
    });

export const removeCustomer = (customerId, onComplete) => dispatch => {
    dispatch({
        type: CUSTOMER_EDITOR_REMOVING,
        payload: customerId,
    });

    const request = {
        url: `/customer/${customerId}`,
        method: "delete",
    };

    trackCustomerRemoveEvent(customerId);

    makePrivateApiCall(request)
        .then(res => {
            trackCustomerRemoveEvent(customerId, { success: true });
            dispatch({ type: CUSTOMER_EDITOR_REMOVED, payload: res.data });
            onComplete(res.data);
        })
        .catch(err => {
            trackCustomerRemoveEvent(customerId, { error: true });

            let messages = [];
            const data = get(err, "response.data", null);

            if (!data) {
                messages.push(err.message);
            } else if (data.details) {
                data.details.forEach(({ message }) => messages.push(message));
            } else if (data.message) {
                messages.push(data.message);
            }

            dispatch({ type: CUSTOMER_EDITOR_ERROR_REMOVING, payload: messages });
        });
};
