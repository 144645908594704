import { toast } from "react-semantic-toasts";

import {
    COMPANY_NOTIFICATION_TOGGLE_DATA,
    COMPANY_NOTIFICATION_TOGGLE_ERROR,
    COMPANY_NOTIFICATION_DATA,
    COMPANY_NOTIFICATION_ERROR,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";
import { setAuthUser } from "utilities/dist/auth/helpers";

export const loadCompanyNotification = () => dispatch => {
    return makePrivateApiCall({
        url: "/company/notification",
    })
        .then(res => {
            dispatch({
                type: COMPANY_NOTIFICATION_DATA,
                payload: res.data.notification,
            });
        })
        .catch(err => {
            dispatch({
                type: COMPANY_NOTIFICATION_ERROR,
                payload: err.message,
            });
        });
};

export const toggleCompanyNotification =
    ({ notifications }) =>
    dispatch => {
        return makePrivateApiCall({
            url: `/company/notification`,
            method: "put",
            data: { notifications },
        })
            .then(res => {
                if (res.data) {
                    dispatch({
                        type: COMPANY_NOTIFICATION_TOGGLE_DATA,
                        payload: res.data.notification,
                    });
                    toast({
                        icon: "bell outline",
                        type: "success",
                        title: `Notification changes successfully`,
                        description: `Preferences saved.`,
                    });
                    setAuthUser(res.data.notification.user);
                }
            })
            .catch(err => {
                dispatch({
                    type: COMPANY_NOTIFICATION_TOGGLE_ERROR,
                    payload: err.message,
                });
                toast({
                    icon: "bell outline",
                    type: "error",
                    title: `Notification did not changes`,
                    description: `Something went wrong`,
                });
            });
    };
