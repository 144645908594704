import {
    AUTH_SIGNIN_REQUEST,
    AUTH_SIGNIN_SUCCESS,
    AUTH_SIGNIN_INPUT_CHANGE,
    AUTH_SIGNIN_INPUT_ERROR,
    AUTH_SIGNUP_REQUEST,
    AUTH_SIGNUP_SUCCESS,
    AUTH_SIGNUP_INPUT_CHANGE,
    AUTH_SIGNUP_INPUT_ERROR,
    AUTH_RESET_INPUT_CHANGE,
    AUTH_TOGGLE_PASSWORD_FIELD,
    AUTH_SIGNOUT,
    AUTH_EXPIRE,
    AUTH_PROFILE_UPDATING,
    AUTH_PROFILE_UPDATED,
    AUTH_PROFILE_UPDATE_ERROR,
} from "../constants";
import { COMPANY_EDITOR_SAVED, COMPANY_FEATURE_TOGGLE_DATA } from "../../company/constants";
import {
    PRODUCT_PRICE_TIER_REMOVED,
    PRODUCT_PRICE_TIER_SAVED,
} from "../../product/price-tier/constants";

const initialState = {
    signinErrors: null,
    signupErrors: null,
    signingIn: true,
    signingUp: false,
    showPassword: false,
    resetInputs: {
        email: "",
    },
    signinInputs: {
        email: "",
        password: "",
    },
    signupInputs: {
        name: "",
        title: "",
        email: "",
        password: "",
        confirm_password: "",
    },
    user: null,
    settings: {},
    expired: false,
    estAppActive: "",

    profileUpdating: false,
    profileUpdateErrors: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_SIGNIN_REQUEST:
            return {
                ...state,
                signingIn: true,
                signinErrors: null,
            };

        case AUTH_EXPIRE:
            return {
                ...state,
                expired: true,
            };

        case AUTH_TOGGLE_PASSWORD_FIELD:
            return {
                ...state,
                showPassword: !state.showPassword,
            };

        case AUTH_SIGNIN_INPUT_CHANGE:
            return {
                ...state,
                signinInputs: { ...state.signinInputs, ...action.payload },
            };

        case AUTH_SIGNIN_INPUT_ERROR:
            return {
                ...state,
                signingIn: false,
                signinErrors: action.payload,
            };

        case AUTH_SIGNIN_SUCCESS:
            return {
                ...state,
                expired: false,
                signingIn: false,
                signinErrors: null,
                user: action.payload,
            };

        case AUTH_SIGNUP_REQUEST:
            return {
                ...state,
                signingUp: true,
                signupErrors: null,
            };

        case AUTH_SIGNUP_INPUT_CHANGE:
            return {
                ...state,
                signupInputs: { ...state.signupInputs, ...action.payload },
            };

        case AUTH_SIGNUP_INPUT_ERROR:
            return {
                ...state,
                signingUp: false,
                signupErrors: action.payload,
            };

        case AUTH_RESET_INPUT_CHANGE:
            return {
                ...state,
                resetInputs: { ...state.resetInputs, ...action.payload },
            };

        case AUTH_SIGNUP_SUCCESS:
            return {
                ...state,
                signingUp: false,
                signupErrors: null,
                user: action.payload,
            };

        case AUTH_SIGNOUT:
            return {
                ...initialState,
                signingIn: false,
                signingUp: false,
            };

        case AUTH_PROFILE_UPDATING:
            return {
                ...state,
                profileUpdating: action.payload,
            };

        case AUTH_PROFILE_UPDATED:
            return {
                ...state,
                user: action.payload,
                profileUpdating: false,
            };

        case AUTH_PROFILE_UPDATE_ERROR:
            return {
                ...state,
                profileUpdating: false,
                profileUpdateErrors: action.payload,
            };

        case COMPANY_EDITOR_SAVED:
            return {
                ...state,
                user: { ...state.user, company: action.payload },
            };

        case PRODUCT_PRICE_TIER_REMOVED:
            // eslint-disable-next-line no-case-declarations
            const userWithFilteredProductPriceTier = { ...state.user };

            userWithFilteredProductPriceTier.company.productPriceTiers =
                userWithFilteredProductPriceTier.company.productPriceTiers.filter(
                    ({ id }) => id !== action.payload
                );

            return {
                ...state,
                user: userWithFilteredProductPriceTier,
            };

        case PRODUCT_PRICE_TIER_SAVED:
            // eslint-disable-next-line no-case-declarations
            const userWithSavedPriceTier = { ...state.user };

            // eslint-disable-next-line no-case-declarations
            const existingPriceTierIndex =
                userWithSavedPriceTier.company.productPriceTiers.findIndex(
                    ({ id }) => id === action.payload.id
                );

            if (existingPriceTierIndex >= 0) {
                userWithSavedPriceTier.company.productPriceTiers[existingPriceTierIndex] =
                    action.payload;
            } else {
                userWithSavedPriceTier.company.productPriceTiers.push(action.payload);
            }

            return {
                ...state,
                user: userWithSavedPriceTier,
            };

        case COMPANY_FEATURE_TOGGLE_DATA: // eslint-disable-next-line no-case-declarations
            const user = { ...state.user };
            // eslint-disable-next-line no-case-declarations
            let replaced = false;

            user.company.companyFeatures = user.company.companyFeatures.map(f => {
                if (f.name === action.payload.feature.name) {
                    replaced = true;
                    return action.payload.feature;
                }

                return f;
            });

            if (!replaced) {
                user.company.companyFeatures.push(action.payload.feature);
            }

            return {
                ...state,
                user,
            };

        default:
            return state;
    }
};
