import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Grid, Menu } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import { isFeatureActive, OrderFeature } from "../../company/feature/helpers";
import SendUserInvitationModal from "../components/invitation-modal";
import SingleUserComponent from "../components/single-user";
import CustomerUserContainerGrid from "../containers/grid";

const CustomerUserHomePage = () => {
    const [showInvitationalModal, setShowInvitationalModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const gridRef = useRef(null);

    const { company, customerUserList, companyFeatures } = useSelector(
        ({ companyEditor, customerUserList, auth }) => ({
            company: companyEditor,
            customerUserList: customerUserList,
            companyFeatures: auth.user.company,
        })
    );
    const { customerData } = customerUserList || {};
    const subHeader = `List of ${customerData?.name ? `${customerData?.name}'s` : ""} users`;
    const hasOrderFeature = isFeatureActive(companyFeatures, OrderFeature);

    return (
        <Grid>
            <TopNav
                headerIcon="users"
                subheader={subHeader}
                headerContent="Customer Users"
                extraButtons={() => (
                    <>
                        {!!hasOrderFeature && (
                            <Menu.Item
                                onClick={() => setShowInvitationalModal(true)}
                                icon="send"
                                name="Invite user"
                                data-cy="invite"
                            />
                        )}
                    </>
                )}
            />
            <SendUserInvitationModal
                showInvitationalModal={showInvitationalModal}
                setShowInvitationalModal={setShowInvitationalModal}
                company={company}
                customer={customerData}
            />

            <Grid.Column mobile={16} tablet={16} computer={12}>
                <CustomerUserContainerGrid
                    showInvitationalModal={showInvitationalModal}
                    setShowInvitationalModal={setShowInvitationalModal}
                    tabc_id={customerData?.tabc_id}
                    ref={gridRef}
                    onRowSelect={data => setSelectedUser(data)}
                />
            </Grid.Column>

            {!!selectedUser && (
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <SingleUserComponent
                        user={selectedUser}
                        setSelectedUser={setSelectedUser}
                        customerData={customerData}
                        company={company}
                    />
                </Grid.Column>
            )}
        </Grid>
    );
};

export default CustomerUserHomePage;
