import { ALL_CUSTOMER_USER_GRID_STATE_CHANGE } from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    pageSize: 25,
    currentPage: 0,

    sortModel: [],
    filterModel: {},
};

const changeUserGridState = (state, payload) => ({ ...state, ...payload });

const reducers = {
    [ALL_CUSTOMER_USER_GRID_STATE_CHANGE]: changeUserGridState,
};

export default generateReducer(reducers, initialState);
