import React from "react";
import { format } from "date-fns";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Header, Icon } from "semantic-ui-react";

import { DateFormat } from "utilities/dist/invoice/constants";
import { changeQuickSearchQuery } from "./actions";
import { parseServerTimestampForClient } from "../../common/helpers";
import { changeInvoiceGridState } from "../../invoice/grid/actions";
import { formatAmount } from "utilities/dist/helpers";

const CompanyQuickSearchContainer = () => {
    const { suggestions, inputQuery, isLoading, error } = useSelector(
        ({ companyQuickSearch }) => companyQuickSearch
    );

    const dispatch = useDispatch();
    const handleChange = (e, data) => {
        if (data.value.indexOf("/customer") === 0) {
            dispatch(
                changeInvoiceGridState({
                    filterModel: {
                        customer: {
                            filter: data.value.replace("/customer/", ""),
                            filterType: "text",
                            type: "equals",
                        },
                    },
                })
            );
            dispatch(push("/invoice"));
        } else {
            dispatch(push(data.value));
        }
    };
    const handleSearchChange = (e, { searchQuery }) =>
        dispatch(changeQuickSearchQuery(searchQuery));

    const invoiceOptions = suggestions.invoices.map(inv => {
        return {
            value: `/invoice/${inv.number}`,
            key: `quick_suggestion_invoice_${inv.id}`,
            text: `${inv.number}`,
            content: (
                <Header as="h4">
                    <Icon name="newspaper" />
                    <Header.Content>
                        #{inv.number}
                        {parseInt(inv.beer) > 0 || inv.beer === true ? "B" : ""}
                        <Header.Subheader>{inv.customer.name}</Header.Subheader>
                        <Header.Subheader>
                            {format(parseServerTimestampForClient(inv.invoiced_at), DateFormat)} |
                            Total {formatAmount(inv.total)}
                        </Header.Subheader>
                    </Header.Content>
                </Header>
            ),
        };
    });

    const customerOptions = suggestions.customers.map(cust => {
        return {
            value: `/customer/${cust.name}`,
            key: `quick_suggestion_customer_${cust.id}`,
            text: `${cust.name} ${cust.address} ${cust.city}`,
            content: (
                <Header as="h4">
                    <Icon name="users" />
                    <Header.Content>
                        {cust.name}
                        <Header.Subheader>{cust.address}</Header.Subheader>
                        <Header.Subheader>
                            {[cust.city, cust.state, cust.zip].join(", ")}
                        </Header.Subheader>
                    </Header.Content>
                </Header>
            ),
        };
    });

    return (
        <div>
            <Dropdown
                fluid
                search
                selection
                error={!!error}
                minCharacters={3}
                autoComplete="off"
                loading={isLoading}
                selectOnBlur={false}
                onChange={handleChange}
                searchQuery={inputQuery}
                onSearchChange={handleSearchChange}
                placeholder={`Find Invoice/Customer...`}
                icon={inputQuery.length > 2 ? "dropdown" : null}
                options={[...customerOptions, ...invoiceOptions]}
                noResultsMessage={
                    inputQuery.length > 2 ? `Sorry, no results found for "${inputQuery}"` : null
                }
            />
        </div>
    );
};

export default CompanyQuickSearchContainer;
