import { APP_API_CALL_ERROR } from "../constants";

export const apiCallError = err => dispatch => {
    // eslint-disable-next-line no-console
    console.log("api call resulted in error", err);
    dispatch({
        type: APP_API_CALL_ERROR,
        payload: err.message,
    });
};
