import {
    INVOICE_PAYMENT_GRID_DATA,
    INVOICE_PAYMENT_GRID_ERROR,
    INVOICE_PAYMENT_GRID_LOADING,
    INVOICE_PAYMENT_GRID_PAGE_CHANGE,
    INVOICE_PAYMENT_GRID_STATE_CHANGE,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    error: null,
    payments: [],
    isLoading: false,
    currentPage: 1,
    lastRow: null,
    pageSize: 25,
    totalAmount: 0,
    sortModel: [
        {
            colId: "received_at",
            sort: "desc",
        },
    ],
    filterModel: {},
};

const setGridData = (state, { payments, lastRow }) => ({
    ...state,
    error: null,
    isLoading: false,
    lastRow,
    payments,
    totalAmount: payments.reduce((total, payment) => payment.amount + total, 0),
});

const changeGridPage = (state, currentPage) => ({ ...state, currentPage });
const changeGridState = (state, payload) => ({ ...state, ...payload });
const setGridLoading = state => ({ ...state, isLoading: true });
const setGridError = (state, error) => ({ ...state, isLoading: false, error });

const reducers = {
    [INVOICE_PAYMENT_GRID_DATA]: setGridData,
    [INVOICE_PAYMENT_GRID_ERROR]: setGridError,
    [INVOICE_PAYMENT_GRID_LOADING]: setGridLoading,

    [INVOICE_PAYMENT_GRID_PAGE_CHANGE]: changeGridPage,
    [INVOICE_PAYMENT_GRID_STATE_CHANGE]: changeGridState,
};

export default generateReducer(reducers, initialState);
