import { get } from "lodash";

import {
    STAMP_ROLL_SAVING,
    STAMP_ROLL_SAVED,
    STAMP_ROLL_INPUT_CHANGE,
    STAMP_ROLL_INPUT_ERROR,
    STAMP_ROLL_LOADING,
    STAMP_ROLL_DATA,
    STAMP_ROLL_ERROR,
    STAMP_ROLL_UPDATED,
} from "../constants";
import { loadLastStamp } from "../warning/actions";
import { makePrivateApiCall } from "utilities/dist/api";
import { trackSaveStampRollEvent } from "../../common/analytics";

export const saveStamp =
    (stamp = {}) =>
    dispatch => {
        trackSaveStampRollEvent();
        dispatch({
            type: STAMP_ROLL_SAVING,
            payload: true,
        });

        const request = {
            url: `/stamp/roll`,
            method: "post",
            data: stamp,
        };

        if (stamp.id) {
            request.user += `/${stamp.id}`;
            request.method = " put";
        }

        return makePrivateApiCall(request)
            .then(res => {
                if (res.data) {
                    dispatch({ type: STAMP_ROLL_SAVED, payload: res.data });
                    dispatch(loadLastStamp());
                    dispatch(loadStampRoll());
                    return res.data;
                }
            })
            .catch(err => {
                let messages = [];
                const data = get(err, "response.data", null);

                if (!data) {
                    messages.push(err.message);
                } else if (data.details) {
                    data.details.forEach(({ message }) => messages.push(message));
                } else if (data.message) {
                    messages.push(data.message);
                }

                dispatch(setStampEditorError(messages));
                return null;
            });
    };

export const setStampEditorInput = (inputs = {}) => ({
    type: STAMP_ROLL_INPUT_CHANGE,
    payload: inputs,
});

export const setStampEditorError = error => ({
    type: STAMP_ROLL_INPUT_ERROR,
    payload: error,
});

export const loadStampRoll = () => dispatch => {
    dispatch({ type: STAMP_ROLL_LOADING });

    return makePrivateApiCall({
        url: "/stamp/roll",
    })
        .then(res => {
            if (res.data) {
                dispatch({ type: STAMP_ROLL_DATA, payload: res.data });
                return res.data;
            }
        })
        .catch(err => {
            let messages = [];
            const data = get(err, "response.data", null);

            if (!data) {
                messages.push(err.message);
            } else if (data.message) {
                messages.push(data.message);
            }

            dispatch({
                type: STAMP_ROLL_ERROR,
                payload: messages,
            });

            return null;
        });
};

export const toggleStampRollActive = stamp => dispatch => {
    return makePrivateApiCall({
        data: { active: !stamp.active },
        url: `/stamp/roll/${stamp.id}`,
        method: "PUT",
    })
        .then(res => {
            if (res.data) {
                dispatch({ type: STAMP_ROLL_UPDATED, payload: res.data });
                dispatch(loadLastStamp());
                return res.data;
            }
        })
        .catch(err => {
            let messages = [];
            const data = get(err, "response.data", null);

            if (!data) {
                messages.push(err.message);
            } else if (data.message) {
                messages.push(data.message);
            }

            return messages;
        });
};
