import React from "react";
import { Link } from "react-router-dom";
import { Grid, Menu } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import TopNav from "../../common/containers/top-nav";
import InventoryContainerGrid from "../grid/index.container";
import { isFeatureActive } from "../../company/feature/helpers";
import { InventoryFeatureDetails } from "../../company/feature/helpers";
import CompanyFeatureComponentItemLarge from "../../company/feature/item-large.component";
import { showCompanyFeatureToggleConfirmation } from "../../company/feature/actions";
import CompanyFeatureContainerConfirmToggle from "../../company/feature/confirm-toggle.container";

const InventoryPageHome = () => {
    const { company } = useSelector(({ auth }) => ({
        company: auth.user.company,
    }));
    const hasInventoryFeature = isFeatureActive(company, InventoryFeatureDetails);
    const dispatch = useDispatch();
    const handleActivateFeature = feature => {
        dispatch(showCompanyFeatureToggleConfirmation(feature));
    };

    return (
        <Grid>
            <TopNav
                headerIcon="warehouse"
                headerContent="Inventory"
                subheader="Manage and track inventory"
                extraButtons={() =>
                    hasInventoryFeature && (
                        <Menu.Item as={Link} icon="plus" name="Re-stock" to="/inventory/restock" />
                    )
                }
            />

            <Grid.Column mobile={16} computer={16}>
                {hasInventoryFeature ? (
                    <InventoryContainerGrid />
                ) : (
                    <>
                        <CompanyFeatureContainerConfirmToggle />
                        <CompanyFeatureComponentItemLarge
                            toggleActivation={handleActivateFeature}
                            feature={InventoryFeatureDetails}
                            isActive={false}
                        />
                    </>
                )}
            </Grid.Column>
        </Grid>
    );
};

export default InventoryPageHome;
