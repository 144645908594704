import { formatCacheTimestamp, generateReducer } from "../helpers";
import {
    APP_CACHE_RESET_TIMESTAMP,
    APP_SET_CACHE_RESET_TIMESTAMP,
    APP_VERSION_CHANGE,
} from "../constants";

const initialState = {
    current: null,
    cacheTimestamp: localStorage.getItem(APP_CACHE_RESET_TIMESTAMP),
};

const changeVersion = (state, current) => ({
    ...state,
    current,
    cacheTimestamp: formatCacheTimestamp(),
});
const setCacheTimestamp = (state, cacheTimestamp) => ({ ...state, cacheTimestamp });

const reducers = {
    [APP_VERSION_CHANGE]: changeVersion,
    [APP_SET_CACHE_RESET_TIMESTAMP]: setCacheTimestamp,
};

export default generateReducer(reducers, initialState);
