import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

const OrderComponentInvoiceNumber = ({ invoices = [] }) => {
    return (
        <>
            {invoices.map(inv => (
                <Link
                    key={inv.id}
                    title="Invoice number"
                    to={`/invoice/${!!inv.beer ? inv.number + "B" : inv.number}`}
                >
                    {!inv.beer ? (
                        <Button compact size="mini">{`#${inv.number}`}</Button>
                    ) : (
                        <Button compact size="mini">
                            {`#${inv.number}` + "B"}
                        </Button>
                    )}
                </Link>
            ))}
        </>
    );
};

export default OrderComponentInvoiceNumber;
