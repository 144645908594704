import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";
import CurrencyInput from "react-currency-masked-input";
import { Grid, Input, Form, Button, Checkbox, Icon } from "semantic-ui-react";

import { InvoiceItemType } from "../types";
import { CustomerType } from "../../customer/types";
import { formatAmount } from "utilities/dist/helpers";
import ProductSearchContainer from "../../product/search/index.container";

const focusAndSelectInput = inputRef => {
    if (inputRef) {
        inputRef.focus();
        inputRef.select();
    }
};

const InvoiceBuilderComponentEditor = ({
    item,
    customer = {},
    cantAdd,
    onProductChange,
    onPriceChange,
    onQtyChange,
    onTaxableToggle,
    onAddProduct,
    hideTaxable = false,
    priceFieldLabel = "Item Price",
    showOnDeal = false,
    onDealToggle,
    hideOrderHistory = false,
}) => {
    const disableAdd = cantAdd;
    let searchInput = useRef(null);
    let qtyInput = useRef(null);

    useEffect(() => {
        // means no product selected
        if (!item.product && searchInput.current) {
            searchInput.current.searchRef.current.focus();
        } else {
            if (qtyInput.current) {
                if (item.price > 0) {
                    // if navigated and selected product using arrow buttons, on selection the dropdown is force focused
                    // add a timeout to work around that and move focus to qty
                    setTimeout(() => {
                        focusAndSelectInput(qtyInput.current);
                    }, 100);
                } else {
                    // hacky, should be through react ref
                    setTimeout(() => {
                        focusAndSelectInput(document.querySelector('input[name="price"]'));
                    }, 100);
                }
            }
        }
    }, [item]);

    return (
        <>
            <Form>
                <Grid className="compact">
                    <Grid.Row>
                        <Grid.Column computer={8} tablet={16} mobile={16}>
                            <Form.Field>
                                <label>Product</label>
                                <ProductSearchContainer
                                    customer={customer}
                                    dropdownRef={searchInput}
                                    onSelect={onProductChange}
                                    hideOrderHistory={hideOrderHistory}
                                />
                            </Form.Field>
                        </Grid.Column>
                        {!isEmpty(item.product) && (
                            <>
                                <Grid.Column computer={2} tablet={4} mobile={8}>
                                    <Form.Field>
                                        <label htmlFor="field_input_price">{priceFieldLabel}</label>
                                        <div className="ui fluid left icon input hide-number-arrows">
                                            <Icon name="dollar sign" />
                                            <CurrencyInput
                                                name="price"
                                                id="field_input_price"
                                                value={parseFloat(item.price || 0).toFixed(2)}
                                                onKeyUp={e => {
                                                    // On Enter, we move focus to Qty so that user can keep using enter to move 1 field at a time
                                                    if (e?.key === "Enter") {
                                                        focusAndSelectInput(qtyInput.current);
                                                    }
                                                }}
                                                onChange={(e, value) => onPriceChange(e, { value })}
                                            />
                                        </div>
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column computer={2} tablet={4} mobile={8}>
                                    <Form.Field>
                                        <label htmlFor="field_input_qty">Qty</label>
                                        <Input
                                            fluid
                                            step="1"
                                            type="number"
                                            ref={qtyInput}
                                            value={item.qty}
                                            id="field_input_qty"
                                            onChange={onQtyChange}
                                            onKeyUp={e => {
                                                // On Enter, we add the row because this is the last editable field
                                                if (e?.key === "Enter") {
                                                    if (!disableAdd) onAddProduct();
                                                }
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                {!hideTaxable && (
                                    <Grid.Column computer={1} tablet={2} mobile={16}>
                                        <Form.Field>
                                            <label>Taxable</label>
                                            <Checkbox
                                                toggle
                                                className="tp-10"
                                                checked={item.taxable}
                                                onChange={onTaxableToggle}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                )}
                                {showOnDeal && (
                                    <Grid.Column computer={1} tablet={2} mobile={16}>
                                        <Form.Field>
                                            <label>On Deal</label>
                                            <Checkbox
                                                toggle
                                                className="tp-10"
                                                checked={item.on_deal}
                                                onChange={onDealToggle}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                )}
                                <Grid.Column computer={3} tablet={6} mobile={16}>
                                    <Form.Field>
                                        <label>
                                            <br />
                                        </label>
                                        <Button
                                            data-cy="add_product"
                                            fluid
                                            positive
                                            type="button"
                                            disabled={disableAdd}
                                            onClick={onAddProduct}
                                            content={`Add (${formatAmount(item.total)})`}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </>
                        )}
                    </Grid.Row>
                </Grid>
            </Form>
        </>
    );
};

InvoiceBuilderComponentEditor.propTypes = {
    item: InvoiceItemType,
    customer: CustomerType,

    cantAdd: PropTypes.bool.isRequired,
    onQtyChange: PropTypes.func.isRequired,
    onPriceChange: PropTypes.func.isRequired,
    onAddProduct: PropTypes.func.isRequired,
    onProductChange: PropTypes.func.isRequired,
    onTaxableToggle: PropTypes.func,
    hideTaxable: PropTypes.bool,
};

export default InvoiceBuilderComponentEditor;
