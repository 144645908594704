import React from "react";
import { Grid } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import { CloverContainerHistory } from "../containers/history";

const CloverPageHome = () => {
    return (
        <Grid>
            <TopNav
                headerIcon="leaf"
                subheader="Manage Clover Integration"
                headerContent="Clover"
            />

            <Grid.Column mobile={16} computer={16}>
                  <CloverContainerHistory />
            </Grid.Column>
        </Grid>
    );
};

export default CloverPageHome;
