import React, { useState, useEffect, useCallback } from "react";
import { Grid, Message } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import InvoiceComponentView from "../components/view";

import { makePrivateApiCall } from "utilities/dist/api";

const InvoicePagePdf = ({ match }) => {
    const { token } = match.params;

    const [error, setError] = useState(null);
    const [invoice, setInvoice] = useState({});
    const [isLoading, setLoading] = useState(false);

    const loadInvoice = useCallback(() => {
        makePrivateApiCall({
            url: `/pdf/invoice/${token}`,
        })
            .then(res => {
                setLoading(false);
                if (res.data) {
                    setInvoice(res.data);
                    window.READY_FOR_PDF_EXPORT = true;
                    setError(null);
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
            });
    }, [token]);

    // when a value change is requested, let's first make sure that the value is actually changing from the current value
    // if not, no point showing the modal window asking for confirmation, right?
    //

    useEffect(() => {
        setLoading(true);
        loadInvoice();
    }, []);

    if (!isLoading && error) {
        return <Message content={error} header="Sorry, something went wrong!" />;
    }

    return (
        <>
            <Grid className="invoice-view-page">
                <TopNav
                    headerIcon="newspaper"
                    subheader="Details of your invoice"
                    headerContent={`Invoice #${invoice?.number || ""}`}
                />

                <Grid.Column mobile={16}>
                    <InvoiceComponentView
                        invoice={invoice}
                        company={invoice?.company}
                        isLoading={isLoading}
                    />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default InvoicePagePdf;
