import React from "react";
import { Link } from "react-router-dom";
import { Grid, Menu } from "semantic-ui-react";
import { useSelector } from "react-redux";

import TopNav from "../../common/containers/top-nav";
import InvoiceGridContainer from "../grid/index.container";
import StampWarningContainer from "../../stamp/warning/index.container";
import { EmailFeatureDetails, isFeatureActive } from "../../company/feature/helpers";

const InvoicePageHome = () => {
    const { company } = useSelector(({ auth }) => ({
        company: auth.user.company,
    }));
    const hasEmailFeature = isFeatureActive(company, EmailFeatureDetails);

    return (
        <Grid>
            <TopNav
                headerIcon="newspaper"
                headerContent="Invoices"
                subheader="All your past invoices"
                extraButtons={() => (
                    <>
                        <Menu.Item as={Link} icon="plus" name="New Invoice" to="/invoice/create" />
                        {hasEmailFeature && (
                            <Menu.Item as={Link} icon="envelope" name="Email Log" to="/emails" />
                        )}
                    </>
                )}
            />

            <Grid.Column mobile={16}>
                <StampWarningContainer />
                <InvoiceGridContainer />
            </Grid.Column>
        </Grid>
    );
};

export default InvoicePageHome;
