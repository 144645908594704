import React from "react";
import { connect } from "react-redux";
import { Form } from "formik-semantic-ui";
import { bindActionCreators } from "redux";
import { toast } from "react-semantic-toasts";
import { Message, Modal, Button, Icon, Header } from "semantic-ui-react";

import {
    saveProduct,
    deleteProduct,
    archiveProduct,
    resetEditingProduct,
    loadDistributorList,
    setProductEditorError,
    showProductEditorModal,
} from "./actions";
import { validateProductCreate, validateProductUpdate } from "./validators";

import ProductComponentEditorForm from "./index.component";
import { isCompanyFeatureActive } from "utilities/dist/company/helpers";
import { BottlePosFeatureDetails, CloverFeatureDetails } from "../../company/feature/helpers";

class ProductEditorContainer extends React.Component {
    handleSubmit = async (productData, formik) => {
        try {
            let product;
            let customerId = this.props.customerId;

            if (productData.id) product = await validateProductUpdate(productData);
            else product = await validateProductCreate(productData);

            const result = await this.props.saveProduct(product, customerId);

            if (!!result && !!this.props.onComplete) {
                this.props.onComplete({ ...result, scanned: !!productData?.scanned });
                this.props.resetEditingProduct();
                toast({
                    icon: "martini glass",
                    title: `Product created`,
                    description: `${result.product} saved.`,
                });
            }

            formik.setSubmitting(false);
        } catch (err) {
            const messages = [];
            err.details.forEach(errDet => {
                formik.setFieldError(errDet.context.key, errDet.message);
                messages.push(errDet.message);
            });

            formik.setSubmitting(false);
            this.props.setProductEditorError(messages);
        }
    };

    showExistingProduct = e => {
        e.preventDefault();
        return null;
    };

    render() {
        const {
            errors,
            inputs,
            showModal,
            isSaving,
            isScanned,
            product,
            isArchiving,
            isDeleting,
            showProductEditorModal,
            resetEditingProduct,
            deleteProduct,
            archiveProduct,
            isBottlePosActive,
            isCloverActive,
        } = this.props;

        return (
            <Modal
                closeIcon
                open={showModal}
                onClose={() => {
                    showProductEditorModal(false, isScanned);
                    resetEditingProduct();
                }}
            >
                <Modal.Header>
                    {product && product.id ? "Edit product" : "Create new product"}
                </Modal.Header>
                <Modal.Content>
                    {(isBottlePosActive || isCloverActive) && (
                        <Message
                            warning
                            header={
                                isBottlePosActive
                                    ? "Bottle POS integration is active"
                                    : "Clover integration is active"
                            }
                            content={
                                product && product.id
                                    ? isBottlePosActive
                                        ? "Most fields are disabled when bottle POS integration is active since these fields will be synchronized from bottle POS."
                                        : "Most fields are disabled when clover integration is active since these fields will be synchronized from clover."
                                    : isBottlePosActive
                                    ? "Please create your product in bottle POS and yDrink Invoice will automatically import it overnight."
                                    : "Please create your product in clover and yDrink Invoice will automatically import it overnight."
                            }
                        />
                    )}

                    {!isBottlePosActive && !isCloverActive && (
                        <Form
                            initialValues={inputs}
                            isSubmitting={isSaving}
                            onSubmit={this.handleSubmit}
                            render={props => (
                                <ProductComponentEditorForm
                                    {...props}
                                    isBottlePosActive={isBottlePosActive}
                                    isCloverActive={isCloverActive}
                                    onCancel={() => showProductEditorModal(false)}
                                />
                            )}
                        />
                    )}

                    {errors.length > 0 && (
                        <Message
                            error
                            content={errors.map(err => {
                                const existingProductErrorKey = "already exists";
                                if (err?.includes(existingProductErrorKey)) {
                                    const showExistingProduct = () =>
                                        this.props.onComplete(
                                            err.split(existingProductErrorKey)[0]?.trim()
                                        );

                                    return (
                                        <>
                                            {err}.{" "}
                                            <a href="#" onClick={showExistingProduct}>
                                                Click here to see the existing product
                                            </a>
                                        </>
                                    );
                                }
                                return err;
                            })}
                            icon="warning sign"
                            header="Error saving product!"
                        />
                    )}
                </Modal.Content>

                {product && product.id && (
                    <Modal.Actions>
                        <div className="text-left">
                            <Header
                                color="red"
                                size="small"
                                content="Carefully read the following!"
                            />
                            <p>
                                Archiving the product will keep the invoice history in the database
                                but it will stop showing up when creating new invoices.
                                <br />
                                You may only delete a product if it has no invoice history.
                            </p>
                            <Button
                                icon
                                basic
                                size="tiny"
                                labelPosition="left"
                                loading={isArchiving}
                                onClick={() => {
                                    archiveProduct(product).then(success => {
                                        if (success && !!this.props.onComplete) {
                                            this.props.onComplete(success);
                                        }
                                    });
                                }}
                                color={product.archived ? "blue" : "orange"}
                            >
                                <Icon name={"archive"} />
                                {product.archived ? "Restore" : "Archive"}
                            </Button>
                            {!isBottlePosActive && (
                                <Button
                                    icon
                                    basic
                                    color="red"
                                    size="tiny"
                                    labelPosition="left"
                                    loading={isDeleting}
                                    onClick={() => {
                                        deleteProduct(product.id).then(success => {
                                            if (success && !!this.props.onComplete) {
                                                this.props.onComplete(success);
                                            }
                                        });
                                    }}
                                >
                                    <Icon name="trash" />
                                    Delete
                                </Button>
                            )}
                        </div>
                    </Modal.Actions>
                )}
            </Modal>
        );
    }
}

const mapStateToProps = ({ productEditor, productGrid, auth }) => ({
    errors: productEditor.errors,
    inputs: productEditor.inputs,
    product: productEditor.product,
    isSaving: productEditor.isSaving,
    showModal: productEditor.showModal,
    isScanned: productEditor.isScanned,
    isDeleting: productEditor.isDeleting,
    isArchiving: productEditor.isArchiving,
    customerId: productGrid.customerId,
    company: auth.user.company,
    isBottlePosActive: isCompanyFeatureActive(auth.user.company, BottlePosFeatureDetails.id),
    isCloverActive: isCompanyFeatureActive(auth.user.company, CloverFeatureDetails.id),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            archiveProduct,
            deleteProduct,
            saveProduct,
            setProductEditorError,
            resetEditingProduct,
            loadDistributorList,
            showProductEditorModal: show => dispatch(showProductEditorModal(show)),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProductEditorContainer);
