import PropTypes from "prop-types";
import React, { useState } from "react";
import { format, parse, addDays } from "date-fns";
import { Popup, Button } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";

import { DateFormat } from "../helpers";
import { parseServerTimestampForClient } from "../../common/helpers";

const InvoiceComponentTimestamp = ({ invoiced_at, onChange }) => {
    const [showPicker, setShowPicker] = useState(false);
    const handleDateChange = (e, data) => {
        data.value = parse(data.value, DateFormat, new Date());
        setShowPicker(false);
        onChange(e, data);
    };

    return (
        <Popup
            on="click"
            open={showPicker}
            disabled={!onChange}
            onClose={() => setShowPicker(false)}
            trigger={
                <Button
                    compact
                    size="mini"
                    content={format(parseServerTimestampForClient(invoiced_at), DateFormat)}
                    onClick={() => !!onChange && setShowPicker(true)}
                />
            }
        >
            <DateInput
                inline
                initialDate={invoiced_at}
                onChange={handleDateChange}
                maxDate={addDays(new Date(), 10)}
                // bit hacky cause we use date-fns for date stuff but the picker package uses moment so the formats are not interchangable
                dateFormat={DateFormat.toUpperCase()}
                value={format(parseServerTimestampForClient(invoiced_at), DateFormat)}
            />
        </Popup>
    );
};

InvoiceComponentTimestamp.propTypes = {
    onChange: PropTypes.func,
    invoiced_at: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
};

export default InvoiceComponentTimestamp;
