import {
    COMPANY_LOGO_DATA,
    COMPANY_LOGO_ERROR,
    COMPANY_LOGO_LOADING,
    COMPANY_LOGO_UPLOADING,
    COMPANY_LOGO_UPLOAD_ERROR,
    COMPANY_LOGO_UPLOAD_SUCCESS,
    COMPANY_LOGO_REMOVING,
    COMPANY_LOGO_REMOVE_ERROR,
    COMPANY_LOGO_REMOVE_SUCCESS,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    uploadError: null,
    uploadingImage: null,

    removeError: null,
    removingLogoId: null,

    logos: [],
    error: null,
    isLoading: false,
};

const setUploadingImage = (state, uploadingImage) => ({
    ...state,
    uploadingImage,
    uploadError: null,
});
const setUploadError = (state, uploadError) => ({
    ...state,
    uploadingImage: null,
    uploadError,
});
const setUploadData = (state, logo) => ({
    ...state,
    uploadError: null,
    uploadingImage: null,
    logos: [logo, ...state.logos],
});

const setRemovingLogo = (state, removingLogoId) => ({
    ...state,
    removingLogoId,
    removeError: null,
});
const setRemovingError = (state, removeError) => ({
    ...state,
    removingLogoId: null,
    removeError,
});
const setRemovedLogo = (state, logoId) => ({
    ...state,
    removeError: null,
    removingLogoId: null,
    logos: state.logos.filter(({ id }) => id !== logoId),
});

const setLogos = (state, { rows }) => ({
    ...state,
    logos: rows,
    isLoading: false,
});
const setLogosError = (state, error) => ({ ...state, error, isLoading: false });
const setLogosLoading = state => ({ ...state, error: null, isLoading: true });

const reducers = {
    [COMPANY_LOGO_UPLOADING]: setUploadingImage,
    [COMPANY_LOGO_UPLOAD_ERROR]: setUploadError,
    [COMPANY_LOGO_UPLOAD_SUCCESS]: setUploadData,

    [COMPANY_LOGO_DATA]: setLogos,
    [COMPANY_LOGO_ERROR]: setLogosError,
    [COMPANY_LOGO_LOADING]: setLogosLoading,

    [COMPANY_LOGO_REMOVING]: setRemovingLogo,
    [COMPANY_LOGO_REMOVE_SUCCESS]: setRemovedLogo,
    [COMPANY_LOGO_REMOVE_ERROR]: setRemovingError,
};

export default generateReducer(reducers, initialState);
