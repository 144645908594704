import {
    INVOICE_GRID_DATA,
    INVOICE_GRID_ERROR,
    INVOICE_GRID_LOADING,
    INVOICE_GRID_PAGE_CHANGE,
    INVOICE_GRID_STATE_CHANGE,
    INVOICE_BUILDER_SAVED,
} from "../constants";
import { allGridColumns } from "./columns";
import { generateReducer } from "../../common/helpers";

const initialState = {
    error: null,
    invoices: [],
    isLoading: false,
    currentPage: 1,
    lastRow: null,
    pageSize: 25,
    sortModel: [
        {
            colId: "invoiced_at",
            sort: "desc",
        },
    ],
    filterModel: {},
    columnDefs: Object.values(allGridColumns),
};

const setGridData = (state, { invoices, lastRow }) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        lastRow,
        invoices,
    };
};

const updateGridInvoice = (state, invoice) => {
    const invoices = state.invoices.map(inv => {
        if (inv.id === invoice.id) {
            return { ...inv, ...invoice };
        }

        return inv;
    });

    return { ...state, invoices };
};
const changeGridPage = (state, currentPage) => ({ ...state, currentPage });
const changeGridState = (state, payload) => ({ ...state, ...payload });
const setGridLoading = state => ({ ...state, isLoading: true });
const setGridError = (state, error) => ({ ...state, isLoading: false, error });

const reducers = {
    [INVOICE_GRID_DATA]: setGridData,
    [INVOICE_GRID_ERROR]: setGridError,
    [INVOICE_GRID_LOADING]: setGridLoading,

    [INVOICE_BUILDER_SAVED]: updateGridInvoice,

    [INVOICE_GRID_PAGE_CHANGE]: changeGridPage,
    [INVOICE_GRID_STATE_CHANGE]: changeGridState,
};

export default generateReducer(reducers, initialState);
