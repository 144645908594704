import React from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Grid, Segment, Dimmer, Loader, Image } from "semantic-ui-react";

import InvoiceComponentCompanyHeader from "../../invoice/components/company-header";
import { CompanyType } from "../../company/types";
import { InvoiceType } from "../../invoice/types";
import OrderComponentProductList from "./order-list";
import OrderComponentStatus from "./status";
import InvoiceComponentCustomerHeader from "../../invoice/components/customer-header";
import OrderComponentInvoiceNumber from "./invoice-number";
import UserOrderInfo from "./user-order-info";
import { InternalSkuFeature, TieredPricing } from "../../company/feature/helpers";
import { isCompanyFeatureActive } from "utilities/dist/company/helpers";

const OrderComponentView = ({ isLoading, order, company }) => {
    const isInternalSkuActive = isCompanyFeatureActive(company, InternalSkuFeature.id);
    const isTieredPricingActive = isCompanyFeatureActive(company, TieredPricing.id);
    const customerPriceTier = isTieredPricingActive
        ? company?.productPriceTiers?.find(
              ({ id }) => order?.customerUser?.customer?.product_price_tier_id === id
          )
        : null;

    return (
        <Dimmer.Dimmable as={Segment} vertical className="invoice-view-dimmer">
            <Dimmer active={isLoading} inverted>
                <Loader>Loading</Loader>
            </Dimmer>
            {!isEmpty(order) ? (
                <div className="sp-5">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column
                                width={10}
                                textAlign="left"
                                className="invoice-view-company-header-container"
                            >
                                {order.logo ? (
                                    <div className="bp-10">
                                        <Image
                                            floated="left"
                                            src={order.logo.path}
                                            style={{
                                                marginRight: "10px",
                                                maxHeight: "100px",
                                                maxWidth: "200px",
                                            }}
                                        />
                                        {/* stupid hack cause semantic ui adds margin top on headers that are not first child */}
                                        <div>
                                            <InvoiceComponentCompanyHeader company={company} />
                                        </div>
                                    </div>
                                ) : (
                                    <InvoiceComponentCompanyHeader company={company} />
                                )}

                                <OrderComponentStatus status={order.status} />
                                {order.invoices?.length > 0 && (
                                    <OrderComponentInvoiceNumber invoices={order.invoices} />
                                )}
                            </Grid.Column>
                            <Grid.Column
                                width={6}
                                className="invoice-view-customer-header-container"
                            >
                                <InvoiceComponentCustomerHeader
                                    priceTier={customerPriceTier}
                                    customer={order?.customerUser?.customer}
                                />
                                <UserOrderInfo order={order} />
                            </Grid.Column>
                        </Grid.Row>
                        <OrderComponentProductList
                            items={order}
                            isInternalSkuActive={isInternalSkuActive}
                            onShowInlineEditor={() => null}
                        />
                    </Grid>
                </div>
            ) : (
                <div className="hp-30"></div>
            )}
        </Dimmer.Dimmable>
    );
};

OrderComponentView.propTypes = {
    company: CompanyType,
    invoice: InvoiceType,
    onDateChange: PropTypes.func,
    onStatusChange: PropTypes.func,
    onDueDateChange: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default OrderComponentView;
