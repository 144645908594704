"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateInvoicePaymentCreate = exports.paymentSchema = void 0;

var _joi = _interopRequireDefault(require("joi"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const paymentSchema = {
  received_at: _joi.default.string().trim().label("Receive Date").required(),
  amount: _joi.default.number().min(0.01).label("Amount").required(),
  note: _joi.default.string().allow("").trim()
};
exports.paymentSchema = paymentSchema;

const validateInvoicePaymentCreate = (payment, schema) => {
  return _joi.default.object(schema).validateAsync(payment, {
    convert: true,
    stripUnknown: true
  });
};

exports.validateInvoicePaymentCreate = validateInvoicePaymentCreate;