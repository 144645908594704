import { toast } from "react-semantic-toasts";
import { buildCustomerInviteLink } from "utilities/dist/customer/helpers";

export const getRegisteredTime = data => {
    return data?.userActivity?.length
        ? data?.created_at
        : data?.emailInvitation?.length
        ? data?.emailInvitation[0]?.updated_at
        : "";
};
export const getInvitedAtTime = data => {
    return data?.emailInvitation?.length
        ? data.emailInvitation[0].invited_at
        : data?.invited_at
        ? data.invited_at
        : "";
};

/**
 *
 * @typedef {Object} InviteLinkParams
 * @property {string} subDomain - subdomain of the company
 * @property {string} domain - base domain of the app, changes based on env
 * @property {string} tabcId - TABC ID of the company
 * @property {string} [email] - email address of the user being invited
 * @property {string} [name] - name of the user being invited
 */

/**
 * Generates invite link, copies it to clipboard and shows a toast confirming the action
 * @param {InviteLinkParams} params
 */
export const copyInvitationLink = params => {
    const registrationLink = buildCustomerInviteLink(params);
    navigator.clipboard.writeText(registrationLink);
    toast({
        description: `Copied to clipboard`,
        time: 1000,
        type: "success",
        icon: "checkmark",
        title: "Copied",
    });
};
