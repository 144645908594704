import React from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";

import { changeInvoiceGridState } from "../../invoice/grid/actions";
import { setInvoiceBuilderCustomer } from "../../invoice/builder/actions";
import { changeCustomerGridState } from "../../customer/actions";
import { changePaymentGridState } from "../../invoice/payment-grid/actions";

class OrderComponentGridActions extends React.Component {
    handleInvoiceHistory = e => {
        e.preventDefault();
        this.props.changeInvoiceGridState({
            filterModel: {
                customer: {
                    filter: this.props.data.customerUser?.customer?.name,
                    filterType: "text",
                    type: "equals",
                },
            },
        });
        this.props.push("/invoice");
    };
    handleCustomerInfo = e => {
        e.preventDefault();
        this.props.changeCustomerGridState({
            filterModel: {
                name: {
                    filter: this.props.data.customerUser?.customer?.name,
                    filterType: "text",
                    type: "equals",
                },
            },
        });
        this.props.push("/customer");
    };
    handlePaymentInfo = e => {
        e.preventDefault();
        this.props.changePaymentGridState({
            filterModel: {
                customer: {
                    filter: this.props.data.customerUser?.customer?.name,
                    filterType: "text",
                    type: "equals",
                },
            },
        });
        this.props.push("/payment");
    };

    render() {
        return (
            <>
                <a href="/invoice" title="Invoice History" onClick={this.handleInvoiceHistory}>
                    <Icon name="newspaper" />
                </a>
                &nbsp;&nbsp;&nbsp;
                <a href="/customer" title="Customer info" onClick={this.handleCustomerInfo}>
                    <Icon name="user" />
                </a>
                &nbsp;&nbsp;&nbsp;
                <a href="/payment" title="Payment info" onClick={this.handlePaymentInfo}>
                    <Icon name="payment" />
                </a>
            </>
        );
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            push,
            changeInvoiceGridState,
            changeCustomerGridState,
            changePaymentGridState,
            setInvoiceBuilderCustomer,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps, null, {
    forwardRef: true,
})(OrderComponentGridActions);
