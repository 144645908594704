import React from "react";
import { List, Message } from "semantic-ui-react";
import { format } from "date-fns";
import { DateTimeFormat } from "utilities/dist/invoice/constants";
import { formatAmount } from "utilities/dist/helpers";

export const InventoryHistoryComponent = ({ history = [] }) => {
    if (!history?.length) {
        return <Message warning content="This product's inventory was never updated" />;
    }
    return (
        <List
            relaxed
            items={history.reverse().map(h => {
                const isAdded = h.qty > 0;
                const descriptions = [format(new Date(h.created_at), DateTimeFormat)];

                if (h.source === "manual") {
                    descriptions.push("Manual update");
                }

                if (h.invoice?.number) {
                    descriptions.push(`Invoice #${h.invoice.number}`);
                }

                if (h.ref_number) {
                    descriptions.push(`Restock Ref #${h.ref_number}`);
                }

                if (h.on_deal) {
                    descriptions.push("On deal");
                }

                if (h.unit_price && !h.invoice_id) {
                    descriptions.push(`Cost: ${formatAmount(h.unit_price)}`);
                }

                return {
                    key: `inventory_history_${h.id}`,
                    icon: { name: isAdded ? "plus" : "minus", color: isAdded ? "green" : "red" },
                    header: isAdded
                        ? `${Math.abs(h.qty)} Item added`
                        : `${Math.abs(h.qty)} Item ${h.invoice_id ? "invoiced" : "subtracted"}`,
                    description: descriptions.filter(Boolean).join(" | "),
                };
            })}
        />
    );
};
