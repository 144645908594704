import { shape, number, string, boolean, oneOfType } from "prop-types";

export const CustomerType = shape({
    id: number.isRequired,
    name: string.isRequired,
    tabc_id: string.isRequired,
    address: string.isRequired,
    city: string.isRequired,
    state: string.isRequired,
    zip: oneOfType([string, number]).isRequired,
    ydk_est_id: oneOfType([string, number]).isRequired,
    fintech_option: oneOfType([boolean, number]),
});
