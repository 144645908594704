import React, { useState } from "react";
import { format } from "date-fns";
import { pick } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { Table, Dropdown, Icon, Button, Confirm, Input, Modal, Message } from "semantic-ui-react";
import { parseServerTimestampForClient } from "../../common/helpers";
import { DateFormat } from "utilities/dist/invoice/constants";
import { loadUploadedDocuments, setDocumentEditorId, updateDocumentName } from "./actions";

const editorSelector = ({ invoiceDocument }) =>
    pick(invoiceDocument, ["editorDocumentId", "editorIsSaving", "editorError"]);

const DocumentTableRow = ({ doc, isRemovingDoc, isLoading, removeDocumentWhenConfirmed }) => {
    const [confirmingRemoval, setConfirmingRemoval] = useState(0);
    const dispatch = useDispatch();
    const { editorDocumentId, editorIsSaving } = useSelector(editorSelector);
    const [newFileName, setNewFileName] = useState("");
    const [newFileExtension, setNewFileExtension] = useState("");

    return (
        <Table.Row>
            <Table.Cell colSpan="3" collapsing>
                <Icon
                    name={doc.mimetype === "application/pdf" ? "file pdf" : "file image"}
                    className={doc.mimetype === "application/pdf" ? "red" : "green"}
                />{" "}
                {doc.originalname}
            </Table.Cell>
            <Table.Cell>
                {" "}
                {format(parseServerTimestampForClient(doc.updatedAt), DateFormat)}
            </Table.Cell>
            <Table.Cell textAlign="right">
                <Button.Group size="mini">
                    <Button
                        basic
                        color="blue"
                        icon="download"
                        className="no-print"
                        as="a"
                        href={doc.path}
                        target="blank"
                        download
                    />
                    <Button
                        basic
                        color="blue"
                        icon="edit"
                        className="no-print"
                        loading={isRemovingDoc}
                        disabled={isRemovingDoc}
                        onClick={() => {
                            const fragments = doc.originalname.split(".");
                            const extension = fragments.pop();
                            const name = fragments.join(".");
                            setNewFileName(name);
                            setNewFileExtension(extension);
                            dispatch(setDocumentEditorId(doc.id));
                        }}
                    />
                    <Button
                        basic
                        color="red"
                        icon="trash"
                        className="no-print"
                        loading={isRemovingDoc}
                        disabled={isRemovingDoc}
                        onClick={() => setConfirmingRemoval(1)}
                    />
                </Button.Group>
                <Confirm
                    header="Change file name"
                    cancelButton={{
                        content: "Nevermind",
                        disabled: editorIsSaving,
                        loading: editorIsSaving,
                    }}
                    onConfirm={() => {
                        dispatch(
                            updateDocumentName(
                                editorDocumentId,
                                `${newFileName}.${newFileExtension}`
                            )
                        ).then(() => {
                            setNewFileName("");
                        });
                    }}
                    open={editorDocumentId === doc.id}
                    content={
                        <Modal.Content>
                            <Input
                                fluid
                                autoFocus
                                size="large"
                                value={newFileName}
                                error={!newFileName}
                                labelPosition="right"
                                placeholder="Set a descriptive file name"
                                label={{ basic: true, content: newFileExtension }}
                                onChange={e => setNewFileName(e.target.value)}
                            />
                            {!newFileName && <Message error content="File name can not be empty" />}
                        </Modal.Content>
                    }
                    confirmButton={
                        <Button
                            positive
                            loading={editorIsSaving}
                            disabled={editorIsSaving}
                            content="Update Name"
                        />
                    }
                    onCancel={() => {
                        setNewFileName("");
                        dispatch(setDocumentEditorId(null));
                    }}
                />
                <Confirm
                    header="Remove Document?"
                    cancelButton="Nevermind"
                    onConfirm={() => removeDocumentWhenConfirmed(doc.id)}
                    open={confirmingRemoval > 0}
                    content="Warning! Sure you want to remove this document?"
                    confirmButton={
                        <Button
                            negative
                            loading={isLoading}
                            disabled={isLoading}
                            content="Remove Document"
                        />
                    }
                    onCancel={() => setConfirmingRemoval(0)}
                />
            </Table.Cell>
        </Table.Row>
    );
};

export const InvoiceDocumentTable = ({
    isLoading,
    documents,
    uploadingDocument,
    removingDocumentsId,
    handleFileUploadClick,
    handleCameraUploadClick,
    removeDocumentWhenConfirmed,
}) => {
    let hiddenInput = null;
    return (
        <Table striped className="no-print">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Documents</Table.HeaderCell>
                    <Table.HeaderCell colSpan="6" textAlign="right">
                        <Dropdown
                            compact={true}
                            text="Upload Doc"
                            icon="upload"
                            floating
                            labeled
                            button
                            className="icon no-print green"
                            basic
                            positive
                        >
                            <Dropdown.Menu direction="left">
                                <Dropdown.Item
                                    basic
                                    positive
                                    icon="hdd"
                                    className="no-print"
                                    content="Upload From Computer"
                                    loading={!!uploadingDocument}
                                    onClick={() => hiddenInput.click()}
                                />

                                <Dropdown.Item
                                    basic
                                    positive
                                    icon="camera"
                                    className="no-print"
                                    content="Upload From Camera"
                                    loading={!!uploadingDocument}
                                    onClick={handleCameraUploadClick}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                        <input
                            hidden
                            type="file"
                            ref={el => (hiddenInput = el)}
                            onChange={handleFileUploadClick}
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            {documents?.length > 0 && (
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan="3">Document Name</Table.HeaderCell>
                        <Table.HeaderCell>Uploaded on</Table.HeaderCell>

                        <Table.HeaderCell textAlign="right">
                            <span className="no-print">Actions</span>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            )}

            <Table.Body>
                {!documents.length ? (
                    <Table.Row>
                        <Table.Cell>No documents uploaded for this invoice</Table.Cell>
                    </Table.Row>
                ) : (
                    documents.map(doc => (
                        <DocumentTableRow
                            doc={doc}
                            isLoading={isLoading}
                            key={`invoice_doc_${doc.id}`}
                            isRemovingDoc={removingDocumentsId === doc.id}
                            removeDocumentWhenConfirmed={removeDocumentWhenConfirmed}
                        />
                    ))
                )}
            </Table.Body>
        </Table>
    );
};
