"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAuthUser = exports.setAuthToken = exports.getAuthUser = exports.getAuthToken = void 0;

var _constants = require("./constants");

const setAuthToken = (token = "") => {
  if (token && token.length > 5) return localStorage.setItem(_constants.AUTH_STORAGE_TOKEN, token);
  return localStorage.removeItem(_constants.AUTH_STORAGE_TOKEN);
};

exports.setAuthToken = setAuthToken;

const getAuthToken = () => {
  return localStorage.getItem(_constants.AUTH_STORAGE_TOKEN);
};

exports.getAuthToken = getAuthToken;

const setAuthUser = user => {
  if (user) return localStorage.setItem(_constants.AUTH_STORAGE_USER, JSON.stringify(user));
  return localStorage.removeItem(_constants.AUTH_STORAGE_TOKEN);
};

exports.setAuthUser = setAuthUser;

const getAuthUser = () => {
  const user = localStorage.getItem(_constants.AUTH_STORAGE_USER);
  if (user) return JSON.parse(user);
  return null;
};

exports.getAuthUser = getAuthUser;