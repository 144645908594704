import { isBefore, isAfter, format, parse, addHours } from "date-fns";
import { DateFormat } from "utilities/dist/invoice/constants";
import { makePrivateApiCall } from "utilities/dist/api";

export function dateFilterComparator(filterDate, cellValue) {
    if (isBefore(cellValue, filterDate)) {
        return -1;
    } else if (isAfter(cellValue, filterDate)) {
        return 1;
    } else {
        return 0;
    }
}

// given a set of reducer map with keys referring to the action type and values are functions that handle the reduce action
// this function will return a reducer function that can be linked to redux store
export const generateReducer = (reducers, initialState) => {
    return (state = initialState, action) => {
        if (action.type in reducers) {
            return reducers[action.type](state, action.payload);
        }

        return state;
    };
};

export const gridCurrencyFormatter = data => {
    const formattedNumber = Math.round(Number(data.value))
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return "$" + formattedNumber;
};

export const gridPercentageFormatter = data => {
    if (Number(data.value) === 0) {
        return "";
    }

    const formattedNumber = parseCurrency(data.value);
    return `${formattedNumber}%`;
};

export const gridPercentageStyle = data => {
    const number = Number(data.value);

    if (number < 0) {
        return { color: "red" };
    } else if (number > 0) {
        return { color: "green" };
    }

    return null;
};

export const formatStampRange = ({ from, to, prefix }) => {
    const pre = prefix.toUpperCase();
    return `${pre}${from}-${pre}${to}`;
};

export const parseAmount = amount => parseFloat(amount).toFixed(2);
export const parseCurrency = amount => Number(parseAmount(amount));

export const formatCacheTimestamp = () => format(new Date(), DateFormat);
export const serverTimestampFormat = "yyyy-MM-dd HH:mm:ss";
export const serverDateFormat = "yyyy-MM-dd";
const dateHasTimezoneInfo = dateString => {
    if (typeof dateString === "object") return true;

    return dateString.includes("T") && dateString.endsWith("Z");
};
// All prod users are in texas so instead of using a TZ library, we enforce TX timezone on all dates
export const parseServerTimestampForClient = dateString =>
    dateHasTimezoneInfo(dateString)
        ? addHours(new Date(dateString), 6)
        : parse(dateString, serverTimestampFormat, new Date());
export const parseServerDateForClient = dateString =>
    dateHasTimezoneInfo(dateString)
        ? addHours(new Date(dateString), 6)
        : parse(dateString, serverDateFormat, new Date());

export const updatePlacedOrderCount = setPlacedOrderCount => {
    makePrivateApiCall({
        url: "/order/count",
        method: "get",
    })
        .then(res => {
            setPlacedOrderCount(res.data);
        })
        .catch(err => {
            return err;
        });
};

export const SemanticColors = [
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "teal",
    "blue",
    "violet",
    "purple",
    "pink",
    "brown",
    "grey",
    "black",
];
