import React, { useEffect, useRef, useState } from "react";
import {
    Label,
    Dimmer,
    Icon,
    Button,
    Dropdown,
    Modal,
    Loader,
    Ref,
    Message,
    Form,
} from "semantic-ui-react";
import { useHistory } from "react-router";

import { sendInvoiceViaEmail } from "./actions";
import { makePrivateApiCall } from "utilities/dist/api";

const InvoiceEmailModal = ({ invoice, open, setOpen }) => {
    const emailInputField = useRef();
    const [sentEmailAddr, setSentEmailAddr] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const { push: pushToRoute } = useHistory();

    const [formState, setFormState] = useState({
        emails: [],
        currentValues: [],
    });
    const [notes, setNotes] = useState("");

    const { emails, currentValues } = formState;

    const setDefaultCustomerEmail = () => {
        if (
            invoice?.customer?.contact_email &&
            !sentEmailAddr?.includes(invoice?.customer?.contact_email)
        ) {
            const value = invoice?.customer?.contact_email;
            setFormState({
                ...formState,
                emails: [...emails, { text: value, value }],
                currentValues: [value],
            });
            return;
        }
        setFormState({
            ...formState,
            currentValues: [],
        });
    };

    useEffect(() => {
        if (sentEmailAddr) {
            setDefaultCustomerEmail();
        }
    }, [sentEmailAddr]);

    const { id } = invoice || {};
    useEffect(() => {
        if (id && open) {
            makePrivateApiCall({
                url: `/invoice/${id}/email`,
            }).then(res => {
                if (res.data.length) {
                    setSentEmailAddr(res.data.map(item => item.emailed_to));
                }
            });
        }
    }, [id, open]);

    const cancelModal = () => {
        setOpen(false);
        setError("");
        setStatus(false);
    };

    const statusCheck = () => {
        pushToRoute("/emails");
        setError("");
    };

    const addEmail = (e, { value }) => {
        setError("");
        // TODO: change email-grid validation using JOI
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
            setFormState({
                ...formState,
                emails: [...emails, { text: value, value }],
                currentValues: [...currentValues, value],
            });
            setError("");
        } else {
            setError("Input valid email address");
            setFormState({
                ...formState,
            });
        }
    };

    const setValue = (e, { value }) => {
        setFormState({ ...formState, currentValues: value });
        setError("");
    };

    if (!open) return null;
    const sendEmail = () => {
        if (!formState.currentValues.length) {
            setError("Email address cannot be empty");
        } else {
            setLoading(true);
            sendInvoiceViaEmail({
                notes,
                beer: invoice.beer,
                emails: currentValues,
                invoiceNumber: invoice.number,
                companyId: invoice.company_id,
            })
                .then(() => {
                    setLoading(false);
                    setStatus(true);
                    setStatusMessage(true);
                    setNotes("");
                })
                .catch(() => {
                    setLoading(false);
                    setStatus(true);
                    setStatusMessage(false);
                    setNotes("");
                });
        }
    };

    return (
        <Modal closeIcon open={open} size="small" onClose={cancelModal}>
            {loading && (
                <Modal.Content>
                    <Dimmer active inverted>
                        <Loader size="large">Sending Emails</Loader>
                    </Dimmer>
                </Modal.Content>
            )}

            <>
                <Modal.Header>
                    <div className="flex justify-between">
                        <span>
                            <Icon className="mail" />
                            {status
                                ? statusMessage
                                    ? "Your email has been sent"
                                    : "Error sending email"
                                : `Send Invoice #${invoice.number}${
                                      !!invoice.beer ? "B" : ""
                                  } to customers via email`}
                        </span>
                    </div>
                </Modal.Header>

                <Modal.Content>
                    {!status && <h5>Type more email address to send Invoice to more people</h5>}
                    {status ? (
                        !statusMessage ? (
                            <h5>
                                Failed to send email. Please make sure the email addresses are valid
                                and try again
                            </h5>
                        ) : (
                            <h5>
                                Please allow a few moments for the email to be sent to recipients.
                                Click `Email log` to view your email activity below.
                            </h5>
                        )
                    ) : (
                        <>
                            {error && (
                                <Label basic color="red" pointing="below">
                                    {error}
                                </Label>
                            )}

                            <Ref innerRef={emailInputField}>
                                <Dropdown
                                    options={emails}
                                    placeholder="Add email addresses"
                                    additionLabel={<i style={{ color: "green" }}>Add: </i>}
                                    search
                                    selection
                                    fluid
                                    allowAdditions
                                    multiple
                                    onAddItem={addEmail}
                                    onChange={setValue}
                                    value={currentValues}
                                />
                            </Ref>
                            <br />
                            <Form>
                                <h5>Additional note (Optional)</h5>
                                <Form.TextArea
                                    placeholder="Write notes..."
                                    onChange={e => setNotes(e.target.value)}
                                    value={notes}
                                />
                            </Form>

                            {sentEmailAddr?.length ? (
                                <Message warning>
                                    This Invoice has already been sent to{" "}
                                    <strong>"{sentEmailAddr.join(", ")}"</strong>
                                </Message>
                            ) : (
                                ""
                            )}
                        </>
                    )}
                </Modal.Content>

                <Modal.Actions>
                    <Button
                        icon={status ? "envelope" : "cancel"}
                        onClick={!status ? cancelModal : statusCheck}
                        content={!status ? "Cancel" : "Email log"}
                    />

                    <Button
                        content={status ? "Close" : "Send Email"}
                        labelPosition="right"
                        icon="checkmark"
                        onClick={status ? cancelModal : sendEmail}
                        positive
                    />
                </Modal.Actions>
            </>
        </Modal>
    );
};
export default InvoiceEmailModal;
