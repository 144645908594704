import React from "react";
import { Grid } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

import TopNav from "../../common/containers/top-nav";
import InvoicePaymentGridContainer from "../payment-grid/index.container";
import { isFeatureActive, PaymentFeatureDetails } from "../../company/feature/helpers";
import CompanyFeatureComponentItemLarge from "../../company/feature/item-large.component";
import CompanyFeatureContainerConfirmToggle from "../../company/feature/confirm-toggle.container";
import { showCompanyFeatureToggleConfirmation } from "../../company/feature/actions";

const InvoicePagePayment = () => {
    const { company } = useSelector(({ auth }) => ({
        company: auth.user.company,
    }));
    const hasPaymentFeature = isFeatureActive(company, PaymentFeatureDetails);

    const dispatch = useDispatch();
    const handleActivateFeature = feature =>{
        dispatch(showCompanyFeatureToggleConfirmation(feature));
    }

    return (
        <Grid>
            <TopNav headerIcon="dollar" headerContent="Payments" subheader="Payment Report" />

            <Grid.Column mobile={16} computer={hasPaymentFeature ? 10 : 16}>
                {hasPaymentFeature ? (
                    <InvoicePaymentGridContainer />
                ) : (
                    <>
                        <CompanyFeatureContainerConfirmToggle />
                        <CompanyFeatureComponentItemLarge
                            toggleActivation={handleActivateFeature}
                            feature={PaymentFeatureDetails}
                            isActive={false}
                        />
                    </>
                )}
            </Grid.Column>
        </Grid>
    );
};

export default InvoicePagePayment;
