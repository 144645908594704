import {
    ORDER_GRID_DATA,
    ORDER_GRID_ERROR,
    ORDER_GRID_LOADING,
    ORDER_GRID_PAGE_CHANGE,
    ORDER_GRID_STATE_CHANGE,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";

export const loadOrderGridPage = (state, grid) => dispatch => {
    let { lastRow, sortModel, filterModel } = state;
    dispatch({
        type: ORDER_GRID_LOADING,
    });

    const params = {
        endRow: grid.endRow,
        filterModel,
        sortModel,
        startRow: grid.startRow,
    };

    return makePrivateApiCall({
        url: "order/grid",
        method: "get",
        params,
    })
        .then(res => {
            if (res.data) {
                const orders = res.data.rows;
                lastRow = res.data.count;

                grid.successCallback(orders, lastRow);

                dispatch({
                    type: ORDER_GRID_DATA,
                    payload: { orders, lastRow },
                });
            }
        })
        .catch(err => {
            grid.failCallback(err);
            dispatch({
                type: ORDER_GRID_ERROR,
                payload: err.message,
            });
        });
};

export const changeOrderGridPage = page => {
    return {
        type: ORDER_GRID_PAGE_CHANGE,
        payload: page,
    };
};

export const changeOrderGridState = gridState => dispatch => {
    return dispatch({
        type: ORDER_GRID_STATE_CHANGE,
        payload: gridState,
    });
};
