"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.USER_ACTIVITY_TYPES = exports.SYSTEM_ACTIVITY_TYPES = exports.ACTIVITY_MODULES = void 0;
const ACTIVITY_MODULES = {
  AUTH: "AUTH",
  BOTTLE_POS: "BOTTLE_POS",
  INVOICE: "INVOICE",
  PAYMENT: "PAYMENT"
};
exports.ACTIVITY_MODULES = ACTIVITY_MODULES;
const USER_ACTIVITY_TYPES = {
  SIGN_IN: "SIGN_IN",
  UPDATE: "UPDATE",
  CREATE: "CREATE",
  DELETE: "DELETE"
};
exports.USER_ACTIVITY_TYPES = USER_ACTIVITY_TYPES;
const SYSTEM_ACTIVITY_TYPES = {
  INVOICE_SYNC: "INVOICE_SYNC",
  PRODUCT_SYNC: "PRODUCT_SYNC"
};
exports.SYSTEM_ACTIVITY_TYPES = SYSTEM_ACTIVITY_TYPES;