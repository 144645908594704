import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makePrivateApiCall } from "utilities/dist/api";

import { ProductPriceTierListComponent } from "../components/list";
import { ProductPriceTierCreatorContainer } from "./creator";
import {
    showProductPriceTierModal,
    setPriceTierRemoveId,
    setProductPriceTierInput,
} from "../actions";
import { ProductPriceTierRemoveModalContainer } from "./remove-modal";

export const ProductPriceTierListContainer = () => {
    const dispatch = useDispatch();
    const productPriceTiers = useSelector(({ auth }) =>
        auth.user.company.productPriceTiers?.sort((a, b) => b.is_base_tier - a.is_base_tier)
    );
    const [customerCountByPriceTier, setCustomerCountByPriceTier] = useState({});

    const syncCustomerCount = () =>
        makePrivateApiCall({
            url: `/product/price-tier/count-customer`,
        }).then(res => {
            setCustomerCountByPriceTier(res.data);
        });

    useEffect(() => {
        syncCustomerCount();
    }, []);

    return (
        <>
            <ProductPriceTierRemoveModalContainer
                customerCountByPriceTier={customerCountByPriceTier}
                onRemove={syncCustomerCount}
            />
            <ProductPriceTierCreatorContainer />
            <ProductPriceTierListComponent
                priceTiers={productPriceTiers}
                customerCountByPriceTier={customerCountByPriceTier}
                onEditClick={priceTier => {
                    dispatch(setProductPriceTierInput(priceTier));
                    dispatch(showProductPriceTierModal(true));
                }}
                onAddNewTier={() => dispatch(showProductPriceTierModal(true))}
                onRemoveClick={id => dispatch(setPriceTierRemoveId(id))}
            />
        </>
    );
};
