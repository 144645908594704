import React from "react";
import { format } from "date-fns";
import Clipboard from "react-clipboard.js";
import { Segment, Header, List, Button } from "semantic-ui-react";
import { toast } from "react-semantic-toasts";

import { parseServerTimestampForClient } from "../../common/helpers";
import { formatAmount } from "utilities/dist/helpers";
import { DateFormat } from "utilities/dist/invoice/constants";

const ProductSingleComponent = ({ product, onEdit }) => {
    const prices = [
        `List ${formatAmount(product.product_price)}`,
        product.buying_price ? `Buying ${formatAmount(product.buying_price)}` : "",
    ].filter(Boolean);
    const deets = [product.product_type, product.product_size].map(p => p.toUpperCase());

    const productPriceHistory = product.priceChanges;

    return (
        <>
            <Segment attached="top">
                <p>
                    <Clipboard
                        component="i"
                        data-clipboard-text={product.product}
                        button-className="copy icon clickable"
                        onSuccess={data => {
                            toast({
                                icon: "copy outline",
                                title: `Copied to clipboard`,
                                description: `${data.text} has been copied`,
                            });
                        }}
                    />{" "}
                    <b>{product.product}</b>
                    <br />
                    <Clipboard
                        component="i"
                        data-clipboard-text={product.upc}
                        button-className="copy icon clickable"
                        onSuccess={data => {
                            toast({
                                icon: "copy outline",
                                title: `Copied to clipboard`,
                                description: `${data.text} has been copied`,
                            });
                        }}
                    />{" "}
                    UPC: {product.upc}
                    <br />
                    {[...deets, ...prices].join(" | ")}
                </p>

                {productPriceHistory?.length > 0 && (
                    <>
                        <Header size="small" content="Price Changes" dividing />
                        <List relaxed>
                            {productPriceHistory.map(change => (
                                <List.Item key={`product_price_change_${change.id}`}>
                                    <List.Header>
                                        {formatAmount(change.old_price)} {"->"}{" "}
                                        {formatAmount(change.new_price)}
                                    </List.Header>
                                    <List.Description>
                                        Changed on{" "}
                                        {format(
                                            parseServerTimestampForClient(change.created_at),
                                            DateFormat
                                        )}
                                    </List.Description>
                                    <List.Description>
                                        Effective from{" "}
                                        {format(
                                            parseServerTimestampForClient(change.effective_date),
                                            DateFormat
                                        )}
                                    </List.Description>
                                </List.Item>
                            ))}
                        </List>
                    </>
                )}
            </Segment>
            {!!onEdit && (
                <Button
                    icon="edit"
                    content="Edit"
                    attached="bottom"
                    onClick={() => onEdit(product)}
                />
            )}
        </>
    );
};

export default ProductSingleComponent;
