import { pick } from "lodash";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Input } from "formik-semantic-ui";
import { Segment, Message, Header } from "semantic-ui-react";

import { authUpdateProfile } from "../actions";

const AuthContainerProfile = () => {
    const [showPassword, setShowPassword] = useState(false);

    const dispatch = useDispatch();
    const { user, profileUpdating, profileUpdateErrors } = useSelector(({ auth }) => auth);

    const handleSubmit = async (data, formik) => {
        await dispatch(authUpdateProfile(data));
        formik.setSubmitting(false);
    };

    return (
        <>
            <Header
                subheader="Changing password will reset your account's password"
                content="Account Details"
                attached="top"
                as="h3"
            />
            <Segment attached>
                <Form
                    onSubmit={handleSubmit}
                    isSubmitting={profileUpdating}
                    initialValues={pick(user, ["name"])}
                >
                    <Input name="name" label="Name" type="text" />
                    <Input
                        name="password"
                        label="Password"
                        inputProps={{
                            action: (
                                <Button
                                    type="button"
                                    icon={showPassword ? "eye" : "eye slash"}
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            ),
                            type: showPassword ? "text" : "password",
                        }}
                    />
                    <Button.Submit positive>Save Account</Button.Submit>
                </Form>

                {profileUpdateErrors && profileUpdateErrors.length > 0 && (
                    <Message
                        error
                        icon="warning sign"
                        content={profileUpdateErrors}
                        header="Error saving company!"
                    />
                )}
            </Segment>
        </>
    );
};

export default AuthContainerProfile;
