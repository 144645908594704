import React from "react";
import { Grid } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import { BottlePosContainerSyncLog } from "../containers/sync-log";

const BottlePosPageSyncLog = ({ computedMatch }) => {
    return (
        <Grid>
            <TopNav headerIcon="flask" subheader="Bottle POS Sync Log" headerContent="Bottle POS" />

            <Grid.Column mobile={16} computer={16}>
                <BottlePosContainerSyncLog syncId={computedMatch.params.syncId} />
            </Grid.Column>
        </Grid>
    );
};

export default BottlePosPageSyncLog;
