import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { generateReducer } from "../helpers";
import { APP_SIDE_NAV_MINIMIZE, APP_SIDE_NAV_TOGGLE, APP_SIDE_NAV_VIEW_CHANGE } from "../constants";

const initialState = {
    view: null,
    isMini: true,
    isShown: true,
};

const changeView = (state, view) => ({ ...state, view });
const resize = (state, isMini) => ({ ...state, isMini });
const toggle = state => ({ ...state, isShown: !state.isShown });

const reducers = {
    [APP_SIDE_NAV_VIEW_CHANGE]: changeView,
    [APP_SIDE_NAV_MINIMIZE]: resize,
    [APP_SIDE_NAV_TOGGLE]: toggle,
};

const navReducer = generateReducer(reducers, initialState);

export default persistReducer(
    {
        key: "nav",
        storage,
    },
    navReducer
);
