import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { DateTimeWithoutYear } from "utilities/dist/invoice/constants";

import InvoiceComponentStatus from "../components/status";
import { cloneInvoice } from "../builder/actions";
import { EmailFeatureDetails, isFeatureActive } from "../../company/feature/helpers";
import { buildInvoiceNumberWithBeer, generatePdf } from "../helpers";
import InvoiceEmailModal from "../builder/invoice-email.component";

export const InvoiceLinkRenderer = ({ value }) => {
    // grid values are parsed through valueGetter which adds B if inv is for beer so we replace that from link
    return <Link to={`/invoice/${value}`}>#{value}</Link>;
};

export const InvoiceStatusRenderer = ({ value }) => {
    return (
        <span className="tm-10 dib">
            <InvoiceComponentStatus size="mini" noIcon={true} status={value} />
        </span>
    );
};
export const InvoiceHistoryRenderer = ({ data }) => {
    const statusPattern = /(\b[a-z](?!\s))/g;
    const invoiceStatus = data?.status?.replace(/_|payment/gi, " ");
    return (
        <>
            {data?.orders?.length ? (
                <Popup
                    trigger={<Icon name="cart" color="green" />}
                    content={`${format(
                        new Date(data.orders[0].created_at),
                        DateTimeWithoutYear
                    )} Order #${data.orders[0].order_number} placed`}
                    basic
                />
            ) : (
                ""
            )}{" "}
            {data?.statusInfo?.length > 0 ? (
                <Popup
                    trigger={<Icon name="newspaper" color="green" />}
                    content={
                        <>
                            <p>
                                {format(new Date(data.created_at), DateTimeWithoutYear)} - Invoice
                                created
                            </p>
                            <div>
                                {data.statusInfo.map(i => (
                                    <p key={i.id}>
                                        {format(new Date(i?.created_at), DateTimeWithoutYear)} -
                                        Status changed to{" "}
                                        <b>
                                            {i.new_status
                                                .replace(statusPattern, x => x.toUpperCase())
                                                .replace(/_|payment/gi, " ")}
                                        </b>{" "}
                                    </p>
                                ))}
                            </div>
                        </>
                    }
                    basic
                />
            ) : (
                <>
                    <Popup
                        trigger={<Icon name="newspaper" color="green" />}
                        content={
                            <>
                                {data?.created_at !== data?.updated_at ? (
                                    <>
                                        <p>
                                            {data?.created_at
                                                ? format(
                                                      new Date(data?.created_at),
                                                      DateTimeWithoutYear
                                                  )
                                                : ""}{" "}
                                            - Invoice created
                                        </p>
                                        <p>
                                            {data?.updated_at
                                                ? format(
                                                      new Date(data?.updated_at),
                                                      DateTimeWithoutYear
                                                  )
                                                : ""}{" "}
                                            - status changed to{" "}
                                            <b>
                                                {invoiceStatus.replace(statusPattern, x =>
                                                    x.toUpperCase()
                                                )}
                                            </b>
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p>
                                            {data?.created_at
                                                ? format(
                                                      new Date(data?.created_at),
                                                      DateTimeWithoutYear
                                                  )
                                                : " "}{" "}
                                            - Invoice created
                                        </p>
                                    </>
                                )}
                            </>
                        }
                        basic
                    />
                </>
            )}{" "}
            {data?.payments?.length ? (
                <Popup
                    trigger={<Icon name="payment" color="green" />}
                    content={`Payment has received at ${format(
                        new Date(data.payments[0].received_at),
                        DateTimeWithoutYear
                    )}`}
                    basic
                />
            ) : (
                <Popup
                    trigger={<Icon name="payment" color="grey" />}
                    content="No payments logged"
                    basic
                />
            )}
        </>
    );
};

export const InvoiceActionsRenderer = ({ data }) => {
    const dispatch = useDispatch();
    const handleClone = () => dispatch(cloneInvoice(data));
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [emailModalOpen, setEmailModalOpen] = useState(false);

    const { company } = useSelector(({ auth }) => ({
        company: auth.user.company,
    }));
    const hasEmailFeature = isFeatureActive(company, EmailFeatureDetails);

    if (!data) {
        return null;
    }

    return (
        <>
            <Link
                to="/invoice"
                title="Download Pdf"
                onClick={async e => {
                    e.preventDefault();
                    if (generatingPdf) return;
                    await generatePdf(buildInvoiceNumberWithBeer(data), setGeneratingPdf);
                }}
            >
                <Icon disabled={generatingPdf} name={generatingPdf ? "spinner" : "file"} />
            </Link>
            <Link title="Edit Invoice" to={`/invoice/${buildInvoiceNumberWithBeer(data)}/edit`}>
                <Icon name="pencil" />
            </Link>
            <Link title="Clone Invoice" onClick={handleClone} to={`/invoice/create`}>
                <Icon name="copy" />
            </Link>
            {hasEmailFeature && (
                <>
                    <Link
                        title="Email"
                        onClick={e => {
                            e.preventDefault();
                            setEmailModalOpen(true);
                        }}
                        to="/invoice"
                    >
                        <Icon name="envelope" />
                    </Link>
                    <InvoiceEmailModal
                        invoice={data}
                        open={emailModalOpen}
                        setOpen={setEmailModalOpen}
                    />
                </>
            )}
        </>
    );
};
