import { Header, Label, Message } from "semantic-ui-react";
import React, { useState, useEffect } from "react";
import { isString, startCase } from "lodash";
import { format } from "date-fns";

import { CustomerType } from "../../customer/types";
import { loadCustomerExpiration } from "../../customer/services/helpers";
import { DateFormat } from "utilities/dist/invoice/constants";
import { parseServerDateForClient } from "../../common/helpers";
import { PriceTierLabel } from "../../product/price-tier/components/label";

const InvoiceComponentCustomerHeader = ({ customer = {}, priceTier = null }) => {
    const [expiration, setExpiration] = useState(null);

    useEffect(() => {
        if (customer && customer.id) {
            loadCustomerExpiration(customer.id).then(tabcData => {
                setExpiration(tabcData);
            });
        }
    }, [customer]);

    let expirationMessage = "",
        delinquentMessage = "";

    if (expiration) {
        if (expiration.isExpired || expiration.expiringInDays) {
            if (!expiration.expiration_date) {
                expirationMessage = "has expired";
            } else {
                const date = format(
                    parseServerDateForClient(expiration.expiration_date),
                    DateFormat
                );
                if (expiration.isExpired) {
                    expirationMessage = `has expired on ${date}`;
                } else if (expiration.expiringInDays < 30) {
                    expirationMessage = `is about to expire on ${date}`;
                }
            }
        } else if (expiration.delinquency) {
            // if permit is expired, delinquent warning is redundant so this block is only evaluated if expiration warning is not applicable in the previous condition
            delinquentMessage = format(
                parseServerDateForClient(expiration.delinquency.effective_date),
                DateFormat
            );
        }
    }

    return (
        <>
            <Header textAlign="right">
                <Header sub>
                    <b>TABC ID #{customer.tabc_id}</b>
                </Header>
                {startCase(customer.name)}
                <Header.Subheader>{startCase(customer.address)}</Header.Subheader>
                <Header.Subheader>
                    {[customer.city, customer.state, customer.zip].filter(isString).join(", ")}
                    {!!priceTier && (
                        <p className="tp-10 no-print">
                            <PriceTierLabel priceTier={priceTier} />
                        </p>
                    )}
                </Header.Subheader>
            </Header>
            {!!expirationMessage && (
                <Message
                    className="no-print"
                    error={expiration.isExpired}
                    warning={expiration.expiringInDays < 30}
                    content={`${customer.name}'s TABC permit ${expirationMessage}!`}
                />
            )}
            {!!delinquentMessage && (
                <Message
                    warning
                    className="no-print"
                    header={`${customer.name} is on the TABC delinquent list`}
                    content={`No sales or deliveries may be made on or after ${expirationMessage}!`}
                />
            )}
        </>
    );
};

InvoiceComponentCustomerHeader.propTypes = {
    customer: CustomerType.isRequired,
};

export default InvoiceComponentCustomerHeader;
