import { shape, string, number, bool, any, arrayOf, oneOfType } from "prop-types";

import { ProductType } from "../product/types";
import { CustomerType } from "../customer/types";

export const InvoiceItemType = shape({
    id: number,
    total: number,
    price: number,
    product: ProductType,
    qty: oneOfType([string, number]),
    taxable: oneOfType([bool, number]),
});

export const InvoiceType = shape({
    id: number,
    total: number,
    number: number,
    status: string,
    invoiced_at: any,
    tax_total: number,
    sub_total: number,
    customer: CustomerType,
    items: arrayOf(InvoiceItemType),
});
