"use strict";

module.exports = {
  logo_upload_path: "test-uploaded-files",
  uploaded_fintech_invoice_file_path: "uploaded_fintech_invoice",
  bodyLimit: "100kb",
  corsHeaders: ["Link"],
  redis: {
    host: process.env.REDIS_HOST || "127.0.0.1",
    port: process.env.REDIS_PORT || "6379"
  },
  // keep the tokens alive for 23h
  jwt_expiration: "23h",
  jwt_encryption: "aasdfasdf sdf adsf",
  pdf_path: "/temp/pdf",
  s3: {
    bucket: "ynvoice-test123",
    access: {
      region: "",
      secretAccessKey: "",
      accessKeyId: ""
    }
  }
};