import {
    INVENTORY_GRID_CELL_EDIT_CONFIRMATION_TOGGLE,
    INVENTORY_GRID_CELL_UPDATE_SUCCESS,
    INVENTORY_GRID_DATA,
    INVENTORY_GRID_ERROR,
    INVENTORY_GRID_LOADING,
    INVENTORY_GRID_PAGE_CHANGE,
    INVENTORY_GRID_STATE_CHANGE,
} from "../constants";
import { authExpire } from "../../auth/actions";
import { makePrivateApiCall } from "utilities/dist/api";
import { parseAmount } from "../../common/helpers";
import { parseCurrency } from "../../common/helpers";
import { toast } from "react-semantic-toasts";

export const loadInventoryGridPage = (state, grid) => dispatch => {
    let { lastRow, sortModel, filterModel } = state;

    dispatch({
        type: INVENTORY_GRID_LOADING,
    });

    const params = {
        endRow: grid.endRow,
        filterModel,
        sortModel,
        startRow: grid.startRow,
    };

    return makePrivateApiCall({
        url: "/inventory/grid",
        method: "get",
        params,
    })
        .then(res => {
            if (res.data) {
                const inventories = res.data.rows;
                lastRow = res.data.count;

                grid.successCallback(inventories, lastRow);

                dispatch({
                    type: INVENTORY_GRID_DATA,
                    payload: { inventories, lastRow },
                });
            }
        })
        .catch(err => {
            grid.failCallback(err);

            if (err.response && err.response.status === 401) {
                return dispatch(authExpire());
            }

            dispatch({
                type: INVENTORY_GRID_ERROR,
                payload: err.message,
            });
        });
};

export const changeInventoryGridPage = page => {
    return {
        type: INVENTORY_GRID_PAGE_CHANGE,
        payload: page,
    };
};

export const changeInventoryGridState = gridState => dispatch =>
    dispatch({
        type: INVENTORY_GRID_STATE_CHANGE,
        payload: gridState,
    });

const formatInventoryForExport = inventoryRow => {
    const row = {
        Reorder: inventoryRow.reorder_needed ? "Yes" : "No",
        UPC: inventoryRow.product.upc,
        Product: inventoryRow.product.product,
        "Size": inventoryRow.product.product_size,
        "Unit Price": parseAmount(inventoryRow.product.product_price),
        "In Stock": inventoryRow.qty,
        Distributor: inventoryRow.distributor,
        "Min Reorder": inventoryRow.reorder_level,
        "Days To Reorder": inventoryRow.reorder_time_in_days,
        "Qty To Reorder": inventoryRow.reorder_qty,
        "Inventory Value": parseAmount(
            parseCurrency(inventoryRow.product.product_price) * inventoryRow.qty
        ),
    };

    return row;
};

export const exportInventory = state => () => {
    let { sortModel, filterModel } = state;

    const params = {
        filterModel,
        sortModel,
        ignoreLimit: true,
    };

    return makePrivateApiCall({
        url: "/inventory/grid",
        method: "get",
        params,
    }).then(res => {
        if (res.data) {
            return res.data.rows.map(formatInventoryForExport);
        }

        throw new Error("No inventory found");
    });
};

export const setInventoryGridCellEditConfirmation =
    (isEnabled = false) =>
    dispatch =>
        dispatch({
            type: INVENTORY_GRID_CELL_EDIT_CONFIRMATION_TOGGLE,
            payload: isEnabled,
        });

export const updateInventoryItem = (id, column, value) => dispatch => {
    return makePrivateApiCall({
        url: `/inventory/${id}`,
        method: "put",
        data: { [column]: value },
    })
        .then(res => {
            if (!res.data) {
                toast({
                    type: "error",
                    title: "Error updating inventory",
                });
                throw new Error("Error updating inventory");
            }

            dispatch({
                type: INVENTORY_GRID_CELL_UPDATE_SUCCESS,
                payload: res.data,
            });
        })
        .catch(err => {
            toast({
                type: "error",
                description: err.message,
                title: "Error updating inventory",
            });
            // Throw back so that the caller can reset the cell value
            throw err;
        });
};