export const CUSTOMER_USER_LIST_LOADING = "CUSTOMER_USER_LIST_LOADING";
export const CUSTOMER_USER_LIST_DATA = "CUSTOMER_USER_LIST_DATA";
export const CUSTOMER_USER_LIST_UPDATE = "CUSTOMER_USER_LIST_UPDATE";
export const CUSTOMER_USER_LIST_ROW_UPDATE = "CUSTOMER_USER_LIST_ROW_UPDATE";
export const CUSTOMER_USER_LIST_ERROR = "CUSTOMER_USER_LIST_ERROR";
export const CUSTOMER_USER_ACCESS_SAVED = "CUSTOMER_USER_ACCESS_SAVED";

export const ALL_CUSTOMER_USER_LIST_LOADING = "ALL_CUSTOMER_USER_LIST_LOADING";
export const ALL_CUSTOMER_USER_LIST_DATA = "ALL_CUSTOMER_USER_LIST_DATA";
export const ALL_CUSTOMER_USER_LIST_ERROR = "ALL_CUSTOMER_USER_LIST_ERROR";

export const CUSTOMER_USER_GRID_STATE_CHANGE = "CUSTOMER_USER_GRID_STATE_CHANGE";
export const ALL_CUSTOMER_USER_GRID_STATE_CHANGE = "ALL_CUSTOMER_USER_GRID_STATE_CHANGE";
