"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isCompanyFeatureActive = void 0;

const isCompanyFeatureActive = (company, feature) => {
  return company && company.companyFeatures && !!company.companyFeatures.find(f => f.name === feature && !!f.active);
};

exports.isCompanyFeatureActive = isCompanyFeatureActive;