import { isEmpty } from "lodash";

import {
    PRODUCT_SEARCH_SELECT,
    PRODUCT_SEARCH_CHANGE_QUERY,
    PRODUCT_SEARCH_SUGGESTIONS_DATA,
    PRODUCT_SEARCH_SUGGESTIONS_ERROR,
    PRODUCT_SEARCH_SUGGESTIONS_LOADING,
    PRODUCT_SEARCH_CLEAR,
    PRODUCT_EDITOR_SAVED,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    error: null,
    selected: {},
    inputQuery: "",
    suggestions: [],
    isLoading: false,
    hasInitiatedSearch: false,
};

const setLoading = state => ({ ...state, isLoading: true, hasInitiatedSearch: true });
const changeQuery = (state, inputQuery) => {
    const newState = {
        ...state,
        inputQuery,
        hasInitiatedSearch: inputQuery.length < 3,
    };

    if (inputQuery.length === "") newState.suggestions = [];

    return newState;
};
const clearProduct = state => ({
    ...state,
    inputQuery: "",
    hasInitiatedSearch: false,
    selected: {},
    suggestions: [],
});

const setSuggestionError = (state, error) => ({
    ...state,
    isLoading: false,
    error,
});
const setSuggestions = (state, suggestions) => ({
    ...state,
    suggestions,
    isLoading: false,
    error: null,
});
const addNewProductToSuggestion = (state, product) => ({
    ...state,
    suggestions: [...state.suggestions.filter(({ id }) => product.id !== id), product],
});
const selectProduct = (state, selected) => ({
    ...state,
    selected,
    suggestions: isEmpty(selected) ? [] : state.suggestions,
    inputQuery: !isEmpty(selected) ? `${selected.product} (${selected.product_size})` : "",
});

const reducers = {
    [PRODUCT_SEARCH_CLEAR]: clearProduct,
    [PRODUCT_SEARCH_SELECT]: selectProduct,
    [PRODUCT_SEARCH_CHANGE_QUERY]: changeQuery,
    [PRODUCT_SEARCH_SUGGESTIONS_LOADING]: setLoading,
    [PRODUCT_SEARCH_SUGGESTIONS_DATA]: setSuggestions,
    [PRODUCT_SEARCH_SUGGESTIONS_ERROR]: setSuggestionError,

    [PRODUCT_EDITOR_SAVED]: addNewProductToSuggestion,
};

export default generateReducer(reducers, initialState);
