import React from "react";
import { isString } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Input, Form, Dropdown, Button, Message, Segment } from "semantic-ui-react";

import { history } from "../../store";
import {
    changeInvoiceBuilderStamp,
    addInvoiceBuilderStampRoll,
    selectInvoiceBuilderStampRoll,
} from "../builder/actions";
import { formatStampRange } from "../../common/helpers";

const RollSelector = ({ rolls, onSelect }) => {
    const options = rolls.map((roll, rollIndex) => ({
        icon: "ticket",
        value: rollIndex,
        key: `stamp_roll_${roll.roll_id}_${roll.firstAvailable}_${roll.lastAvailable}`,
        text: formatStampRange({
            prefix: roll.prefix,
            from: roll.firstAvailable,
            to: roll.lastAvailable,
        }),
    }));

    options.push({
        key: `stamp_roll_create_link`,
        value: "/stamp/roll",
        text: "Add New Roll",
        icon: "plus",
    });

    return (
        <Dropdown
            onChange={(e, data) => {
                if (isString(data.value) && data.value.indexOf("/") >= 0) history.push(data.value);
                else onSelect(data.value);
            }}
            selectOnBlur={false}
            text="Change Roll"
            options={options}
            floating
            button
        />
    );
};

const InvoiceBuilderContainerStampInput = () => {
    const { stamps, isEditing, editingStamp, availableStampSequences } = useSelector(
        ({ invoiceBuilder }) => ({
            stamps: invoiceBuilder.stamps,
            editingStamp: invoiceBuilder.editingStamp,
            isEditing: invoiceBuilder.invoice && invoiceBuilder.invoice.id,
            availableStampSequences: invoiceBuilder.availableStampSequences,
        })
    );

    const dispatch = useDispatch();
    const handleRollSelect = rollIndex => dispatch(selectInvoiceBuilderStampRoll(rollIndex));
    const handleStampChange = stampData => dispatch(changeInvoiceBuilderStamp(stampData));
    const handleStampRollAdd = e => {
        e.preventDefault();
        dispatch(addInvoiceBuilderStampRoll());
    };
    const handleInputClear = () => dispatch(changeInvoiceBuilderStamp({}));

    const handleAddRollClick = () => {
        if (availableStampSequences.length < 1) {
            return history.push("/stamp/roll");
        }

        handleRollSelect(0);
    };

    if (!editingStamp.roll_id) {
        return (
            <Button
                onClick={handleAddRollClick}
                attached="bottom"
                content="Stamps"
                size="tiny"
                icon="plus"
            />
        );
    }

    let overlap = null;
    stamps.forEach(stamp => {
        const { roll_id, from, to } = stamp;

        if (editingStamp.roll_id !== roll_id) return;

        const hasOverlap =
            (editingStamp.from >= from && editingStamp.from <= to) ||
            (editingStamp.to <= to && editingStamp.to >= from);

        if (hasOverlap) overlap = stamp;
    });

    let fromIsAboveBoundary = false,
        toIsBelowBoundary = false,
        fromIsBelowBoundary = false,
        toIsAboveBoundary = false;

    if (editingStamp.lastAvailable) {
        fromIsAboveBoundary = editingStamp.from > editingStamp.lastAvailable;
        toIsAboveBoundary = editingStamp.to > editingStamp.lastAvailable;

        // if editing invoice, it will always be below boundary so no point showing that
        if (!isEditing) {
            toIsBelowBoundary = editingStamp.to < editingStamp.firstAvailable;
            fromIsBelowBoundary = editingStamp.from < editingStamp.firstAvailable;
        }
    }

    return (
        <>
            <Segment attached>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Stamp Start</label>
                            <Input
                                size="small"
                                type="number"
                                value={editingStamp.from}
                                placeholder="Stamps From"
                                className="hide-number-arrows"
                                icon={{
                                    name: "close",
                                    link: true,
                                    onClick: e => {
                                        e.preventDefault();
                                        // when from is cleared, we need to clear both start and end since it usually means they will select a new block
                                        handleStampChange({ from: "", to: "" });
                                    },
                                }}
                                min={editingStamp.firstAvailable}
                                label={editingStamp.prefix ? editingStamp.prefix : false}
                                onChange={(e, { value }) =>
                                    handleStampChange({
                                        from: value,
                                    })
                                }
                            />
                            {fromIsBelowBoundary && (
                                <div className="ui pointing above prompt label">
                                    Stamp number {editingStamp.from} is below the first available
                                    stamp <b>{editingStamp.firstAvailable}</b> in the roll.
                                </div>
                            )}
                            {fromIsAboveBoundary && (
                                <div className="ui pointing above prompt label">
                                    Stamp number {editingStamp.from} is above the last available
                                    stamp <b>{editingStamp.lastAvailable}</b> in the roll.
                                </div>
                            )}
                        </Form.Field>
                        <Form.Field>
                            <label>Stamp End &nbsp;</label>
                            <Input
                                size="small"
                                type="number"
                                placeholder="To"
                                value={editingStamp.to}
                                min={editingStamp.from || 1}
                                className="hide-number-arrows"
                                icon={{
                                    name: "close",
                                    link: true,
                                    onClick: e => {
                                        e.preventDefault();
                                        handleStampChange({ to: "" });
                                    },
                                }}
                                label={editingStamp.prefix ? editingStamp.prefix : false}
                                onChange={(e, { value }) =>
                                    handleStampChange({
                                        to: value,
                                    })
                                }
                            />
                            {toIsAboveBoundary && (
                                <div className="ui pointing above prompt label">
                                    Stamp number {editingStamp.to} is above the last available stamp{" "}
                                    <b>{editingStamp.lastAvailable}</b> in the roll.
                                </div>
                            )}
                            {toIsBelowBoundary && (
                                <div className="ui pointing above prompt label">
                                    End Stamp number must be above the first available stamp{" "}
                                    <b>{editingStamp.firstAvailable}</b> and the start stamp number{" "}
                                    <b>{editingStamp.from}</b> the roll.
                                </div>
                            )}
                        </Form.Field>
                    </Form.Group>

                    {!!overlap && (
                        <Message
                            warning
                            visible
                            content={`${formatStampRange(
                                editingStamp
                            )} is overlapping with already added stamp range ${formatStampRange(
                                overlap
                            )}`}
                        />
                    )}
                </Form>
            </Segment>
            <Button.Group size="tiny" attached>
                <Button
                    content="Add"
                    icon="checkmark"
                    onClick={handleStampRollAdd}
                    disabled={editingStamp.from < 1 || editingStamp.to < editingStamp.from}
                />
                <RollSelector onSelect={handleRollSelect} rolls={availableStampSequences} />
                <Button content="Cancel" onClick={handleInputClear} icon="close" />
            </Button.Group>
        </>
    );
};

export default InvoiceBuilderContainerStampInput;
