import Joi from "joi";
import { omit } from "lodash";

const schema = {
    id: Joi.number().required(),
    name: Joi.string().trim().required(),
    city: Joi.string().trim().required(),
    state: Joi.string().trim().required(),
    address: Joi.string().trim().required(),
    tabc_id: Joi.string().trim().required().label("tabc id"),
    contact_name: Joi.string().trim().allow("", null).label("contact name"),
    contact_phone: Joi.string().trim().allow("", null).label("contact phone"),
    contact_email: Joi.string()
        .email({ tlds: { allow: false } })
        .trim()
        .allow("", null)
        .label("contact email"),
    zip: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
    cash_on_delivery: Joi.alternatives()
        .try(Joi.number().valid(0, 1), Joi.boolean())
        .required()
        .allow(null),
    ydk_est_id: Joi.alternatives().try(Joi.number(), Joi.string().allow("", null)),
    fintech_option: Joi.alternatives()
        .try(Joi.number().valid(0, 1), Joi.boolean())
        .required()
        .allow(null),
    fintech_id: Joi.string().trim().allow("", null).label("fintech id"),
    product_price_tier_id: Joi.number().allow("", null).label("Price Tier"),
};

const validateFintechOption = ({ fintech_option, fintech_id }) => {
    if (fintech_option) {
        Joi.assert(
            fintech_id,
            Joi.string()
                .trim()
                .required()
                .error(() => {
                    return {
                        message: "Fintech ID can't be empty while Fintech Option is enabled",
                    };
                })
        );
    }
};

export const validateCustomerCreate = customer => {
    validateFintechOption(customer);

    return Joi.object(omit(schema, ["id"])).validateAsync(customer, {
        convert: true,
        stripUnknown: true,
    });
};

export const validateCustomerUpdate = customer => {
    validateFintechOption(customer);

    return Joi.object(schema).validateAsync(customer, {
        convert: true,
        stripUnknown: true,
    });
};
