import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form } from "formik-semantic-ui";
import { bindActionCreators } from "redux";
import { toast } from "react-semantic-toasts";
import { Message, Modal, Label } from "semantic-ui-react";

import {
    saveInvoicePayment,
    setInvoicePaymentEditorError,
    showInvoicePaymentEditorModal,
} from "./actions";
import { InvoiceType } from "../types";
import { computePaymentTotal } from "../helpers";
import { formatAmount } from "utilities/dist/helpers";
import { validateInvoicePaymentCreate, validateInvoicePaymentUpdate } from "../validators";

import InvoicePaymentComponentEditorForm from "./editor.component";

class InvoicePaymentEditorContainer extends React.Component {
    formikApi = null;

    handleSubmit = async (paymentData, formik) => {
        try {
            let payment;

            if (paymentData.id) payment = await validateInvoicePaymentUpdate(paymentData);
            else payment = await validateInvoicePaymentCreate(paymentData);

            const res = await this.props.saveInvoicePayment(this.props.invoice.id, payment);
            formik.setSubmitting(false);

            if (res) this.props.onComplete(payment);
        } catch (err) {
            const messages = [];
            err.details.forEach(errDet => {
                formik.setFieldError(errDet.context.key, errDet.message);
                messages.push(errDet.message);
            });

            formik.setSubmitting(false);
            this.props.setInvoicePaymentEditorError(messages);
        }
    };

    handleConfirm = data => {
        this.formikApi && this.formikApi.setSubmitting(false);

        if (data !== null) {
            let description = ["Invoice Payment saved in database."];

            toast({
                description: <Message.List items={description} />,
                time: 6000,
                type: "success",
                icon: "checkmark",
                title: "Created Invoice Payment",
            });

            this.props.history.push("/payment");
        }
    };

    render() {
        const { invoice, errors, inputs, showModal, isSaving, toggleModal } = this.props;
        const paymentTotal = computePaymentTotal(invoice);
        const paymentRemainder = invoice.total - paymentTotal;

        return (
            <Modal closeIcon size="small" open={showModal} onClose={() => toggleModal(false)}>
                <Modal.Header>
                    Log payment &nbsp;
                    <Label
                        basic
                        size="tiny"
                        color={paymentRemainder > 0 ? "blue" : "green"}
                        content={
                            paymentRemainder > 0
                                ? `Remaining ${formatAmount(paymentRemainder)}`
                                : "Fully Paid"
                        }
                    />
                </Modal.Header>
                <Modal.Content>
                    {paymentRemainder <= 0 && (
                        <Message
                            warning
                            icon="warning sign"
                            content="Invoice has been fully paid, sure you want to log another payment?"
                        />
                    )}

                    <Form
                        initialValues={inputs}
                        isSubmitting={isSaving}
                        onSubmit={this.handleSubmit}
                        render={props => (
                            <InvoicePaymentComponentEditorForm
                                {...props}
                                onCancel={() => toggleModal(false)}
                            />
                        )}
                    />

                    {errors.length > 0 && (
                        <Message
                            error
                            content={errors}
                            icon="warning sign"
                            header="Error saving payment!"
                        />
                    )}
                </Modal.Content>
            </Modal>
        );
    }
}

const mapStateToProps = ({ invoicePayment }) => ({
    errors: invoicePayment.errors,
    inputs: invoicePayment.inputs,
    isSaving: invoicePayment.isSaving,
    showModal: invoicePayment.showModal,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            saveInvoicePayment,
            setInvoicePaymentEditorError,
            toggleModal: show => dispatch(showInvoicePaymentEditorModal(show)),
        },
        dispatch
    );

InvoicePaymentEditorContainer.propTypes = {
    invoice: InvoiceType,
    onComplete: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePaymentEditorContainer);
