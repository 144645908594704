import { keyBy } from "lodash";
import { format } from "date-fns";

import { DateFormat } from "utilities/dist/invoice/constants";
import { parseServerTimestampForClient } from "../../common/helpers";

export const allGridColumns = keyBy(
    [
        {
            hide: true,
            field: "roll_id",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
        },
        {
            field: "invoice.customer.name",
            headerName: "Customer",
            cellRenderer: function ({ data }) {
                if (!data) return null;
                return data.invoice.customer.name + "<br/>" + data.invoice.customer.address;
            },
            valueGetter: function ({ data }) {
                return data
                    ? `${data.invoice.customer.name}, ${data.invoice.customer.address}`
                    : null;
            },
        },
        {
            sortable: false,
            headerName: "TABC ID",
            field: "invoice.customer.tabc_id",
        },
        {
            field: "invoice.invoiced_at",
            headerName: "Date",
            // filter: 'agDateColumnFilter',
            valueGetter: ({ data }) => {
                return data && data.invoice.invoiced_at
                    ? format(parseServerTimestampForClient(data.invoice.invoiced_at), DateFormat)
                    : null;
            },
        },
        {
            headerName: "Invoice",
            field: "invoice.number",
            filter: "agNumberColumnFilter",
            cellRenderer: "invoiceLinkRenderer",
            valueGetter: function ({ data }) {
                return data ? data.invoice.number : null;
            },
        },
        {
            width: 100,
            field: "prefix",
            sortable: false,
            headerName: "Prefix",
        },
        {
            field: "from",
            type: "numericColumn",
            headerName: "Start Stamp",
            filter: "agNumberColumnFilter",
            valueGetter: function ({ data }) {
                return data ? `${data.prefix || ""}${data.from}` : null;
            },
        },
        {
            field: "to",
            type: "numericColumn",
            headerName: "End Stamp",
            filter: "agNumberColumnFilter",
            valueGetter: function ({ data }) {
                return data ? `${data.prefix || ""}${data.to}` : null;
            },
        },
        {
            sortable: false,
            field: "stamp_count",
            type: "numericColumn",
            headerName: "Used",
            filter: "agNumberColumnFilter",
            valueFormatter: function ({ data }) {
                if (!data || !data.from) return 0;
                return data.to - data.from + 1;
            },
            valueGetter: function ({ data }) {
                if (!data || !data.from) return 0;
                return data.to - data.from + 1;
            },
            "data-cy": "stamp_used",
        },
        {
            sortable: false,
            type: "numericColumn",
            field: "stamp_balance",
            headerName: "Balance",
            filter: "agNumberColumnFilter",
            valueFormatter: function ({ data }) {
                if (!data || !data.from || !data.stampRoll) return "-";
                return data.stampRoll.to - data.to;
            },
            valueGetter: function ({ data }) {
                if (!data || !data.from || !data.stampRoll) return "-";
                return data.stampRoll.to - data.to;
            },
        },
    ],
    "field"
);
