"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderStatuses = void 0;
const OrderStatuses = {
  PLACED: "placed",
  DRAFT: "draft",
  INVOICED: "invoiced",
  DELIVERED: "delivered",
  CANCELLED: "cancelled",
  PARTIALLY_INVOICED: "partially_invoiced"
};
exports.OrderStatuses = OrderStatuses;