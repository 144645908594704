import React from "react";
import { Segment, Header, Button } from "semantic-ui-react";
import { toast } from "react-semantic-toasts";
import { format } from "date-fns";
import { useDispatch } from "react-redux";

import { revokeInvitationEmail, updateUserPermission } from "../actions/list";
import { DateTimeFormat } from "utilities/dist/invoice/constants";
import { CUSTOMER_USER_LIST_ROW_UPDATE } from "../constants";
import { copyInvitationLink, getRegisteredTime } from "../helpers";
import config from "utilities/dist/config";

const SingleUserComponent = ({ setSelectedUser, user: customerUser, company, customerData }) => {
    const dispatch = useDispatch();
    const isEnable = customerUser.is_enable;
    const isRevoked = customerUser.revoked;
    const userLogginTime = customerUser?.userActivity?.map(item => item.created_at);

    const isAlreadyRegistered = getRegisteredTime(customerUser);

    const handlePermission = () => {
        updateUserPermission({ permission: !isEnable, customerUser, dispatch }).then(res => {
            toast({
                description: `You have updated access successfully`,
                time: 2000,
                type: "success",
                icon: "checkmark",
                title: "User Access",
            });
            setSelectedUser(res.data);
            dispatch({
                type: CUSTOMER_USER_LIST_ROW_UPDATE,
                payload: res.data,
            });
        });
    };

    const handleRevoke = () => {
        revokeInvitationEmail({ revoke: !isRevoked, customerUser, dispatch }).then(res => {
            toast({
                description: `You have revoked access for this email`,
                time: 2000,
                type: "success",
                icon: "checkmark",
                title: "Revoked",
            });
            setSelectedUser(res.data);
            dispatch({
                type: CUSTOMER_USER_LIST_ROW_UPDATE,
                payload: res.data,
            });
        });
    };
    const handleReactivate = () => {
        revokeInvitationEmail({ revoke: !isRevoked, customerUser, dispatch }).then(res => {
            toast({
                description: `You have re-activated the access for this email`,
                time: 2000,
                type: "success",
                icon: "checkmark",
                title: "Re-activate",
            });
            setSelectedUser(res.data);
            dispatch({
                type: CUSTOMER_USER_LIST_ROW_UPDATE,
                payload: res.data,
            });
        });
    };
    const copyInvitation = () =>
        copyInvitationLink({
            subDomain: company?.inputs?.subdomain,
            tabcId: customerData?.tabc_id,
            domain: config.client_host,
            ...customerUser,
        });

    return (
        <>
            <Segment attached="top">
                <div>
                    <p>
                        <b>{customerUser.name}</b>
                        <br />
                        {customerUser.email}
                        <br />
                        {customerUser.phone_number}
                    </p>
                </div>

                <Header size="small" content="User Activity" dividing />
                <p>
                    Last Signed In at:{" "}
                    {customerUser?.userActivity?.length
                        ? format(new Date(userLogginTime), DateTimeFormat)
                        : "Never Signed In"}
                </p>
            </Segment>

            {!!isEnable || !!isRevoked ? (
                <Button
                    icon="user times"
                    content={isAlreadyRegistered ? "Disable access" : "Re-activate"}
                    attached="bottom"
                    onClick={isAlreadyRegistered ? handlePermission : handleReactivate}
                />
            ) : (
                <>
                    <Button.Group attached="bottom">
                        <Button
                            icon={isAlreadyRegistered ? "user plus" : "cancel"}
                            color={isAlreadyRegistered ? "green" : "gray"}
                            content={isAlreadyRegistered ? "Enable access" : "Revoke"}
                            onClick={isAlreadyRegistered ? handlePermission : handleRevoke}
                        />
                        {!isAlreadyRegistered && (
                            <Button
                                color="blue"
                                icon="copy"
                                content="Copy Invite Link"
                                onClick={() => copyInvitation()}
                            />
                        )}
                    </Button.Group>
                </>
            )}
        </>
    );
};

export default SingleUserComponent;
