import React from "react";
import { Grid, Button, TextArea, Form, Table } from "semantic-ui-react";

import InvoiceComponentTotals from "../components/totals";
import InvoiceComponentSummary from "../components/summary";

const InvoiceBuilderFooterComponent = ({
    note,
    stamps,
    totals,
    status,
    invoice,
    isCancelled,

    editStamp,
    changeNote,
    saveInvoice,
    removeStamp,
    handleCancel,
}) => (
    <Table.Row verticalAlign="top">
        <Table.HeaderCell colSpan={3} verticalAlign="top">
            <Grid>
                <Grid.Column width={8}>
                    <InvoiceComponentSummary
                        totals={totals}
                        stamps={stamps}
                        status={status}
                        onStampEdit={editStamp}
                        onStampRemove={removeStamp}
                    />
                </Grid.Column>

                {!isCancelled && (
                    <Grid.Column width={8}>
                        <Form>
                            <Form.Field>
                                <label>Add a note</label>
                                {note ? (
                                    <TextArea rows={5} value={note} onChange={changeNote} />
                                ) : (
                                    <TextArea rows={5} onChange={changeNote} />
                                )}
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                )}
            </Grid>
        </Table.HeaderCell>

        <Table.HeaderCell colSpan={2} textAlign="right" verticalAlign="top">
            <InvoiceComponentTotals totals={totals} />
            {!isCancelled && (
                <div className="tp-10">
                    <Button
                        data-cy="create_invoice"
                        size="small"
                        icon="check"
                        floated="right"
                        color={invoice ? "blue" : "green"}
                        content={invoice ? "Save" : "Create"}
                        onClick={() => saveInvoice(invoice || {})}
                    />
                    {!invoice && (
                        <Button
                            color="red"
                            size="small"
                            icon="close"
                            floated="right"
                            content="Reset"
                            onClick={handleCancel}
                        />
                    )}
                </div>
            )}
        </Table.HeaderCell>
    </Table.Row>
);

export default InvoiceBuilderFooterComponent;
