import {
    INVOICE_CONVERTER_DATA,
    INVOICE_CONVERTER_ERROR,
    INVOICE_CONVERTER_LOADING,
    INVOICE_CONVERTER_UPLOADING,
    INVOICE_CONVERTER_UPLOAD_ERROR,
    INVOICE_CONVERTER_UPLOAD_SUCCESS,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    uploadData: null,
    uploadError: null,
    uploadingInvoice: null,

    files: [],
    error: null,
    isLoading: false,
};

const setUploadingInvoice = (state, uploadingInvoice) => ({
    ...state,
    uploadData: null,
    uploadingInvoice,
    uploadError: null,
});
const setUploadError = (state, uploadError) => ({
    ...state,
    uploadData: null,
    uploadingInvoice: null,
    uploadError,
});
const setUploadData = (state, uploadData) => ({
    ...state,
    uploadData,
    uploadError: null,
    uploadingInvoice: null,
});

const setFiles = (state, { rows }) => ({
    ...state,
    files: rows,
    isLoading: false,
});
const setFilesError = (state, error) => ({ ...state, error, isLoading: false });
const setFilesLoading = state => ({ ...state, error: null, isLoading: true });

const reducers = {
    [INVOICE_CONVERTER_UPLOADING]: setUploadingInvoice,
    [INVOICE_CONVERTER_UPLOAD_ERROR]: setUploadError,
    [INVOICE_CONVERTER_UPLOAD_SUCCESS]: setUploadData,

    [INVOICE_CONVERTER_DATA]: setFiles,
    [INVOICE_CONVERTER_ERROR]: setFilesError,
    [INVOICE_CONVERTER_LOADING]: setFilesLoading,
};

export default generateReducer(reducers, initialState);
