import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Grid, Segment, Dimmer, Loader, Table, Image } from "semantic-ui-react";
import { toast } from "react-semantic-toasts";

import { isCompanyFeatureActive } from "utilities/dist/company/helpers";

import { InvoiceType } from "../types";
import { CompanyType } from "../../company/types";
import { computeTotalFromItems, getProductFullName } from "../helpers";

import InvoiceComponentStatus from "./status";
import InvoiceComponentTotals from "./totals";
import InvoiceComponentSummary from "./summary";
import InvoiceComponentTimestamp from "./timestamp";
import { InvoiceComponentDueDate } from "./due-date";
import InvoiceComponentProductList from "./product-list";
import InvoiceComponentCompanyHeader from "./company-header";
import InvoiceComponentCustomerHeader from "./customer-header";
import InvoiceComponentOrderInfo from "./order-info";
import { InternalSkuFeature, TieredPricing } from "../../company/feature/helpers";

const InvoiceComponentView = ({
    isLoading,
    invoice,
    company,
    onStatusChange,
    onDateChange,
    onDueDateChange,
}) => {
    const totals = !isEmpty(invoice) ? computeTotalFromItems(invoice.items) : {};
    const isInternalSkuActive = isCompanyFeatureActive(company, InternalSkuFeature.id);
    const isTieredPricingActive = isCompanyFeatureActive(company, TieredPricing.id);

    if (invoice.items?.length) {
        invoice.items.sort((prev, next) =>
            getProductFullName(prev.product)?.localeCompare(getProductFullName(next.product))
        );
    }

    const customerPriceTier = isTieredPricingActive
        ? company?.productPriceTiers?.find(
              ({ id }) => invoice?.customer?.product_price_tier_id === id
          )
        : null;

    useEffect(() => {
        if (invoice?.cloverApiRequestLog) {
            invoice.cloverApiRequestLog.forEach(item => {
                if (!item?.success) {
                    const desc = "has not been updated";
                    const type = "error";
                    const icon = "warning sign";
                    const title = "Clover product";
                    toast({
                        description: `${item.cloverProduct.clover_product} ${desc} `,
                        time: 10000,
                        type,
                        icon,
                        title,
                    });
                }
            });
        }
    }, [invoice]);

    return (
        <Dimmer.Dimmable as={Segment} vertical className="invoice-view-dimmer">
            <Dimmer active={isLoading} inverted>
                <Loader>Loading</Loader>
            </Dimmer>
            {!isEmpty(invoice) ? (
                <div className="sp-5">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column
                                width={10}
                                textAlign="left"
                                className="invoice-view-company-header-container"
                            >
                                {invoice.logo ? (
                                    <div className="bp-10">
                                        <Image
                                            floated="left"
                                            src={invoice.logo.path}
                                            style={{
                                                marginRight: "10px",
                                                maxHeight: "100px",
                                                maxWidth: "200px",
                                            }}
                                        />
                                        {/* stupid hack cause semantic ui adds margin top on headers that are not first child */}
                                        <div>
                                            <InvoiceComponentCompanyHeader company={company} />
                                        </div>
                                    </div>
                                ) : (
                                    <InvoiceComponentCompanyHeader company={company} />
                                )}

                                <InvoiceComponentStatus
                                    status={invoice.status}
                                    onChange={onStatusChange}
                                />
                                <InvoiceComponentTimestamp
                                    onChange={onDateChange}
                                    invoiced_at={invoice.invoiced_at}
                                />
                                <InvoiceComponentDueDate
                                    due_at={invoice.due_at}
                                    onChange={onDueDateChange}
                                />
                            </Grid.Column>
                            <Grid.Column
                                width={6}
                                className="invoice-view-customer-header-container"
                            >
                                <InvoiceComponentCustomerHeader
                                    customer={invoice.customer}
                                    priceTier={customerPriceTier}
                                />
                                {invoice.orders?.length > 0 && (
                                    <InvoiceComponentOrderInfo
                                        className="no-print"
                                        order={invoice.orders}
                                    />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        <InvoiceComponentProductList
                            isInternalSkuActive={isInternalSkuActive}
                            items={invoice.items}
                            onShowInlineEditor={() => null}
                            footerContent={
                                <Table.Row verticalAlign="top">
                                    <Table.HeaderCell colSpan={3} verticalAlign="top">
                                        <Grid>
                                            <Grid.Column
                                                width={
                                                    !invoice.note || !invoice.company_note ? 8 : 6
                                                }
                                            >
                                                <InvoiceComponentSummary
                                                    totals={totals}
                                                    status={invoice.status}
                                                    stamps={invoice.stamps}
                                                />
                                            </Grid.Column>
                                            {!!invoice.note && (
                                                <Grid.Column width={!invoice.company_note ? 8 : 5}>
                                                    <b>Note</b>
                                                    <br />
                                                    {invoice.note}
                                                </Grid.Column>
                                            )}

                                            {!!invoice.company_note && (
                                                <Grid.Column width={!invoice.note ? 8 : 5}>
                                                    <b>Company Note</b>
                                                    <br />
                                                    {invoice.company_note}
                                                </Grid.Column>
                                            )}
                                        </Grid>
                                    </Table.HeaderCell>

                                    <Table.HeaderCell
                                        colSpan={2}
                                        textAlign="right"
                                        verticalAlign="top"
                                    >
                                        <InvoiceComponentTotals totals={totals} />
                                        <div className="text-right only-print tp-50 signature-area">
                                            <div className="signed-by">
                                                <div>
                                                    Recipient's signature:
                                                    <span> __________________________________</span>
                                                </div>
                                                <div>
                                                    Driver's signature:
                                                    <span> __________________________________</span>
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                            Date:
                                            <span> ________/________/________________</span>
                                        </div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            }
                        />
                    </Grid>
                </div>
            ) : (
                <div className="hp-30"></div>
            )}
        </Dimmer.Dimmable>
    );
};

InvoiceComponentView.propTypes = {
    company: CompanyType,
    invoice: InvoiceType,
    onDateChange: PropTypes.func,
    onStatusChange: PropTypes.func,
    onDueDateChange: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
};

export default InvoiceComponentView;
