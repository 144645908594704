import {
    STAMP_GRID_DATA,
    STAMP_GRID_ERROR,
    STAMP_GRID_LOADING,
    STAMP_GRID_PAGE_CHANGE,
    STAMP_GRID_STATE_CHANGE,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";

export const loadStampGridPage = (state, grid) => dispatch => {
    let { lastRow, sortModel, filterModel } = state;

    dispatch({
        type: STAMP_GRID_LOADING,
    });

    const params = {
        endRow: grid.endRow,
        filterModel,
        sortModel,
        startRow: grid.startRow,
    };

    return makePrivateApiCall({
        url: "/invoice/stamp/grid",
        method: "get",
        params,
    })
        .then(res => {
            if (res.data) {
                const stamps = res.data.rows;
                lastRow = res.data.count;

                grid.successCallback(stamps, lastRow);

                dispatch({
                    type: STAMP_GRID_DATA,
                    payload: { stamps, lastRow },
                });
            }
        })
        .catch(err => {
            grid.failCallback(err);

            dispatch({
                type: STAMP_GRID_ERROR,
                payload: err.message,
            });
        });
};

export const changeStampGridPage = page => {
    return {
        type: STAMP_GRID_PAGE_CHANGE,
        payload: page,
    };
};

export const changeStampGridState = gridState => dispatch =>
    dispatch({
        type: STAMP_GRID_STATE_CHANGE,
        payload: gridState,
    });
