import React from "react";
import { Table, Popup, Icon } from "semantic-ui-react";

import { formatAmount } from "utilities/dist/helpers";
import { parseCurrency } from "../../common/helpers";
import { ProductTypeAcronyms } from "../../product/constants";
import { getProductFullName } from "../../invoice/helpers";

// order of the keys is very important here to make the table look nice and keep the columns in order
const keyHeaderMap = {
    type: "Type",
    qty: "Item Qty",
    product: "Product",
    price: "Item Price",
    total: "Total",
};
const keyAlignmentMap = {
    type: "left",
    qty: "left",
    product: "left",
    price: "left",
    total: "left",
};

const ItemCell = ({ col, row, isInternalSkuActive }) => {
    let textAlign = keyAlignmentMap[col];
    let value = row[col];

    if (col === "type") {
        value = <b>{ProductTypeAcronyms[row.product?.product_type.toUpperCase()]}</b>;
    }
    if (col === "qty") {
        if (!!row.is_rejected) {
            value = (
                <>
                    <span className="line-through" style={{ textDecoration: "line-through" }}>
                        {value}
                    </span>
                </>
            );
        }
        if (row.new_qty) {
            value = (
                <>
                    <span style={{ textDecoration: "line-through" }}>{row.previous_qty}</span> -{" "}
                    <span>{row.new_qty}</span>
                    {row.qty_change_note && (
                        <span className="lp-10">
                            <Popup
                                content={row.qty_change_note}
                                trigger={<Icon name="info circle" size="large" />}
                            />
                        </span>
                    )}
                </>
            );
        }
    }

    if (col === "product") {
        // For deleted products, we would not have the product object
        if (!value) {
            return <Table.Cell>DELETED PRODUCT</Table.Cell>;
        }
        const productName =
            !!isInternalSkuActive && value.internal_sku && value.internal_sku !== "0"
                ? `${value.internal_sku} - ${getProductFullName(value)}`
                : getProductFullName(value);

        if (!!row.is_rejected) {
            return (
                <Table.Cell className="line-through">
                    {productName}
                    {row.rejection_note && (
                        <span className="lp-10">
                            <Popup
                                content={row.rejection_note}
                                trigger={<Icon name="info circle" size="large" />}
                            />
                        </span>
                    )}
                </Table.Cell>
            );
        }

        if (!!row.is_added) {
            return (
                <Table.Cell>
                    {productName}
                    <span className="lp-10">
                        <Popup
                            content={"Item was not ordered. It was added when order was invoiced."}
                            trigger={<Icon name="info circle" size="large" />}
                        />
                    </span>
                </Table.Cell>
            );
        }

        return <Table.Cell>{productName}</Table.Cell>;
    }

    if (col === "price") {
        value = formatAmount(value);
        if (!!row.is_rejected) {
            value = (
                <>
                    <span
                        className="line-through"
                        style={{ textDecoration: "line-through" }}
                    >{`${formatAmount(row.previous_price)}`}</span>
                </>
            );
        }

        if (row.new_price && parseCurrency(row.new_price) !== parseCurrency(row.price)) {
            value = (
                <>
                    <span style={{ textDecoration: "line-through" }}>{`${formatAmount(
                        row.previous_price
                    )}`}</span>{" "}
                    - <span>{`${formatAmount(row.new_price)}`}</span>
                    {row.price_change_note && (
                        <span className="lp-10">
                            <Popup
                                content={row.price_change_note}
                                trigger={<Icon name="info circle" size="large" />}
                            />
                        </span>
                    )}
                </>
            );
        }
    }
    if (col === "total") {
        value = formatAmount(value);
        if (!!row.is_rejected) {
            value = (
                <>
                    <span
                        className="line-through"
                        style={{ textDecoration: "line-through" }}
                    >{`${formatAmount(row.total)}`}</span>
                </>
            );
        }
    }

    return <Table.Cell textAlign={textAlign}>{value}</Table.Cell>;
};

const OrderComponentProductList = ({ items, footerContent, isInternalSkuActive }) => {
    let columns = keyHeaderMap;
    const itemLogArray =
        items?.itemLog?.map(item => ({
            ...item,
            qty: item.previous_qty,
            price: item.previous_price,
        })) || [];
    const allItems = items.itemLog?.length ? itemLogArray : items?.orderItems;
    const orderItems =
        allItems?.map(item => ({
            ...item,
            product: item.product ? item.product : item.ydk_product,
        })) || [];

    return (
        <Table basic="very">
            <Table.Header>
                <Table.Row>
                    {Object.keys(columns).map(col => (
                        <Table.HeaderCell
                            key={`product_list_header_${col}`}
                            className={`product-list-column-${col}  text-right`}
                        >
                            {columns[col]}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {!!orderItems.length &&
                    orderItems.map((item, i) => (
                        <Table.Row
                            key={`product_list_row_${i}`}
                            negative={!item?.product || item?.is_rejected}
                            warning={item?.is_added}
                        >
                            {Object.keys(columns).map(col => (
                                <ItemCell
                                    col={col}
                                    row={item}
                                    isInternalSkuActive={isInternalSkuActive}
                                    key={`product_list_cell_${i}_${col}`}
                                />
                            ))}
                        </Table.Row>
                    ))}
            </Table.Body>

            {/* We need the style for pretty printing */}
            <Table.Footer style={{ display: "table-row-group" }}>{footerContent}</Table.Footer>
        </Table>
    );
};

export default OrderComponentProductList;
