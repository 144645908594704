import { get } from "lodash";
import { parse } from "date-fns";

import {
    INVOICE_PAYMENT_EDITOR_PRODUCT_RESET,
    INVOICE_PAYMENT_EDITOR_MODAL_TOGGLE,
    INVOICE_PAYMENT_EDITOR_SAVING,
    INVOICE_PAYMENT_EDITOR_SAVED,
    INVOICE_PAYMENT_EDITOR_INPUT,
    INVOICE_PAYMENT_EDITOR_ERROR,
    INVOICE_PAYMENT_REMOVE_ERROR,
    INVOICE_PAYMENT_REMOVING,
    INVOICE_PAYMENT_REMOVED,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";
import { DateFormat } from "../helpers";

export const saveInvoicePayment =
    (invoiceId, payment = {}) =>
    dispatch => {
        dispatch({
            type: INVOICE_PAYMENT_EDITOR_SAVING,
            payload: true,
        });
        const paymentData = { ...payment };
        // we need to convert this because the date coming in is a nicely formatted date without timezone info
        // converting this here will bring that back into actual date obj
        if (paymentData.received_at) {
            paymentData.received_at = parse(paymentData.received_at, DateFormat, new Date());
        }

        const request = {
            url: `/invoice/${invoiceId}/payment`,
            method: "post",
            data: paymentData,
        };

        if (payment.id) {
            request.url = `/invoice/${invoiceId}/payment/${payment.id}`;
            request.method = "put";
        }

        return makePrivateApiCall(request)
            .then(res => {
                if (res.data) {
                    dispatch({ type: INVOICE_PAYMENT_EDITOR_SAVED, payload: res.data });
                }

                return res.data;
            })
            .catch(err => {
                let messages = [];
                const data = get(err, "response.data", null);

                if (!data) {
                    messages.push(err.message);
                } else if (data.details) {
                    data.details.forEach(({ message }) => messages.push(message));
                } else if (data.message) {
                    messages.push(data.message);
                }

                dispatch(setInvoicePaymentEditorError(messages));
            });
    };

export const removeInvoicePayment = (invoiceId, paymentId) => dispatch => {
    dispatch({
        type: INVOICE_PAYMENT_REMOVING,
        payload: true,
    });

    const request = {
        url: `/invoice/${invoiceId}/payment/${paymentId}`,
        method: "delete",
    };

    return makePrivateApiCall(request)
        .then(() => {
            dispatch({
                type: INVOICE_PAYMENT_REMOVED,
                payload: paymentId,
            });
        })
        .catch(err => {
            const message = get(err, "response.data.message", null) || err.message;
            dispatch({
                type: INVOICE_PAYMENT_REMOVE_ERROR,
                payload: message,
            });
        });
};

export const setInvoicePaymentEditorInput =
    (inputs = {}) =>
    dispatch => {
        return dispatch({
            type: INVOICE_PAYMENT_EDITOR_INPUT,
            payload: inputs,
        });
    };

export const setInvoicePaymentEditorError = error => dispatch => {
    return dispatch({
        type: INVOICE_PAYMENT_EDITOR_ERROR,
        payload: error,
    });
};

export const resetEditingInvoicePayment = () => dispatch =>
    dispatch({
        type: INVOICE_PAYMENT_EDITOR_PRODUCT_RESET,
    });

export const showInvoicePaymentEditorModal = show => ({
    type: INVOICE_PAYMENT_EDITOR_MODAL_TOGGLE,
    payload: show,
});
