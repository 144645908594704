import React, { useState } from "react";
import { format, parse, addDays } from "date-fns";
import { Popup, Button } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";

import { DateFormat } from "../helpers";
import { parseServerTimestampForClient } from "../../common/helpers";

export const InvoiceComponentDueDate = ({ due_at, onChange }) => {
    const [showPicker, setShowPicker] = useState(false);
    const handleDateChange = (e, data) => {
        data.value = parse(data.value, DateFormat, new Date());
        setShowPicker(false);
        onChange(e, data);
    };

    const text = due_at ? format(parseServerTimestampForClient(due_at), DateFormat) : "Delivery";
    return (
        <Popup
            on="click"
            open={showPicker}
            disabled={!onChange}
            onClose={() => setShowPicker(false)}
            trigger={
                <Button
                    basic
                    compact
                    size="mini"
                    color="black"
                    content={`Due On ${text}`}
                    onClick={() => !!onChange && setShowPicker(true)}
                />
            }
        >
            <DateInput
                inline
                onChange={handleDateChange}
                maxDate={addDays(new Date(), 30)}
                initialDate={due_at || new Date()}
                // bit hacky cause we use date-fns for date stuff but the picker package uses moment so the formats are not interchangable
                dateFormat={DateFormat.toUpperCase()}
                value={format(
                    due_at ? parseServerTimestampForClient(due_at) : new Date(),
                    DateFormat
                )}
            />
        </Popup>
    );
};
