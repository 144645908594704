import { CUSTOMER_USER_GRID_STATE_CHANGE } from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    pageSize: 25,
    currentPage: 0,

    sortModel: [],
    filterModel: {},
};

const changeGridState = (state, payload) => ({ ...state, ...payload });

const reducers = {
    [CUSTOMER_USER_GRID_STATE_CHANGE]: changeGridState,
};

export default generateReducer(reducers, initialState);
