export const INVENTORY_GRID_DATA = "INVENTORY_GRID_DATA";
export const INVENTORY_GRID_ERROR = "INVENTORY_GRID_ERROR";
export const INVENTORY_GRID_LOADING = "INVENTORY_GRID_LOADING";
export const INVENTORY_GRID_PAGE_CHANGE = "INVENTORY_GRID_PAGE_CHANGE";
export const INVENTORY_GRID_STATE_CHANGE = "INVENTORY_GRID_STATE_CHANGE";

export const INVENTORY_GRID_CELL_EDIT_CONFIRMATION_TOGGLE =
    "INVENTORY_GRID_CELL_EDIT_CONFIRMATION_TOGGLE";

export const INVENTORY_GRID_CELL_UPDATE_SUCCESS = "INVENTORY_GRID_CELL_UPDATE_SUCCESS";

export const INVENTORY_RESTOCK_AWAITING_ITEMS_LOADING = "INVENTORY_RESTOCK_AWAITING_ITEMS_LOADING";
export const INVENTORY_RESTOCK_AWAITING_ITEMS_ERROR = "INVENTORY_RESTOCK_AWAITING_ITEMS_ERROR";
export const INVENTORY_RESTOCK_AWAITING_ITEMS_DATA = "INVENTORY_RESTOCK_AWAITING_ITEMS_DATA";

export const INVENTORY_RESTOCK_SHOW_INLINE_EDITOR = "INVENTORY_RESTOCK_SHOW_INLINE_EDITOR";
export const INVENTORY_RESTOCK_HIDE_INLINE_EDITOR = "INVENTORY_RESTOCK_HIDE_INLINE_EDITOR";
export const INVENTORY_RESTOCK_CHANGE_INLINE_EDITOR = "INVENTORY_RESTOCK_CHANGE_INLINE_EDITOR";

export const INVENTORY_RESTOCK_SET_REF_NUMBER = "INVENTORY_RESTOCK_SET_REF_NUMBER";
export const INVENTORY_RESTOCK_CLEAR_SELECTION = "INVENTORY_RESTOCK_CLEAR_SELECTION";
export const INVENTORY_RESTOCK_ADD_PRODUCT = "INVENTORY_RESTOCK_ADD_PRODUCT";
export const INVENTORY_RESTOCK_SELECT_PRODUCT = "INVENTORY_RESTOCK_SELECT_PRODUCT";
export const INVENTORY_RESTOCK_CHANGE_QTY = "INVENTORY_RESTOCK_CHANGE_QTY";
export const INVENTORY_RESTOCK_TOGGLE_ON_DEAL = "INVENTORY_RESTOCK_TOGGLE_ON_DEAL";
export const INVENTORY_RESTOCK_CHANGE_PRICE = "INVENTORY_RESTOCK_CHANGE_PRICE";
export const INVENTORY_RESTOCK_REMOVE_ITEM = "INVENTORY_RESTOCK_REMOVE_ITEM";
export const INVENTORY_RESTOCK_COMPLETE = "INVENTORY_RESTOCK_COMPLETE";
export const INVENTORY_RESTOCK_SET_DISTRIBUTOR = "INVENTORY_RESTOCK_SET_DISTRIBUTOR";
