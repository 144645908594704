import React from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";

import { changeInvoiceGridState } from "../../invoice/grid/actions";
import { setInvoiceBuilderCustomer } from "../../invoice/builder/actions";
class CustomerComponentGridAcions extends React.Component {
    handleNewInvoice = e => {
        e.preventDefault();
        this.props.setInvoiceBuilderCustomer(this.props.data);
        this.props.push("/invoice/create");
    };

    handleInvoiceHistory = e => {
        e.preventDefault();
        this.props.changeInvoiceGridState({
            filterModel: {
                customer: {
                    filter: this.props.data.name,
                    filterType: "text",
                    type: "equals",
                },
            },
        });
        this.props.push("/invoice");
    };

    handleCustomerEdit = e => {
        e.preventDefault();
        this.props.push(`/customer/${this.props.data.id}/edit`);
    };
    handleCustomerUser = e => {
        e.preventDefault();
        this.props.push(`/customer/${this.props.data.id}/user`);
    };

    render() {
        const { company } = this.props;
        const hasOrderFeature = company?.companyFeatures?.find(f => f.name === "order" && f.active);

        return (
            <>
                <a title="New Invoice" href="/invoice/create" onClick={this.handleNewInvoice}>
                    <Icon name="plus" />
                </a>
                &nbsp;&nbsp;&nbsp;
                <a href="/invoice" title="Invoice History" onClick={this.handleInvoiceHistory}>
                    <Icon name="newspaper" />
                </a>
                &nbsp;&nbsp;&nbsp;
                <a
                    data-cy="edit_customer"
                    title="Edit Customer"
                    onClick={this.handleCustomerEdit}
                    href={`/customer/${this.props.data.id}`}
                >
                    <Icon name="pencil" />
                </a>
                &nbsp;&nbsp;&nbsp;
                {!!hasOrderFeature && (
                    <a
                        data-cy="customer_user"
                        title="Customer user"
                        onClick={this.handleCustomerUser}
                        href={`/customer/user`}
                    >
                        <Icon name="users" />
                    </a>
                )}
            </>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    company: auth.user.company,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            push,
            changeInvoiceGridState,
            setInvoiceBuilderCustomer,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(CustomerComponentGridAcions);
