import { makePrivateApiCall } from "utilities/dist/api";
import { ORDER_BUILDER_SAVING, ORDER_BUILDER_SAVED } from "../constants";

export const saveOrderBuilderOrder =
    (order = {}) =>
    dispatch => {
        dispatch({
            type: ORDER_BUILDER_SAVING,
            payload: true,
        });

        const request = {
            url: `/order/${order.id}`,
            method: "put",
            data: order,
        };

        return makePrivateApiCall(request)
            .then(res => {
                if (res.data) {
                    dispatch({ type: ORDER_BUILDER_SAVED, payload: res.data });
                    return res.data;
                }
            })
            .catch(err => {
                return err;
            });
    };
