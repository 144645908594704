import React from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Grid, Message, Popup, Segment } from "semantic-ui-react";
import { Form, Input } from "formik-semantic-ui";
import InvoiceBuilderComponentEditor from "../../invoice/builder/product-editor.component";
import InvoiceBuilderComponentProductList from "../../invoice/components/product-list";
import {
    loadAwaitingItems,
    showInventoryRestockInlineEditor,
    hideInventoryRestockInlineEditor,
    changeInventoryRestockInlineEditor,
    changeInventoryRestockQty,
    changeInventoryRestockPrice,
    selectInventoryRestockProduct,
    addInventoryRestockProduct,
    removeInventoryRestockItem,
    saveInventory,
    setInventoryRestockDistributor,
    clearSelection,
    setInventoryRestockRefNumber,
    toggleInventoryRestockOnDeal,
} from "./actions";
import { InventoryRestockFooterContentComponent } from "./footer-content.component";
import { clearProductSearch } from "../../product/search/actions";
import { DistributorSelectorComponent } from "../../product/distributor-selector.component";

const DistributorSelector = ({ selectedDistributor = "", onChange }) => {
    return (
        <Form
            initialValues={{ distributor: selectedDistributor }}
            validate={({ distributor }) => onChange(distributor)}
            onSubmit={() => null}
        >
            <DistributorSelectorComponent
                fieldProps={{ width: 16 }}
                inputProps={{ clearable: true }}
            />
        </Form>
    );
};

const RefNumberInputField = ({ ref_number = "", onChange }) => {
    return (
        <Form
            initialValues={{ ref_number }}
            validate={({ ref_number }) => onChange(ref_number)}
            onSubmit={() => null}
        >
            <Input label="PO/Invoice/Reference Number" name="ref_number" />
        </Form>
    );
};

const InventoryContainerRestock = ({ onComplete }) => {
    const {
        items,
        error,
        isLoading,
        inlineEditorFields,
        inlineEditorItemId,
        editor,
        distributor,
        ref_number,
    } = useSelector(({ inventoryRestock }) => inventoryRestock);
    const dispatch = useDispatch();
    const handleLoadAwaitingItems = () => dispatch(loadAwaitingItems());

    return (
        <>
            <Segment loading={isLoading} attached>
                <Grid>
                    <Grid.Column mobile={16} computer={6}>
                        <DistributorSelector
                            selectedDistributor={distributor}
                            onChange={dist => dispatch(setInventoryRestockDistributor(dist))}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={4}>
                        <RefNumberInputField
                            refNumber={ref_number}
                            onChange={refNumber =>
                                dispatch(setInventoryRestockRefNumber(refNumber))
                            }
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={4}>
                        {/* Hack elements to create right spacing above the button */}
                        <div className="ui form">
                            <div className="ui field">
                                <label>&#8203;</label>
                                <Popup
                                    content="Load all out of stock items into the restock list with qty set to minimum reorder qty"
                                    trigger={
                                        <Button
                                            onClick={handleLoadAwaitingItems}
                                            labelPosition="left"
                                            disabled={isLoading}
                                            content="Populate Restock"
                                            icon={{
                                                name: isLoading ? "spinner" : "copy",
                                                loading: isLoading,
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>
                <Divider />
                <InvoiceBuilderComponentEditor
                    item={editor}
                    hideTaxable
                    cantAdd={false}
                    showOnDeal
                    hideOrderHistory
                    onDealToggle={() => dispatch(toggleInventoryRestockOnDeal())}
                    onQtyChange={(_, { value }) => dispatch(changeInventoryRestockQty(value))}
                    onPriceChange={(_, { value }) => dispatch(changeInventoryRestockPrice(value))}
                    onAddProduct={() => {
                        dispatch(addInventoryRestockProduct());
                        dispatch(clearProductSearch());
                    }}
                    onProductChange={product => dispatch(selectInventoryRestockProduct(product))}
                    priceFieldLabel="Item Cost"
                />
            </Segment>
            {!!error && <Message error content={error} attached="bottom" />}
            {!isEmpty(items) && (
                <InvoiceBuilderComponentProductList
                    items={Object.values(items)}
                    footerContent={
                        <InventoryRestockFooterContentComponent
                            clearSelection={() => dispatch(clearSelection())}
                            saveInventory={() => dispatch(saveInventory(onComplete))}
                            isLoading={isLoading}
                        />
                    }
                    isEditable
                    onItemEdit={item =>
                        dispatch(showInventoryRestockInlineEditor(item, ["price", "qty"]))
                    }
                    onItemRemove={product => dispatch(removeInventoryRestockItem(product))}
                    isInternalSkuActive
                    inlineEditorFields={inlineEditorFields}
                    inlineEditorItemId={inlineEditorItemId}
                    onShowInlineEditor={(item, field) =>
                        dispatch(showInventoryRestockInlineEditor(item, field))
                    }
                    onHideInlineEditor={(field, value) =>
                        dispatch(hideInventoryRestockInlineEditor(field, value))
                    }
                    onChangeInlineEditor={(item, field) =>
                        dispatch(changeInventoryRestockInlineEditor(item, field))
                    }
                />
            )}
        </>
    );
};

InventoryContainerRestock.propTypes = {
    onComplete: PropTypes.func.isRequired,
};

export default InventoryContainerRestock;
