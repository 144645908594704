import React from "react";
import { Grid } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import TopNav from "../../common/containers/top-nav";
import OrderGridContainer from "../grid/index.container";
import { isFeatureActive, OrderFeature } from "../../company/feature/helpers";
import CompanyFeatureComponentItemLarge from "../../company/feature/item-large.component";
import { showCompanyFeatureToggleConfirmation } from "../../company/feature/actions";
import CompanyFeatureContainerConfirmToggle from "../../company/feature/confirm-toggle.container";

const OrderPageHome = () => {
    const { company } = useSelector(({ auth }) => ({
        company: auth.user.company,
    }));
    const hasOrderFeature = isFeatureActive(company, OrderFeature);
    const dispatch = useDispatch();
    const handleActivateFeature = feature => {
        dispatch(showCompanyFeatureToggleConfirmation(feature));
    };

    return (
        <Grid>
            <TopNav
                headerIcon="cart arrow down"
                headerContent="Orders"
                subheader="Orders history"
            />

            <Grid.Column mobile={16}>
                {hasOrderFeature ? (
                    <OrderGridContainer />
                ) : (
                    <>
                        <CompanyFeatureContainerConfirmToggle />
                        <CompanyFeatureComponentItemLarge
                            toggleActivation={handleActivateFeature}
                            feature={OrderFeature}
                            isActive={false}
                        />
                    </>
                )}
            </Grid.Column>
        </Grid>
    );
};

export default OrderPageHome;
