import React from "react";
import PropTypes from "prop-types";
import { Label, Button, Icon, Image, Header, Segment } from "semantic-ui-react";
import { paidFeatureIds } from "./helpers";

const CompanyFeatureComponentItemLarge = ({ feature, isActive = false, toggleActivation }) => {
    const FeatureActions = () => {
        let btnText = "";
        const isPaidFeature = paidFeatureIds.includes(feature.id);

        if (feature.comingSoon) btnText = "Coming Soon";
        else if (isPaidFeature) btnText = isActive ? "Deactivate" : "Request Access";
        else btnText = isActive ? "Deactivate" : "Activate";

        return (
            <Button
                size="small"
                disabled={feature.comingSoon}
                onClick={() => toggleActivation(feature)}
                color={feature.comingSoon ? "grey" : isActive ? "red" : "green"}
                data-cy={`feature-button-${feature.id}`}
            >
                {btnText}
                <Icon name="right chevron" />
            </Button>
        );
    };
    return (
        <Segment vertical textAlign="center">
            <Image centered size="large" src={`/images/${feature.id}_feature_logo.svg`} />

            <Label.Group size="small" className="tp-15">
                <Label>{feature.pricing}</Label>
                <Label>{isActive ? "Active" : "Inactive"}</Label>
            </Label.Group>

            <Header>{feature.title}</Header>

            <p>{feature.description}</p>

            <FeatureActions />
        </Segment>
    );
};

CompanyFeatureComponentItemLarge.propTypes = {
    isActive: PropTypes.bool,
    feature: PropTypes.object,
    toggleActivation: PropTypes.func,
};

export default CompanyFeatureComponentItemLarge;
