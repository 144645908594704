export const STAMP_GRID_DATA = "STAMP_GRID_DATA";
export const STAMP_GRID_ERROR = "STAMP_GRID_ERROR";
export const STAMP_GRID_LOADING = "STAMP_GRID_LOADING";
export const STAMP_GRID_PAGE_CHANGE = "STAMP_GRID_PAGE_CHANGE";
export const STAMP_GRID_STATE_CHANGE = "STAMP_GRID_STATE_CHANGE";

export const STAMP_ROLL_DATA = "STAMP_ROLL_DATA";
export const STAMP_ROLL_ERROR = "STAMP_ROLL_ERROR";
export const STAMP_ROLL_LOADING = "STAMP_ROLL_LOADING";

export const STAMP_ROLL_SAVED = "STAMP_ROLL_SAVED";
export const STAMP_ROLL_SAVING = "STAMP_ROLL_SAVING";
export const STAMP_ROLL_UPDATED = "STAMP_ROLL_UPDATED";
export const STAMP_ROLL_INPUT_ERROR = "STAMP_ROLL_INPUT_ERROR";
export const STAMP_ROLL_INPUT_CHANGE = "STAMP_ROLL_INPUT_CHANGE";

export const STAMP_LAST_DATA = "STAMP_LAST_DATA";
export const STAMP_LAST_ERROR = "STAMP_LAST_ERROR";
export const STAMP_LAST_LOADING = "STAMP_LAST_LOADING";
export const STAMP_HIDE_LOW_WARNING = "STAMP_HIDE_LOW_WARNING";
