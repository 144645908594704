"use strict";

// Staging DB Config
const dbConfig = {
  username: "ynv-staging",
  password: "123yDrink!Staging!2025ynv",
  host: "127.0.0.1",
  port: "3306",
  dialect: "mysql",
  // keeping the logging option to false, it makes the development process bit annoying and avoid unnecessary logs
  logging: false
};
module.exports = {
  api_url: "https://api.ynv.devz.link/api",
  client_host: "ynv.devz.link/",
  distributor_host: "https://ynvoice.ynv.devz.link/",
  websocket_url: "wss://api.ynv.devz.link",
  port: 9010,
  s3: {
    bucket: "ynvoice-staging",
    access: {
      region: "eu-central-1",
      secretAccessKey: "HR0iJ9TjPU2IvR6sOkoaLxZLTyC5Sa7nK9hDfHJY",
      accessKeyId: "AKIAZB7TYEGYV4BKP6FS"
    }
  },
  bodyLimit: "5mb",
  databases: {
    ynvoice: {
      database: "ynvoice",
      ...dbConfig
    },
    vg_brand: {
      database: "vg_brand",
      ...dbConfig
    },
    vg_ydk: {
      database: "vg_ydk",
      ...dbConfig
    }
  },
  // keep the tokens alive for 8hrs
  jwt_expiration: 60 * 60 * 8,
  jwt_encryption: "aasdfasdf sdf adsf",
  clover: {
    client_id: "EZ0VGWSVJ44CW",
    client_secret: "93ec0afb-af9d-06ed-0136-40e4365dc54c",
    api_url: "https://sandbox.dev.clover.com"
  },
  mailgun: {
    api_key: "532e603fb5b38e1037aada2ee443ec73-87c34c41-ae3929f3",
    domain: "ydrink.net"
  },
  twilio: {
    account_sid: "AC22fe3d598078ec332f7d7ccd04cb0f8e",
    auth_token: "51f4d45ec2b08ec1c48a85b167d6e400",
    text_from: "5126452428"
  },
  logs: {
    loggly: {
      token: "f9b0b5d9-8acb-4e47-8735-c0eeb59e1747",
      subdomain: "ynvoice",
      tags: ["api", "staging"],
      json: true
    }
  }
};