import React, { useState, useEffect } from "react";
import { Message, Modal, Button } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import { ProductPriceTierSelector } from "../components/selector.js";
import { removeProductPriceTier, setPriceTierRemoveId } from "../actions";
import pluralize from "pluralize";

export const ProductPriceTierRemoveModalContainer = ({
    customerCountByPriceTier = {},
    onRemove,
}) => {
    const dispatch = useDispatch();
    const { isRemoving, removeErrors, productPriceTiers, removingId } = useSelector(
        ({ productPriceTier, auth }) => ({
            removingId: productPriceTier.removingId,
            isRemoving: productPriceTier.isRemoving,
            removeErrors: productPriceTier.removeErrors,
            productPriceTiers: auth.user.company.productPriceTiers,
        })
    );
    const [migratingPriceTier, setMigratingPriceTier] = useState(null);

    const priceTier = productPriceTiers?.find(({ id }) => id === removingId);
    const customerCount = removingId > 0 ? customerCountByPriceTier?.[removingId] : 0;
    const migratableTiers = productPriceTiers?.filter(({ id }) => id !== removingId);

    useEffect(() => {
        return () => {
            setMigratingPriceTier(null);
        };
    }, [removingId]);

    return (
        <Modal open={removingId > 0} loading>
            <Modal.Header>
                Remove "{priceTier?.name}" Tier
                {migratingPriceTier && ` and migrate customers to selected tier`}?
            </Modal.Header>
            <Modal.Content>
                {customerCount ? (
                    <>
                        <p>
                            There {customerCount > 1 ? "are" : "is"}{" "}
                            <b>{pluralize("customers", customerCount, true)}</b> on this tier.
                            <br />
                            Migrate this customer to the selected tier below or make no selection to
                            set them to Base Tier
                        </p>
                        <ProductPriceTierSelector
                            priceTiers={migratableTiers}
                            value={migratingPriceTier}
                            fluid={false}
                            onChange={(_, { value }) => setMigratingPriceTier(value)}
                        />
                    </>
                ) : (
                    <p>
                        There are no customers on this tier, you can safely remove this price tier.
                    </p>
                )}
                {!!removeErrors?.length && (
                    <Message error header="Something went wrong" list={removeErrors} />
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button content="Nevermind" onClick={() => dispatch(setPriceTierRemoveId(null))} />
                <Button
                    negative
                    disabled={isRemoving}
                    loading={isRemoving}
                    content={migratingPriceTier ? "Remove tier and migrate" : "Remove Price Tier"}
                    onClick={() =>
                        dispatch(removeProductPriceTier(removingId, migratingPriceTier)).then(
                            onRemove
                        )
                    }
                />
            </Modal.Actions>
        </Modal>
    );
};
