import {
    CUSTOMER_LIST_LOADING,
    CUSTOMER_LIST_DATA,
    CUSTOMER_LIST_ERROR,
    CUSTOMER_EDITOR_SAVED,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    error: null,
    data: [],
    isLoading: false,
};

const addCustomer = (state, customer) => {
    const newState = { ...state };
    const finder = ({ id }) => id === customer.id;

    if (newState.data.find(finder)) {
        newState.data = newState.data.map(cust => {
            if (finder(cust)) return customer;

            return cust;
        });
    } else {
        newState.data.push(customer);
    }

    return newState;
};

const loadCustomers = (state, data) => ({
    ...state,
    error: null,
    isLoading: false,
    data,
});

const setError = (state, error) => ({ ...state, isLoading: false, error });
const setLoading = state => ({ ...state, isLoading: true });

const reducers = {
    [CUSTOMER_EDITOR_SAVED]: addCustomer,

    [CUSTOMER_LIST_LOADING]: setLoading,
    [CUSTOMER_LIST_ERROR]: setError,
    [CUSTOMER_LIST_DATA]: loadCustomers,
};

const reducer = generateReducer(reducers, initialState);

export default reducer;
