import React from "react";
import { Link } from "react-router-dom";
import { Grid, Segment, Header, Divider, Menu } from "semantic-ui-react";

import TopNav from "../containers/top-nav";
import InvoiceContainerRecent from "../../invoice/containers/recent";
import CompanyStatsContainer from "../../company/stats/index.container";
import StampWarningContainer from "../../stamp/warning/index.container";
import CompanyQuickSearchContainer from "../../company/quick-search/index.container";
import StampRollContainerCurrentSummary from "../../stamp/roll/containers/current-summary";


const CommonHomePage = () => {

    return (
        <Grid>
            <TopNav
                headerContent="Dashboard"
                headerIcon="tachometer alternate"
                subheader="Overview of your customers and invoices"
                extraButtons={() => (
                    <>
                        <Menu.Item
                            data-cy="new_invoice"
                            as={Link}
                            icon="plus"
                            name="New Invoice"
                            to="/invoice/create"
                        />
                        <Menu.Item
                            as={Link}
                            icon="plus"
                            name="New Customer"
                            to="/customer/create"
                        />
                    </>
                )}
            />

            <Grid.Row>
                <Grid.Column mobile={16}>
                    <StampWarningContainer />
                    <Divider hidden />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8} className="bp-15">
                    <StampRollContainerCurrentSummary />

                    <Segment>
                        <Header content="Quick Search" />
                        <CompanyQuickSearchContainer />

                        <Header content="Recent Invoices" />
                        <InvoiceContainerRecent />
                        <Divider hidden />
                    </Segment>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8} className="bp-15">
                    <CompanyStatsContainer />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default CommonHomePage;
