export const INVOICE_BUILDER_ADD_PRODUCT = "INVOICE_BUILDER_ADD_PRODUCT";
export const INVOICE_BUILDER_SET_CUSTOMER = "INVOICE_BUILDER_SET_CUSTOMER";
export const INVOICE_BUILDER_UPDATE_CUSTOMER = "INVOICE_BUILDER_UPDATE_CUSTOMER";

export const INVOICE_BUILDER_SELECT_PRODUCT = "INVOICE_BUILDER_SELECT_PRODUCT";
export const INVOICE_BUILDER_CHANGE_STAMP = "INVOICE_BUILDER_CHANGE_STAMP";
export const INVOICE_BUILDER_ADD_STAMP_ROLL = "INVOICE_BUILDER_ADD_STAMP_ROLL";
export const INVOICE_BUILDER_SELECT_STAMP_ROLL = "INVOICE_BUILDER_SELECT_STAMP_ROLL";
export const INVOICE_BUILDER_REMOVE_STAMP_ROLL = "INVOICE_BUILDER_REMOVE_STAMP_ROLL";
export const INVOICE_BUILDER_CHANGE_STATUS = "INVOICE_BUILDER_CHANGE_STATUS";
export const INVOICE_BUILDER_CHANGE_DATE = "INVOICE_BUILDER_CHANGE_DATE";
export const INVOICE_BUILDER_CHANGE_NOTE = "INVOICE_BUILDER_CHANGE_NOTE";
export const INVOICE_BUILDER_CHANGE_QTY = "INVOICE_BUILDER_CHANGE_QTY";
export const INVOICE_BUILDER_CHANGE_PRICE = "INVOICE_BUILDER_CHANGE_PRICE";
export const INVOICE_BUILDER_TOGGLE_TAXABLE = "INVOICE_BUILDER_TOGGLE_TAXABLE";
export const INVOICE_BUILDER_SAVING = "INVOICE_BUILDER_SAVING";
export const INVOICE_BUILDER_SAVED = "INVOICE_BUILDER_SAVED";
export const INVOICE_BUILDER_CREATED = "INVOICE_BUILDER_CREATED";
export const INVOICE_BUILDER_ERROR = "INVOICE_BUILDER_ERROR";
export const INVOICE_BUILDER_REMOVE_ITEM = "INVOICE_BUILDER_REMOVE_ITEM";
export const INVOICE_BUILDER_INVOICE_LOADING = "INVOICE_BUILDER_INVOICE_LOADING";
export const INVOICE_BUILDER_INVOICE_DATA = "INVOICE_BUILDER_INVOICE_DATA";
export const INVOICE_BUILDER_RESET = "INVOICE_BUILDER_RESET";
export const INVOICE_BUILDER_CHANGE_LOGO = "INVOICE_BUILDER_CHANGE_LOGO";
export const INVOICE_BUILDER_TOGGLE_LOGO_PICKER = "INVOICE_BUILDER_TOGGLE_LOGO_PICKER";

export const INVOICE_BUILDER_CLONING_LAST = "INVOICE_BUILDER_CLONING_LAST";
export const INVOICE_BUILDER_CLONE = "INVOICE_BUILDER_CLONE";

export const ITEM_LOG_REMOVE_ITEM = "ITEM_LOG_REMOVE_ITEM";
export const ITEM_LOG_ADD_PRODUCT = "ITEM_LOG_ADD_PRODUCT";
export const ITEM_LOG_CHANGE_INLINE_EDITOR = "ITEM_LOG_CHANGE_INLINE_EDITOR";
export const ITEM_LOG_SHOW_INLINE_EDITOR = "ITEM_LOG_SHOW_INLINE_EDITOR";
export const ITEM_LOG_HIDE_INLINE_EDITOR = "ITEM_LOG_HIDE_INLINE_EDITOR";
export const RESET_ITEM_LOG = "RESET_ITEM_LOG";

export const INVOICE_BUILDER_SHOW_INLINE_EDITOR = "INVOICE_BUILDER_SHOW_INLINE_EDITOR";
export const INVOICE_BUILDER_HIDE_INLINE_EDITOR = "INVOICE_BUILDER_HIDE_INLINE_EDITOR";
export const INVOICE_BUILDER_CHANGE_INLINE_EDITOR = "INVOICE_BUILDER_CHANGE_INLINE_EDITOR";

export const INVOICE_GRID_DATA = "INVOICE_GRID_DATA";
export const INVOICE_GRID_ERROR = "INVOICE_GRID_ERROR";
export const INVOICE_GRID_LOADING = "INVOICE_GRID_LOADING";
export const INVOICE_GRID_PAGE_CHANGE = "INVOICE_GRID_PAGE_CHANGE";
export const INVOICE_GRID_STATE_CHANGE = "INVOICE_GRID_STATE_CHANGE";

export const INVOICE_PAYMENT_EDITOR_MODAL_TOGGLE = "INVOICE_PAYMENT_EDITOR_MODAL_TOGGLE";
export const INVOICE_PAYMENT_EDITOR_PRODUCT_RESET = "INVOICE_PAYMENT_EDITOR_PRODUCT_RESET";
export const INVOICE_PAYMENT_EDITOR_SAVING = "INVOICE_PAYMENT_EDITOR_SAVING";
export const INVOICE_PAYMENT_EDITOR_SAVED = "INVOICE_PAYMENT_EDITOR_SAVED";
export const INVOICE_PAYMENT_EDITOR_INPUT = "INVOICE_PAYMENT_EDITOR_INPUT";
export const INVOICE_PAYMENT_EDITOR_ERROR = "INVOICE_PAYMENT_EDITOR_ERROR";
export const INVOICE_PAYMENT_REMOVE_ERROR = "INVOICE_PAYMENT_REMOVE_ERROR";
export const INVOICE_PAYMENT_REMOVING = "INVOICE_PAYMENT_REMOVING";
export const INVOICE_PAYMENT_REMOVED = "INVOICE_PAYMENT_REMOVED";

export const INVOICE_PAYMENT_GRID_DATA = "INVOICE_PAYMENT_GRID_DATA";
export const INVOICE_PAYMENT_GRID_ERROR = "INVOICE_PAYMENT_GRID_ERROR";
export const INVOICE_PAYMENT_GRID_LOADING = "INVOICE_PAYMENT_GRID_LOADING";
export const INVOICE_PAYMENT_GRID_PAGE_CHANGE = "INVOICE_PAYMENT_GRID_PAGE_CHANGE";
export const INVOICE_PAYMENT_GRID_STATE_CHANGE = "INVOICE_PAYMENT_GRID_STATE_CHANGE";

export const INVOICE_CONVERTER_DATA = "INVOICE_CONVERTER_DATA";
export const INVOICE_CONVERTER_ERROR = "INVOICE_CONVERTER_ERROR";
export const INVOICE_CONVERTER_LOADING = "INVOICE_CONVERTER_LOADING";
export const INVOICE_CONVERTER_UPLOADING = "INVOICE_CONVERTER_UPLOADING";
export const INVOICE_CONVERTER_UPLOAD_ERROR = "INVOICE_CONVERTER_UPLOAD_ERROR";
export const INVOICE_CONVERTER_UPLOAD_SUCCESS = "INVOICE_CONVERTER_UPLOAD_SUCCESS";

export const INVOICE_DOCUMENT_DATA = "INVOICE_DOCUMENT_DATA";
export const INVOICE_DOCUMENT_ERROR = "INVOICE_DOCUMENT_ERROR";
export const INVOICE_DOCUMENT_LOADING = "INVOICE_DOCUMENT_LOADING";

export const INVOICE_BUILDER_GET_ORDER = "INVOICE_BUILDER_GET_ORDER";

export const INVOICE_EMAIL_GRID_DATA = "INVOICE_EMAIL_GRID_DATA";
export const INVOICE_EMAIL_GRID_ERROR = "INVOICE_EMAIL_GRID_ERROR";
export const INVOICE_EMAIL_GRID_LOADING = "INVOICE_EMAIL_GRID_LOADING";
export const INVOICE_EMAIL_GRID_PAGE_CHANGE = "INVOICE_EMAIL_GRID_PAGE_CHANGE";
export const INVOICE_EMAIL_GRID_STATE_CHANGE = "INVOICE_EMAIL_GRID_STATE_CHANGE";

export const INVOICE_DOCUMENT_UPLOADING = "INVOICE_DOCUMENT_UPLOADING";
export const INVOICE_DOCUMENT_UPLOAD_ERROR = "INVOICE_DOCUMENT_UPLOAD_ERROR";
export const INVOICE_DOCUMENT_UPLOAD_SUCCESS = "INVOICE_DOCUMENT_UPLOAD_SUCCESS";

export const INVOICE_DOCUMENT_UPLOAD_WEBCAM = "INVOICE_DOCUMENT_UPLOAD_WEBCAM";

export const INVOICE_DOCUMENT_REMOVING = "INVOICE_DOCUMENT_REMOVING";
export const INVOICE_DOCUMENT_REMOVE_ERROR = "INVOICE_DOCUMENT_REMOVE_ERROR";
export const INVOICE_DOCUMENT_REMOVE_SUCCESS = "INVOICE_DOCUMENT_REMOVE_SUCCESS";

export const INVOICE_DOCUMENT_EDITOR_ID = "INVOICE_DOCUMENT_EDITOR_ID";
export const INVOICE_DOCUMENT_EDITOR_IS_SAVING = "INVOICE_DOCUMENT_EDITOR_IS_SAVING";
