import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";

const GridPageSizePicker = ({ pageSize, onChange, ...others }) => (
    <Dropdown
        value={pageSize}
        options={[10, 25, 50, 100, 200, 400].map(i => ({
            key: i,
            text: i,
            value: i,
        }))}
        onChange={(e, data) => onChange({ pageSize: Number(data.value) })}
        {...others}
    />
);

GridPageSizePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
};

export default GridPageSizePicker;
