"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateOrderLinkForEstApp = exports.generateOrderLinkForDistApp = exports.generateInvoiceLinkForEstApp = exports.generateInvoiceLinkForDistApp = exports.generateInvitationLink = exports.computeTaxedTotal = exports.buildInvoiceNumberWithBeer = void 0;

var _config = _interopRequireDefault(require("../config"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const buildInvoiceNumberWithBeer = (invoice = {}) => !!invoice.beer ? `${invoice.number}B` : invoice.number;

exports.buildInvoiceNumberWithBeer = buildInvoiceNumberWithBeer;

const generateInvitationLink = ({
  subdomain,
  name = "",
  email = "",
  tabc_id = "",
  token = ""
}) => {
  const userName = name ? `name=${encodeURIComponent(name)}&` : "";
  const userEmail = email ? `email=${email.replaceAll(/\s/g, "")}&` : "";
  const userTabc = tabc_id ? `tabc_id=${tabc_id === null || tabc_id === void 0 ? void 0 : tabc_id.replaceAll(/\s/g, "")}&` : "";
  const userToken = token ? `token=${token}` : "";
  const link = `${_config.default.protocol}${subdomain}.${_config.default.client_host}/signup?${userName}${userEmail}${userTabc}${userToken}`;
  return link;
};

exports.generateInvitationLink = generateInvitationLink;

const generateInvoiceLinkForDistApp = ({
  number,
  withText = false
}) => {
  if (!!withText) return `#${number} - ${_config.default.distributor_host}invoice/${number}`;
  return `${_config.default.distributor_host}invoice/${number}`;
};

exports.generateInvoiceLinkForDistApp = generateInvoiceLinkForDistApp;

const generateInvoiceLinkForEstApp = ({
  invoice,
  subdomain,
  withText = false
}) => {
  if (!!withText) return `#${invoice.number} - ${_config.default.protocol}${subdomain}.${_config.default.client_host}/invoice/${invoice.id}`;
  return `${_config.default.protocol}${subdomain}.${_config.default.client_host}invoice/${invoice.id}`;
};

exports.generateInvoiceLinkForEstApp = generateInvoiceLinkForEstApp;

const generateOrderLinkForEstApp = ({
  order,
  subdomain
}) => {
  return `${_config.default.protocol}${subdomain}.${_config.default.client_host}order/${order.id}`;
};

exports.generateOrderLinkForEstApp = generateOrderLinkForEstApp;

const generateOrderLinkForDistApp = ({
  order_number
}) => {
  return `${_config.default.distributor_host}order/${order_number}`;
};
/**
 * Given a line item, if the item is taxable, it will add a 8.25% sales tax and return the tax amount
 * @param item
 * @param {boolean} item.taxable
 * @param {number} item.total
 * @return {number|number}
 */


exports.generateOrderLinkForDistApp = generateOrderLinkForDistApp;

const computeTaxedTotal = item => {
  return item.taxable ? item.total * 8.25 / 100 : 0;
};

exports.computeTaxedTotal = computeTaxedTotal;