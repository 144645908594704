export const APP_API_CALL_ERROR = "APP_API_CALL_ERROR";

export const APP_SIDE_NAV_VIEW_CHANGE = "APP_SIDE_NAV_VIEW_CHANGE";
export const APP_SIDE_NAV_MINIMIZE = "APP_SIDE_NAV_MINIMIZE";
export const APP_SIDE_NAV_TOGGLE = "APP_SIDE_NAV_TOGGLE";

export const APP_VERSION_CHANGE = "APP_VERSION_CHANGE";
export const APP_CURRENT_VERSION = 3.31;

export const APP_CACHE_RESET_TIMESTAMP = "APP_CACHE_RESET_TIMESTAMP";
export const APP_SET_CACHE_RESET_TIMESTAMP = "APP_SET_CACHE_RESET_TIMESTAMP";
