import {
    COMPANY_QUICK_SEARCH_CHANGE_QUERY,
    COMPANY_QUICK_SEARCH_SUGGESTIONS_DATA,
    COMPANY_QUICK_SEARCH_SUGGESTIONS_ERROR,
    COMPANY_QUICK_SEARCH_SUGGESTIONS_LOADING,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    error: null,
    inputQuery: "",
    isLoading: false,
    suggestions: { invoices: [], customers: [] },
};

const setLoading = state => ({ ...state, isLoading: true });
const changeQuery = (state, inputQuery) => ({ ...state, inputQuery });

const setSuggestionError = (state, error) => ({
    ...state,
    isLoading: false,
    error,
});
const setSuggestions = (state, suggestions) => ({
    ...state,
    suggestions,
    isLoading: false,
    error: null,
});

const reducers = {
    [COMPANY_QUICK_SEARCH_CHANGE_QUERY]: changeQuery,
    [COMPANY_QUICK_SEARCH_SUGGESTIONS_LOADING]: setLoading,
    [COMPANY_QUICK_SEARCH_SUGGESTIONS_DATA]: setSuggestions,
    [COMPANY_QUICK_SEARCH_SUGGESTIONS_ERROR]: setSuggestionError,
};

export default generateReducer(reducers, initialState);
