import Joi from "joi";
import { omit } from "lodash";
import { InvoiceStatuses } from "utilities/dist/invoice/constants";

const companySchema = {
    id: Joi.number().min(1).required(),
    name: Joi.string().trim().required(),
    city: Joi.string().trim().required(),
    state: Joi.string().trim().required(),
    address: Joi.string().trim().required(),
    tabc_id: Joi.string().trim().label("TABC ID").required(),
    zip: Joi.alternatives().try(Joi.string().trim(), Joi.number()).required(),
    subdomain: Joi.string().trim().required(),
    default_invoice_note: Joi.string().allow("").label("Common invoice note").trim(),
    starting_invoice_number: Joi.number().min(1).label("Starting invoice number").required(),
    default_invoice_status: Joi.string().valid(...Object.values(InvoiceStatuses)),
    has_fintech: Joi.alternatives()
        .try(Joi.number().valid(0, 1), Joi.boolean())
        .required()
        .allow(null),
    fintech_id: Joi.string().trim().label("Fintech Id").required(),
    show_prices: Joi.alternatives()
        .try(Joi.boolean(), Joi.number().valid(0, 1, Boolean))
        .required()
        .allow(null),
};

export const validateCompanyUpdate = (company, currentCompany) => {
    let schema = companySchema;
    // if company does not have order feature, we don't need to validate subdomain
    if (!currentCompany.companyFeatures?.find(f => f.name === "order" && f.active)) {
        schema = omit(companySchema, ["subdomain"]);
    }
    if (!company.has_fintech) {
        schema = omit(companySchema, ["fintech_id"]);
    }
    return Joi.object(schema).validateAsync(company, {
        convert: true,
        stripUnknown: true,
    });
};
