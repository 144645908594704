import React, { useEffect } from "react";
import axios from "axios";
import { get } from "lodash";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import { SemanticToastContainer } from "react-semantic-toasts";

import "semantic-ui-less/semantic.less";
import "./styles/app.less";

import { authExpire } from "./auth/actions";
import { store, history, persistor } from "./store";

import AuthMainPage from "./auth/pages/main";
import AuthLogoutPage from "./auth/pages/logout";

import HomePage from "./common/pages/home";
import SettingPage from "./common/pages/setting";
import NotificationPreferencePage from "./common/pages/notification";
import NotFoundPage from "./common/pages/not-found";
import CacheClearPage from "./common/pages/cache-clear";

import CustomerPageHome from "./customer/pages/home";
import CustomerPageEdit from "./customer/pages/edit";
import CustomerPageCreate from "./customer/pages/create";

import CustomerUserHomePage from "./customer-user/pages/home";

import StampPageHome from "./stamp/pages/home";
import StampPageRoll from "./stamp/pages/roll";

import ProductPageHome from "./product/pages/home";
import ProductPageView from "./product/pages/view";

import InvoicePageHome from "./invoice/pages/home";
import InvoicePageView from "./invoice/pages/view";
import InvoicePageEdit from "./invoice/pages/edit";
import InvoicePageCreate from "./invoice/pages/create";
import EmailPageHome from "./invoice/pages/email";

import InvoicePagePayment from "./invoice/pages/payment";

import PrivateRoute from "./common/containers/private-route";
import { initializeTrackingForAnalytics } from "./common/analytics";
import InvoicePagePdf from "./invoice/pages/pdf";
import OrderPageHome from "./order/pages/order";
import OrderPageView from "./order/pages/view";

import InventoryPageHome from "./inventory/pages/home";
import InventoryPageRestock from "./inventory/pages/restock";

import { isProductionDB } from "utilities/dist/config";
import OrderPagePdf from "./order/pages/pdf";
import AllUsersHomePage from "./customer-user/pages/all-users";
import Features from "./common/pages/features";
import BottlePosPageHome from "./bottle-pos/pages/home";
import BottlePosPageSyncLog from "./bottle-pos/pages/sync-log";
import CloverPageHome from "./clover/pages/home";
import CloverPageSyncLog from "./clover/pages/sync-log";
import ActivityPageHome from "./activity/pages/home";

axios.interceptors.response.use(null, err => {
    const status = get(err, "response.status", null);

    if (status === 401) {
        store.dispatch(authExpire());
    }

    return Promise.reject(err);
});

const App = () => {
    useEffect(() => {
        initializeTrackingForAnalytics();
    }, []);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <div className="app-container">
                        <Switch>
                            <PrivateRoute exact path="/" component={HomePage} />
                            <PrivateRoute exact path="/dashboard" component={HomePage} />
                            <PrivateRoute exact path="/setting" component={SettingPage} />
                            <PrivateRoute
                                exact
                                path="/notification"
                                component={NotificationPreferencePage}
                            />{" "}
                            <PrivateRoute exact path="/features" component={Features} />
                            <PrivateRoute exact path="/cache/clear" component={CacheClearPage} />
                            <PrivateRoute exact path="/customer" component={CustomerPageHome} />
                            <PrivateRoute
                                exact
                                path="/customer/create"
                                component={CustomerPageCreate}
                            />
                            <PrivateRoute
                                exact
                                path="/customer/users"
                                component={AllUsersHomePage}
                            />
                            <PrivateRoute
                                exact
                                path="/customer/:customerId/edit"
                                component={CustomerPageEdit}
                            />
                            <PrivateRoute
                                exact
                                path="/customer/:customer_id/user"
                                component={CustomerUserHomePage}
                            />
                            <PrivateRoute exact path="/stamp" component={StampPageHome} />
                            <PrivateRoute exact path="/stamp/roll" component={StampPageRoll} />
                            <PrivateRoute exact path="/product" component={ProductPageHome} />
                            <PrivateRoute exact path="/product/:id" component={ProductPageView} />
                            <PrivateRoute exact path="/invoice" component={InvoicePageHome} />
                            <PrivateRoute exact path="/order" component={OrderPageHome} />
                            <PrivateRoute
                                exact
                                path="/invoice/create"
                                component={InvoicePageCreate}
                            />
                            <PrivateRoute
                                exact
                                path="/invoice/:number"
                                component={InvoicePageView}
                            />
                            <PrivateRoute exact path="/order/:number" component={OrderPageView} />
                            <PrivateRoute
                                exact
                                path="/invoice/:number"
                                component={InvoicePageView}
                            />
                            <PrivateRoute
                                exact
                                path="/invoice/:number/edit"
                                component={InvoicePageEdit}
                            />
                            <PrivateRoute exact path="/emails" component={EmailPageHome} />
                            <PrivateRoute exact path="/payment" component={InvoicePagePayment} />
                            <PrivateRoute exact path="/bottle-pos" component={BottlePosPageHome} />
                            <PrivateRoute
                                exact
                                path="/bottle-pos/:syncId"
                                component={BottlePosPageSyncLog}
                            />
                            <PrivateRoute exact path="/clover" component={CloverPageHome} />
                            <PrivateRoute
                                exact
                                path="/clover/:syncId"
                                component={CloverPageSyncLog}
                            />
                            <PrivateRoute exact path="/activity" component={ActivityPageHome} />
                            <PrivateRoute exact path="/inventory" component={InventoryPageHome} />
                            <PrivateRoute exact path="/inventory/restock" component={InventoryPageRestock} />
                            <Route exact path="/logout" component={AuthLogoutPage} />
                            <Route exact path="/auth/:section" component={AuthMainPage} />
                            <Route exact path="/invoice/pdf/:token" component={InvoicePagePdf} />
                            <Route exact path="/order/pdf/:token" component={OrderPagePdf} />
                            <Route component={NotFoundPage} />
                        </Switch>

                        <SemanticToastContainer position="top-right" />
                    </div>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    );
};

export default App;
