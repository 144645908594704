"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildCustomerInviteLink = exports.buildCustomerAddress = void 0;

/**
 *
 * @param {string} address
 * @param {string} zip
 * @param {string} state
 * @param {string} city
 * @returns {string} - formatted address from the given parameters, in the right order
 */
const buildCustomerAddress = ({
  address,
  zip,
  state,
  city
}) => {
  return [address, city, zip, state].filter(Boolean).join(", ");
};
/**
 * @typedef {Object} InviteLinkParams
 * @property {string} subDomain - subdomain of the company
 * @property {string} domain - base domain of the app, changes based on env
 * @property {string} tabcId - TABC ID of the company
 * @property {string} [email] - email address of the user being invited
 * @property {string} [name] - name of the user being invited
 *
 * @param {InviteLinkParams} data
 *
 * @returns {string} - registration link for the user being invited with the given params
 */


exports.buildCustomerAddress = buildCustomerAddress;

const buildCustomerInviteLink = (data = {}) => {
  const {
    subDomain,
    domain,
    tabcId,
    email,
    name
  } = data;
  const params = new URLSearchParams();
  params.set("tabc_id", tabcId === null || tabcId === void 0 ? void 0 : tabcId.replaceAll(/\s/g, ""));
  params.set("email", email);
  params.set("name", name);
  return `${subDomain}.${domain}/signup?${params.toString()}`;
};

exports.buildCustomerInviteLink = buildCustomerInviteLink;