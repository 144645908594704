import React from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AgGridReact } from "ag-grid-react";
import { Header, Button } from "semantic-ui-react";
import { isEqual, isEmpty, omit, pick } from "lodash";
import { DatesRangeInput } from "semantic-ui-calendar-react";

import { loadProductGridPage, changeProductGridPage, changeProductGridState } from "./actions";
import { showProductEditorModal, loadProductForEdit } from "../editor/actions";
import CustomerContainerPicker from "../../customer/containers/picker";
import GridPageSizePicker from "../../common/components/grid-page-size-picker";

import { parseAmount } from "../../common/helpers";
import { DateFormat } from "utilities/dist/invoice/constants";
import {
    InternalSkuFeature,
    InventoryFeatureDetails,
    isFeatureActive,
} from "../../company/feature/helpers";
import { allGridColumns } from "./columns";

const NoProductsOverlay = () => {
    return <Header>You have not created any product yet.</Header>;
};

const LoadingProductsOverlay = () => {
    return <Header>Loading your product catalog, hang tight...</Header>;
};

const frameworkComponents = {
    noProductsOverlay: NoProductsOverlay,
    loadingProductsOverlay: LoadingProductsOverlay,
};

class DataGrid extends React.Component {
    grid = null;

    componentDidMount() {
        this.props.loadProductGridPage(this.props);
    }

    componentWillUnmount() {
        this.grid = null;
    }

    onGridReady = grid => {
        this.grid = grid;
        this.grid.api.sizeColumnsToFit();

        // this.grid.api.paginationGoToPage(this.props.currentPage);
        this.isInternalSkuActive(this.props.company);
        // Ensure that if there are products already available, loading state is not shown
        if (!this.props.products?.length) {
            this.grid.api.showLoadingOverlay();
        }
        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.setDatasource(this.gridDataSource);

        this.grid.api.addEventListener("sortChanged", () => {
            this.props.changeProductGridState({
                sortModel: this.grid.api.getSortModel(),
            });
        });

        this.grid.api.addEventListener("filterChanged", () => {
            this.props.changeProductGridState({
                filterModel: this.grid.api.getFilterModel(),
            });
        });

        this.grid.api.addEventListener("rowDoubleClicked", e => {
            if (!e.data || !e.data.id) return;

            this.props.loadProductForEdit({
                ...e.data,
                product_price: parseAmount(e.data.product_price),
            });
            this.props.showProductEditorModal(true);
        });

        this.grid.api.addEventListener("rowClicked", e => {
            if (!e.data || !e.data.id || !this.props.onRowSelect) return;

            this.props.onRowSelect(e.data);
        });
        this.toggleCustomerColumn(this.props.customerId);
    };

    changePage = () => {
        if (this.grid) {
            const page = this.grid.api.paginationGetCurrentPage() + 1;

            if (page === this.props.currentPage) return;

            this.props.changeProductGridPage(page);
        }
    };

    componentDidUpdate(prevProps) {
        if (!this.grid) return;
        this.isInternalSkuActive(this.props.company);

        if (prevProps.isLoading && !this.props.isLoading) {
            this.grid.api.hideOverlay();
            this.grid.api.setFilterModel(this.props.filterModel);
        }

        if (!prevProps.isLoading && this.props.isLoading && !this.props.products?.length) {
            this.grid.api.showLoadingOverlay();
        }

        if (!this.props.isLoading && !this.props.products?.length) {
            // this.grid.api.showNoRowsOverlay();
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.grid.api.paginationSetPageSize(this.props.pageSize);
        }

        if (!isEqual(prevProps.filterModel, this.props.filterModel)) {
            this.grid.api.setFilterModel(this.props.filterModel);
        }

        if (!isEqual(prevProps.customerId, this.props.customerId)) {
            this.toggleCustomerColumn(this.props.customerId);
        }
    }

    toggleCustomerColumn = customerId => {
        this.grid.columnApi.getColumn("product_price").colDef.headerName = customerId
            ? "Customer Price"
            : "List Price";
        this.grid.api.refreshHeader();

        this.grid.api.sizeColumnsToFit();
    };

    isInternalSkuActive = company => {
        this.grid.columnApi.setColumnVisible(
            "internal_sku",
            isFeatureActive(company, InternalSkuFeature)
        );
    };

    reloadGridData = () => this.props.loadProductGridPage(this.props);

    applyNameFilter = (name = "") => {
        const nameFilter = this.grid.api.getFilterInstance("product");

        nameFilter.setModel({
            filterType: "text",
            type: "equals",
            filter: name,
        });

        this.grid.api.onFilterChanged();
    };

    handleDateRangeChange = (e, { value }) => {
        this.props.changeProductGridState({ dateRange: value });

        if (value.length === 0) {
            return this.props.loadProductGridPage({ ...this.props, dateRange: "" });
        }

        if (value.split(" - ").length > 1) {
            this.props.loadProductGridPage({ ...this.props, dateRange: value });
        }
    };

    handleCustomerChange = customer => {
        const customerId = get(customer, "id", null);
        this.props.changeProductGridState({ customerId });
        this.props.loadProductGridPage({ ...this.props, customerId });
    };

    toggleArchiveFilter = () => {
        const archivedFilter = this.grid.api.getFilterInstance("archived");
        const current = archivedFilter.getModel();

        archivedFilter.setModel({
            type: "equals",
            filter: current ? (current.filter === 1 ? 0 : 1) : 1,
        });

        this.grid.api.onFilterChanged();
    };

    render() {
        const { filterModel, customerId, company } = this.props;
        const hasFilterOtherThanArchived = !isEmpty(omit(filterModel, ["archived"]));
        const onlyArchived = filterModel.archived && filterModel.archived.filter === 1;
        const conditionalColumns = ["distributor", "internal_sku"];

        return (
            <>
                <div className="clear bm-10">
                    <GridPageSizePicker
                        button
                        compact
                        pointing="top"
                        pageSize={this.props.pageSize}
                        className="right floated basic"
                        text={`${this.props.pageSize}/page`}
                        onChange={this.props.changeProductGridState}
                    />

                    {hasFilterOtherThanArchived && (
                        <Button
                            size="mini"
                            icon="filter"
                            floated="right"
                            content="Clear Filters"
                            onClick={() =>
                                this.grid.api.setFilterModel(pick(filterModel, ["archived"]))
                            }
                        />
                    )}

                    <CustomerContainerPicker
                        dropdownProps={{
                            size: "mini",
                            fluid: false,
                            clearable: true,
                            placeholder: "Select Customer",
                        }}
                        selectedCustomerId={customerId}
                        onSelect={this.handleCustomerChange}
                    />

                    <Button
                        size="mini"
                        floated="right"
                        basic={!onlyArchived}
                        onClick={this.toggleArchiveFilter}
                        icon={onlyArchived ? "archive" : "checkmark"}
                        content={onlyArchived ? "Archived" : "Active"}
                    />

                    <Button
                        size="mini"
                        icon="download"
                        floated="right"
                        content="Export"
                        onClick={() =>
                            this.grid.api.exportDataAsCsv({
                                fileName: `products_${Date.now()}`,
                                allColumns: true,
                                columnKeys: Object.keys(allGridColumns).filter(field => {
                                    if (!conditionalColumns.includes(field)) {
                                        return true;
                                    }

                                    // For sku column, only export if feature is active
                                    if (field === "internal_sku") {
                                        return isFeatureActive(company, InternalSkuFeature);
                                    }

                                    // For sku column, only export if feature is active
                                    if (field === "distributor") {
                                        return isFeatureActive(company, InventoryFeatureDetails);
                                    }
                                }),
                            })
                        }
                    />

                    <DatesRangeInput
                        size="mini"
                        className="fr"
                        clearable={true}
                        animation={null}
                        maxDate={new Date()}
                        value={this.props.dateRange}
                        placeholder="Invoiced Date Range"
                        dateFormat={DateFormat.toUpperCase()}
                        onChange={this.handleDateRangeChange}
                    />
                </div>
                <div className="ag-theme-balham bm-10 clear">
                    <AgGridReact
                        pagination
                        reactNext={true}
                        floatingFilter={true}
                        rowSelection="single"
                        domLayout="autoHeight"
                        style={{ width: "90%" }}
                        rowData={this.props.products}
                        onGridReady={this.onGridReady}
                        columnDefs={this.props.columnDefs}
                        cacheBlockSize={this.props.pageSize}
                        onPaginationChanged={this.changePage}
                        frameworkComponents={frameworkComponents}
                        // TODO: For some reason, when the data is still being loaded, no rows overlay is shown
                        // noRowsOverlayComponent="noProductsOverlay"
                        loadingOverlayComponent="loadingProductsOverlay"
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            filter: true,
                        }}
                    ></AgGridReact>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ productGrid, auth }) => ({
    products: productGrid.products,
    pageSize: productGrid.pageSize,
    isLoading: productGrid.isLoading,
    sortModel: productGrid.sortModel,
    dateRange: productGrid.dateRange,
    customerId: productGrid.customerId,
    columnDefs: productGrid.columnDefs,
    filterModel: productGrid.filterModel,
    currentPage: productGrid.currentPage,
    company: auth.user.company,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadProductGridPage,
            changeProductGridState,
            loadProductForEdit,
            showProductEditorModal,
            changeProductGridPage: page => dispatch(changeProductGridPage(page)),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(DataGrid);
