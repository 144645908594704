import React from "react";
import { Grid, Header, Message, Segment } from "semantic-ui-react";
import ProductSingleComponent from "../grid/single.component";
import { useSelector } from "react-redux";
import { InventoryHistoryComponent } from "./inventory-history.component";

export const ProductViewContainer = ({ id }) => {
    const { product, isLoading, error } = useSelector(({ productView }) => ({
        product: productView.products[id],
        isLoading: productView.isLoading[id],
        error: productView.errors[id],
    }));

    if (error) {
        return <Message negative header="Error loading product" content={error} />;
    }

    return (
        <Grid>
            {isLoading && !product ? (
                <Grid.Column computer={16}>
                    <Segment loading={isLoading} />
                </Grid.Column>
            ) : (
                product && (
                    <>
                        <Grid.Column computer={6}>
                            <ProductSingleComponent product={product} />
                        </Grid.Column>
                        {product?.inventory && (
                            <Grid.Column computer={10}>
                                <Header attached="top" as="h4">
                                    Inventory History
                                </Header>
                                <Segment attached>
                                    <InventoryHistoryComponent
                                        history={product.inventory.inventoryHistory}
                                    />
                                </Segment>
                            </Grid.Column>
                        )}
                    </>
                )
            )}
        </Grid>
    );
};
