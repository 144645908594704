import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isArray, isObject } from "lodash";
import { toast } from "react-semantic-toasts";
import { Form, Button, Input, TextArea, Checkbox } from "formik-semantic-ui";
import { Segment, Message, Header } from "semantic-ui-react";

import { validateCompanyUpdate } from "./validators";
import { saveCompany, setCompanyEditorError, setCompanyEditorInput } from "./actions";
import InvoiceComponentStatus from "../../invoice/components/status";

class CompanyEditorContainer extends React.Component {
    handleSubmit = async (companyData, formik) => {
        try {
            companyData = await validateCompanyUpdate(companyData, this.props.company);
            await this.props.saveCompany(companyData);
            formik.setSubmitting(false);
        } catch (err) {
            const messages = [];
            err.details.forEach(errDet => {
                formik.setFieldError(errDet.context.key, errDet.message);
                messages.push(errDet.message);
            });

            formik.setSubmitting(false);
            this.props.setCompanyEditorError(messages);
        }
    };

    handleConfirm = data => {
        this.formikApi && this.formikApi.setSubmitting(false);

        if (data === null) {
            return;
        }

        if (isArray(data)) {
            data.forEach(errDet => {
                this.formikApi.setFieldError(errDet.context.key, errDet.message);
            });
            return;
        }

        if (isObject(data)) {
            let description = ["Company saved in database."];

            toast({
                description: <Message.List items={description} />,
                time: 6000,
                type: "success",
                icon: "checkmark",
                title: "Created Company",
            });

            this.props.history.push("/company");
        }
    };
    constructor(props) {
        super(props);
        this.state = {
            has_fintech: !!this.props.company.has_fintech,
            show_prices: !!this.props.company.show_prices,
        };
    }

    toggleFintech = () => {
        this.setState({
            has_fintech: !this.state.has_fintech,
        });
    };
    toggleShowPrices = () => {
        this.setState({
            show_prices: !this.state.show_prices,
        });
    };

    render() {
        const { errors, inputs, isSaving, isLoadingCompany, companyError, company } = this.props;
        const hasOrderFeature = company?.companyFeatures?.find(f => f.name === "order" && f.active);

        return (
            <>
                <Header
                    subheader="Changes made here will show up on your invoices"
                    content="Company Details"
                    attached="top"
                    as="h3"
                />
                <Segment attached loading={isLoadingCompany} disabled={!!companyError}>
                    <Form
                        initialValues={inputs}
                        isSubmitting={isSaving}
                        onSubmit={this.handleSubmit}
                        render={({ values: { default_invoice_status } }) => {
                            return (
                                <Form.Children>
                                    <Input name="name" label="Name" type="text" />
                                    <Form.Group widths="2">
                                        <Input name="address" label="Address" type="text" />

                                        <Input name="tabc_id" label="TABC ID" type="text" />
                                    </Form.Group>
                                    <Form.Group widths="2">
                                        <Input name="city" label="City" type="text" />
                                        <Input name="state" label="State" type="text" />
                                        <Input name="zip" label="Zip" type="text" />
                                    </Form.Group>

                                    <Form.Group widths="2">
                                        {hasOrderFeature && (
                                            <Input
                                                size="small"
                                                type="number"
                                                name="subdomain"
                                                label="Company Subdomain"
                                            />
                                        )}
                                        <Input
                                            size="small"
                                            type="number"
                                            name="starting_invoice_number"
                                            label="Starting Invoice Number"
                                        />
                                    </Form.Group>

                                    <InvoiceComponentStatus
                                        status={default_invoice_status}
                                        name="default_invoice_status"
                                        isFormik={true}
                                        label="Select default invoice status"
                                    />

                                    <Form.Group widths="2">
                                        <Checkbox
                                            type="checkbox"
                                            name="has_fintech"
                                            label="Fintech"
                                            inputProps={{
                                                toggle: true,
                                                onChange: () => this.toggleFintech(),
                                            }}
                                        />
                                        {this.state.has_fintech && (
                                            <Input
                                                name="fintech_id"
                                                label="Fintech Id"
                                                inputProps={{
                                                    placeholder: "Fintech ID",
                                                    "data-cy": "fintech_id",
                                                }}
                                            />
                                        )}
                                    </Form.Group>
                                    {hasOrderFeature && (
                                        <Form.Group widths="2">
                                            <Checkbox
                                                type="checkbox"
                                                name="show_prices"
                                                label="Show prices"
                                                inputProps={{
                                                    toggle: true,
                                                    onChange: () => this.toggleShowPrices(),
                                                }}
                                            />
                                        </Form.Group>
                                    )}

                                    <TextArea
                                        name="default_invoice_note"
                                        label="Default note for invoices"
                                    />

                                    <Button.Submit data-cy="save_company" positive>
                                        Save Company
                                    </Button.Submit>
                                </Form.Children>
                            );
                        }}
                    />

                    {errors.length > 0 && (
                        <Message
                            error
                            content={errors}
                            icon="warning sign"
                            header="Error saving company!"
                        />
                    )}
                </Segment>
            </>
        );
    }
}

const mapStateToProps = ({ companyEditor, auth }) => ({
    company: auth.user.company,

    errors: companyEditor.errors,
    inputs: companyEditor.inputs,
    isSaving: companyEditor.isSaving,
    companyError: companyEditor.companyError,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            saveCompany,
            setCompanyEditorError,
            setCompanyEditorInput,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEditorContainer);
