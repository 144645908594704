import React from "react";
import { format } from "date-fns";
import { connect } from "react-redux";
import { isEqual, get } from "lodash";
import { bindActionCreators } from "redux";
import { AgGridReact } from "ag-grid-react";
import { Header, Button } from "semantic-ui-react";
import { DatesRangeInput } from "semantic-ui-calendar-react";

import { loadPaymentGridPage, changePaymentGridPage, changePaymentGridState } from "./actions";
import GridPageSizePicker from "../../common/components/grid-page-size-picker";
import { InvoiceLinkRenderer } from "../grid/cellRenderers.component";
import { allGridColumns } from "./columns";
import { DateFormat } from "utilities/dist/invoice/constants";
import { formatAmount } from "utilities/dist/helpers";

const NoPaymentsOverlay = () => {
    return <Header>No payment history found.</Header>;
};

const frameworkComponents = {
    noPaymentsOverlay: NoPaymentsOverlay,
    invoiceLinkRenderer: InvoiceLinkRenderer,
};

class DataGrid extends React.Component {
    state = {
        dateRange: "",
    };

    handleDateRangeChange = (e, { value }) => {
        const filterModel = { ...this.props.filterModel };
        const [start, end] = value?.split(" - ");

        if (start && end && this.grid) {
            filterModel.received_at = {
                type: "inRange",
                filterType: "date",
                dateTo: format(new Date(end), "yyyy-MM-dd"),
                dateFrom: format(new Date(start), "yyyy-MM-dd"),
            };
        } else if (filterModel.received_at) {
            delete filterModel["received_at"];
        }

        this.props.changePaymentGridState({ filterModel });
        this.setState({ dateRange: value });
    };

    grid = null;
    gridDataSource = {
        getRows: params => {
            this.props.loadPaymentGridPage(this.props, params);
        },
    };

    componentWillUnmount() {
        this.grid = null;
    }

    onGridReady = grid => {
        this.grid = grid;

        setTimeout(() => this.grid && this.grid.api.sizeColumnsToFit(), 300);

        // this.grid.api.paginationGoToPage(this.props.currentPage);
        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.setDatasource(this.gridDataSource);

        this.grid.api.addEventListener("sortChanged", () => {
            this.props.changePaymentGridState({
                sortModel: this.grid.api.getSortModel(),
            });
        });

        this.grid.api.addEventListener("filterChanged", () => {
            this.props.changePaymentGridState({
                filterModel: this.grid.api.getFilterModel(),
            });
        });
    };

    reloadGridData = () => {
        this.grid && this.grid.api.refreshInfiniteCache();
    };

    changePage = () => {
        if (this.grid) {
            const page = this.grid.api.paginationGetCurrentPage() + 1;

            if (page === this.props.currentPage) return;

            this.props.changePaymentGridPage(page);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (!this.grid) return;

        if (prevProps.isLoading && !this.props.isLoading) {
            this.grid.api.hideOverlay();
        }

        if (!prevProps.isLoading && this.props.isLoading) {
            this.grid.api.showLoadingOverlay();
        }

        if (!this.props.isLoading && this.props.payments.length < 1) {
            this.grid.api.showNoRowsOverlay();
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.grid.api.paginationSetPageSize(this.props.pageSize);
        }

        if (!isEqual(prevProps.filterModel, this.props.filterModel)) {
            this.grid.api.setFilterModel(this.props.filterModel);
        }

        const dateFilter = get(this.props, "filterModel.received_at", null);
        if (
            dateFilter &&
            dateFilter.dateFrom &&
            this.state.dateRange === "" &&
            prevState.dateRange === ""
        ) {
            const from = format(new Date(dateFilter.dateFrom), DateFormat),
                to = format(new Date(dateFilter.dateTo), DateFormat);

            this.setState({ dateRange: `${from} - ${to}` });
        }
    }

    render() {
        const { totalAmount, pageSize } = this.props;

        return (
            <>
                <div className="clear bm-10">
                    {totalAmount > 0 && (
                        <Header floated="left" as="h4">
                            Total: {formatAmount(totalAmount)}
                        </Header>
                    )}

                    <GridPageSizePicker
                        button
                        compact
                        pointing="top"
                        pageSize={pageSize}
                        text={`${pageSize}/page`}
                        className="right floated basic"
                        onChange={this.props.changePaymentGridState}
                    />

                    <Button
                        size="mini"
                        icon="download"
                        floated="right"
                        content="Export"
                        onClick={() =>
                            this.grid.api.exportDataAsCsv({
                                fileName: `payments_report_${Date.now()}`,
                                // columnKeys: without(shownColumns, 'actions')
                            })
                        }
                    />

                    <DatesRangeInput
                        size="mini"
                        className="fr"
                        clearable={true}
                        animation={null}
                        maxDate={new Date()}
                        placeholder="From - To"
                        value={this.state.dateRange}
                        dateFormat={DateFormat.toUpperCase()}
                        onChange={this.handleDateRangeChange}
                    />
                </div>
                <div className="ag-theme-balham bm-10">
                    <AgGridReact
                        pagination
                        rowHeight={50}
                        reactNext={true}
                        floatingFilter={true}
                        rowSelection="single"
                        domLayout="autoHeight"
                        rowModelType="infinite"
                        onGridReady={this.onGridReady}
                        cacheBlockSize={this.props.pageSize}
                        onPaginationChanged={this.changePage}
                        columnDefs={Object.values(allGridColumns)}
                        frameworkComponents={frameworkComponents}
                        noRowsOverlayComponent="noPaymentsOverlay"
                        defaultColDef={{ resizable: true, sortable: true, filter: true }}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ invoicePaymentGrid }) => ({
    payments: invoicePaymentGrid.payments,
    pageSize: invoicePaymentGrid.pageSize,
    isLoading: invoicePaymentGrid.isLoading,
    sortModel: invoicePaymentGrid.sortModel,
    columnDefs: invoicePaymentGrid.columnDefs,
    filterModel: invoicePaymentGrid.filterModel,
    totalAmount: invoicePaymentGrid.totalAmount,
    currentPage: invoicePaymentGrid.currentPage,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadPaymentGridPage,
            changePaymentGridState,
            changePaymentGridPage: page => dispatch(changePaymentGridPage(page)),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DataGrid);
