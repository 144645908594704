import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { minimizeSideNav, maximizeSideNav } from "../actions";
import { Responsive, Icon, Label, Menu } from "semantic-ui-react";

import { useOrderUpdateWithSocket } from "../hooks";
import { loadCompanyNotification } from "../../company/notification/actions";

const pages = [
    {
        title: "Dashboard",
        route: "/dashboard",
        icon: "tachometer alternate",
    },
    {
        title: "Order",
        route: "/order",
        icon: "cart arrow down",
    },
    {
        title: "Customers",
        route: "/customer",
        icon: "users",
    },
    {
        title: "Invoices",
        route: "/invoice",
        icon: "newspaper",
    },
    {
        title: "Payments",
        route: "/payment",
        icon: "dollar",
    },
    {
        title: "Stamps",
        route: "/stamp",
        icon: "ticket",
    },
    {
        title: "Products",
        route: "/product",
        icon: "martini glass",
    },
    {
        title: "Inventory",
        route: "/inventory",
        icon: "warehouse",
    },
];

const menuClasses = (page, path) => {
    const cls = ["hp-15 hm-10 sidenav-item db"];

    if (path.indexOf(page.route) === 0 || (page.route === "/dashboard" && path === "/")) {
        cls.push("active");
    }

    return cls.join(" ");
};

const SmallIconMenu = React.memo(
    ({ path, placedOrderCount }) => {
        return (
            <>
                {pages.map(page => (
                    <Link
                        to={page.route}
                        key={page.icon}
                        title={page.title}
                        className={menuClasses(page, path)}
                    >
                        {page.title === "Order" ? (
                            <>
                                {placedOrderCount ? (
                                    <Menu compact id="order-menu">
                                        <Menu.Item as="a">
                                            <Label floating size="small" color="red" key="green">
                                                {placedOrderCount}
                                            </Label>
                                            <Icon name={page.icon} className="order-icon" />
                                        </Menu.Item>
                                    </Menu>
                                ) : (
                                    <>
                                        <Icon name={page.icon} />
                                    </>
                                )}
                            </>
                        ) : (
                            <Icon name={page.icon} />
                        )}

                        <Responsive {...Responsive.onlyComputer} className="hide-on-mini" as="span">
                            {page.title}
                        </Responsive>
                    </Link>
                ))}
            </>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.path === nextProps.path &&
            prevProps.placedOrderCount === nextProps.placedOrderCount
        );
    }
);

SmallIconMenu.displayName = "MemoizedSmallIconMenu";

const MenuHeader = React.memo(
    ({ name, email }) => {
        return (
            <div className="tm-20 bm-40 sp-15 clear text-center">
                <div className="text-white text-bold sidenav-name-initials">
                    <Link title="Setting" to="/setting" className="text-white text-bold">
                        {name
                            .split(" ")
                            .map(part => part[0])
                            .join("")
                            .toUpperCase()}
                    </Link>
                </div>

                <Responsive {...Responsive.onlyComputer} className="hide-on-mini text-white">
                    <div>
                        <Link to="/setting" className="text-white text-bold">
                            {name}
                        </Link>
                    </div>
                    <div>{email}</div>
                </Responsive>
            </div>
        );
    },
    (prevProps, nextProps) => {
        return prevProps.name === nextProps.name && prevProps.email === nextProps.email;
    }
);

MenuHeader.displayName = "MemoizedMenuHeader";

const MenuToggle = React.memo(
    ({ minimized }) => {
        const dispatch = useDispatch();
        const minimize = () => dispatch(minimizeSideNav());
        const maximize = () => dispatch(maximizeSideNav());
        return (
            <Responsive
                as="a"
                {...Responsive.onlyComputer}
                className="hp-15 hm-10 sidenav-item sidenav-collapse clickable"
                onClick={e => {
                    e.preventDefault();
                    minimized ? maximize() : minimize();
                }}
            >
                <Icon name={minimized ? "arrow alternate circle right" : "arrow left"} />
                {minimized ? "" : <span>Minimize</span>}
            </Responsive>
        );
    },
    (prevProps, nextProps) => prevProps.minimized === nextProps.minimized
);

MenuToggle.displayName = "MemoizedMenuToggle";

const SideNav = ({ match }) => {
    const { user, minimized, notification, grid } = useSelector(
        ({ auth, nav, companyNotification, orderGrid }) => ({
            user: auth.user,
            minimized: nav.isMini,
            notification: companyNotification,
            grid: orderGrid,
        })
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadCompanyNotification());
    }, []);

    const { placedOrderCount } = useOrderUpdateWithSocket(notification, grid);

    useEffect(() => {
        document.title = `yDrink Invoice ${placedOrderCount > 0 ? `(${placedOrderCount})` : ""}`;
    }, [placedOrderCount]);

    return (
        <>
            <div>
                <MenuHeader name={user.name} email={user.email} />
                <SmallIconMenu path={match.path} placedOrderCount={placedOrderCount} />
            </div>

            <MenuToggle minimized={minimized} />
        </>
    );
};

export default withRouter(SideNav);
