import React from "react";
import pluralize from "pluralize";
import { List, Button, Segment, Message, Icon } from "semantic-ui-react";

import { computeStampTotal } from "../helpers";
import { InvoiceStatuses } from "utilities/dist/invoice/constants";

import { formatStampRange } from "../../common/helpers";

import InvoiceStampContainerInput from "../builder/stamp-input.container";

const InvoiceComponentSummary = ({ status, totals, stamps = [], onStampRemove, onStampEdit }) => {
    const totalStampsAssigned = computeStampTotal(stamps);
    const missingStamps = totals.positiveLiquorCount - totalStampsAssigned;

    return (
        <div className="summary-area">
            {totals.liquorItem && (
                <>
                    {status === InvoiceStatuses.CANCELLED && (
                        <Message attached="top" error className="no-print">
                            <Icon name="warning circle" />
                            Cancelled invoice. Below stamps are are available for use.
                        </Message>
                    )}
                    {missingStamps > 0 && (
                        <Message attached="top" warning className="no-print">
                            <Icon name="warning circle" />
                            {pluralize("More Stamp", missingStamps, true)} Needed
                        </Message>
                    )}
                    {missingStamps < 0 && (
                        <Message warning attached="top" className="no-print">
                            <Icon name="warning circle" />
                            {pluralize("Extra Stamp", -missingStamps, true)} Added
                        </Message>
                    )}
                    <Message attached>
                        <b>
                            {stamps.length > 0
                                ? pluralize("TABC Stamp", totalStampsAssigned, true)
                                : "TABC Stamps"}
                        </b>
                    </Message>
                    {stamps.length > 0 && (
                        <Segment attached>
                            <div className="lp-5">
                                <List verticalAlign="middle">
                                    {stamps.map(({ roll_id, from, to, prefix }, stampIndex) => {
                                        return (
                                            <List.Item
                                                clearing
                                                key={`stamp_info_${roll_id}_${from}_${to}`}
                                            >
                                                {!!onStampRemove && (
                                                    <List.Content
                                                        floated="left"
                                                        className="no-print"
                                                    >
                                                        <Button.Group size="mini">
                                                            <Button
                                                                icon="remove"
                                                                onClick={() => {
                                                                    onStampRemove(stampIndex);
                                                                }}
                                                            />
                                                            <Button
                                                                icon="pencil"
                                                                onClick={() => {
                                                                    onStampEdit(stampIndex);
                                                                }}
                                                            />
                                                        </Button.Group>
                                                    </List.Content>
                                                )}
                                                <List.Content floated="left" className="tp-5">
                                                    <List.Description>
                                                        {to - from + 1} <Icon name="ticket" />{" "}
                                                        {formatStampRange({
                                                            from,
                                                            to,
                                                            prefix,
                                                        })}
                                                    </List.Description>
                                                </List.Content>
                                            </List.Item>
                                        );
                                    })}
                                </List>
                            </div>
                        </Segment>
                    )}
                    {!!onStampRemove && <InvoiceStampContainerInput />}
                </>
            )}
            <Message attached>
                <b>
                    {pluralize("Item", totals.totalItems, true)} :{" "}
                    {pluralize(totals.naItem > 0 ? "unit" : "btl", totals.totalUnits, true)}
                </b>
            </Message>
            <Segment attached="bottom">
                <div className="lp-5">
                    {totals.liquorItem && (
                        <>
                            {totals.liquorItem} DS : {pluralize("btl", totals.liquorCount, true)}
                            <br />
                        </>
                    )}
                    {totals.wineItem && (
                        <>
                            {totals.wineItem} Wine : {pluralize("btl", totals.wineCount, true)}
                            <br />
                        </>
                    )}
                    {totals.naItem && (
                        <>
                            {totals.naItem} N/A : {pluralize("unit", totals.naCount, true)}
                            <br />
                        </>
                    )}
                    {totals.beerItem && (
                        <>
                            {totals.beerItem} Beer : {pluralize("btl", totals.beerCount, true)}
                            <br />
                        </>
                    )}
                </div>
            </Segment>
        </div>
    );
};

export default InvoiceComponentSummary;
