import { pick } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Message, Menu, Confirm, Button } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import CustomerContainerEditor from "../containers/editor";

import { loadCustomerForEdit, resetEditingCustomer, removeCustomer } from "../actions";
import { toast } from "react-semantic-toasts";
import { CUSTOMER_EDITOR_ERROR_REMOVING } from "../constants";

const CustomerPageEdit = ({ computedMatch, history }) => {
    const [isConfirmingRemoval, setIsConfirmingRemoval] = useState(false);
    const dispatch = useDispatch();
    const { error, isRemoving, removalErrors, customer } = useSelector(({ customerEditor }) =>
        pick(customerEditor, ["error", "customer", "isRemoving", "removalErrors", "customer"])
    );

    useEffect(() => {
        dispatch(loadCustomerForEdit(computedMatch.params.customerId));

        return () => {
            dispatch(resetEditingCustomer());
        };
    }, [computedMatch.params.customerId]);

    return (
        <Grid>
            <Confirm
                header="Are you sure?"
                cancelButton="Nevermind"
                onConfirm={() =>
                    dispatch(
                        removeCustomer(computedMatch.params.customerId, () => {
                            history.goBack();

                            toast({
                                description: `${customer.name} has been removed`,
                                time: 30000,
                                type: "success",
                                icon: "checkmark",
                                title: "Customer removed",
                            });
                        })
                    )
                }
                open={isConfirmingRemoval}
                content={
                    <div className="content">
                        <p>
                            Warning! Sure you want to remove this customer? All associated data with
                            the customer will be removed along with invoices and users of this
                            customer account.
                        </p>
                        {!!removalErrors?.length && (
                            <Message error header="Error removing customer" list={removalErrors} />
                        )}
                    </div>
                }
                confirmButton={
                    <Button
                        negative
                        loading={isRemoving}
                        disabled={isRemoving}
                        content="Yes, Remove Customer"
                    />
                }
                onCancel={() => {
                    setIsConfirmingRemoval(false);
                    dispatch({ type: CUSTOMER_EDITOR_ERROR_REMOVING, payload: [] })
                }}
            />
            <TopNav
                headerIcon="briefcase"
                subheader="Update existing customer details"
                headerContent="Edit Customer"
                extraButtons={() => (
                    <>
                        <Menu.Item
                            icon="briefcase"
                            name="All Customers"
                            onClick={() => history.goBack()}
                        />
                        <Menu.Item
                            disabled={isRemoving}
                            icon="trash"
                            name="Remove"
                            onClick={() => setIsConfirmingRemoval(true)}
                        />
                    </>
                )}
            />

            <Grid.Column mobile={16}>
                {error && (
                    <Message
                        error
                        content={error}
                        header={`Error loading customer #${computedMatch.params.customerId}`}
                    />
                )}
                <CustomerContainerEditor />
            </Grid.Column>
        </Grid>
    );
};

export default CustomerPageEdit;
