import { pick } from "lodash";

import {
    COMPANY_STATS_DATA,
    COMPANY_STATS_ERROR,
    COMPANY_STATS_LOADING,
    COMPANY_STATS_CHANGE_DATERANGE,
} from "../constants";
import { dateRanges } from "./helpers";
import { generateReducer } from "../../common/helpers";

const initialState = {
    stats: {},
    error: null,
    isLoading: false,
    exportableProductStats: [],

    dateRange: dateRanges[0].name,
};

const setLoading = state => ({ ...state, isLoading: true });
const setError = (state, error) => ({ ...state, isLoading: false, error });
const setData = (state, stats) => ({
    ...state,
    stats,
    error: null,
    isLoading: false,
    exportableProductStats: !stats.productStats
        ? []
        : stats.productStats.map(i => ({
              ...i.product,
              ...pick(i, ["total_invoices", "total_qty", "total_amount"]),
          })),
});

const changeDateRange = (state, dateRange) => ({ ...state, dateRange });

const reducers = {
    [COMPANY_STATS_DATA]: setData,
    [COMPANY_STATS_ERROR]: setError,
    [COMPANY_STATS_LOADING]: setLoading,

    [COMPANY_STATS_CHANGE_DATERANGE]: changeDateRange,
};

export default generateReducer(reducers, initialState);
