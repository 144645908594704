import React, { useEffect } from "react";
import { Grid, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

import TopNav from "../../common/containers/top-nav";
import { useDispatch, useSelector } from "react-redux";
import { loadProduct } from "../view/actions";
import { ProductViewContainer } from "../view/index.container";

export const ProductPageView = ({ computedMatch }) => {
    const id = Number(computedMatch.params?.id);
    const dispatch = useDispatch();
    const product = useSelector(({ productView }) => productView.products?.[id]);

    useEffect(() => {
        dispatch(loadProduct({ id }));
    }, [id, dispatch]);

    return (
        <Grid>
            <TopNav
                headerIcon="newspaper"
                headerContent={product?.product || "Product"}
                subheader={
                    product
                        ? [product.product_size, product.upc, product.internal_sku]
                              ?.filter(Boolean)
                              .join(" | ")
                        : "Product details"
                }
                extraButtons={() => (
                    <>
                        <Menu.Item
                            as={Link}
                            to="/inventory"
                            icon="chevron left"
                            name="All Inventory"
                        />
                    </>
                )}
            />
            <Grid.Column mobile={16} tablet={16}>
                <ProductViewContainer id={id} />
            </Grid.Column>
        </Grid>
    );
};
export default ProductPageView;
