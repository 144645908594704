import {
    PRODUCT_GRID_DATA,
    PRODUCT_GRID_ERROR,
    PRODUCT_GRID_LOADING,
    PRODUCT_GRID_PAGE_CHANGE,
    PRODUCT_GRID_STATE_CHANGE,
} from "../constants";
import { allGridColumns } from "./columns";
import { generateReducer } from "../../common/helpers";

const initialState = {
    error: null,
    products: [],
    isLoading: false,
    currentPage: 1,
    lastRow: null,
    pageSize: 25,
    sortModel: [
        {
            colId: "units_sold",
            sort: "desc",
        },
    ],
    dateRange: "",
    customerId: null,
    columnDefs: Object.values(allGridColumns),
    filterModel: { archived: { type: "equals", filter: 0 } },
};

const setGridData = (state, { products, lastRow }) => ({
    ...state,
    error: null,
    isLoading: false,
    lastRow,
    products,
});

const changeGridPage = (state, currentPage) => ({
    ...state,
    currentPage,
});
const changeGridState = (state, payload) => ({
    ...state,
    ...payload,
});
const setGridLoading = state => ({
    ...state,
    isLoading: true,
});
const setGridError = (state, error) => ({
    ...state,
    isLoading: false,
    error,
});
const updateGridRow = (state, updatedProduct) => {
    const products = state.products.map(product => {
        if (product.id === updatedProduct.id) {
            return { ...product, ...updatedProduct };
        }

        return product;
    });

    return {
        ...state,
        products,
    };
};

const reducers = {
    [PRODUCT_GRID_DATA]: setGridData,
    [PRODUCT_GRID_ERROR]: setGridError,
    [PRODUCT_GRID_LOADING]: setGridLoading,
    [PRODUCT_GRID_PAGE_CHANGE]: changeGridPage,
    [PRODUCT_GRID_STATE_CHANGE]: changeGridState,
    // When product is saved in editor, update the grid row to reflect updated product
    // [PRODUCT_EDITOR_SAVED]: updateGridRow,
};

export default generateReducer(reducers, initialState);
