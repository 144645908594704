import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Menu } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import InvoiceBuilderContainer from "../builder/index.container";

import { loadStampRoll } from "../../stamp/roll/actions";
import { loadInvoiceForEdit, resetInvoiceBuilder } from "../builder/actions";
import { resetItemLog } from "../item-log/actions";

const InvoicePageEdit = ({ history, computedMatch }) => {
    const { number } = computedMatch.params;
    const dispatch = useDispatch();
    const loadInvoice = () => {
        dispatch(loadStampRoll());
        dispatch(loadInvoiceForEdit(number));

        // returned function is called when component is unmounted
        // when user moves away from edit page, we want to reset invoice builder to make sure
        // when creating new invoice or editing another, this current one does not get tangled up
        return () => {
            dispatch(resetInvoiceBuilder());
            dispatch(resetItemLog());
        };
    };

    useEffect(loadInvoice, [number]);

    return (
        <Grid>
            <TopNav
                headerIcon="newspaper"
                headerContent={`Edit #${number}`}
                subheader={`Update Invoice`}
                extraButtons={() => (
                    <Menu.Item
                        icon="chevron left"
                        name="Invoice"
                        onClick={() => history.goBack()}
                    />
                )}
            />

            <Grid.Column mobile={16}>
                <InvoiceBuilderContainer />
            </Grid.Column>
        </Grid>
    );
};

export default InvoicePageEdit;
