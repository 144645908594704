import { makePrivateApiCall } from "utilities/dist/api";

export const loadCustomerAutoFillSuggestions = query => {
    return makePrivateApiCall({
        url: "/customer/auto-fill-suggestions",
        params: { query },
    });
};

const loadedExpirations = [];
export const loadCustomerExpiration = customerId => {
    if (loadedExpirations[customerId] !== undefined) {
        return Promise.resolve(loadedExpirations[customerId]);
    }

    return makePrivateApiCall({
        url: `/customer/${customerId}/tabc-expiration`,
    }).then(res => {
        loadedExpirations[customerId] = res.data;
        return res.data;
    });
};
