import { generateReducer } from "../../common/helpers";
import {
    ALL_CUSTOMER_USER_LIST_LOADING,
    ALL_CUSTOMER_USER_LIST_ERROR,
    CUSTOMER_USER_ACCESS_SAVED,
    CUSTOMER_USER_LIST_ROW_UPDATE,
    CUSTOMER_USER_LIST_UPDATE,
    ALL_CUSTOMER_USER_LIST_DATA,
} from "../constants";

const initialState = {
    error: null,
    customerData: null,
    data: [],
    isLoading: false,
};

const loadAllCustomerUsers = (state, data) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        data: data,
    };
};
const newCustomerUsers = (state, invitedUser) => {
    return { ...state, error: null, isLoading: false, data: [...state.data, invitedUser] };
};

const updateCustomerUser = (state, updatedCustomerUserData) => {
    return {
        ...state,
        data: state.data.map(customerUser => {
            if (customerUser.token && customerUser.email === updatedCustomerUserData.email)
                return updatedCustomerUserData;
            if (!customerUser.token && customerUser.email === updatedCustomerUserData.email)
                return updatedCustomerUserData;
            return customerUser;
        }),
        isLoading: false,
    };
};

const resetState = () => initialState;

const setError = (state, error) => ({ ...state, isLoading: false, error });
const setLoading = state => ({ ...state, isLoading: true });

const reducers = {
    [CUSTOMER_USER_ACCESS_SAVED]: resetState,
    [ALL_CUSTOMER_USER_LIST_LOADING]: setLoading,
    [ALL_CUSTOMER_USER_LIST_ERROR]: setError,
    [ALL_CUSTOMER_USER_LIST_DATA]: loadAllCustomerUsers,
    [CUSTOMER_USER_LIST_UPDATE]: newCustomerUsers,
    [CUSTOMER_USER_LIST_ROW_UPDATE]: updateCustomerUser,
};

export default generateReducer(reducers, initialState);
