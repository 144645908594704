import {
    STAMP_LAST_DATA,
    STAMP_LAST_LOADING,
    STAMP_LAST_ERROR,
    STAMP_HIDE_LOW_WARNING,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";

export const loadLastStamp = () => dispatch => {
    dispatch({
        type: STAMP_LAST_LOADING,
    });

    return makePrivateApiCall({
        url: "stamp/remaining",
    })
        .then(res => {
            if (res.data) {
                dispatch({ type: STAMP_LAST_DATA, payload: res.data });
                return res.data;
            } else {
                dispatch({ type: STAMP_LAST_ERROR, payload: "No data found" });
            }
        })
        .catch(err =>
            dispatch({
                type: STAMP_LAST_ERROR,
                payload: err.message,
            })
        );
};

export const hideLowStampWarning = () => ({
    type: STAMP_HIDE_LOW_WARNING,
});
