import React from "react";
import { Link } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";
import { format } from "date-fns";
import { DateTimeFormat } from "utilities/dist/invoice/constants";

import OrderComponentStatus from "../components/status";

export const OrderLinkRenderer = ({ value }) => {
    // grid values are parsed through valueGetter which adds B if inv is for beer so we replace that from link
    return <Link to={`/order/${value}`}>#{value}</Link>;
};

export const OrderStatusRenderer = ({ value }) => {
    return (
        <span className="tm-10 dib">
            <OrderComponentStatus size="mini" noIcon={true} status={value} />
        </span>
    );
};
export const OrderHistoryRenderer = ({ data }) => {
    const invoiceNumberWithB = inv => {
        return inv.beer ? `${inv.number}B` : inv.number;
    };
    return (
        <>
            {data.invoices?.length ? (
                <Popup
                    trigger={<Icon name="newspaper" color="green" />}
                    content={
                        <p>
                            {" "}
                            Invoice
                            {data.invoices.map(inv => (
                                <Link
                                    key={inv.id}
                                    title="Invoice number"
                                    to={`/invoice/${!!inv.beer ? inv.number + "B" : inv.number}`}
                                >
                                    {" "}
                                    #{invoiceNumberWithB(inv)}
                                </Link>
                            ))}{" "}
                            created at{" "}
                            {format(new Date(data.invoices[0].invoiced_at), DateTimeFormat)}{" "}
                        </p>
                    }
                    basic
                />
            ) : (
                <Popup
                    trigger={<Icon name="newspaper" color="grey" />}
                    content="No invoice has created from this order"
                    basic
                />
            )}
        </>
    );
};
