import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Table, TableCell } from "semantic-ui-react";
import { Form, Button, Checkbox } from "formik-semantic-ui";

import { toggleCompanyNotification } from "./actions";

const CompanyNotificationContainer = () => {
    const { email_notification, app_notification } = useSelector(
        ({ companyNotification }) => companyNotification
    );

    const dispatch = useDispatch();

    const handleNotification = (notifications, formik) => {
        dispatch(toggleCompanyNotification({ notifications })).finally(() => {
            formik.setSubmitting(false);
        });
    };

    return (
        <>
            <Header
                subheader="Manage notification preferences to enhance your enjoyment"
                content="Notification Preference"
                attached="top"
                as="h3"
            />

            <Form
                onSubmit={handleNotification}
                initialValues={{ email_notification, app_notification }}
            >
                <Table attached>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>E-mail</Table.HeaderCell>
                            <Table.HeaderCell>App</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Order Placed</Table.Cell>
                            <Table.Cell>
                                <Checkbox name="email_notification" />
                            </Table.Cell>
                            <Table.Cell>
                                <Checkbox name="app_notification" />
                            </Table.Cell>
                        </Table.Row>
                        <TableCell>
                            <Button.Submit positive>Save Preference</Button.Submit>
                        </TableCell>
                    </Table.Body>
                </Table>
            </Form>
        </>
    );
};

export default CompanyNotificationContainer;
//attached loading={isLoadingCompany} disabled={!!companyError}
