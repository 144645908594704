"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseAuthSchema = void 0;

var _joi = _interopRequireDefault(require("joi"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const baseAuthSchema = {
  email: _joi.default.string().email({
    tlds: {
      allow: false
    }
  }).required().messages({
    "string.empty": `Email can't be an empty field`
  }),
  password: _joi.default.string().required().min(5).messages({
    "string.empty": `Password can't be an empty field`
  })
};
exports.baseAuthSchema = baseAuthSchema;