import React, { useEffect, useState } from "react";
import { isArray, isEmpty } from "lodash";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";

import {
    Grid,
    Segment,
    Message,
    Button,
    Dimmer,
    Loader,
    Divider,
    Header,
    Icon,
    Image,
    Modal,
} from "semantic-ui-react";

import InvoiceComponentStatus from "../components/status";
import InvoiceComponentTimestamp from "../components/timestamp";
import InvoiceBuilderContainerCustomer from "./customer.container";
import InvoiceComponentProductList from "../components/product-list";
import InvoiceBuilderComponentEditor from "./product-editor.component";
import InvoiceComponentCompanyHeader from "../components/company-header";
import CompanyLogoGalleryContainer from "../../company/logo/gallery.container";

import {
    resetInvoiceBuilder,
    changeInvoiceBuilderQty,
    addInvoiceBuilderProduct,
    changeInvoiceBuilderNote,
    cloneLastInvoiceBuilder,
    changeInvoiceBuilderDate,
    removeInvoiceBuilderItem,
    changeInvoiceBuilderLogo,
    saveInvoiceBuilderInvoice,
    changeInvoiceBuilderPrice,
    changeInvoiceBuilderStatus,
    selectInvoiceBuilderProduct,
    toggleInvoiceBuilderTaxable,
    createInvoiceBuilderInvoice,
    showInvoiceBuilderInlineEditor,
    hideInvoiceBuilderInlineEditor,
    changeInvoiceBuilderInlineEditor,
    changeShowInvoiceBuilderLogoPicker,
    removeInvoiceBuilderStampRoll,
    changeInvoiceBuilderStamp,
} from "./actions";
import { InvoiceStatuses } from "utilities/dist/invoice/constants";

import { changeInvoiceGridState } from "../grid/actions";
import { clearProductSearch } from "../../product/search/actions";
import { isBeer, isLiquor, computeTotalFromItems, buildInvoiceNumberWithBeer } from "../helpers";
import InvoiceBuilderFooterContent from "./footer-content.component";
import InvoiceConverterUploaderContainer from "../converter/uploader.container";
import { loadCompanyLogos } from "../../company/logo/actions";
import {
    InternalSkuFeature,
    InventoryFeatureDetails,
    isFeatureActive,
} from "../../company/feature/helpers";
import { Link } from "react-router-dom";
import InvoiceBuilderContainerConfirmToggle from "./confirm-toggle.container";
import {
    addItemLogProduct,
    changeItemLogInlineEditor,
    hideItemLogInlineEditor,
    itemLogRemoveItem,
    resetItemLog,
    showItemLogInlineEditor,
} from "../item-log/actions";
import { useProductEditorErrors } from "./product-editor-error";

const InvoiceBuilderContainer = () => {
    const {
        inlineEditorItemId,
        inlineEditorFields,
        showLogoPicker,
        cloningLast,
        invoiced_at,
        isLoading,
        isSaving,
        customer,
        order_number,
        logo_id,
        invoice,
        company,
        stamps,
        editor,
        status,
        logos,
        errors,
        items,
        note,
        orderNumber,
        itemLogInlineEditorFields,
        itemLogInlineEditorItemId,
        itemLog,
    } = useSelector(({ invoiceBuilder, auth, companyLogo, itemLog }) => ({
        company: auth.user.company,

        logos: companyLogo.logos,

        inlineEditorItemId: invoiceBuilder.inlineEditorItemId,
        inlineEditorFields: invoiceBuilder.inlineEditorFields,
        showLogoPicker: invoiceBuilder.showLogoPicker,
        cloningLast: invoiceBuilder.cloningLast,
        invoiced_at: invoiceBuilder.invoiced_at,
        isLoading: invoiceBuilder.isLoading,
        isSaving: invoiceBuilder.isSaving,
        customer: invoiceBuilder.customer,
        order_number: invoiceBuilder.order_number,
        logo_id: invoiceBuilder.logo_id
            ? invoiceBuilder.logo_id
            : auth.user.company?.default_logo_id,
        invoice: invoiceBuilder.invoice,
        stamps: invoiceBuilder.stamps,
        editor: invoiceBuilder.editor,
        errors: invoiceBuilder.errors,
        status: invoiceBuilder.status,
        items: invoiceBuilder.items,
        note: invoiceBuilder.note,
        orderNumber: invoiceBuilder.order_number,
        itemLogInlineEditorFields: itemLog.inlineEditorFields,
        itemLogInlineEditorItemId: itemLog.inlineEditorItemId,
        itemLog: itemLog.items,
    }));
    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        if (logos?.length > 0) dispatch(loadCompanyLogos());
    }, []);

    const logo = logos.find(({ id }) => logo_id === id);
    const isCancelled = !!invoice && invoice.status === InvoiceStatuses.CANCELLED;
    const itemsArr = Object.values(items);
    const itemLogArr = Object.values(itemLog);
    const totals = computeTotalFromItems(itemsArr);
    const canCloneLast = (!invoice || !invoice.id) && isEmpty(itemsArr);
    const hasBeer = !!itemsArr.find(i => isBeer(i));
    // by doing a !hasBeer first, we make sure the second check is only performed if no beer item has been added
    // saves us an entire loop through all the items in the invoice
    const hasLiquor = !!itemsArr.find(i => isLiquor(i));
    const orderNumberFromInvoice = invoice?.orders.length ? invoice?.orders[0].order_number : "";

    const cloneLast = () => dispatch(cloneLastInvoiceBuilder(customer));
    const handleCancel = () => {
        dispatch(resetInvoiceBuilder());
        dispatch(resetItemLog());
    };
    const confirmSaveInvoiceData = (saveFunc, invoiceData) => {
        dispatch(
            saveFunc({
                ...invoiceData,
                customer,
                orderNumber: order_number,
                stamps,
                status,
                invoiced_at,
                note,
                logo_id,
                items: itemsArr,
                itemLog: itemLogArr,
            })
        ).then(inv => {
            if (isArray(inv)) {
                if (inv.length > 1) {
                    dispatch(
                        changeInvoiceGridState({
                            filterModel: {
                                number: {
                                    type: "inRange",
                                    filterType: "number",
                                    filter: inv[0].number,
                                    filterTo: inv[1].number,
                                },
                            },
                        })
                    );
                    return dispatch(push(`/invoice`));
                } else {
                    return dispatch(push(`/invoice/${buildInvoiceNumberWithBeer(inv[0])}`));
                }
            } else if (!isEmpty(inv)) {
                return dispatch(push(`/invoice/${buildInvoiceNumberWithBeer(inv)}`));
            }
        });
    };
    const priceZeroItems = itemsArr.filter(item => item.price === 0);

    const saveInvoice = invoiceData => {
        let saveFunc;
        if (!priceZeroItems.length) {
            saveFunc = invoiceData.id ? saveInvoiceBuilderInvoice : createInvoiceBuilderInvoice;

            confirmSaveInvoiceData(saveFunc, invoiceData);
        } else {
            setShowModal(true);
        }
    };
    const saveOnConfirm = () => {
        const saveFunc = invoice?.id ? saveInvoiceBuilderInvoice : createInvoiceBuilderInvoice;

        confirmSaveInvoiceData(saveFunc, invoice);
        setShowModal(false);
    };
    const handleToggleLogoPicker = showPicker =>
        dispatch(changeShowInvoiceBuilderLogoPicker(showPicker));
    const handleDateChange = invoiced_at => dispatch(changeInvoiceBuilderDate(invoiced_at));
    const handleStatusChange = status => dispatch(changeInvoiceBuilderStatus(status));
    const handleProductSelect = product => dispatch(selectInvoiceBuilderProduct(product));
    const changePrice = (e, { value }) => dispatch(changeInvoiceBuilderPrice(value));
    const changeNote = (e, { value }) => dispatch(changeInvoiceBuilderNote(value));
    const changeQty = (e, { value }) => dispatch(changeInvoiceBuilderQty(value));
    const toggleTaxable = () => dispatch(toggleInvoiceBuilderTaxable());
    const addProduct = () => {
        dispatch(addInvoiceBuilderProduct());
        dispatch(clearProductSearch());
        if (orderNumber) {
            dispatch(addItemLogProduct());
        }
    };
    const handleLogoChange = logo_id => {
        dispatch(changeInvoiceBuilderLogo(logo_id));
        handleToggleLogoPicker(false);
    };

    const handleHideInlineEditor = field => {
        dispatch(hideInvoiceBuilderInlineEditor(field));
        dispatch(hideItemLogInlineEditor(field));
    };

    const handleChangeInlineEditor = (field, value) =>
        dispatch(changeInvoiceBuilderInlineEditor(field, value));

    const handleChangeItemLogInlineEditor = (field, value) =>
        dispatch(changeItemLogInlineEditor(field, value));

    const handleShowItemLogInlineEditor = (item, field) =>
        dispatch(showItemLogInlineEditor(item, field));

    const handleShowInlineEditor = (item, field) =>
        dispatch(showInvoiceBuilderInlineEditor(item, field));

    const removeStamp = rollIndex => dispatch(removeInvoiceBuilderStampRoll(rollIndex));

    const editStamp = rollIndex => {
        dispatch(removeInvoiceBuilderStampRoll(rollIndex));
        dispatch(changeInvoiceBuilderStamp(stamps[rollIndex]));
    };
    const handleItemRemove = item => {
        dispatch(removeInvoiceBuilderItem(item));
        dispatch(itemLogRemoveItem(item));
    };

    const handleItemEdit = item => {
        handleShowItemLogInlineEditor(item, ["actions", "qty", "price"]);
        handleShowInlineEditor(item, ["price", "qty"]);
    };
    const isInternalSkuActive = isFeatureActive(company, InternalSkuFeature);
    const isInventoryActive = isFeatureActive(company, InventoryFeatureDetails);

    const { showTypeMisMatchError, errors: productEditorErrors } = useProductEditorErrors({
        hasBeer,
        item: editor,
        isInventoryActive,
        invoiceId: invoice?.id,
        totalItems: itemsArr.length,
        isInvoiceBeer: invoice?.beer === true || invoice?.beer === "1",
    });

    useEffect(() => {
        if (!status) handleStatusChange(company.default_invoice_status);
    }, [status]);

    return (
        <Dimmer.Dimmable as={Grid} dimmed={isLoading || isSaving}>
            <Dimmer active={isLoading || isSaving} inverted>
                <Loader>Loading</Loader>
            </Dimmer>
            <Grid.Row>
                <Grid.Column textAlign="left" mobile={16} tablet={10} computer={10}>
                    <Modal
                        closeIcon
                        closeOnEscape={true}
                        open={showLogoPicker}
                        closeOnDimmerClick={true}
                        onClose={() => handleToggleLogoPicker(false)}
                    >
                        <Modal.Content>
                            <CompanyLogoGalleryContainer onSelect={handleLogoChange} />
                        </Modal.Content>
                    </Modal>

                    {!!logo ? (
                        <div className="bp-10">
                            <Image
                                floated="left"
                                className="clickable rm-10"
                                src={logo.path}
                                onClick={() => handleToggleLogoPicker(true)}
                                style={{
                                    maxWidth: "200px",
                                    maxHeight: "100px",
                                }}
                            />
                            <InvoiceComponentCompanyHeader company={company} />
                        </div>
                    ) : (
                        <>
                            <Header.Subheader
                                className="no-print clickable"
                                onClick={() => handleToggleLogoPicker(true)}
                                style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                }}
                            >
                                <Icon name="picture" /> Add Logo
                            </Header.Subheader>

                            <InvoiceComponentCompanyHeader company={company} />
                        </>
                    )}
                    {!!showModal && (
                        <InvoiceBuilderContainerConfirmToggle
                            saveOnConfirm={saveOnConfirm}
                            setShowModal={setShowModal}
                            zeroPricedItems={priceZeroItems}
                            saveInvoice={saveInvoice}
                        />
                    )}

                    <InvoiceComponentStatus
                        status={status}
                        onChange={(e, { value }) => handleStatusChange(value)}
                    />
                    <InvoiceComponentTimestamp
                        invoiced_at={invoiced_at}
                        onChange={(e, { value }) => handleDateChange(value)}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={6} computer={6}>
                    <InvoiceBuilderContainerCustomer />
                    {orderNumber ? (
                        <Header textAlign="right">
                            <Header.Subheader className="no-print">
                                Creating invoice from Order{" "}
                                <Link title="Order info" to={`/order/${orderNumber}`}>
                                    #{orderNumber}
                                </Link>
                            </Header.Subheader>
                        </Header>
                    ) : (
                        ""
                    )}
                </Grid.Column>
            </Grid.Row>

            {customer && customer.id && (
                <Grid.Row verticalAlign="middle">
                    <Grid.Column width={16}>
                        {errors.length > 0 && (
                            <Message
                                error
                                header="Something Went Wrong"
                                list={errors.length > 1 && errors}
                                content={errors.length === 1 && errors}
                            />
                        )}
                        <Segment disabled={isCancelled} attached={productEditorErrors?.length}>
                            <Grid.Row className="ui grid computer only tablet only">
                                <Grid.Column>
                                    {canCloneLast && !!customer.fintech_option && (
                                        <InvoiceConverterUploaderContainer />
                                    )}

                                    {canCloneLast && (
                                        <Button
                                            onClick={cloneLast}
                                            labelPosition="left"
                                            disabled={cloningLast}
                                            content="Replicate Last Invoice"
                                            icon={{
                                                name: cloningLast ? "spinner" : "copy",
                                                loading: cloningLast,
                                            }}
                                        />
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="ui grid mobile only" textAlign="center">
                                <Grid.Column className="ui coloumn grid">
                                    <Button.Group className="ui grid" size="mini">
                                        {canCloneLast && (
                                            <Button
                                                onClick={cloneLast}
                                                icon={{
                                                    name: cloningLast ? "spinner" : "copy",
                                                    loading: cloningLast,
                                                }}
                                                content="Replicate Last Invoice"
                                                disabled={cloningLast}
                                            />
                                        )}
                                    </Button.Group>
                                </Grid.Column>
                            </Grid.Row>
                            {!orderNumber && <Divider horizontal>Or</Divider>}

                            <InvoiceBuilderComponentEditor
                                item={editor}
                                customer={customer}
                                onQtyChange={changeQty}
                                onAddProduct={addProduct}
                                onPriceChange={changePrice}
                                onTaxableToggle={toggleTaxable}
                                onProductChange={handleProductSelect}
                                cantAdd={showTypeMisMatchError}
                            />
                        </Segment>

                        {!!productEditorErrors.length && (
                            <Message
                                color="yellow"
                                attached="bottom"
                                header="Please be aware"
                                list={productEditorErrors}
                            />
                        )}
                    </Grid.Column>

                    {itemsArr.length > 0 && (
                        <Grid.Column width={16}>
                            <div className="tp-15">
                                <InvoiceComponentProductList
                                    isInternalSkuActive={isInternalSkuActive}
                                    orderNumber={orderNumber ? orderNumber : orderNumberFromInvoice}
                                    items={itemsArr}
                                    isEditable={!isCancelled}
                                    onItemEdit={handleItemEdit}
                                    onItemRemove={handleItemRemove}
                                    inlineEditorFields={inlineEditorFields}
                                    itemLogInlineEditorFields={itemLogInlineEditorFields}
                                    inlineEditorItemId={inlineEditorItemId}
                                    itemLogInlineEditorItemId={itemLogInlineEditorItemId}
                                    onShowInlineEditor={handleShowInlineEditor}
                                    onShowInItemLogInlineEditor={handleShowItemLogInlineEditor}
                                    onHideInlineEditor={handleHideInlineEditor}
                                    onChangeInlineEditor={handleChangeInlineEditor}
                                    onChangeItemLogInlineEditor={handleChangeItemLogInlineEditor}
                                    footerContent={
                                        <InvoiceBuilderFooterContent
                                            {...{
                                                hasLiquor,
                                                isCancelled,
                                                note: !note.length ? "" : note,
                                                invoice,
                                                totals,
                                                stamps,
                                                status,
                                                changeNote,
                                                saveInvoice,
                                                handleCancel,
                                                removeStamp,
                                                editStamp,
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </Grid.Column>
                    )}
                </Grid.Row>
            )}
        </Dimmer.Dimmable>
    );
};

export default InvoiceBuilderContainer;
