import { keyBy } from "lodash";
import { format } from "date-fns";

import { parseServerTimestampForClient } from "../../common/helpers";
import { DateFormat, formatItemWithUnits } from "../helpers";
import { formatAmount } from "utilities/dist/helpers";

export const allGridColumns = keyBy(
    [
        {
            filter: false,
            sortable: false,
            headerName: "Invoice history",
            cellRenderer: "invoiceHistoryRenderer",
            valueGetter: function ({ data }) {
                return !data ? null : data;
            },
        },
        {
            field: "status",
            headerName: "Status",
            cellRenderer: "invoiceStatusRenderer",
            valueGetter: function ({ data }) {
                return !data ? null : data?.status;
            },
        },
        {
            field: "number",
            headerName: "Number",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            valueGetter: function ({ data }) {
                if (!data) return null;

                return data.beer && (parseInt(data.beer) > 0 || data.beer === true)
                    ? `${data.number}B`
                    : data.number;
            },
            cellRenderer: "invoiceLinkRenderer",
        },
        {
            field: "total",
            headerName: "Total",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            valueGetter: function ({ data }) {
                if (!data) return null;
                return formatAmount(data.total);
            },
        },
        {
            headerName: "Date",
            field: "invoiced_at",
            filter: "date",
            filterParams: {
                browserDatePicker: true,
            },
            valueGetter: ({ data }) => {
                return data && data.invoiced_at
                    ? format(parseServerTimestampForClient(data.invoiced_at), DateFormat)
                    : null;
            },
        },
        {
            headerName: "Due On",
            field: "due_at",
            filter: "date",
            filterParams: {
                browserDatePicker: true,
            },
            valueGetter: ({ data }) => {
                return data && data.due_at
                    ? format(parseServerTimestampForClient(data.due_at), DateFormat)
                    : "On Delivery";
            },
        },
        {
            field: "customer",
            headerName: "Customer",
            sortable: false,
            valueGetter: function ({ data }) {
                return !data ? null : data.customer.name + ", " + data.customer.address;
            },
            cellRenderer: function ({ data }) {
                if (!data) return null;
                return data.customer.name + "<br/>" + data.customer.address;
            },
        },

        {
            filter: false,
            field: "items",
            sortable: false,
            suppressMenu: true,
            headerName: "Items",
            valueGetter: formatItemWithUnits,
        },
        {
            filter: false,
            sortable: false,
            field: "actions",
            suppressMenu: true,
            headerName: "Actions",
            cellRenderer: "invoiceActionsRenderer",
        },
    ],
    "field"
);
