import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Redirect, withRouter } from "react-router-dom";
import { Modal, Segment, Dimmer, Loader } from "semantic-ui-react";

import SideNav from "./side-nav";
import ReAuth from "../../auth/containers/re-auth";

import { authValidate } from "../../auth/actions";
import { APP_CURRENT_VERSION } from "../constants";
import { updateToCurrentVersion, setCacheTimestamp } from "../actions";
import { formatCacheTimestamp } from "../helpers";
import { setUserDetailsForAnalytics } from "../analytics";

const SigningInModal = () => (
    <Modal open={true} size="small">
        <Modal.Header>Checking your authentication</Modal.Header>

        <Modal.Content>
            <Segment>
                <Dimmer active inverted>
                    <Loader />
                </Dimmer>
                <div className="hp-15 sp-15"></div>
            </Segment>
        </Modal.Content>
    </Modal>
);

class PrivateRoute extends React.Component {
    UNSAFE_componentWillMount() {
        if (!this.props.currentVersion) {
            this.props.updateToCurrentVersion(APP_CURRENT_VERSION);
        } else if (this.props.currentVersion < APP_CURRENT_VERSION) {
            this.props.updateToCurrentVersion(APP_CURRENT_VERSION);
            if (this.props.location.pathname !== "/cache/clear")
                window.location.href = "/cache/clear";
        }

        if (!this.props.cacheTimestamp) {
            this.props.setCacheTimestamp();
        } else if (this.props.cacheTimestamp !== formatCacheTimestamp()) {
            this.props.setCacheTimestamp();
            if (this.props.location.pathname !== "/cache/clear")
                window.location.href = "/cache/clear";
        }

        this.props.authValidate();
    }

    redirectToLogin(props) {
        return (
            <Redirect
                to={{
                    pathname: "/auth/signin",
                    state: { from: props.location },
                }}
            />
        );
    }

    renderPage(Component, props) {
        return (
            <div className="page-wrapper">
                {props.showSideNav && (
                    <div className={`page-sidenav no-print ${props.miniSideNav ? "mini" : ""}`}>
                        <SideNav {...props} />
                    </div>
                )}

                <div className="page-container">
                    <Component {...props} />
                </div>

                <ReAuth />
            </div>
        );
    }
    componentDidUpdate(prevProps) {
        if (prevProps?.user?.id !== this.props?.user?.id) {
            setUserDetailsForAnalytics(this.props?.user);
        }
    }

    render() {
        const { component: Component, ...propsWithoutComponent } = this.props;

        return (
            <Route
                {...propsWithoutComponent}
                render={() =>
                    this.props.isSigningIn ? (
                        <SigningInModal />
                    ) : this.props.user ? (
                        this.renderPage(Component, propsWithoutComponent)
                    ) : (
                        this.redirectToLogin(propsWithoutComponent)
                    )
                }
            />
        );
    }
}

const mapStateToProps = ({ auth, nav, version }) => ({
    user: auth.user,
    isSigningIn: auth.signingIn,

    currentVersion: version.current,
    cacheTimestamp: version.cacheTimestamp,

    // for side navbar
    miniSideNav: nav.isMini,
    showSideNav: nav.isShown,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            authValidate,
            updateToCurrentVersion,
            setCacheTimestamp,
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));
