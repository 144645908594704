import { size } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { Card, Button } from "semantic-ui-react";
import { useSelector } from "react-redux";

const CommonComponentMiscSettings = ({ user }) => {
    const { currentVersion } = useSelector(({ version }) => ({
        currentVersion: version.current,
    }));

    return (
        <>
            <Card fluid>
                <Card.Content>
                    <Card.Header>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {" "}
                            {user.name}
                            <div style={{ fontSize: "14px", fontWeight: "normal" }}>
                                <p>v{currentVersion}</p>
                            </div>
                        </div>
                    </Card.Header>
                </Card.Content>
                <Card.Content>
                    <Card.Description>{user.email}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <Card.Description>
                        If you're noticing any anomalies or not seeing something you expect to see,
                        clear your cached data first and try again.
                        <br />
                        <br />
                        All your local settings and preferences will be reset.
                    </Card.Description>
                    <br />
                    <Button fluid as={Link} to="/cache/clear" color="orange">
                        Clear Cached Data
                    </Button>
                </Card.Content>
            </Card>
        </>
    );
};

export default CommonComponentMiscSettings;
