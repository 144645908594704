import React from "react";
import { Grid } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import ActivityContainerGrid from "../containers/grid";

const ActivityPageHome = () => {
      return (
            <Grid>
                  <TopNav
                        headerIcon="history"
                        subheader="Monitor system and user activities"
                        headerContent="Activities"
                  />

                  <Grid.Column mobile={16} computer={16}>
                        <ActivityContainerGrid />
                  </Grid.Column>
            </Grid>
      );
};

export default ActivityPageHome;
