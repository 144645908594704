import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { Grid, Menu, Segment, Message } from "semantic-ui-react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getNewInvoiceNumber } from "../helpers";
import { loadInvoiceForEdit, setDefaultInvoiceBuilderLogo } from "../builder/actions";

import TopNav from "../../common/containers/top-nav";
import { loadStampRoll } from "../../stamp/roll/actions";
import InvoiceBuilderContainer from "../builder/index.container";
import StampRollContainerEditor from "../../stamp/roll/containers/editor";

const InvoicePageCreate = () => {
    const dispatch = useDispatch();

    const [number, setNumber] = useState(null);
    const { invoice, stamp, stampLoading } = useSelector(({ invoiceBuilder, stampWarning }) => ({
        stampLoading: stampWarning.isLoading,
        invoice: invoiceBuilder.invoice,
        stamp: stampWarning.data,
    }));

    const initialLoad = () => {
        dispatch(loadStampRoll());
        // if create page is loaded, there might be a previously added items, invoice etc. in the builder so let's clear those up
        if (invoice) dispatch(loadInvoiceForEdit(null));

        // get new number for the invoice
        getNewInvoiceNumber().then(setNumber);
        dispatch(setDefaultInvoiceBuilderLogo());
    };

    useEffect(initialLoad, [invoice]);

    return (
        <Grid>
            <TopNav
                headerIcon="newspaper"
                subheader="Create New Invoice"
                headerContent={`New Invoice #${number || "..."}`}
                extraButtons={() => (
                    <>
                        <Menu.Item
                            as={Link}
                            to="/invoice"
                            icon="chevron left"
                            name="All Invoices"
                        />
                    </>
                )}
            />

            <Grid.Column mobile={16}>
                {stampLoading ? (
                    <Segment className="hp-30" loading />
                ) : isEmpty(stamp.firstAvailableStamp) ? (
                    <Grid>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Message
                                warning
                                as={Link}
                                className="db"
                                to="/stamp/roll"
                                header="Stamp roll missing"
                                content="Please add your current stamp roll before creating an invoice"
                            />
                            <StampRollContainerEditor />
                        </Grid.Column>
                    </Grid>
                ) : (
                    <InvoiceBuilderContainer />
                )}
            </Grid.Column>
        </Grid>
    );
};

export default InvoicePageCreate;
