import { format } from "date-fns";
import { Link } from "react-router-dom";
import { startCase, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Header, Grid, Segment, Icon, Button } from "semantic-ui-react";

import { DateFormat } from "../helpers";
import { parseServerTimestampForClient } from "../../common/helpers";
import { makePrivateApiCall } from "utilities/dist/api";
import { formatAmount } from "utilities/dist/helpers";

const InvoiceContainerRecent = () => {
    const [invoices, setInvoices] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const loadInvoices = () =>
            makePrivateApiCall({ url: "/invoice/recent" })
                .then(res => {
                    setLoading(false);
                    if (res.data) {
                        setInvoices(res.data);
                    }
                })

                .catch(() => setLoading(false));

        loadInvoices();
    }, []);

    if (!isLoading && isEmpty(invoices)) {
        return (
            <Segment placeholder vertical>
                <Header icon>
                    <Icon name="newspaper outline" />
                    You have not created any invoice yet.
                </Header>
                <Button positive as={Link} to="/invoice/create">
                    Create Invoice
                </Button>
            </Segment>
        );
    }

    return (
        <>
            {invoices.map(inv => (
                <Grid key={`recent_invoice_${inv.id}`} divided>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Header as="h5">
                                <Link to={`/invoice/${inv.number}`}>
                                    <Header.Content>
                                        #{inv.number}
                                        {parseInt(inv.beer) > 0 || inv.beer === true ? "B" : ""}
                                    </Header.Content>
                                </Link>
                                <Header.Subheader>
                                    {format(
                                        parseServerTimestampForClient(inv.invoiced_at),
                                        DateFormat
                                    )}{" "}
                                    | {formatAmount(inv.total)}
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                        <Grid.Column>
                            <Header as="h5">
                                <Header.Content>
                                    {startCase(inv.customer.name)}
                                    <Header.Subheader>{inv.customer.address}</Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            ))}
        </>
    );
};

export default InvoiceContainerRecent;
