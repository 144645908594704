import { isFunction } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Segment, Header, Item, Message, Button, Confirm } from "semantic-ui-react";

import CompanyLogoUploaderContainer from "./uploader.container";
import { loadCompanyLogos, removeCompanyLogo } from "./actions";

const CompanyLogoGalleryContainer = ({ onSelect }) => {
    const [attemptedLoading, setAttemptedLoading] = useState(false);
    const [confirmingRemoval, setConfirmingRemoval] = useState(0);

    const dispatch = useDispatch();
    const removeLogo = logoId => dispatch(removeCompanyLogo(logoId));
    const { logos, isLoading, error, removingLogoId, removeError } = useSelector(
        ({ companyLogo }) => companyLogo
    );

    useEffect(() => {
        if (logos.length < 1 && !isLoading && !attemptedLoading) {
            setAttemptedLoading(true);
            dispatch(loadCompanyLogos());
        }
    }, [logos, isLoading, attemptedLoading, dispatch]);

    return (
        <>
            <Header
                subheader="Manage company logo to be attached with your invoices"
                content="Company Logo"
                attached="top"
                as="h3"
            />
            <Segment attached loading={isLoading}>
                <CompanyLogoUploaderContainer />

                <Confirm
                    size="tiny"
                    header="Remove Logo?"
                    confirmButton="Remove"
                    cancelButton="Nevermind"
                    open={confirmingRemoval > 0}
                    onConfirm={() => {
                        removeLogo(confirmingRemoval);
                        setConfirmingRemoval(0);
                    }}
                    onCancel={() => setConfirmingRemoval(0)}
                    content="Removing this logo will remove it from any invoices it may be associated with"
                />

                {!isLoading && error && (
                    <Message error content={error} header="Error loading Logos" />
                )}

                {!!removeError && (
                    <Message error content={removeError} header="Error Removing Logo" />
                )}

                {logos.length > 0 && (
                    <Item.Group relaxed>
                        {logos.map(logo => (
                            <Item key={`company_logo_${logo.id}`}>
                                <Item.Image size="tiny" src={logo.path} />

                                <Item.Content verticalAlign="middle">
                                    <Item.Meta as="a" target="_blank" href={logo.path}>
                                        {logo.originalname}
                                    </Item.Meta>
                                    <Item.Extra>
                                        {isFunction(onSelect) && (
                                            <Button
                                                size="tiny"
                                                color="green"
                                                onClick={() => onSelect(logo.id)}
                                            >
                                                Select
                                            </Button>
                                        )}
                                        <Button
                                            size="tiny"
                                            loading={removingLogoId === logo.id}
                                            disabled={removingLogoId === logo.id}
                                            onClick={() => setConfirmingRemoval(logo.id)}
                                        >
                                            Remove
                                        </Button>
                                    </Item.Extra>
                                </Item.Content>
                            </Item>
                        ))}
                    </Item.Group>
                )}
            </Segment>
        </>
    );
};

export default CompanyLogoGalleryContainer;
