import React from "react";
import { format } from "date-fns";
import { connect } from "react-redux";
import { isEqual, get } from "lodash";
import { bindActionCreators } from "redux";
import { AgGridReact } from "ag-grid-react";
import { Header } from "semantic-ui-react";

import { loadEmailGridPage, changeEmailGridPage, changeEmailGridState } from "./actions";
import GridPageSizePicker from "../../common/components/grid-page-size-picker";
import { InvoiceLinkRenderer } from "../grid/cellRenderers.component";
import { allGridColumns } from "./columns";
import { DateFormat } from "utilities/dist/invoice/constants";

const NoEmailsOverlay = () => {
    return <Header>No Email history found.</Header>;
};

const frameworkComponents = {
    noEmailsOverlay: NoEmailsOverlay,
    invoiceLinkRenderer: InvoiceLinkRenderer,
};

class DataGrid extends React.Component {
    state = {
        dateRange: "",
    };

    grid = null;
    gridDataSource = {
        getRows: params => {
            this.props.loadEmailGridPage(this.props, params);
        },
    };

    componentWillUnmount() {
        this.grid = null;
    }

    onGridReady = grid => {
        this.grid = grid;

        setTimeout(() => this.grid && this.grid.api.sizeColumnsToFit(), 300);

        // this.grid.api.paginationGoToPage(this.props.currentPage);
        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.setDatasource(this.gridDataSource);

        this.grid.api.addEventListener("sortChanged", () => {
            this.props.changeEmailGridState({
                sortModel: this.grid.api.getSortModel(),
            });
        });

        this.grid.api.addEventListener("filterChanged", () => {
            this.props.changeEmailGridState({
                filterModel: this.grid.api.getFilterModel(),
            });
        });
    };

    reloadGridData = () => { 
        this.grid && this.grid.api.refreshInfiniteCache();
    };

    changePage = () => {
        if (this.grid) {
            const page = this.grid.api.paginationGetCurrentPage() + 1;

            if (page === this.props.currentPage) return;

            this.props.changeEmailGridState(page);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (!this.grid) return;

        if (prevProps.isLoading && !this.props.isLoading) {
            this.grid.api.hideOverlay();
        }

        if (!prevProps.isLoading && this.props.isLoading) {
            this.grid.api.showLoadingOverlay();
        }

        if (!this.props.isLoading && this.props?.emails?.length < 1) {
            this.grid.api.showNoRowsOverlay();
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.grid.api.paginationSetPageSize(this.props.pageSize);
        }

        if (!isEqual(prevProps.filterModel, this.props.filterModel)) {
            this.grid.api.setFilterModel(this.props.filterModel);
        }
        const dateFilter = get(this.props, "filterModel.created_at", null);
        if (
            dateFilter &&
            dateFilter.dateFrom &&
            this.state.dateRange === "" &&
            prevState.dateRange === ""
        ) {
            const from = format(new Date(dateFilter.dateFrom), DateFormat),
                to = format(new Date(dateFilter.dateTo), DateFormat);

            this.setState({ dateRange: `${from} - ${to}` });
        }
    }

    render() {
        const { pageSize } = this.props;

        return (
            <>
                <div className="clear bm-10">
                    <GridPageSizePicker
                        button
                        compact
                        pointing="top"
                        pageSize={pageSize}
                        text={`${pageSize}/page`}
                        className="right floated basic"
                        onChange={this.props.changeEmailGridState}
                    />
                </div>
                <div className="ag-theme-balham bm-10">
                    <AgGridReact
                        pagination
                        rowHeight={50}
                        reactNext={true}
                        floatingFilter={true}
                        rowSelection="single"
                        domLayout="autoHeight"
                        rowModelType="infinite"
                        onGridReady={this.onGridReady}
                        cacheBlockSize={this.props.pageSize}
                        onPaginationChanged={this.changePage}
                        columnDefs={Object.values(allGridColumns)}
                        frameworkComponents={frameworkComponents}
                        noRowsOverlayComponent="noEmailsOverlay"
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            filter: true,
                            wrapText: true,
                            flex: 1,
                            autoHeight: true,
                        }}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ invoiceEmailGrid }) => ({
    emails: invoiceEmailGrid.emails,
    pageSize: invoiceEmailGrid.pageSize,
    isLoading: invoiceEmailGrid.isLoading,
    sortModel: invoiceEmailGrid.sortModel,
    columnDefs: invoiceEmailGrid.columnDefs,
    filterModel: invoiceEmailGrid.filterModel,
    currentPage: invoiceEmailGrid.currentPage,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadEmailGridPage,
            changeEmailGridState,
            changeEmailGridPage: page => dispatch(changeEmailGridPage(page)),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DataGrid);
