import React from "react";
import { Grid, Table } from "semantic-ui-react";
import { format } from "date-fns";

import { DateTimeFormat } from "utilities/dist/invoice/constants";

const OrderNotesComponent = ({ order }) => {
    return (
        <>
            {order?.note?.length ? (
                <Grid.Column mobile={16}>
                    <Table fixed className="no-print">
                        <Table.Header>
                            <Table.HeaderCell>Notes</Table.HeaderCell>
                            <Table.HeaderCell>{order?.note?.length ? "Date" : ""}</Table.HeaderCell>
                        </Table.Header>

                        <Table.Body>
                            {order?.note?.length &&
                                order.note.map(item => (
                                    <Table.Row key="note">
                                        <Table.Cell>{item.note}</Table.Cell>
                                        <Table.Cell>
                                            {format(new Date(item.created_at), DateTimeFormat)}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                        </Table.Body>
                    </Table>
                </Grid.Column>
            ) : (
                ""
            )}
        </>
    );
};
export default OrderNotesComponent;
