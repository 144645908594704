import Joi from "joi";

export const ValidateInvitationSchema = Joi.object({
    name: Joi.string().min(3).max(40).required().messages({
        "string.empty": `User name is required !`,
    }),
    email: Joi.string()
        .email({
            tlds: {
                allow: false,
            },
        })
        .required()
        .messages({
            "string.empty": `Email can't be an empty field !`,
        }),
    tabc_id: Joi.string().messages({
        "string.empty": `Tabc ID is required !`,
    }),
});
