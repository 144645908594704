import { format } from "date-fns";
import { isString, isArray } from "lodash";

import { generateReducer } from "../../common/helpers";
import {
    INVOICE_PAYMENT_EDITOR_MODAL_TOGGLE,
    INVOICE_PAYMENT_EDITOR_SAVED,
    INVOICE_PAYMENT_EDITOR_SAVING,
    INVOICE_PAYMENT_EDITOR_INPUT,
    INVOICE_PAYMENT_EDITOR_ERROR,
    INVOICE_PAYMENT_REMOVE_ERROR,
    INVOICE_PAYMENT_REMOVING,
    INVOICE_PAYMENT_REMOVED,
} from "../constants";
import { DateFormat } from "../helpers";

const initialState = {
    showModal: false,
    errors: [],
    inputs: {
        received_at: format(new Date(), DateFormat),
        amount: "0",
        note: "",
    },
    isSaving: false,

    removing: false,
    removeError: null,
};

const setError = (state, error) => {
    let { errors } = state;

    if (isString(error)) {
        errors.push(error);
    }

    if (isArray(error)) {
        errors = error;
    }

    return { ...state, isSaving: false, errors };
};
const setSaving = state => ({ ...state, isSaving: true });
const resetState = () => initialState;

const setInput = (state, inputs = {}) => {
    return { ...state, inputs: { ...state.inputs, ...inputs } };
};
const toggleEditorModal = (state, showModal) => ({ ...state, showModal });
const setRemoveError = (state, removeError) => ({
    ...state,
    removeError,
    removing: false,
});
const setRemoving = (state, removing) => ({
    ...state,
    removing,
    removeError: null,
});
const setRemoved = state => ({ ...state, removing: false, removeError: null });

const reducers = {
    [INVOICE_PAYMENT_EDITOR_MODAL_TOGGLE]: toggleEditorModal,
    [INVOICE_PAYMENT_EDITOR_SAVED]: resetState,
    [INVOICE_PAYMENT_EDITOR_SAVING]: setSaving,
    [INVOICE_PAYMENT_EDITOR_INPUT]: setInput,
    [INVOICE_PAYMENT_EDITOR_ERROR]: setError,
    [INVOICE_PAYMENT_REMOVE_ERROR]: setRemoveError,
    [INVOICE_PAYMENT_REMOVING]: setRemoving,
    [INVOICE_PAYMENT_REMOVED]: setRemoved,
};

export default generateReducer(reducers, initialState);
