"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MomentDateFormat = exports.InvoiceStatuses = exports.FilenameDateFormat = exports.DateTimeWithoutYear = exports.DateTimeFormat = exports.DateFormat = void 0;
const InvoiceStatuses = {
  PAID: "paid",
  DRAFT: "draft",
  INVOICED: "invoiced",
  CANCELLED: "cancelled",
  PAYMENT_DUE: "payment_due",
  PARTIALLY_PAID: "partially_paid",
  PAYMENT_DUE_SOON: "payment_due_soon",
  DELIVERED: "delivered"
};
exports.InvoiceStatuses = InvoiceStatuses;
const FilenameDateFormat = "dd_MMM_yyyy";
exports.FilenameDateFormat = FilenameDateFormat;
const DateFormat = "dd MMM, yyyy";
exports.DateFormat = DateFormat;
const DateTimeFormat = "dd MMM, yyyy hh:mm a";
exports.DateTimeFormat = DateTimeFormat;
const MomentDateFormat = "YYYY-MM-DD";
exports.MomentDateFormat = MomentDateFormat;
const DateTimeWithoutYear = "dd MMM hh:mm a";
exports.DateTimeWithoutYear = DateTimeWithoutYear;