import React, { useState } from "react";
import { Button, Grid } from "semantic-ui-react";
import { Form, Input, Checkbox } from "formik-semantic-ui";

import { ProductPriceTierSelector } from "../../product/price-tier/components/selector";

const CustomerComponentEditorForm = ({
    values,
    handleReset,
    hasFintech = false,
    productPriceTiers = [],
    hasTieredPricingEnabled = false,
    setFieldTouched,
    setFieldValue,
    onAddNewTier
}) => {
    const [isFintechEnabled, setIsFintechEnabled] = useState(!!values?.fintech_option);

    return (
        <Grid divided>
            <Grid.Row>
                <Grid.Column mobile={16}>
                    <Form.Group widths="equal">
                        <Input
                            name="tabc_id"
                            label="TABC ID"
                            inputProps={{
                                placeholder: "MB1234",
                                "data-cy": "tabc_id",
                            }}
                        />

                        <Input
                            name="name"
                            label="Name"
                            inputProps={{
                                placeholder: "Customer Name",
                                "data-cy": "name",
                            }}
                        />

                        <Input
                            name="address"
                            label="Address"
                            inputProps={{
                                placeholder: "123 Main st",
                                "data-cy": "address",
                            }}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Input
                            name="city"
                            label="City"
                            inputProps={{
                                placeholder: "Austin",
                                "data-cy": "city",
                            }}
                        />
                        <Input
                            name="state"
                            label="State"
                            inputProps={{
                                placeholder: "TX",
                                "data-cy": "state",
                            }}
                        />
                        <Input
                            name="zip"
                            label="Zip"
                            inputProps={{
                                placeholder: "78078",
                                "data-cy": "zip",
                            }}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Input
                            name="contact_name"
                            label="Contact Name"
                            inputProps={{
                                placeholder: "Full Name",
                                "data-cy": "contact_name",
                            }}
                        />
                        <Input
                            name="contact_email"
                            label="Contact Email"
                            inputProps={{
                                placeholder: "customer@email.com",
                                "data-cy": "email",
                            }}
                        />
                        <Input
                            name="contact_phone"
                            label="Contact Phone"
                            inputProps={{
                                placeholder: "Contact Phone",
                                "data-cy": "phone",
                            }}
                        />
                    </Form.Group>
                    {hasTieredPricingEnabled && (
                        <Form.Group widths={4}>
                            <div className="field">
                                <div className="label">Price Tier</div>
                                <ProductPriceTierSelector
                                    search
                                    clearable={false}
                                    priceTiers={productPriceTiers}
                                    allowAdditions
                                    additionLabel="Create New Tier "
                                    onAddItem={onAddNewTier}
                                    value={values?.product_price_tier_id}
                                    onChange={(_, { value }) => {
                                        setFieldValue("product_price_tier_id", value);
                                        setFieldTouched("product_price_tier_id");
                                    }}
                                />
                            </div>
                        </Form.Group>
                    )}
                    <br />
                    <Form.Group widths={3}>
                        <Checkbox
                            data-cy="cash_on_delivery"
                            name="cash_on_delivery"
                            label="Cash On Delivery"
                            inputProps={{ toggle: true }}
                        />
                        {!!hasFintech && (
                            <>
                                <Checkbox
                                    type="checkbox"
                                    name="fintech_option"
                                    label="Fintech Option"
                                    inputProps={{
                                        toggle: true,
                                        onChange: () => setIsFintechEnabled(!isFintechEnabled),
                                    }}
                                />
                                {!!isFintechEnabled && (
                                    <Input
                                        name="fintech_id"
                                        label="Fintech ID"
                                        inputProps={{
                                            placeholder: "Fintech ID",
                                            "data-cy": "fintech_id",
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </Form.Group>
                </Grid.Column>

                <Grid.Column width={16}>
                    <br />
                    <Button data-cy="save_customer" color="teal" type="submit">
                        {values.id ? "Update" : "Create"} Customer
                    </Button>
                    <Button type="button" color="orange" onClick={() => handleReset()}>
                        Reset
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default CustomerComponentEditorForm;
