import React from "react";
import { connect } from "react-redux";
import { Grid, Header, Icon, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import TopNav from "../containers/top-nav";
import AuthContainerProfile from "../../auth/containers/profile";
import CommonComponentMiscSettings from "../components/misc-settings";
import CompanyEditorContainer from "../../company/editor/index.container";
import CompanyLogoGalleryContainer from "../../company/logo/gallery.container";
import { isFeatureActive, OrderFeature, TieredPricing } from "../../company/feature/helpers";
import { ProductPriceTierListContainer } from "../../product/price-tier/containers/list";

const CommonPageSettingCompany = ({ user }) => {
    const { company } = useSelector(({ auth }) => ({
        company: auth.user.company,
    }));
    const hasOrderFeature = isFeatureActive(company, OrderFeature);
    const hasPriceTierFeature = isFeatureActive(company, TieredPricing);

    return (
        <Grid>
            <TopNav
                headerIcon="cog"
                headerContent="Settings"
                subheader="Notification preferences"
                extraButtons={() => (
                    <>
                        <Menu.Item name="Features" icon="object group" to="/features" as={Link} />
                        {!!hasOrderFeature && (
                            <Menu.Item
                                name="Notification"
                                icon="bell"
                                to="/notification"
                                as={Link}
                            />
                        )}
                        <Menu.Item name="Activities" icon="history" to="/activity" as={Link} />
                    </>
                )}
            />

            <Grid.Row>
                <Grid.Column width={16} className="bp-15">
                    <Header size="large" icon="briefcase" content="Company" />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8} className="bp-15">
                    <CompanyEditorContainer />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8} className="bp-15">
                    <CompanyLogoGalleryContainer />
                </Grid.Column>
            </Grid.Row>

            {hasPriceTierFeature && (
                <Grid.Row>
                    <Grid.Column width={16} className="bp-15">
                        <Header size="large">
                            <Icon.Group size="large">
                                <Icon name="dollar sign" />
                                <Icon corner name="percent" />
                            </Icon.Group>
                            <Header.Content>Price Tier</Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} className="bp-15">
                        <ProductPriceTierListContainer />
                    </Grid.Column>
                </Grid.Row>
            )}

            <Grid.Row>
                <Grid.Column width={16} className="bp-15">
                    <Header icon="user" size="large" content="Profile" />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={6} className="bp-15">
                    <AuthContainerProfile />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={6} className="bp-15">
                    <CommonComponentMiscSettings user={user} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
});

export default connect(mapStateToProps)(CommonPageSettingCompany);
