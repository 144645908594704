import React from "react";
import { Link } from "react-router-dom";
import { Button, Table } from "semantic-ui-react";

export const CloverComponentFailedInvoices = ({ invoices = [] }) => {
    return (
        <Table basic="very">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Invoice</Table.HeaderCell>
                    <Table.HeaderCell>Failed At</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {invoices.map(inv => (
                    <Table.Row key={`failed_inv_${inv.invoice_id}_${inv.company_id}`}>
                        <Table.Cell>
                            <Link to={`invoice/${inv.invoice.number}`}>{inv.invoice.number}</Link>
                        </Table.Cell>
                        <Table.Cell>{inv.updatedAt}</Table.Cell>
                        <Table.Cell>
                            <Button content="Retry" size="mini" />
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};
