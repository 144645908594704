export const PRODUCT_PRICE_TIER_INPUT = "PRODUCT_PRICE_TIER_INPUT";
export const PRODUCT_PRICE_TIER_ERROR = "PRODUCT_PRICE_TIER_ERROR";
export const PRODUCT_PRICE_TIER_SAVING = "PRODUCT_PRICE_TIER_SAVING";
export const PRODUCT_PRICE_TIER_SAVED = "PRODUCT_PRICE_TIER_SAVED";
export const PRODUCT_PRICE_TIER_MODAL_TOGGLE = "PRODUCT_PRICE_TIER_MODAL_TOGGLE";

export const PRODUCT_PRICE_TIER_REMOVE_MODAL_TOGGLE = "PRODUCT_PRICE_TIER_REMOVE_MODAL_TOGGLE";
export const PRODUCT_PRICE_TIER_REMOVING = "PRODUCT_PRICE_TIER_REMOVING";
export const PRODUCT_PRICE_TIER_REMOVED = "PRODUCT_PRICE_TIER_REMOVED";
export const PRODUCT_PRICE_TIER_REMOVE_ERROR = "PRODUCT_PRICE_TIER_REMOVE_ERROR";
