import { keyBy } from "lodash";

import { CUSTOMER_LIST_LOADING, CUSTOMER_LIST_DATA, CUSTOMER_LIST_ERROR } from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";
import { isCompanyFeatureActive } from "utilities/dist/company/helpers";
import { TieredPricing } from "../../company/feature/helpers";

export const loadCustomerList = () => (dispatch, getState) => {
    dispatch({
        type: CUSTOMER_LIST_LOADING,
    });

    makePrivateApiCall({
        url: "/customer",
        method: "get",
    })
        .then(res => {
            if (res.data) {
                let payload = res.data;

                const { company = {} } = getState()?.auth?.user || {};
                const hasTieredPricingEnabled = isCompanyFeatureActive(company, TieredPricing.id);

                if (hasTieredPricingEnabled) {
                    const priceTiersById = keyBy(company.productPriceTiers, "id");
                    payload = payload.map(customer => ({
                        ...customer,
                        productPriceTier: priceTiersById[customer.product_price_tier_id],
                    }));
                }

                dispatch({
                    type: CUSTOMER_LIST_DATA,
                    payload,
                });
            }
        })
        .catch(err => {
            dispatch({
                type: CUSTOMER_LIST_ERROR,
                payload: err.message,
            });
        });
};
