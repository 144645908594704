import { get, omit } from "lodash";
import { toast } from "react-semantic-toasts";

import {
    INVOICE_BUILDER_UPDATE_CUSTOMER,
    INVOICE_BUILDER_SET_CUSTOMER,
    INVOICE_BUILDER_SELECT_PRODUCT,
    INVOICE_BUILDER_TOGGLE_TAXABLE,
    INVOICE_BUILDER_CHANGE_PRICE,
    INVOICE_BUILDER_CHANGE_QTY,
    INVOICE_BUILDER_ADD_PRODUCT,
    INVOICE_BUILDER_SAVED,
    INVOICE_BUILDER_SAVING,
    INVOICE_BUILDER_ERROR,
    INVOICE_BUILDER_REMOVE_ITEM,
    INVOICE_BUILDER_INVOICE_DATA,
    INVOICE_BUILDER_INVOICE_LOADING,
    INVOICE_BUILDER_CHANGE_STATUS,
    INVOICE_BUILDER_CHANGE_DATE,
    INVOICE_BUILDER_CHANGE_STAMP,
    INVOICE_BUILDER_REMOVE_STAMP_ROLL,
    INVOICE_BUILDER_CHANGE_NOTE,
    INVOICE_BUILDER_RESET,
    INVOICE_BUILDER_CLONING_LAST,
    INVOICE_BUILDER_CLONE,
    INVOICE_BUILDER_CREATED,
    INVOICE_BUILDER_SHOW_INLINE_EDITOR,
    INVOICE_BUILDER_HIDE_INLINE_EDITOR,
    INVOICE_BUILDER_CHANGE_INLINE_EDITOR,
    INVOICE_BUILDER_CHANGE_LOGO,
    INVOICE_BUILDER_TOGGLE_LOGO_PICKER,
    INVOICE_BUILDER_ADD_STAMP_ROLL,
    INVOICE_BUILDER_SELECT_STAMP_ROLL,
    INVOICE_BUILDER_GET_ORDER,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";
import { loadLastStamp } from "../../stamp/warning/actions";
import {
    trackInvoiceCreateEvent,
    trackInvoiceEditEvent,
    trackSendEmailEvent,
} from "../../common/analytics";
import { createItemLog } from "../helpers";

export const resetInvoiceBuilder = () => ({
    type: INVOICE_BUILDER_RESET,
});

export const setInvoiceBuilderCustomer = customer => dispatch =>
    dispatch({
        type: INVOICE_BUILDER_SET_CUSTOMER,
        payload: customer,
    });

export const updateInvoiceBuilderCustomer = customer => dispatch =>
    dispatch({
        type: INVOICE_BUILDER_UPDATE_CUSTOMER,
        payload: customer,
    });

export const removeInvoiceBuilderItem = product => ({
    type: INVOICE_BUILDER_REMOVE_ITEM,
    payload: product,
});

export const changeInvoiceBuilderStamp = stampData => ({
    type: INVOICE_BUILDER_CHANGE_STAMP,
    payload: stampData,
});

export const removeInvoiceBuilderStampRoll = stampIndex => ({
    type: INVOICE_BUILDER_REMOVE_STAMP_ROLL,
    payload: stampIndex,
});

export const addInvoiceBuilderStampRoll = () => ({
    type: INVOICE_BUILDER_ADD_STAMP_ROLL,
});

export const selectInvoiceBuilderStampRoll = rollIndex => ({
    type: INVOICE_BUILDER_SELECT_STAMP_ROLL,
    payload: rollIndex,
});

export const changeInvoiceBuilderStatus = status => ({
    type: INVOICE_BUILDER_CHANGE_STATUS,
    payload: status,
});

export const changeInvoiceBuilderNote = note => ({
    type: INVOICE_BUILDER_CHANGE_NOTE,
    payload: note,
});

export const changeInvoiceBuilderDate = invoiced_at => ({
    type: INVOICE_BUILDER_CHANGE_DATE,
    payload: invoiced_at,
});

export const selectInvoiceBuilderProduct = product => ({
    type: INVOICE_BUILDER_SELECT_PRODUCT,
    payload: product,
});

export const changeInvoiceBuilderQty = (qty, id) => ({
    type: INVOICE_BUILDER_CHANGE_QTY,
    payload: { id, qty },
});

export const changeInvoiceBuilderPrice = (price, id) => ({
    type: INVOICE_BUILDER_CHANGE_PRICE,
    payload: { id, price },
});

export const toggleInvoiceBuilderTaxable = id => ({
    type: INVOICE_BUILDER_TOGGLE_TAXABLE,
    payload: id,
});

export const addInvoiceBuilderProduct = () => ({
    type: INVOICE_BUILDER_ADD_PRODUCT,
});

export const setInvoiceBuilderError = errors => ({
    type: INVOICE_BUILDER_ERROR,
    payload: errors,
});

export const setDefaultInvoiceBuilderLogo = () => (dispatch, getState) => {
    const { auth } = getState();
    // FIXME: this function is firing when invoice create page loads but for some reason auth.user.company is undefiner, need to fix
    dispatch(changeInvoiceBuilderLogo(auth.user?.company?.default_logo_id));
};

export const changeShowInvoiceBuilderLogoPicker = show => ({
    type: INVOICE_BUILDER_TOGGLE_LOGO_PICKER,
    payload: show,
});

export const changeInvoiceBuilderLogo = logo_id => ({
    type: INVOICE_BUILDER_CHANGE_LOGO,
    payload: logo_id,
});

export const createInvoiceBuilderInvoice =
    (invoice = {}) =>
    dispatch => {
        dispatch({
            type: INVOICE_BUILDER_SAVING,
            payload: true,
        });
        const request = {
            url: `/invoice`,
            method: "post",
        };
        if (typeof invoice.note !== "string") invoice.note = undefined;
        trackInvoiceCreateEvent(invoice, {});

        return makePrivateApiCall({
            ...request,
            data: invoice,
        })
            .then(results => {
                trackInvoiceCreateEvent(invoice, { success: true });
                dispatch({
                    type: INVOICE_BUILDER_CREATED,
                    payload: results.data,
                });
                dispatch(loadLastStamp());
                return results.data;
            })
            .catch(err => {
                trackInvoiceCreateEvent(invoice, { error: true });
                handleSavingError(dispatch, err);
            });
    };

const handleSavingError = (dispatch, err) => {
    let messages = [];
    const details = get(err, "response.data.details", null);

    if (details) {
        messages = details.map(det => det.message);
    } else {
        const message = get(err, "response.data.message", null) || err.message;
        messages.push(message);
    }

    dispatch(setInvoiceBuilderError(messages));
    return false;
};

export const saveInvoiceBuilderInvoice =
    (invoice = {}) =>
    dispatch => {
        dispatch({
            type: INVOICE_BUILDER_SAVING,
            payload: true,
        });
        const request = {
            url: `/invoice/${invoice.id}`,
            method: "put",
            data: invoice,
        };

        trackInvoiceEditEvent(invoice, {});

        return makePrivateApiCall(request)
            .then(res => {
                trackInvoiceEditEvent(invoice, { success: true });
                if (res.data) {
                    dispatch({ type: INVOICE_BUILDER_SAVED, payload: res.data });
                    dispatch(loadLastStamp());
                    return res.data;
                }
            })
            .catch(err => {
                trackInvoiceEditEvent(invoice, { error: true });
                handleSavingError(dispatch, err);
            });
    };

// eslint-disable-next-line no-unused-vars
export const loadInvoiceForEdit = number => (dispatch, getState) => {
    if (number === null) {
        return dispatch({
            type: INVOICE_BUILDER_INVOICE_DATA,
            payload: null,
        });
    }

    // the number can be XXX or XXXB and buildInvoiceNumberWithBeer will return XXX or XXXB depending on
    // the beer flag so we can directly compare with the number

    //if we enable this line down below the invoice edit will work but create problem for those invoice which are created from order
    // const { invoiceGrid } = getState();
    // const fromGrid = invoiceGrid.invoices.find(inv =>
    //     isEqual(buildInvoiceNumberWithBeer(inv), number)
    // );
    //
    // if (fromGrid) {
    //     return dispatch({
    //         type: INVOICE_BUILDER_INVOICE_DATA,
    //         payload: fromGrid,
    //     });
    // }

    dispatch({
        type: INVOICE_BUILDER_INVOICE_LOADING,
        payload: true,
    });
    return makePrivateApiCall({
        url: `/invoice/${number}`,
    })
        .then(res => {
            if (res.data) {
                if (res.data.orders.length) {
                    const items = createItemLog(res.data.items);
                    dispatch(
                        cloneInvoice({
                            items,
                            order_number: res?.data?.orders[0]?.order_number,
                        })
                    );
                }
                return dispatch({
                    type: INVOICE_BUILDER_INVOICE_DATA,
                    payload: res.data,
                });
            }
        })
        .catch(err => {
            const message = get(err, "response.data.message", null) || err.message;
            dispatch(setInvoiceBuilderError([message]));
            return false;
        });
};

export const cloneInvoice = invoice => ({
    type: INVOICE_BUILDER_CLONE,
    payload: invoice,
});

export const cloneLastInvoiceBuilder =
    (customer = {}) =>
    dispatch => {
        dispatch({ type: INVOICE_BUILDER_CLONING_LAST, payload: true });

        return makePrivateApiCall({
            url: "/invoice/last",
            params: { customerId: customer.id },
        })
            .then(res => {
                dispatch({ type: INVOICE_BUILDER_CLONING_LAST, payload: false });
                if (res.data) {
                    return dispatch(cloneInvoice(res.data));
                } else {
                    toast({
                        type: "warning",
                        description: "Sorry you have no previous invoices for this customer",
                        title: "No invoice found",
                    });
                }
            })
            .catch(err => {
                toast({
                    type: "error",
                    description: err.message,
                    title: "Error Replicating Last Invoice",
                });
            });
    };
export const getOrderProductForInvoice = (number, defaultStatus) => dispatch => {
    dispatch({ type: INVOICE_BUILDER_GET_ORDER, payload: true });

    return makePrivateApiCall({
        url: `/order/${number}`,
    })
        .then(res => {
            dispatch({ type: INVOICE_BUILDER_GET_ORDER, payload: false });
            if (res.data) {
                const { modifiedOrderItems, order } = res.data;
                if (modifiedOrderItems?.length) {
                    order.orderItems = modifiedOrderItems;
                }
                const orderItems = createItemLog(order?.orderItems);

                return dispatch(
                    cloneInvoice({
                        ...omit(order, ['note']),
                        status: defaultStatus,
                        items: orderItems,
                        customer: order.customerUser.customer,
                    })
                );
            } else {
                toast({
                    type: "warning",
                    description: "Sorry you have no previous invoices for this customer",
                    title: "No invoice found",
                });
            }
        })
        .catch(err => {
            toast({
                type: "error",
                description: err.message,
                title: "Error Creating Invoice From Order",
            });
        });
};
export const showInvoiceBuilderInlineEditor = (item, inlineEditorFields) => ({
    type: INVOICE_BUILDER_SHOW_INLINE_EDITOR,
    payload: { item, inlineEditorFields },
});

export const hideInvoiceBuilderInlineEditor = field => ({
    type: INVOICE_BUILDER_HIDE_INLINE_EDITOR,
    payload: field,
});

export const changeInvoiceBuilderInlineEditor = (field, value) => ({
    type: INVOICE_BUILDER_CHANGE_INLINE_EDITOR,
    payload: { field, value },
});

export const sendInvoiceViaEmail = ({ emails, invoiceNumber, notes, beer }) => {
    trackSendEmailEvent(emails, { success: true });
    return makePrivateApiCall({
        url: `/invoice/${invoiceNumber}/email`,
        data: { emails, notes, beer },
        method: "post",
    });
};
