import pluralize from "pluralize";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { Message } from "semantic-ui-react";
import { get, isString, isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import { loadLastStamp } from "../../warning/actions";
import { formatStampRange } from "../../../common/helpers";

const StampRollContainerCurrentSummary = () => {
    const dispatch = useDispatch();
    const initialLoad = () => {
        if (!isLoading && !error) dispatch(loadLastStamp());
    };

    const { company, isLoading, stamp, error } = useSelector(({ auth, stampWarning }) => ({
        company: get(auth, "user.company", null),
        isLoading: stampWarning.isLoading,
        error: stampWarning.error,
        stamp: stampWarning.data,
    }));

    useEffect(initialLoad, []);

    if (!company) {
        return null;
    }

    let header,
        content,
        icon = "ticket",
        negative = false;

    if (isLoading) {
        return null;
    } else if (error) {
        header = "Error Checking Stamp Status";
        content = (
            <Message.Content>
                {error}. &nbsp;
                <u className="clickable" onClick={() => dispatch(loadLastStamp())}>
                    Check Again
                </u>
            </Message.Content>
        );
        negative = true;
    } else if (!isEmpty(stamp.rolls)) {
        const { rolls, availableStampCount } = stamp;

        header = `${pluralize("active roll", rolls.length, true)}, ${pluralize(
            "available stamp",
            availableStampCount,
            true
        )}`;
        content = rolls.map(roll => formatStampRange(roll)).join(" | ");
    }

    if (!header) {
        return null;
    }

    return (
        <Message
            icon={icon}
            negative={negative}
            header={
                <Message.Header as={Link} to="/stamp/roll">
                    {header}
                </Message.Header>
            }
            content={
                isString(content) ? (
                    <Message.Content as={Link} to="/stamp/roll">
                        {content}
                    </Message.Content>
                ) : (
                    content
                )
            }
        />
    );
};

export default StampRollContainerCurrentSummary;
