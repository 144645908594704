import {
    CUSTOMER_USER_LIST_LOADING,
    CUSTOMER_USER_LIST_DATA,
    CUSTOMER_USER_LIST_ERROR,
    CUSTOMER_USER_LIST_UPDATE,
    ALL_CUSTOMER_USER_LIST_LOADING,
    ALL_CUSTOMER_USER_LIST_DATA,
    ALL_CUSTOMER_USER_LIST_ERROR,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";

export const loadCustomerUserList =
    ({ customerId }) =>
    dispatch => {
        dispatch({
            type: CUSTOMER_USER_LIST_LOADING,
        });

        makePrivateApiCall({
            url: `/customer/${customerId}/user`,
            method: "get",
        })
            .then(res => {
                if (res.data) {
                    dispatch({
                        type: CUSTOMER_USER_LIST_DATA,
                        payload: res.data,
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: CUSTOMER_USER_LIST_ERROR,
                    payload: err.message,
                });
            });
    };
export const sendInvitationEmail =
    ({ values, customer }) =>
    dispatch => {
        makePrivateApiCall({
            url: `/customer/user-invitation`,
            data: { values, customer },
            method: "post",
        })
            .then(res => {
                if (res.data) {
                    dispatch({
                        type: CUSTOMER_USER_LIST_UPDATE,
                        payload: res.data,
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: CUSTOMER_USER_LIST_ERROR,
                    payload: err.message,
                });
            });
    };
export const updateUserPermission = ({ permission, customerUser }) => {
    return makePrivateApiCall({
        url: `/customer/user-permission`,
        data: { permission, customerUser },
        method: "put",
    });
};
export const revokeInvitationEmail = ({ revoke, customerUser }) => {
    return makePrivateApiCall({
        url: `/customer/user-invitation-revoke`,
        data: { revoke, customerUser },
        method: "put",
    });
};

export const loadAllCustomerUsersList = () => dispatch => {
    dispatch({
        type: ALL_CUSTOMER_USER_LIST_LOADING,
    });

    makePrivateApiCall({
        url: "/customer/all-users",
        method: "get",
    })
        .then(res => {
            if (res.data) {
                dispatch({
                    type: ALL_CUSTOMER_USER_LIST_DATA,
                    payload: res.data,
                });
            }
        })
        .catch(err => {
            dispatch({
                type: ALL_CUSTOMER_USER_LIST_ERROR,
                payload: err.message,
            });
        });
};
