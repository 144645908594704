import React from "react";
import { Container, Header, Button, Icon, Segment } from "semantic-ui-react";

const NotFound = () => (
    <Container>
        <Segment placeholder vertical>
            <Header icon>
                <Icon name="warning sign" />A bit lost, are we ?
                <Header.Subheader>
                    You may have wandered off too far from the known land of yDrink Invoice. Time to
                    get back to safety?
                </Header.Subheader>
            </Header>
            <Button positive as="a" href="/">
                Show me the way
            </Button>
        </Segment>
    </Container>
);

export default NotFound;
