"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompanyFeatures = void 0;
const CompanyFeatures = {
  PAYMENT: "payment",
  ORDER: "order",
  EMAIL: "email",
  BARCODE: "barcode",
  CLOVER: "clover",
  INTERNAL_SKU: "internal_sku",
  BOTTLE_POS: "bottle_pos",
  INVENTORY: "inventory",
  TIERED_PRICING: "tiered_pricing"
};
exports.CompanyFeatures = CompanyFeatures;