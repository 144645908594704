import React from "react";
import { addDays } from "date-fns";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isArray, isObject } from "lodash";
import { toast } from "react-semantic-toasts";
import { DateInput } from "semantic-ui-calendar-react";
import { Form, Button, Input } from "formik-semantic-ui";
import { Segment, Message, Header } from "semantic-ui-react";

import { validateStampUpdate, validateStampCreate } from "../validators";
import { saveStamp, setStampEditorError, setStampEditorInput } from "../actions";
import { DateFormat } from "utilities/dist/invoice/constants";

class StampRollContainerEditor extends React.Component {
    handleSubmit = async (stampData, formik) => {
        try {
            if (stampData.id) {
                stampData = await validateStampUpdate(stampData);
            } else {
                stampData = await validateStampCreate(stampData);
            }

            const savedStamp = await this.props.saveStamp(stampData);
            formik.setSubmitting(false);
            if (savedStamp) {
                formik.resetForm();
            }
        } catch (err) {
            const messages = [];
            err.details.forEach(errDet => {
                formik.setFieldError(errDet.context.key, errDet.message);
                messages.push(errDet.message);
            });

            formik.setSubmitting(false);
            this.props.setStampEditorError(messages);
        }
    };

    handleConfirm = data => {
        this.formikApi && this.formikApi.setSubmitting(false);

        if (data === null) {
            return;
        }

        if (isArray(data)) {
            data.forEach(errDet => {
                this.formikApi.setFieldError(errDet.context.key, errDet.message);
            });
            return;
        }

        if (isObject(data)) {
            let description = ["Stamp saved in database."];

            toast({
                description: <Message.List items={description} />,
                time: 6000,
                type: "success",
                icon: "checkmark",
                title: "Created Stamp",
            });
        }
    };

    render() {
        const { inputErrors, inputs, isSaving } = this.props;

        return (
            <>
                <Header
                    subheader="Got a new stamp roll, register it here to keep track of them"
                    content="Add Stamp Roll"
                    attached="top"
                    as="h3"
                />
                <Segment attached loading={isSaving}>
                    <Form
                        initialValues={inputs}
                        isSubmitting={isSaving}
                        onSubmit={this.handleSubmit}
                        render={({ values, setFieldValue }) => (
                            <Form.Children>
                                <Form.Group widths="2">
                                    <Input
                                        name="from"
                                        label="Start"
                                        inputProps={{
                                            type: "number",
                                            "data-cy": "stamp_from",
                                        }}
                                    />

                                    <Input
                                        name="to"
                                        label="End"
                                        inputProps={{
                                            type: "number",
                                            "data-cy": "stamp_to",
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group widths={2}>
                                    <Input
                                        name="prefix"
                                        label="Prefix"
                                        inputProps={{
                                            "data-cy": "stamp_prefix",
                                        }}
                                    />

                                    <DateInput
                                        animation="none"
                                        name="received_at"
                                        placeholder="Date"
                                        iconPosition="left"
                                        label="Received On"
                                        closeOnMouseLeave={true}
                                        value={values.received_at}
                                        maxDate={addDays(new Date(), 3)}
                                        dateFormat={DateFormat.toUpperCase()}
                                        onChange={(e, data) =>
                                            setFieldValue("received_at", data.value)
                                        }
                                    />
                                </Form.Group>

                                <Button.Submit data-cy="save_roll" positive>
                                    Save Roll
                                </Button.Submit>
                            </Form.Children>
                        )}
                    />

                    {inputErrors.length > 0 && (
                        <Message
                            error
                            icon="warning sign"
                            content={inputErrors}
                            header="Error saving stamp!"
                        />
                    )}
                </Segment>
            </>
        );
    }
}

const mapStateToProps = ({ stampRoll }) => ({
    inputs: stampRoll.inputs,
    isSaving: stampRoll.isSaving,
    inputErrors: stampRoll.inputErrors,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            saveStamp,
            setStampEditorError,
            setStampEditorInput,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(StampRollContainerEditor);
