import Joi from "joi";
import { baseAuthSchema } from "utilities/dist/auth/validator";

import { makePublicApiCall, makePrivateApiCall } from "utilities/dist/api";
import { setAuthToken, setAuthUser } from "utilities/dist/auth/helpers";

export const validateSigninData = credentials => {
    return Joi.object(baseAuthSchema, { abortEarly: false }).validate(credentials);
};

export const validateProfileData = (profile = {}) => {
    const schema = {
        name: Joi.string().min(3).trim().required(),
        password: Joi.string().min(4).optional(),
    };

    return Joi.object(schema).validate(profile, {
        stripUnknown: true,
        abortEarly: false,
        convert: true,
    });
};

export const validateSignupData = credentials => {
    const schema = {
        name: Joi.string().required(),
        title: Joi.string().required(),
        ...baseAuthSchema,
        confirm_password: Joi.string()
            .required()
            .label("Confirm Password")
            .valid(Joi.ref("password"))
            .options({
                language: {
                    any: {
                        allowOnly: "!!Passwords do not match",
                    },
                },
            }),
    };

    return Joi.object(schema, { abortEarly: false }).validateAsync(credentials);
};

export const signin = credentials => {
    return makePublicApiCall({
        url: "auth/signin",
        method: "post",
        data: credentials,
    }).then(res => {
        setAuthToken(res.data.access_token);
        setAuthUser(res.data.user);

        return res.data.user;
    });
};

export const signup = credentials => {
    return makePublicApiCall({
        url: "auth/signup",
        method: "post",
        data: credentials,
    }).then(res => {
        setAuthToken(res.data.access_token);
        setAuthUser(res.data.user);

        return res.data.user;
    });
};

export const signout = () => {
    setAuthToken(null);
    setAuthUser(null);

    return makePublicApiCall({
        url: "auth/signout",
        method: "post",
    });
};

export const validateToken = () => {
    return makePrivateApiCall({
        url: "validate_token",
        method: "get",
    }).then(res => {
        setAuthUser(res.data.user);

        return res.data.user;
    });
};

export const updateProfile = (data = {}) => {
    return makePrivateApiCall({
        url: "user",
        method: "put",
        data,
    }).then(res => {
        setAuthUser(res.data.user);
        return res.data.user;
    });
};
