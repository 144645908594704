import {
    COMPANY_FEATURE_TOGGLE_DATA,
    COMPANY_FEATURE_TOGGLE_ERROR,
    COMPANY_FEATURE_TOGGLE_LOADING,
    COMPANY_FEATURE_TOGGLE_CONFIRMING,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";
import { toast } from "react-semantic-toasts";

export const showCompanyFeatureToggleConfirmation = confirming => ({
    type: COMPANY_FEATURE_TOGGLE_CONFIRMING,
    payload: confirming,
});

export const toggleCompanyFeature = feature => dispatch => {
    dispatch({
        type: COMPANY_FEATURE_TOGGLE_LOADING,
    });

    return makePrivateApiCall({
        url: `/company/feature`,
        method: "post",
        data: feature,
    })
        .then(res => {
            if (res.data) {
                dispatch({
                    type: COMPANY_FEATURE_TOGGLE_DATA,
                    payload: res.data,
                });
            }
        })
        .catch(err => {
            if (err?.response?.data?.message) {
                dispatch({
                    type: COMPANY_FEATURE_TOGGLE_ERROR,
                    payload: err.response.data.message,
                });
            } else {
                dispatch({
                    type: COMPANY_FEATURE_TOGGLE_ERROR,
                    payload: err.message,
                });
            }
        });
};

export const syncHistoryFromClover = () => dispatch => {
    dispatch({
        type: COMPANY_FEATURE_TOGGLE_LOADING,
    });

    return makePrivateApiCall({
        url: `/company/sync_clover_product`,
        method: "post",
    })
        .then(res => {
            if (res.data) {
                dispatch({
                    type: COMPANY_FEATURE_TOGGLE_DATA,
                    payload: res.data,
                });
                toast({
                    time: 3000,
                    type: "success",
                    icon: "checkmark",
                    description: "Product synced from clover",
                    title: "Product sync",
                });
            }
        })
        .catch(err => {
            if (err?.response?.data?.message) {
                dispatch({
                    type: COMPANY_FEATURE_TOGGLE_ERROR,
                    payload: err.response.data.message,
                });
                toast({
                    time: 3000,
                    type: "error",
                    icon: "warning sign",
                    description: err?.response?.data?.message,
                    title: "Product sync",
                });
            } else {
                dispatch({
                    type: COMPANY_FEATURE_TOGGLE_ERROR,
                    payload: err.message,
                });
            }
        });
};
