import { format } from "date-fns";
import { DateTimeFormat } from "utilities/dist/invoice/constants";
import { getInvitedAtTime, getRegisteredTime } from "./helpers";

export const CustomerUserGridColumns = [
    {
        headerName: "Name",
        field: "name",
        minWidth: 150,
    },
    {
        headerName: "Email address",
        field: "email",
        minWidth: 100,
    },
    {
        headerName: "Invited at",
        field: "invited_at",
        minWidth: 150,
        cellRenderer: function ({ data }) {
            const invitedAtTime = getInvitedAtTime(data);
            return invitedAtTime ? format(new Date(invitedAtTime), DateTimeFormat) : "";
        },
    },
    {
        headerName: "Registered at",
        field: "created_at",
        minWidth: 150,
        cellRenderer: function ({ data }) {
            const registeredTime = getRegisteredTime(data);
            return registeredTime ? format(new Date(registeredTime), DateTimeFormat) : "";
        },
    },
    {
        headerName: "Access",
        field: "is_enable",
        maxWidth: 150,
        cellRenderer: function ({ data }) {
            const registeredTime = getRegisteredTime(data);
            return registeredTime
                ? !data.revoked && data.is_enable !== true
                    ? "Disabled"
                    : "Enabled"
                : data.revoked !== true
                ? ""
                : "Revoked";
        },
    },
];

export const AllCustomerUsersGridColumns = [
    {
        headerName: "Name",
        field: "name",
        minWidth: 150,
    },
    {
        headerName: "Email address",
        field: "email",
        minWidth: 100,
    },
    {
        headerName: "Customer",
        field: "customer_name",
        minWidth: 150,
        valueGetter: function ({ data }) {
            return !data ? null : data.customer.name + ", " + data.customer.address;
        },
        cellRenderer: function ({ data }) {
            if (!data) return null;
            return data.customer.name + "<br/>" + data.customer.address;
        },
        autoHeight: true,
        wrapText: true,
    },
    {
        headerName: "Phone Number",
        field: "phone_number",
        minWidth: 150,
    },
    {
        headerName: "Registered at",
        field: "registered_at",
        minWidth: 100,
        valueGetter: function ({ data }) {
            return format(new Date(data?.created_at), DateTimeFormat);
        },
    },
];
