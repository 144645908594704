import {
      ACTIVITY_GRID_DATA,
      ACTIVITY_GRID_ERROR,
      ACTIVITY_GRID_LOADING,
      ACTIVITY_GRID_PAGE_CHANGE,
      ACTIVITY_GRID_STATE_CHANGE,
  } from "./constants";
  import { makePrivateApiCall } from "utilities/dist/api";
  
  export const loadActivityGridPage = (state, grid) => dispatch => {
      let { lastRow, sortModel, filterModel } = state;
      dispatch({
          type: ACTIVITY_GRID_LOADING,
      });
  
      const params = {
          endRow: grid.endRow,
          filterModel,
          sortModel,
          startRow: grid.startRow,
      };
  
      return makePrivateApiCall({
          url: "user/user-activity/grid",
          method: "get",
          params,
      })
          .then(res => {
              if (res.data) {
                  const activities = res.data.rows;
                  lastRow = res.data.count;
  
                  grid.successCallback(activities, lastRow);
  
                  dispatch({
                      type: ACTIVITY_GRID_DATA,
                      payload: { activities, lastRow },
                  });
              }
          })
          .catch(err => {
              grid.failCallback(err);
              dispatch({
                  type: ACTIVITY_GRID_ERROR,
                  payload: err.message,
              });
          });
  };
  
  export const changeActivityGridPage = page => {
      return {
          type: ACTIVITY_GRID_PAGE_CHANGE,
          payload: page,
      };
  };
  
  export const changeActivityGridState = gridState => dispatch => {
      return dispatch({
          type: ACTIVITY_GRID_STATE_CHANGE,
          payload: gridState,
      });
  };
  