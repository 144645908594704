import {
      ACTIVITY_GRID_DATA,
      ACTIVITY_GRID_ERROR,
      ACTIVITY_GRID_LOADING,
      ACTIVITY_GRID_PAGE_CHANGE,
      ACTIVITY_GRID_STATE_CHANGE,
  } from "./constants";
  import { generateReducer } from "../common/helpers";
  
  const initialState = {
      error: null,
      activities: [],
      isLoading: false,
      currentPage: 1,
      lastRow: null,
      pageSize: 25,
      sortModel: [
          {
              colId: "created_at",
              sort: "desc",
          },
      ],
      filterModel: {},
  };
  
  const setGridData = (state, { activities, lastRow }) => ({
      ...state,
      error: null,
      isLoading: false,
      lastRow,
      activities,
  });
  
  const changeGridPage = (state, currentPage) => ({ ...state, currentPage });
  const changeGridState = (state, payload) => ({ ...state, ...payload });
  const setGridLoading = state => ({ ...state, isLoading: true });
  const setGridError = (state, error) => ({ ...state, isLoading: false, error });
  
  const reducers = {
      [ACTIVITY_GRID_DATA]: setGridData,
      [ACTIVITY_GRID_ERROR]: setGridError,
      [ACTIVITY_GRID_LOADING]: setGridLoading,
  
      [ACTIVITY_GRID_PAGE_CHANGE]: changeGridPage,
      [ACTIVITY_GRID_STATE_CHANGE]: changeGridState,
  };
  
  export default generateReducer(reducers, initialState);
  