import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import { connectRouter, routerMiddleware } from "connected-react-router";
import auth from "./auth/reducers";
import { nav, version } from "./common/reducers";
import stampGrid from "./stamp/grid/reducers";
import stampRoll from "./stamp/roll/reducers";
import stampWarning from "./stamp/warning/reducers";
import invoiceGrid from "./invoice/grid/reducer";
import invoiceBuilder from "./invoice/builder/reducer";
import invoicePayment from "./invoice/payment/reducer";
import invoiceConverter from "./invoice/converter/reducer";
import itemLog from "./invoice/item-log/reducer";
import invoicePaymentGrid from "./invoice/payment-grid/reducer";
import invoiceDocument from "./invoice/document-upload/reducer";
import invoiceEmailGrid from "./invoice/email-grid/reducer";
import { customerList, customerEditor, customerGrid } from "./customer/reducers";
import {
    customerUserList,
    customerUserGrid,
    allUsersList,
    allUsersGrid,
} from "./customer-user/reducers/";
import companyLogo from "./company/logo/reducers";
import companyStats from "./company/stats/reducers";
import companyEditor from "./company/editor/reducers";
import companyFeature from "./company/feature/reducers";
import companyNotification from "./company/notification/reducers";
import companyQuickSearch from "./company/quick-search/reducers";
import productGrid from "./product/grid/reducers";
import productSearch from "./product/search/reducers";
import productEditor from "./product/editor/reducers";
import orderGrid from "./order/grid/reducer";
import activityGrid from "./activity/reducers";
import inventoryGrid from "./inventory/grid/reducer";
import inventoryRestock from "./inventory/restock/reducer";
import productView from "./product/view/reducer";
import productPriceTier from "./product/price-tier/reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const history = createBrowserHistory();
const appReducer = persistCombineReducers(
    {
        storage,
        key: "root",
        whitelist: [
            "nav",
            "version",
            "stampRoll",
            "stampWarning",
            "productEditor",
            "customerList",
            "customerUserList",
            "customerEditor",
            "customerGrid",
            "customerUserGrid",
            "companyStats",
            "companyEditor",
            "companyLogo",
            "companyFeature",
            "companyNotification",
            "invoiceBuilder",
            "itemLog",
            "invoicePayment",
            "invoiceConverter",
            "invoicePaymentGrid",
            "invoiceDocument",
            "allUsersList",
            "allUsersGrid",
            "activityGrid",
            "productView",
            "inventoryRestock",
            "productPriceTier",
        ],
    },
    {
        router: connectRouter(history),
        auth,
        nav,
        version,
        stampGrid,
        stampRoll,
        stampWarning,
        productSearch,
        productEditor,
        productGrid,
        customerList,
        customerEditor,
        customerGrid,
        customerUserList,
        allUsersList,
        allUsersGrid,
        customerUserGrid,
        companyQuickSearch,
        companyStats,
        companyEditor,
        companyLogo,
        companyFeature,
        companyNotification,
        invoiceBuilder,
        itemLog,
        invoiceGrid,
        invoicePayment,
        invoiceConverter,
        invoicePaymentGrid,
        invoiceDocument,
        invoiceEmailGrid,
        orderGrid,
        activityGrid,
        inventoryGrid,
        inventoryRestock,
        productView,
        productPriceTier,
    }
);
const rootReducer = (state, action) => {
    if (action.type === "AUTH_SIGNOUT") {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};
export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
);
export const persistor = persistStore(store);
