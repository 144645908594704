import { get, isString } from "lodash";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { Message } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

import { loadLastStamp, hideLowStampWarning } from "./actions";

const StampWarningContainer = () => {
    const dispatch = useDispatch();
    const initialLoad = () => {
        if (!hideWarning && !isLoading && !error) dispatch(loadLastStamp());
    };
    const handleDismiss = () => dispatch(hideLowStampWarning());

    const { company, isLoading, stamp, error, hideWarning } = useSelector(
        ({ auth, stampWarning }) => ({
            company: get(auth, "user.company", null),
            hideWarning: stampWarning.hideWarning,
            isLoading: stampWarning.isLoading,
            error: stampWarning.error,
            stamp: stampWarning.data,
        })
    );

    useEffect(initialLoad, [hideWarning]);

    if (!company) {
        return null;
    }

    let header,
        content,
        icon = "ticket",
        negative = false,
        warning = true;

    if (isLoading || hideWarning) {
        return null;
    } else if (error) {
        header = "Error Checking Stamp Status";
        content = (
            <Message.Content>
                {error}. &nbsp;
                <u className="clickable" onClick={() => dispatch(loadLastStamp())}>
                    Check Again
                </u>
            </Message.Content>
        );
        warning = false;
        negative = true;
    } else if (stamp.totalStampCount === null) {
        header = "Stamp details missing";
        content =
            "To help manage and assign stamps automatically to your invoice, we need you to configure your stamp availability.";
    } else if (stamp.availableStampCount < 500) {
        header = "Low on stamps";
        content = `You have only ${stamp.availableStampCount} stamps left.`;
    }

    if (!header) {
        return null;
    }

    return (
        <Message
            icon={icon}
            warning={warning}
            negative={negative}
            onDismiss={handleDismiss}
            header={
                <Message.Header as={Link} to="/stamp/roll">
                    {header}
                </Message.Header>
            }
            content={
                isString(content) ? (
                    <Message.Content as={Link} to="/stamp/roll">
                        {content}
                    </Message.Content>
                ) : (
                    content
                )
            }
        />
    );
};

export default StampWarningContainer;
