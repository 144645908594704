import { keyBy } from "lodash";

import { formatAmount } from "utilities/dist/helpers";
import { ProductTypeAcronyms } from "../constants";

export const allGridColumns = keyBy(
    [
        {
            minWidth: 260,
            field: "product",
            headerName: "Name",
        },
        {
            hide: true,
            minWidth: 60,
            field: "internal_sku",
            headerName: "SKU",
        },
        {
            hide: true,
            field: "upc",
            headerName: "UPC",
        },
        {
            minWidth: 25,
            headerName: "Type",
            field: "product_type",
            valueFormatter: function ({ data }) {
                return data ? ProductTypeAcronyms[data.product_type] : "";
            },
        },
        {
            minWidth: 25,
            headerName: "Size",
            field: "product_size",
        },
        {
            minWidth: 40,
            type: "numericColumn",
            headerName: "Purchased",
            field: "last_purchase_price",
            filter: "agNumberColumnFilter",
            valueFormatter: function ({ data }) {
                if (!data || !data.last_purchase_price) return null;
                return formatAmount(data.last_purchase_price);
            },
            valueGetter: function ({ data }) {
                if (!data || !data.last_purchase_price) return null;
                return formatAmount(data.last_purchase_price);
            },
        },
        {
            minWidth: 20,
            type: "numericColumn",
            field: "product_price",
            headerName: "List Price",
            filter: "agNumberColumnFilter",
            valueFormatter: function ({ data }) {
                if (!data || !data?.product_price) return null;
                if (!data.priceChanges?.length) {
                    return formatAmount(data.product_price);
                }
                const latestPriceChange = data.priceChanges
                    .filter(priceChange => Date.parse(priceChange.effective_date) <= new Date())
                    .sort(
                        (a, b) => Date.parse(b.effective_date) - Date.parse(a.effective_date)
                    )?.[0];
                if (latestPriceChange?.new_price) {
                    return formatAmount(latestPriceChange.new_price);
                }
            },
            valueGetter: function ({ data }) {
                if (!data || !data?.product_price) return null;
                if (!data.priceChanges?.length) {
                    return formatAmount(data.product_price);
                }
                const latestPriceChange = data.priceChanges
                    .filter(priceChange => Date.parse(priceChange.effective_date) <= new Date())
                    .sort(
                        (a, b) => Date.parse(b.effective_date) - Date.parse(a.effective_date)
                    )?.[0];
                if (latestPriceChange?.new_price) {
                    return formatAmount(latestPriceChange.new_price);
                }
            },
        },
        {
            minWidth: 25,
            field: "units_sold",
            type: "numericColumn",
            headerName: "Units Sold",
            filter: "agNumberColumnFilter",
        },
        {
            minWidth: 25,
            field: "amount_sold",
            type: "numericColumn",
            headerName: "Total $",
            filter: "agNumberColumnFilter",
            valueFormatter: function ({ data }) {
                if (!data) return null;
                return formatAmount(data.amount_sold);
            },
            valueGetter: function ({ data }) {
                if (!data) return null;
                return formatAmount(data.amount_sold);
            },
        },
        {
            hide: true,
            minWidth: 60,
            field: "distributor",
            headerName: "Distributor",
            filter: false,
            sortable: false,
            valueGetter: ({ data }) => data?.inventory?.distributor || null,
        },
        {
            hide: true,
            minWidth: 0,
            field: "archived",
            type: "numericColumn",
            headerName: "Archived",
            filter: "agNumberColumnFilter",
        },
    ],
    "field"
);
