export const COMPANY_STATS_DATA = "COMPANY_STATS_DATA";
export const COMPANY_STATS_ERROR = "COMPANY_STATS_ERROR";
export const COMPANY_STATS_LOADING = "COMPANY_STATS_LOADING";
export const COMPANY_STATS_CHANGE_DATERANGE = "COMPANY_STATS_CHANGE_DATERANGE";

export const COMPANY_QUICK_SEARCH_CHANGE_QUERY = "COMPANY_QUICK_SEARCH_CHANGE_QUERY";
export const COMPANY_QUICK_SEARCH_SUGGESTIONS_DATA = "COMPANY_QUICK_SEARCH_SUGGESTIONS_DATA";
export const COMPANY_QUICK_SEARCH_SUGGESTIONS_ERROR = "COMPANY_QUICK_SEARCH_SUGGESTIONS_ERROR";
export const COMPANY_QUICK_SEARCH_SUGGESTIONS_LOADING = "COMPANY_QUICK_SEARCH_SUGGESTIONS_LOADING";

export const COMPANY_EDITOR_SAVING = "COMPANY_EDITOR_SAVING";
export const COMPANY_EDITOR_SAVED = "COMPANY_EDITOR_SAVED";
export const COMPANY_EDITOR_INPUT = "COMPANY_EDITOR_INPUT";
export const COMPANY_EDITOR_ERROR = "COMPANY_EDITOR_ERROR";

export const COMPANY_LOGO_DATA = "COMPANY_LOGO_DATA";
export const COMPANY_LOGO_ERROR = "COMPANY_LOGO_ERROR";
export const COMPANY_LOGO_LOADING = "COMPANY_LOGO_LOADING";

export const COMPANY_LOGO_UPLOADING = "COMPANY_LOGO_UPLOADING";
export const COMPANY_LOGO_UPLOAD_ERROR = "COMPANY_LOGO_UPLOAD_ERROR";
export const COMPANY_LOGO_UPLOAD_SUCCESS = "COMPANY_LOGO_UPLOAD_SUCCESS";

export const COMPANY_LOGO_REMOVING = "COMPANY_LOGO_REMOVING";
export const COMPANY_LOGO_REMOVE_ERROR = "COMPANY_LOGO_REMOVE_ERROR";
export const COMPANY_LOGO_REMOVE_SUCCESS = "COMPANY_LOGO_REMOVE_SUCCESS";

export const COMPANY_FEATURE_TOGGLE_CONFIRMING = "COMPANY_FEATURE_TOGGLE_CONFIRMING";
export const COMPANY_FEATURE_TOGGLE_LOADING = "COMPANY_FEATURE_TOGGLE_LOADING";
export const COMPANY_FEATURE_TOGGLE_ERROR = "COMPANY_FEATURE_TOGGLE_ERROR";
export const COMPANY_FEATURE_TOGGLE_DATA = "COMPANY_FEATURE_TOGGLE_DATA";

export const SYNC_HISTORY_CLOVER_DATA = "SYNC_HISTORY_CLOVER_DATA";

export const COMPANY_NOTIFICATION_TOGGLE_DATA = "COMPANY_NOTIFICATION_TOGGLE_DATA";
export const COMPANY_NOTIFICATION_TOGGLE_ERROR = "COMPANY_NOTIFICATION_TOGGLE_ERROR";

export const COMPANY_NOTIFICATION_DATA = "COMPANY_NOTIFICATION_DATA";
export const COMPANY_NOTIFICATION_ERROR = "COMPANY_NOTIFICATION_ERROR";
