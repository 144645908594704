import React from "react";
import { Link } from "react-router-dom";
import { Grid, Menu } from "semantic-ui-react";
import { useSelector } from "react-redux";

import TopNav from "../../common/containers/top-nav";
import CustomerContainerGrid from "../containers/grid";
import { isFeatureActive, OrderFeature } from "../../company/feature/helpers";

const CustomerPageHome = () => {
    const { company } = useSelector(({ auth }) => ({
        company: auth.user.company,
    }));
    const hasOrderFeature = isFeatureActive(company, OrderFeature);
    return (
        <Grid>
            <TopNav
                headerIcon="briefcase"
                subheader="All your customers"
                headerContent="Customers"
                extraButtons={() => (
                    <>
                        <Menu.Item
                            as={Link}
                            icon="plus"
                            name="New Customer"
                            to="/customer/create"
                        />
                        {!!hasOrderFeature && (
                            <Menu.Item as={Link} icon="users" name="Users" to="/customer/users" />
                        )}
                    </>
                )}
            />
            <Grid.Row>
                <Grid.Column width={16}>
                    <CustomerContainerGrid />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default CustomerPageHome;
