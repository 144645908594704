import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Dimmer, Message } from "semantic-ui-react";

import { loadStampRoll } from "../actions";
import StampRollActiveToggle from "../active-toggle.component";

const fields = [
    { header: "Date Received", field: "received_at" },
    { header: "Stamp Start", field: "from" },
    { header: "Stamp End", field: "to" },
    { header: "Total", field: "total" },
    { header: "Available", field: "available", align: "center" },
    { header: "Actions", field: "actions", align: "center" },
];

const fieldData = (field, stamp) => {
    if (field === "actions") {
        return <StampRollActiveToggle stamp={stamp} />;
    }

    if (field === "total") {
        return stamp.to - stamp.from + 1;
    }

    if (field === "available") {
        return stamp.stamps ? stamp.stamps.length : 0;
    }

    if (["from", "to"].indexOf(field) >= 0 && !!stamp.prefix) {
        return `${stamp.prefix.toUpperCase()}${stamp[field]}`;
    }

    return stamp[field];
};

const StampRollContainerList = () => {
    const dispatch = useDispatch();
    const { data, error, isLoading } = useSelector(({ stampRoll }) => stampRoll);

    useEffect(() => {
        dispatch(loadStampRoll());
    }, [dispatch]);

    if (!isLoading && (isEmpty(data) || error)) {
        if (error) {
            return <Message negative content={error} header="Error loading stamp rolls" />;
        }

        return (
            <Message
                negative
                header="No roll found"
                content="Please register your TABC stamp roll"
            />
        );
    }

    return (
        <Dimmer.Dimmable as={Table} dimmed={isLoading}>
            <Table.Header>
                <Table.Row>
                    {fields.map(({ header, align }) => (
                        <Table.HeaderCell textAlign={align} key={`stamp_roll_header_${header}`}>
                            {header}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {data.map(stamp => (
                    <Table.Row key={`stamp_roll_row_${stamp.id}`}>
                        {fields.map(({ field, align }) => (
                            <Table.Cell
                                key={`stamp_roll_row_${stamp.id}_${field}`}
                                textAlign={align || "left"}
                            >
                                {fieldData(field, stamp)}
                            </Table.Cell>
                        ))}
                    </Table.Row>
                ))}
            </Table.Body>
        </Dimmer.Dimmable>
    );
};

export default StampRollContainerList;
