import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce, isEqual } from "lodash";
import { AgGridReact } from "ag-grid-react";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { Segment, Divider, Pagination, Header, Icon, Button } from "semantic-ui-react";

import { loadCustomerList, changeCustomerGridState } from "../actions";
import { setInvoiceBuilderCustomer } from "../../invoice/builder/actions";

import { CustomerGridColumns } from "../columns";
import CustomerComponentGridActions from "../components/grid-actions";
import GridPageSizePicker from "../../common/components/grid-page-size-picker";
import { TieredPricing, isFeatureActive } from "../../company/feature/helpers";

const frameworkComponents = {
    customerGridActions: CustomerComponentGridActions,
};

class CustomerContainerGrid extends React.Component {
    grid = null;

    UNSAFE_componentWillMount() {
        // attempt to load only if no data has been loaded before and there isn't already an api call happening right now
        if (!this.props.data.isLoading) this.props.loadCustomerList();
    }

    componentDidUpdate(prevProps) {
        if (!this.grid) return;

        this.isTieredPricingEnabled(this.props.company);

        if (!isEqual(prevProps.hiddenColumns, this.props.hiddenColumns)) {
            this.syncColumns();
        }

        if (!this.props.isLoading) {
            this.grid.api.hideOverlay();
            this.resizeGrid();
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.grid.api.paginationSetPageSize(this.props.pageSize);
        }

        if (prevProps.currentPage !== this.props.currentPage) {
            this.grid.api.paginationGoToPage(this.props.currentPage);
        }

        if (this.props.isLoading) {
            this.grid.api.showLoadingOverlay();
        }
    }

    resizeGrid = debounce(() => {
        this.grid && this.grid.api.sizeColumnsToFit();
    }, 300);

    isTieredPricingEnabled = company => {
        this.grid.columnApi.setColumnVisible(
            "product_price_tier_id",
            isFeatureActive(company, TieredPricing)
        );
    };

    onGridReady = grid => {
        this.grid = grid;
        this.syncColumns();

        if (this.props.isLoading) {
            grid.api.showLoadingOverlay();
        }

        this.isTieredPricingEnabled(this.props.company);
        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.addEventListener("cellDoubleClicked", e => {
            if (e.colDef.field === "name") {
                this.props.setInvoiceBuilderCustomer(e.data);
                this.props.history.push("/invoice/create");
            }
        });

        this.grid.api.addEventListener("sortChanged", () => {
            this.props.changeCustomerGridState({
                sortModel: this.grid.api.getSortModel(),
            });
        });

        this.grid.api.addEventListener("filterChanged", () => {
            this.props.changeCustomerGridState({
                filterModel: this.grid.api.getFilterModel(),
            });
        });
    };

    syncColumns = () => {
        this.grid.columnApi.setColumnsVisible(this.props.hiddenColumns, false);
        this.grid.columnApi.setColumnsVisible(this.props.shownColumns, true);
        this.grid.api.sizeColumnsToFit();
    };
    render() {
        const { data, pageSize, isLoading, changeCustomerGridState } = this.props;

        if (!isLoading && data.length < 1) {
            return (
                <Segment placeholder vertical>
                    <Header icon>
                        <Icon name="newspaper outline" />
                        You have not added any customer.
                    </Header>
                    <Button
                        positive
                        data-cy="add_customer"
                        onClick={() => this.props.push("/customer/create")}
                    >
                        Add Customer
                    </Button>
                </Segment>
            );
        }

        return (
            <>
                <Segment className="customer-grid" vertical>
                    <div className="ag-theme-balham">
                        <AgGridReact
                            rowData={data}
                            rowHeight={50}
                            reactNext={true}
                            pagination={true}
                            floatingFilter={true}
                            domLayout="autoHeight"
                            // paginationPageSize={10}
                            suppressPaginationPanel={true}
                            onGridReady={this.onGridReady}
                            columnDefs={CustomerGridColumns}
                            frameworkComponents={frameworkComponents}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                resizable: true,
                                suppressMenu: true,
                            }}
                        />
                    </div>

                    <div className="tp-10 clearing">
                        <GridPageSizePicker
                            button
                            compact
                            pointing="top"
                            pageSize={pageSize}
                            text={`${pageSize}/page`}
                            className="left floated basic"
                            onChange={changeCustomerGridState}
                        />
                        <Pagination
                            size="mini"
                            floated="right"
                            defaultActivePage={1}
                            totalPages={Math.round(data.length / pageSize)}
                            onPageChange={(e, data) =>
                                changeCustomerGridState({ currentPage: data.activePage - 1 })
                            }
                        />
                        <Divider hidden fitted clearing />
                    </div>
                </Segment>
            </>
        );
    }
}

const mapStateToProps = ({ customerGrid, customerList, auth }) => ({
    data: customerList.data,
    error: customerList.error,
    isLoading: customerList.isLoading,

    pageSize: customerGrid.pageSize,
    sortModel: customerGrid.sortModel,
    currentPage: customerGrid.currentPage,
    filterModel: customerGrid.filterModel,

    company: auth.user.company,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            push,
            loadCustomerList,
            changeCustomerGridState,
            setInvoiceBuilderCustomer,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerContainerGrid));
