import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Button, Form, Segment, Message } from "semantic-ui-react";

import { authSignupInputChange, authSignup } from "../actions";

const SignupForm = ({ inputs, authSignupInputChange, isSigningUp, authSignup, signupErrors }) => (
    <Form size="large" error={!!signupErrors} onSubmit={() => authSignup(inputs)}>
        <Segment stacked>
            <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                value={inputs.email}
                placeholder="E-mail address"
                error={signupErrors && !!signupErrors["email"]}
                onChange={e => authSignupInputChange("email", e.target.value)}
            />

            <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                value={inputs.name}
                placeholder="Full Name"
                error={signupErrors && !!signupErrors["name"]}
                onChange={e => authSignupInputChange("name", e.target.value)}
            />

            <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                value={inputs.title}
                placeholder="Title"
                error={signupErrors && !!signupErrors["title"]}
                onChange={e => authSignupInputChange("title", e.target.value)}
            />

            <Form.Input
                fluid
                icon="lock"
                type="password"
                iconPosition="left"
                placeholder="Password"
                value={inputs.password}
                error={signupErrors && !!signupErrors["password"]}
                onChange={e => authSignupInputChange("password", e.target.value)}
            />

            <Form.Input
                fluid
                icon="lock"
                type="password"
                iconPosition="left"
                placeholder="Confirm Password"
                value={inputs.confirm_password}
                error={signupErrors && !!signupErrors["confirm_password"]}
                onChange={e => authSignupInputChange("confirm_password", e.target.value)}
            />

            {!!signupErrors && <Message error list={Object.values(signupErrors)} />}

            <Button fluid color="teal" size="large" loading={isSigningUp} disabled={isSigningUp}>
                Signup
            </Button>
        </Segment>

        <Message>
            Already have an account? <Link to="/auth/signin">Sign In</Link>
        </Message>
    </Form>
);

const mapStateToProps = ({ auth }) => ({
    inputs: auth.signupInputs,
    isSigningUp: auth.signingUp,
    signupErrors: auth.signupErrors,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            authSignupInputChange,
            authSignup,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
