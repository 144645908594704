import React from "react";
import { Segment, Divider, Pagination, Header, Icon } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce, isEqual } from "lodash";
import { push } from "connected-react-router";
import { withRouter } from "react-router-dom";

import { AllCustomerUsersGridColumns } from "../column";
import GridPageSizePicker from "../../common/components/grid-page-size-picker";
import { loadAllCustomerUsersList } from "../actions/list";
import { changeAllCustomerUserGridState } from "../actions/grid";

class AllCustomerUsersContainerGrid extends React.Component {
    grid = null;

    UNSAFE_componentWillMount() {
        // attempt to load only if no data has been loaded before and there isn't already an api call happening right now
        this.props.loadAllCustomerUsersList();
    }

    componentDidUpdate(prevProps) {
        if (!this.grid) return;

        if (!isEqual(prevProps.hiddenColumns, this.props.hiddenColumns)) {
            this.syncColumns();
        }

        if (!this.props.isLoading) {
            this.grid.api.hideOverlay();
            this.resizeGrid();
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.grid.api.paginationSetPageSize(this.props.pageSize);
        }

        if (prevProps.currentPage !== this.props.currentPage) {
            this.grid.api.paginationGoToPage(this.props.currentPage);
        }

        if (this.props.isLoading) {
            this.grid.api.showLoadingOverlay();
        }
    }

    resizeGrid = debounce(() => {
        this.grid && this.grid.api.sizeColumnsToFit();
    }, 300);

    onGridReady = grid => {
        this.grid = grid;
        this.syncColumns();

        if (this.props.isLoading) {
            grid.api.showLoadingOverlay();
        }

        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.addEventListener("sortChanged", () => {
            this.props.changeAllCustomerUserGridState({
                sortModel: this.grid.api.getSortModel(),
            });
        });

        this.grid.api.addEventListener("filterChanged", () => {
            this.props.changeAllCustomerUserGridState({
                filterModel: this.grid.api.getFilterModel(),
            });
        });
        this.grid.api.addEventListener("rowClicked", e => {
            if (!e.data || !e.data.id || !this.props.onRowSelect) return;

            this.props.onRowSelect(e.data);
        });
    };

    syncColumns = () => {
        this.grid.columnApi.setColumnsVisible(this.props.hiddenColumns, false);
        this.grid.columnApi.setColumnsVisible(this.props.shownColumns, true);
        this.grid.api.sizeColumnsToFit();
    };

    render() {
        const { data, pageSize, isLoading, changeAllCustomerUserGridState } = this.props;

        if (!isLoading && data.length < 1) {
            return (
                <Segment placeholder>
                    <Header icon>
                        <Icon name="newspaper outline" />
                        You have no registered user.
                    </Header>
                </Segment>
            );
        }

        return (
            <>
                <Segment className="customer-grid" vertical>
                    <div className="ag-theme-balham">
                        <AgGridReact
                            rowData={data}
                            rowHeight={40}
                            reactNext={true}
                            pagination={true}
                            floatingFilter={true}
                            domLayout="autoHeight"
                            // paginationPageSize={10}
                            suppressPaginationPanel={true}
                            onGridReady={this.onGridReady}
                            columnDefs={AllCustomerUsersGridColumns}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                resizable: true,
                                suppressMenu: true,
                            }}
                        />
                    </div>

                    <div className="tp-10 clearing">
                        <GridPageSizePicker
                            button
                            compact
                            pointing="top"
                            pageSize={pageSize}
                            text={`${pageSize}/page`}
                            className="left floated basic"
                            onChange={changeAllCustomerUserGridState}
                        />
                        <Pagination
                            size="mini"
                            floated="right"
                            defaultActivePage={1}
                            totalPages={Math.round(data.length / pageSize)}
                            onPageChange={(e, data) =>
                                changeAllCustomerUserGridState({ currentPage: data.activePage - 1 })
                            }
                        />
                        <Divider hidden fitted clearing />
                    </div>
                </Segment>
            </>
        );
    }
}

const mapStateToProps = ({ allUsersGrid, allUsersList }) => ({
    data: allUsersList.data,
    error: allUsersList.error,
    isLoading: allUsersList.isLoading,

    pageSize: allUsersGrid.pageSize,
    sortModel: allUsersGrid.sortModel,
    currentPage: allUsersGrid.currentPage,
    filterModel: allUsersGrid.filterModel,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            push,
            loadAllCustomerUsersList,
            changeAllCustomerUserGridState,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AllCustomerUsersContainerGrid));
