import { useDispatch, useSelector } from "react-redux";
import { Grid, Menu } from "semantic-ui-react";
import React, { useRef, useState } from "react";

import { showProductEditorModal, loadProductForEdit } from "../editor/actions";

import TopNav from "../../common/containers/top-nav";
import ProductGridContainer from "../grid/index.container";
import ProductEditorContainer from "../editor/index.container";
import ProductSingleComponent from "../grid/single.component";
import { Link } from "react-router-dom";
import { isCompanyFeatureActive } from "utilities/dist/company/helpers";
import { CompanyFeatures } from "utilities/dist/company/constants";

const ProductPageHome = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const gridRef = useRef(null);
    const dispatch = useDispatch();
    const showCreator = () => {
        dispatch(showProductEditorModal(true));
    };
    const showEditor = product => {
        dispatch(loadProductForEdit(product));
        dispatch(showProductEditorModal(true));
    };
    const { company } = useSelector(({ auth }) => ({
        company: auth.user.company,
    }));
    const hasInventoryFeature = isCompanyFeatureActive(company, CompanyFeatures.INVENTORY);

    return (
        <Grid>
            <TopNav
                headerIcon="newspaper"
                headerContent="Products"
                subheader="Manage your products"
                extraButtons={() => (
                    <>
                        <Menu.Item
                            data-cy="new_product"
                            icon="plus"
                            name="New Product"
                            onClick={showCreator}
                        />
                        {hasInventoryFeature && (
                            <Menu.Item
                                data-cy="inventory"
                                icon="warehouse"
                                as={Link}
                                name="Inventory"
                                to="/inventory"
                            />
                        )}
                    </>
                )}
            />

            <ProductEditorContainer
                onComplete={success => {
                    // if success passed us a string, assume it's product name and apply it to the grid
                    if (typeof success === "string" && gridRef.current) {
                        gridRef.current.applyNameFilter(success);
                        dispatch(showProductEditorModal(false));
                        return;
                    }
                    if (!!success && gridRef.current) {
                        gridRef.current.reloadGridData();
                    }
                }}
            />
            <Grid.Column mobile={16} tablet={16} computer={12}>
                <ProductGridContainer
                    ref={gridRef}
                    onRowSelect={prod => setSelectedProduct(prod)}
                />
            </Grid.Column>
            {!!selectedProduct && (
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <ProductSingleComponent
                        product={selectedProduct}
                        onEdit={product => showEditor(product)}
                    />
                </Grid.Column>
            )}
        </Grid>
    );
};

export default ProductPageHome;
