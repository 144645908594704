import { Link } from "react-router-dom";
import { isEmpty, startCase } from "lodash";
import { format, isBefore, addDays } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { Grid, Menu, Message, Modal, Confirm, Button } from "semantic-ui-react";
import { useHistory } from "react-router";

import TopNav from "../../common/containers/top-nav";

import { DateFormat } from "../helpers";
import { makePrivateApiCall } from "utilities/dist/api";
import OrderComponentView from "../components/view";
import { saveOrderBuilderOrder } from "../builder/action";
import { getOrderProductForInvoice } from "../../invoice/builder/actions";
import OrderNotesComponent from "../components/order-notes";
const isChangeNeeded = ({ field, previousValue, newValue }) => {
    let from = previousValue,
        to = newValue;

    if (field === "ordered_at") {
        field = "Order Date";
        to = format(new Date(newValue), DateFormat);
        from = format(new Date(previousValue), DateFormat);
    }

    if (field === "due_at") {
        field = "Due Date";
        from = !!previousValue ? format(new Date(previousValue), DateFormat) : "Due on delivery";

        to = isBefore(newValue, addDays(new Date(), 1))
            ? "Due on delivery"
            : format(new Date(newValue), DateFormat);
    }

    if (from === to) {
        return false;
    }

    return { from, to };
};

const ConfirmContent = ({ field, previousValue, newValue }) => {
    const changeValues = isChangeNeeded({ field, previousValue, newValue });

    if (!changeValues) {
        return (
            <Modal.Content>
                {field} is not different than the current one, so there's nothing to update.
            </Modal.Content>
        );
    }

    return (
        <Modal.Content>
            Sure you want to change <b>{startCase(field)}</b> from{" "}
            <b>{startCase(changeValues.from)}</b> to <b>{startCase(changeValues.to)}</b>?
        </Modal.Content>
    );
};

const OrderPageView = ({ computedMatch }) => {
    const { number } = computedMatch.params;

    const [error, setError] = useState(null);
    const [order, setOrder] = useState({});
    const [isLoading, setLoading] = useState(false);

    const [confirmChange, setConfirmChange] = useState(null);

    const dispatch = useDispatch();
    // see if we already have the data we need from grid, if not, load from server
    const { fromGrid, isSaving, company } = useSelector(({ orderGrid, auth }) => ({
        fromGrid: orderGrid.orders.find(ord => {
            const numberMatches = ord.order_number === parseInt(number);

            return numberMatches;
        }),

        company: auth.user.company,
    }));

    if (!!fromGrid && isEmpty(order)) {
        const orderItems =
            fromGrid?.orderItems?.map(item => ({
                ...item,
                product: item.product ? item.product : item.ydk_product,
            })) || [];
        setOrder({ ...fromGrid, orderItems });
    }
    const { push: pushToRoute } = useHistory();

    const handleCreate = () => {
        pushToRoute("/invoice/create");
        dispatch(getOrderProductForInvoice(order.order_number, company.default_invoice_status));
    };

    const loadOrder = useCallback(() => {
        makePrivateApiCall({
            url: `/order/${number}`,
        })
            .then(res => {
                setLoading(false);
                if (res.data) {
                    const { modifiedOrderItems, order } = res.data;
                    if (modifiedOrderItems?.length) {
                        order.orderItems = modifiedOrderItems;
                    }
                    setOrder(order);
                    setError(null);
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
            });
    }, [number]);

    const saveChange = () => {
        dispatch(
            saveOrderBuilderOrder({
                ...order,
                [confirmChange.field]: confirmChange.newValue,
            })
        ).then(ord => {
            setOrder({ ...order, ...ord });
            setConfirmChange(null);
            loadOrder();
        });
    };

    // when a value change is requested, let's first make sure that the value is actually changing from the current value
    // if not, no point showing the modal window asking for confirmation, right?
    //
    const handleChangeRequest =
        field =>
        (e, { value }) => {
            const previousValue = order[field],
                newValue = value,
                changeData = { field, previousValue, newValue },
                changedValues = isChangeNeeded(changeData);

            if (!changedValues) return null;

            setConfirmChange(changeData);
        };

    useEffect(() => {
        if (number) {
            setLoading(true);
            loadOrder();
        }
    }, [number]);

    if (!isLoading && error && !order) {
        return <Message content={error} header="Sorry, something went wrong!" />;
    }
    return (
        <>
            <Grid className="order-view-page">
                <TopNav
                    headerIcon="newspaper"
                    subheader="Details of your order"
                    headerContent={`Order #${number}`}
                    extraButtons={() => (
                        <>
                            <Menu.Item
                                name="All Orders"
                                icon="chevron left"
                                to="/order"
                                as={Link}
                            />
                            {order.status === "placed" && (
                                <Menu.Item
                                    name="Cancel"
                                    content="Cancel"
                                    icon="close left"
                                    value="cancelled"
                                    onClick={handleChangeRequest("status")}
                                />
                            )}
                        </>
                    )}
                />

                <Confirm
                    size="tiny"
                    open={!!confirmChange}
                    onConfirm={saveChange}
                    confirmButton="Yes, Change It"
                    onCancel={() => setConfirmChange(null)}
                    content={!!confirmChange && <ConfirmContent {...confirmChange} />}
                />

                <Grid.Column mobile={16}>
                    <OrderComponentView
                        order={order}
                        company={company}
                        isLoading={isLoading || isSaving}
                        // onStatusChange={handleChangeRequest("status")}
                    />
                </Grid.Column>
                {order.status === "placed" && (
                    <Grid.Column mobile={16}>
                        <Button
                            data-cy="create_order_invoice"
                            size="small"
                            icon="plus"
                            floated="right"
                            color="green"
                            content="Create Invoice"
                            onClick={handleCreate}
                        />
                    </Grid.Column>
                )}
                <Grid.Column mobile={16}>
                    <OrderNotesComponent order={order} />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default OrderPageView;
