import React from "react";
import { capitalize } from "lodash";
import { Button, Input, Form, Dropdown } from "formik-semantic-ui";

import { SemanticColors } from "../../../common/helpers";

export const ProductPriceTierCreatorComponent = ({ onCancel }) => {
    return (
        <>
            <Input name="name" label="Name" type="text" />
            <Form.Group>
                <Input name="percentage" label="Percentage of base price" type="number" />
                <Dropdown
                    name="color"
                    label="Percentage of base price"
                    options={SemanticColors.filter(color => color !== "green").map(color => ({
                        key: color,
                        value: color,
                        text: capitalize(color),
                        label: { color, circular: true, empty: true },
                    }))}
                />
            </Form.Group>
            <Button content="Cancel" onClick={onCancel} />
            <Button.Submit positive>Save Price Tier</Button.Submit>
        </>
    );
};
