import React, { useState } from "react";
import { Button, Checkbox, Confirm, Modal } from "semantic-ui-react";

export const InventoryGridCellEditConfirmationComponent = ({
    fieldName = "Qty",
    oldValue,
    newValue,
    isOpen = false,
    row = {},
    onCancel = () => null,
    onConfirm = () => null,
}) => {
    const [isQuickModeEnabled, setIsQuickModeEnabled] = useState(false);

    return (
        <Confirm
            open={isOpen}
            header={`Update ${fieldName}`}
            cancelButton="Cancel"
            onConfirm={() => onConfirm({ isQuickModeEnabled })}
            content={() => (
                <Modal.Content>
                    Changing value of <b>{fieldName}</b> from <b>{oldValue}</b> to <b>{newValue}</b>{" "}
                    for {row?.product?.product}
                    <br />
                    <br />
                    <p>
                        If you want to adjust inventory without a confirmation step, please check
                        the box below and enable quick edit more.
                    </p>
                    <Checkbox
                        label="Enable quick edit mode"
                        checked={isQuickModeEnabled}
                        onChange={(e, data) => setIsQuickModeEnabled(data.checked)}
                    />
                </Modal.Content>
            )}
            confirmButton={<Button content={`Update ${fieldName}`} />}
            onCancel={onCancel}
        />
    );
};
