import React from "react";
import { connect } from "react-redux";
import { isEqual, isEmpty } from "lodash";
import { bindActionCreators } from "redux";
import { AgGridReact } from "ag-grid-react";
import { Header, Button } from "semantic-ui-react";
import csvDownload from "json-to-csv-export";
import { toast } from "react-semantic-toasts";

import {
    exportInventory,
    updateInventoryItem,
    loadInventoryGridPage,
    changeInventoryGridPage,
    changeInventoryGridState,
    setInventoryGridCellEditConfirmation,
} from "./actions";
import { ProductLinkRenderer } from "./cellRenderers.component";
import GridPageSizePicker from "../../common/components/grid-page-size-picker";
import { format } from "date-fns";
import { InventoryGridQuickEditModeWarningComponent } from "./quick-edit-mode-warning.component";
import { InventoryGridCellEditConfirmationComponent } from "./cell-edit-confirmation.component";

const NoInventoriesOverlay = () => {
    return <Header>You have not created any inventory yet.</Header>;
};

const frameworkComponents = {
    productLinkRenderer: ProductLinkRenderer,
    noInventoriesOverlay: NoInventoriesOverlay,
};

class DataGrid extends React.Component {
    state = {
        isExporting: false,
        confirmEdit: null,
    };

    grid = null;
    gridDataSource = {
        getRows: params => {
            this.props.loadInventoryGridPage(this.props, params);
        },
    };

    componentWillUnmount() {
        this.grid = null;
    }

    onGridReady = grid => {
        this.grid = grid;

        this.grid.api.sizeColumnsToFit();

        // this.grid.api.paginationGoToPage(this.props.currentPage);
        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.setDatasource(this.gridDataSource);

        this.grid.api.addEventListener("sortChanged", () => {
            this.props.changeInventoryGridState({
                sortModel: this.grid.api.getSortModel(),
            });
        });

        this.grid.api.addEventListener("filterChanged", () => {
            this.props.changeInventoryGridState({
                filterModel: this.grid.api.getFilterModel(),
            });
        });
    };

    reloadGridData = () => {
        this.grid && this.grid.api.refreshInfiniteCache();
    };

    changePage = () => {
        if (this.grid) {
            const page = this.grid.api.paginationGetCurrentPage() + 1;

            if (page === this.props.currentPage) return;

            this.props.changeInventoryGridPage(page);
        }
    };

    componentDidUpdate(prevProps) {
        if (!this.grid) return;

        if (prevProps.isLoading && !this.props.isLoading) {
            this.grid.api.hideOverlay();
        }

        if (!prevProps.isLoading && this.props.isLoading) {
            this.grid.api.showLoadingOverlay();
        }

        if (!this.props.isLoading && this.props.inventories.length < 1) {
            this.grid.api.showNoRowsOverlay();
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.grid.api.paginationSetPageSize(this.props.pageSize);
        }

        if (!isEqual(prevProps.filterModel, this.props.filterModel)) {
            this.grid.api.setFilterModel(this.props.filterModel);
        }
    }

    export = () => {
        this.setState({ isExporting: true });
        this.props
            .exportInventory(this.props)
            .then(inventoryEntries => {
                csvDownload(inventoryEntries, `inventory_${format(new Date(), "yyy_MM_dd")}.csv`);
            })
            .catch(err => {
                toast({
                    time: 30000,
                    type: "error",
                    icon: "warehouse",
                    title: "Error exporting inventory",
                    list: [`Failed to export inventory data!`, `Details: ${err.message}.`],
                });
            })
            .finally(() => this.setState({ isExporting: false }));
    };

    async handleCellValueChanged(e) {
        // Don't trigger the popup is data is same
        if (`${e.oldValue}` === `${e.newValue}`) return;

        try {
            this.validateCellValue(e.colDef.field, e.newValue);
        } catch (err) {
            return this.resetCellValue(e.node.id, e.colDef.field, e.oldValue);
        }

        // if quick edit mode is disabled, show the confirmation popup which will take care of saving the value
        if (this.props.showCellEditConfirmation) {
            this.setState({
                confirmEdit: {
                    fieldName: e.colDef.headerName,
                    field: e.colDef.field,
                    newValue: e.newValue,
                    oldValue: e.oldValue,
                    // Used to update the cell value if something goes wrong
                    nodeId: e.node.id,
                    row: e.data,
                },
            });
            return;
        }

        try {
            await this.props.updateInventoryItem(e.data.id, e.colDef.field, e.newValue);
        } catch (err) {
            this.resetCellValue(e.node.id, e.colDef.field, e.oldValue);
        }
    }

    validateCellValue(field, newValue) {
        if (field !== "distributor" && Number.isNaN(Number(newValue))) {
            toast({
                type: "error",
                title: "Invalid value",
                description: `${field} must be a number`,
            });
            throw new Error("Value must be a number");
        }
    }

    resetCellValue(nodeId, field, oldValue) {
        // Quickest way to reset the value of a row in the grid, causing it to refresh
        // This helps us reset the value of the cell to the original value if edit fails
        // We need this because ag-grid resets the value as soon as input in cell is saved
        if (!nodeId) return;

        this.grid.api.forEachNode(node => {
            if (node.id === nodeId) node.setData({ ...node.data, [field]: oldValue });
        });
    }

    render() {
        return (
            <>
                <InventoryGridCellEditConfirmationComponent
                    isOpen={!!this.state.confirmEdit}
                    onConfirm={async ({ isQuickModeEnabled = false }) => {
                        const { row, field, newValue } = this.state.confirmEdit || {};
                        if (isQuickModeEnabled) {
                            this.props.setInventoryGridCellEditConfirmation(false);
                        }
                        try {
                            await this.props.updateInventoryItem(row.id, field, newValue);
                        } catch (err) {
                            const { nodeId, field, oldValue } = this.state.confirmEdit || {};
                            this.resetCellValue(nodeId, field, oldValue);
                        }
                        this.setState({ confirmEdit: null });
                    }}
                    onCancel={() => {
                        const { nodeId, field, oldValue } = this.state.confirmEdit || {};
                        this.resetCellValue(nodeId, field, oldValue);
                        this.setState({ confirmEdit: null });
                    }}
                    {...this.state.confirmEdit}
                />
                <InventoryGridQuickEditModeWarningComponent />
                <div className="clear bm-10">
                    <GridPageSizePicker
                        button
                        compact
                        pointing="top"
                        pageSize={this.props.pageSize}
                        className="right floated basic"
                        text={`${this.props.pageSize}/page`}
                        onChange={this.props.changeInventoryGridState}
                    />

                    {!isEmpty(this.props.filterModel) && (
                        <Button
                            size="mini"
                            icon="filter"
                            floated="right"
                            content="Clear Filters"
                            onClick={() => this.grid.api.setFilterModel({})}
                        />
                    )}

                    <Button size="mini" floated="right" content="Export" onClick={this.export} />
                </div>
                <div className="ag-theme-balham bm-10 clear inventory-grid">
                    <AgGridReact
                        pagination
                        rowHeight={50}
                        reactNext={true}
                        floatingFilter={true}
                        rowSelection="single"
                        domLayout="autoHeight"
                        rowModelType="infinite"
                        onGridReady={this.onGridReady}
                        columnDefs={this.props.columnDefs}
                        cacheBlockSize={this.props.pageSize}
                        onPaginationChanged={this.changePage}
                        frameworkComponents={frameworkComponents}
                        noRowsOverlayComponent="noInventoriesOverlay"
                        onCellValueChanged={this.handleCellValueChanged.bind(this)}
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            filter: true,
                            wrapHeaderText: true,
                            autoHeaderHeight: true,
                        }}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ inventoryGrid, auth }) => ({
    company: auth.user.company,

    pageSize: inventoryGrid.pageSize,
    isLoading: inventoryGrid.isLoading,
    sortModel: inventoryGrid.sortModel,
    columnDefs: inventoryGrid.columnDefs,
    filterModel: inventoryGrid.filterModel,
    currentPage: inventoryGrid.currentPage,
    inventories: inventoryGrid.inventories,
    showCellEditConfirmation: inventoryGrid.showCellEditConfirmation,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            exportInventory,
            updateInventoryItem,
            loadInventoryGridPage,
            changeInventoryGridState,
            setInventoryGridCellEditConfirmation,
            changeInventoryGridPage: page => dispatch(changeInventoryGridPage(page)),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DataGrid);
