import { isArray } from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-semantic-toasts";
import { Button, Message } from "semantic-ui-react";

import { toggleStampRollActive } from "./actions";

const StampRollActiveToggle = ({ stamp }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleClick = () => {
        setLoading(true);

        dispatch(toggleStampRollActive(stamp)).then(result => {
            setLoading(false);

            if (isArray(result)) {
                toast({
                    time: 6000,
                    type: "error",
                    icon: "warning sign",
                    title: "Error Updating Stamp Roll",
                    description: <Message.List items={result} />,
                });
            }
        });
    };

    let content, color;
    if (stamp.finished) {
        color = "grey";
        content = "Finished";
    } else if (stamp.active) {
        color = "green";
        content = "Active";
    } else {
        color = "red";
        content = "Inactive";
    }

    return (
        <Button
            basic
            size="mini"
            color={color}
            loading={loading}
            content={content}
            onClick={handleClick}
            disabled={stamp.finished}
        />
    );
};

export default StampRollActiveToggle;
