import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Message } from "semantic-ui-react";
import { setInventoryGridCellEditConfirmation } from "./actions";

export const InventoryGridQuickEditModeWarningComponent = () => {
    const showCellEditConfirmation = useSelector(
        ({ inventoryGrid }) => inventoryGrid.showCellEditConfirmation
    );
    const dispatch = useDispatch();

    if (showCellEditConfirmation) {
        return null;
    }

    return (
        <Message
            header="Quick edit mode enabled"
            content={
                <>
                    <p>
                        In quick edit mode, any change you make in the grid will be saved
                        immediately without any confirmation step. If you want to review your change
                        before saving, please disable quick edit mode first.
                    </p>
                    <Button
                        size="tiny"
                        color="orange"
                        content="Disable Quick Edit"
                        onClick={() => dispatch(setInventoryGridCellEditConfirmation(true))}
                    />
                </>
            }
        />
    );
};
