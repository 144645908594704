"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTotalUnits = exports.getTotalAmount = exports.getOrderTotalUnits = exports.getOrderTotalAmount = exports.getFilteredPriceTotal = exports.formatAmount = exports.convertObjectToPlainArray = void 0;

const convertObjectToPlainArray = obj => Object.keys(obj).map(key => obj[key]);

exports.convertObjectToPlainArray = convertObjectToPlainArray;

const getTotalAmount = productArray => productArray.map(item => item.quantity * item.last_invoiced_price || item.product_price).reduce((acc, val) => acc + val, 0).toFixed(2);

exports.getTotalAmount = getTotalAmount;

const getTotalUnits = productArray => productArray.map(item => item.quantity).reduce((acc, val) => acc + val, 0);

exports.getTotalUnits = getTotalUnits;

const getOrderTotalAmount = productArray => productArray.map(item => parseInt(item.qty) * item.price).reduce((acc, val) => acc + val, 0).toFixed(2);

exports.getOrderTotalAmount = getOrderTotalAmount;

const getOrderTotalUnits = (productArray = []) => {
  return productArray.map(item => item.new_qty > 0 ? parseInt(item.new_qty) : parseInt(item.qty)).reduce((acc, val) => acc + val, 0);
};

exports.getOrderTotalUnits = getOrderTotalUnits;
const moneyFormatter = new Intl.NumberFormat("en-US", {
  currency: "USD",
  style: "currency",
  minimumFractionDigits: 2
});

const getFilteredPriceTotal = productArray => {
  const totalPrice = productArray.map(item => parseInt(item.total)).reduce((acc, val) => acc + val, 0).toFixed(2);
  return moneyFormatter.format(totalPrice);
};

exports.getFilteredPriceTotal = getFilteredPriceTotal;

const formatAmount = amount => moneyFormatter.format(amount);

exports.formatAmount = formatAmount;