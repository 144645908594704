import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";

import TopNav from "../../common/containers/top-nav";
import AllCustomerUsersContainerGrid from "../containers/all-users-grid";
import SingleUserComponent from "../components/single-user";

const AllUsersHomePage = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const subHeader = "List of all customer users of the company";
    const { company, customerUserList } = useSelector(({ companyEditor, customerUserList }) => ({
        company: companyEditor,
        customerUserList: customerUserList,
    }));
    const customerData = customerUserList.customerData;
    return (
        <Grid>
            <TopNav headerIcon="users" headerContent="Customer Users" subheader={subHeader} />

            <Grid.Column mobile={16} tablet={16} computer={12}>
                <AllCustomerUsersContainerGrid onRowSelect={data => setSelectedUser(data)} />
            </Grid.Column>

            {!!selectedUser && (
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <SingleUserComponent
                        user={selectedUser}
                        setSelectedUser={setSelectedUser}
                        company={company}
                        customerData={customerData}
                    />
                </Grid.Column>
            )}
        </Grid>
    );
};

export default AllUsersHomePage;
