import { CUSTOMER_USER_GRID_STATE_CHANGE, ALL_CUSTOMER_USER_GRID_STATE_CHANGE } from "../constants";

export const changeCustomerUserGridState = gridState => dispatch =>
    dispatch({
        type: CUSTOMER_USER_GRID_STATE_CHANGE,
        payload: gridState,
    });

export const changeAllCustomerUserGridState = gridState => dispatch =>
    dispatch({
        type: ALL_CUSTOMER_USER_GRID_STATE_CHANGE,
        payload: gridState,
    });
