import Joi from "joi";

const schema = {
    product: Joi.string().trim().uppercase().required(),
    product_size: Joi.string().trim().label("product size").required(),
    upc: Joi.string().trim().allow("").optional(),
    internal_sku: Joi.string().trim().label("internal sku").allow(null).allow("").optional(),
    last_purchase_price: Joi.number()
        .min(0)
        .label("purchased price")
        .allow(null)
        .allow("")
        .optional(),
    product_price: Joi.number().min(0.01).label("product price").required(),
    product_type: Joi.string().trim().uppercase().label("product type").required(),
    distributor: Joi.string().trim().empty().allow(null).allow("").optional(),
    reorder_level: Joi.number().empty().allow(null).allow("").optional(),
    reorder_qty: Joi.number().empty().allow(null).allow("").optional(),
    reorder_time_in_days: Joi.number().allow(null).empty().allow("").optional(),
};

const updateSchema = {
    ...schema,
    id: Joi.number().min(0),
    ydk_product_id: Joi.number().required(),
    effective_date: Joi.alternatives(Joi.string(), Joi.date()).optional(),
};

export const validateProductUpdate = product => {
    return Joi.object(updateSchema).validateAsync(product, {
        convert: true,
        stripUnknown: true,
    });
};

export const validateProductCreate = product => {
    return Joi.object(schema).validateAsync(product, {
        convert: true,
        stripUnknown: true,
    });
};
