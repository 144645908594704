import React, { useState } from "react";
import { toast } from "react-semantic-toasts";
import { Button, Header, Icon, Modal, Form } from "semantic-ui-react";
import { useDispatch } from "react-redux";

import { sendInvitationEmail } from "../actions/list";
import { ValidateInvitationSchema } from "./validateInvitation";
import config from "utilities/dist/config";
import { copyInvitationLink } from "../helpers";

function SendUserInvitationModal({
    company,
    showInvitationalModal,
    setShowInvitationalModal,
    customer,
}) {
    const [values, setValues] = useState({
        name: "",
        email: "",
        tabc_id: customer?.tabc_id,
    });
    const [error, setError] = useState("");
    const dispatch = useDispatch();

    const handleChange = name => e => {
        setValues({
            ...values,
            [name]: e.target.value,
        });
    };

    const sendInvitation = async () => {
        try {
            await ValidateInvitationSchema.validateAsync(values);
            await dispatch(sendInvitationEmail({ values, customer }));
            setShowInvitationalModal(false);
            setError("");
            setValues({});
            toast({
                description: `You have invited sucessfully`,
                time: 6000,
                type: "success",
                icon: "checkmark",
                title: "Invited",
            });
        } catch (err) {
            if (err?.isJoi) {
                setError(err.message);
            } else {
                setError(err.response.data.message);
            }
        }
    };
    const closeModal = () => {
        setShowInvitationalModal(false);
        setError("");
    };
    const copyInvitation = () =>
        copyInvitationLink({
            subDomain: company?.inputs?.subdomain,
            tabcId: customer?.tabc_id,
            domain: config.client_host,
            ...values,
        });

    return (
        <Modal closeIcon open={showInvitationalModal} onClose={() => closeModal()}>
            <Header icon="send" content="Send Invitation" />

            <Modal.Content>
                <Form onSubmit={sendInvitation}>
                    <Form.Field>
                        <label>Name</label>
                        <input
                            type="text"
                            name="name"
                            onChange={handleChange("name")}
                            placeholder="Enter user's name"
                            data-cy="input_name"
                        />
                        {error && error?.includes("name") ? (
                            <p className="text-red">{error}</p>
                        ) : (
                            ""
                        )}
                    </Form.Field>
                    <Form.Field>
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            onChange={handleChange("email")}
                            placeholder="Enter email address"
                            data-cy="input_email"
                        />
                        {(error && error?.includes("email" || "Email")) ||
                        error?.includes("registered") ? (
                            <p className="text-red">{error}</p>
                        ) : (
                            ""
                        )}
                    </Form.Field>
                    <div className="text-right">
                        <Button color="gray" onClick={() => closeModal()}>
                            <Icon name="remove" /> Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button data-cy="send_invitation" positive type="submit">
                            <Icon name="checkmark" /> Invite
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                            data-cy="copy_invitation"
                            color="blue"
                            onClick={() => copyInvitation()}
                        >
                            <Icon name="copy" /> Copy Invite Link
                        </Button>
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <div className="text-left">
                    <Header color="blue" size="small" content="Please note" />
                    <p>
                        Inviting the user will send a link that will forword the user to Company's
                        Signup form
                        <br />
                        This may share customer's TABC ID with them.
                    </p>
                </div>
            </Modal.Actions>
        </Modal>
    );
}

export default SendUserInvitationModal;
