import React from "react";
import { Header } from "semantic-ui-react";
import { format } from "date-fns";
import { DateTimeFormat } from "utilities/dist/invoice/constants";

const UserOrderInfo = ({ order }) => {
    const ordered_at = order?.ordered_at;
    return (
        <Header textAlign="right">
            <Header.Subheader className="no-print">
                Order Placed by {order.customerUser?.name}
                <br />
                {format(new Date(ordered_at), DateTimeFormat)}
            </Header.Subheader>
        </Header>
    );
};

export default UserOrderInfo;
