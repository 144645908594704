import { get } from "lodash";
import { parse } from "date-fns";
import { toast } from "react-semantic-toasts";

import {
    PRODUCT_EDITOR_INPUT,
    PRODUCT_EDITOR_ERROR,
    PRODUCT_EDITOR_SAVING,
    PRODUCT_EDITOR_SAVED,
    PRODUCT_EDITOR_PRODUCT_DATA,
    PRODUCT_EDITOR_PRODUCT_RESET,
    PRODUCT_EDITOR_MODAL_TOGGLE,
    PRODUCT_EDITOR_ARCHIVE_LOADING,
    PRODUCT_EDITOR_ARCHIVE_SUCCESS,
    PRODUCT_EDITOR_ARCHIVE_ERROR,
    PRODUCT_EDITOR_DELETE_LOADING,
    PRODUCT_EDITOR_DELETE_SUCCESS,
    PRODUCT_EDITOR_DELETE_ERROR,
    PRODUCT_EDITOR_DISTRIBUTOR_LIST_LOADING,
    PRODUCT_EDITOR_DISTRIBUTOR_LIST_SUCCESS,
    PRODUCT_EDITOR_DISTRIBUTOR_LIST_ERROR,
    PRODUCT_EDITOR_DISTRIBUTOR_LIST_ADD,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";
import { DateFormat } from "utilities/dist/invoice/constants";
import { trackProductCreateEvent } from "../../common/analytics";

export const saveProduct = (product = {}, customerId = {}) => {
    return dispatch => {
        trackProductCreateEvent(product, {});
        dispatch({
            type: PRODUCT_EDITOR_SAVING,
            payload: true,
        });

        if (product.effective_date) {
            product.effective_date = parse(product.effective_date, DateFormat, new Date());
        }
        const request = {
            url: `/product`,
            method: "post",
            data: product,
        };

        if (product.id) {
            request.url = `/product/${product.id}`;
            request.method = "put";
            request.data = { ...product, customerId };
        }

        return makePrivateApiCall(request)
            .then(res => {
                trackProductCreateEvent(product, { success: true });
                if (res.data) {
                    dispatch({ type: PRODUCT_EDITOR_SAVED, payload: res.data });
                }

                return res.data;
            })
            .catch(err => {
                trackProductCreateEvent(product, { error: true });
                const details = get(err, "response.data.details", []);
                if (details.length) {
                    dispatch(setProductEditorError(details.map(errDet => errDet?.message)));
                    return;
                }
                const message = get(err, "response.data.message", null) || err.message;
                dispatch(setProductEditorError([message]));
            });
    };
};

export const setProductEditorInput =
    (inputs = {}) =>
    dispatch => {
        return dispatch({
            type: PRODUCT_EDITOR_INPUT,
            payload: inputs,
        });
    };

export const setProductEditorError = error => dispatch => {
    return dispatch({
        type: PRODUCT_EDITOR_ERROR,
        payload: error,
    });
};

export const loadProductForEdit = product => ({
    type: PRODUCT_EDITOR_PRODUCT_DATA,
    payload: product,
});

export const resetEditingProduct = () => dispatch =>
    dispatch({
        type: PRODUCT_EDITOR_PRODUCT_RESET,
    });

export const showProductEditorModal = payload => ({
    type: PRODUCT_EDITOR_MODAL_TOGGLE,
    payload,
});

export const archiveProduct = product => dispatch => {
    dispatch({
        type: PRODUCT_EDITOR_ARCHIVE_LOADING,
    });

    return makePrivateApiCall({
        method: "put",
        url: `product/${product.id}`,
        data: { ...product, archived: !product.archived },
    })
        .then(res => {
            dispatch({
                type: PRODUCT_EDITOR_ARCHIVE_SUCCESS,
            });

            if (res.data) {
                dispatch({ type: PRODUCT_EDITOR_SAVED, payload: res.data });
            }

            return res.data;
        })
        .catch(err => {
            const message = get(err, "response.data.message", null) || err.message;
            toast({
                time: 6000,
                type: "error",
                icon: "warning sign",
                description: message,
                title: "Did not archive product",
            });
            dispatch({
                type: PRODUCT_EDITOR_ARCHIVE_ERROR,
                payload: message,
            });
            return false;
        });
};

export const deleteProduct = productId => dispatch => {
    dispatch({
        type: PRODUCT_EDITOR_DELETE_LOADING,
    });

    return makePrivateApiCall({
        url: `product/${productId}`,
        method: "delete",
    })
        .then(() => {
            toast({
                time: 6000,
                icon: "trash",
                type: "success",
                title: "Product Removed",
                description: "Successfully removed product",
            });
            dispatch({
                type: PRODUCT_EDITOR_DELETE_SUCCESS,
            });

            return true;
        })
        .catch(err => {
            const message = get(err, "response.data.message", null) || err.message;
            toast({
                time: 6000,
                type: "error",
                icon: "warning sign",
                description: message,
                title: "Did not remove product",
            });
            dispatch({
                type: PRODUCT_EDITOR_DELETE_ERROR,
                payload: message,
            });

            return false;
        });
};

export const loadDistributorList = () => dispatch => {
    dispatch({
        type: PRODUCT_EDITOR_DISTRIBUTOR_LIST_LOADING,
    });

    makePrivateApiCall({
        url: `inventory/distributors`,
    })
        .then(res => {
            dispatch({
                type: PRODUCT_EDITOR_DISTRIBUTOR_LIST_SUCCESS,
                payload: res.data,
            });
        })
        .catch(err => {
            const message = get(err, "response.data.message", null) || err.message;
            dispatch({
                type: PRODUCT_EDITOR_DISTRIBUTOR_LIST_ERROR,
                payload: message,
            });
        });
};

export const addCustomDistributor = (distributor) => dispatch => dispatch({
    type: PRODUCT_EDITOR_DISTRIBUTOR_LIST_ADD,
    payload: distributor,
})
