import { APP_SIDE_NAV_VIEW_CHANGE, APP_SIDE_NAV_MINIMIZE, APP_SIDE_NAV_TOGGLE } from "../constants";

export const toggleSideNav = () => dispatch =>
    dispatch({
        type: APP_SIDE_NAV_TOGGLE,
    });

export const minimizeSideNav = () => dispatch =>
    dispatch({
        type: APP_SIDE_NAV_MINIMIZE,
        payload: true,
    });

export const maximizeSideNav = () => dispatch =>
    dispatch({
        type: APP_SIDE_NAV_MINIMIZE,
        payload: false,
    });

export const changeSideNavView = view => ({
    type: APP_SIDE_NAV_VIEW_CHANGE,
    payload: view,
});
