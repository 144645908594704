import pluralize from "pluralize";
import React from "react";
import { Header, Segment, List, Icon, Button, Label } from "semantic-ui-react";

export const ProductPriceTierListComponent = ({
    priceTiers = [],
    onAddNewTier,
    customerCountByPriceTier = {},
    onRemoveClick,
    onEditClick,
}) => {
    const noTiers = priceTiers.length < 1;

    if (noTiers) {
        return (
            <Segment placeholder>
                <Header icon>
                    <Icon name="percent" />
                    No pricing tiers configured
                </Header>
                <Button positive primary content="Create New Tier" onClick={onAddNewTier} />
            </Segment>
        );
    }

    return (
        <>
            <Segment.Group>
                <Header
                    attached="top"
                    as="h3"
                    content="Price Tiers"
                    subheader={"View and manage your pricing tiers"}
                />
                <Segment attached>
                    <List relaxed="very" divided>
                        {priceTiers.map(priceTier => {
                            const customerCount = customerCountByPriceTier?.[priceTier.id];
                            const descriptions = [
                                priceTier.is_base_tier
                                    ? `Default price (Pricing as entered in pricing panel)`
                                    : `${priceTier.percentage}% of base price`,
                            ];

                            descriptions.push(
                                customerCount
                                    ? pluralize("Customer", customerCount, true)
                                    : `No customer on this tier`
                            );

                            return (
                                <List.Item key={`price_tier_${priceTier.id}`}>
                                    <List.Content floated="right">
                                        {priceTier.is_base_tier ? (
                                            <Label color="green">Base</Label>
                                        ) : (
                                            <Button.Group size="mini">
                                                <Button
                                                    icon="edit"
                                                    onClick={() => onEditClick(priceTier)}
                                                />
                                                {priceTiers?.length > 1 && (
                                                    <Button
                                                        icon="trash"
                                                        onClick={() => onRemoveClick(priceTier.id)}
                                                    />
                                                )}
                                            </Button.Group>
                                        )}
                                    </List.Content>
                                    <List.Content>
                                        <List.Header>
                                            {priceTier.name}{" "}
                                            {priceTier.color && (
                                                <Label empty circular color={priceTier.color} />
                                            )}
                                        </List.Header>
                                        <List.Description>
                                            {descriptions.join(" | ")}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            );
                        })}
                    </List>
                </Segment>
                <Segment>
                    <Button icon="plus" primary content="Add New Tier" onClick={onAddNewTier} />
                </Segment>
            </Segment.Group>
        </>
    );
};
