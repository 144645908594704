import React from "react";
import { capitalize, omit, pick } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Confirm, Modal, Table } from "semantic-ui-react";

import { requestSaveCustomer, saveCustomer } from "../actions";
import { isCompanyFeatureActive } from "utilities/dist/company/helpers";
import { TieredPricing } from "../../company/feature/helpers";

const parseValue = (key, value) => {
    if (key === "cash_on_delivery") return value ? "Yes" : "No";
    if (key === "fintech_option") return value ? "Yes" : "No";

    return value;
};

const Content = ({ fields }) => {
    const displayFields = omit(fields, ["ydk_est_id", "id", "product_price_tier_id"]);

    return (
        <Modal.Content>
            Please verify that you want to <b>{fields.id ? "Update" : "Create a new"}</b> customer
            with the following data
            <br />
            <Table>
                <Table.Body>
                    {Object.keys(displayFields).map(key => (
                        <Table.Row key={key}>
                            <Table.Cell>{capitalize(key.replace(/_/gi, " "))}</Table.Cell>
                            <Table.Cell>{parseValue(key, displayFields[key])}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Modal.Content>
    );
};

const CustomerContainerCreateConfirm = ({ onClose }) => {
    const { isConfirming, isSaving, inputs, productPriceTiers, hasTieredPricingEnabled } =
        useSelector(({ customerEditor, auth }) => ({
            ...pick(customerEditor, ["isConfirming", "isSaving", "inputs"]),
            productPriceTiers: auth.user.company.productPriceTiers,
            hasTieredPricingEnabled: isCompanyFeatureActive(auth.user.company, TieredPricing.id),
        }));

    const dispatch = useDispatch();
    const handleConfirm = () => {
        dispatch(saveCustomer(inputs, onClose));
    };
    const handleCancel = () => {
        dispatch(requestSaveCustomer(false));
        onClose(null);
    };

    const displayFields = { ...inputs };

    if (hasTieredPricingEnabled) {
        displayFields.price_tier = productPriceTiers?.find(
            tier => tier.id === inputs.product_price_tier_id
        )?.name;
    }

    return (
        <Confirm
            size="tiny"
            open={isConfirming}
            header="Save Customer"
            onCancel={handleCancel}
            cancelButton="Nevermind"
            onConfirm={handleConfirm}
            content={<Content fields={displayFields} />}
            confirmButton={{ content: "Save", loading: isSaving }}
        />
    );
};

export default CustomerContainerCreateConfirm;
