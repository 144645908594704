import {
    APP_CACHE_RESET_TIMESTAMP,
    APP_SET_CACHE_RESET_TIMESTAMP,
    APP_VERSION_CHANGE,
} from "../constants";
import { formatCacheTimestamp } from "../helpers";

export const updateToCurrentVersion = version => ({
    type: APP_VERSION_CHANGE,
    payload: version,
});

export const setCacheTimestamp = () => dispatch => {
    const date = formatCacheTimestamp();
    localStorage.setItem(APP_CACHE_RESET_TIMESTAMP, date);
    dispatch({
        type: APP_SET_CACHE_RESET_TIMESTAMP,
        payload: date,
    });
};
