import { useDispatch } from "react-redux";
import { useCallback, useState } from "react";
import { get, isEmpty, debounce, omit } from "lodash";
import { loadCustomerAutoFillSuggestions } from "../services/helpers";
import { setCustomerEditorInput, setLoadingCustomerForEdit } from "../actions";

export const useCustomerAutoFillFinder = () => {
    const dispatch = useDispatch();
    const [query, setQuery] = useState("");
    const [error, setError] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const loadSuggestions = useCallback(query => {
        setLoading(true);

        loadCustomerAutoFillSuggestions(query)
            .then(res => {
                setCustomers(res.data);
                setLoading(false);
                setError(null);
            })
            .catch(err => {
                setLoading(false);
                const message = get(err, "response.data.message", null) || err.message;
                setError(message);
            });
    }, []);
    const debouncedLoadSuggestion = useCallback(debounce(loadSuggestions, 300), []);

    const handleSearchChange = (e, { searchQuery }) => {
        setQuery(searchQuery);

        if (searchQuery.length > 2) {
            debouncedLoadSuggestion(searchQuery);
        }
    };

    const handleChange = (e, { value }) => {
        const customer = customers.find(cust => cust.establishment_id === value);

        if (!isEmpty(customer)) {
            const editorInputs = omit(customer, ["permitCodes"]);
            editorInputs.tabc_id = customer.permitCodes[0].permit_code;
            editorInputs.ydk_est_id = customer.permitCodes[0].establishment_id;
            // a bit hacky but it makes sure to trigger a rerender of the formik form with new data
            dispatch(setLoadingCustomerForEdit(true));
            dispatch(setCustomerEditorInput(editorInputs));
            setTimeout(dispatch, 100, setLoadingCustomerForEdit(false));
        }
    };

    return {
        error,
        query,
        isLoading,
        customers,
        handleChange,
        handleSearchChange,
    };
};
