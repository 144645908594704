import { isEmpty } from "lodash";
import { Dropdown } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { loadStampRoll } from "../actions";
import { formatStampRange } from "../../../common/helpers";

const StampRollContainerPicker = props => {
    const dispatch = useDispatch();
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);
    const { data, error, isLoading } = useSelector(({ stampRoll }) => stampRoll);

    useEffect(() => {
        // it's a heavy query, don't want to keep reloading if we have already attempted that once
        // and we usually have it in redux on first load
        if (isEmpty(data) && !alreadyLoaded) {
            dispatch(loadStampRoll());
            setAlreadyLoaded(true);
        }
    }, [alreadyLoaded, data, dispatch]);

    if (!isLoading && (isEmpty(data) || error)) {
        return null;
    }

    const options = data.map(roll => ({
        key: roll.id,
        value: roll.id,
        text: formatStampRange(roll),
    }));

    return (
        <>
            <Dropdown
                options={options}
                loading={isLoading}
                placeholder={`Select stamp roll...`}
                {...props}
            />
        </>
    );
};

export default StampRollContainerPicker;
