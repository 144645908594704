import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "formik-semantic-ui";

import { addCustomDistributor, loadDistributorList } from "./editor/actions";

export const DistributorSelectorComponent = ({
    onCustomInput,
    fieldProps = {},
    inputProps = {},
}) => {
    const { distributorList, isDistributorLoading, distributorListError } = useSelector(
        ({ productEditor }) => productEditor
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (!distributorList?.length && !isDistributorLoading) {
            dispatch(loadDistributorList());
        }
    }, [distributorList, isDistributorLoading]);

    return (
        <>
            <Dropdown
                fieldProps={fieldProps}
                inputProps={{
                    allowAdditions: !!onCustomInput,
                    onAddItem: !!onCustomInput
                        ? (_, { value }) => {
                              dispatch(addCustomDistributor(value));
                              onCustomInput(value);
                          }
                        : () => null,
                    search: true,
                    selection: true,
                    error: distributorListError,
                    loading: isDistributorLoading,
                    ...inputProps,
                }}
                width={6}
                loading={isDistributorLoading}
                name="distributor"
                label="Distributor"
                placeholder="Select Distributor"
                options={distributorList?.map(({ distributor }) => ({
                    key: distributor,
                    value: distributor,
                    text: distributor,
                }))}
            />
        </>
    );
};
