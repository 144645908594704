import React from "react";
import { Button, Label, Message } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import { uploadCompanyLogo } from "./actions";

const CompanyLogoUploaderContainer = () => {
    let hiddenInput = null;
    const dispatch = useDispatch();
    const { uploadError, uploadingDocument } = useSelector(({ companyLogo }) => companyLogo);

    return (
        <>
            <Message
                success
                header="Logo Tip"
                content="Please make sure your logo is 200x100 px (or at least 2:1 in aspect ratio) for printed invoices."
            />
            <Button
                icon="upload"
                className="fluid"
                labelPosition="right"
                loading={!!uploadingDocument}
                onClick={() => hiddenInput.click()}
                label={
                    <Label
                        basic
                        as="label"
                        className="fluid clickable"
                        children={uploadingDocument ? "Uploading..." : "Upload new logo"}
                    />
                }
            />
            <input
                hidden
                type="file"
                ref={el => (hiddenInput = el)}
                onChange={e => dispatch(uploadCompanyLogo(e.target.files[0]))}
            />

            {!!uploadError && <Message error content={uploadError} header="Error Uploading Logo" />}
        </>
    );
};

export default CompanyLogoUploaderContainer;
