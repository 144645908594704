import { isEmpty } from "lodash";
import { parseServerTimestampForClient } from "../common/helpers";

export const transformProductToItem = (
    product,
    { customerPriceTier = null, isTieredPricingActive = false }
) => {
    let price = product.product_price,
        qty = 1,
        timestamp;
    let taxable = false;

    // For tiered pricing, use a different flow entirely
    if (isTieredPricingActive) {
        if (customerPriceTier) {
            price += Number((customerPriceTier.percentage / 100) * price);
        }
    } else {
        const priceChanges = [];
        // if the price has been changed after the last time it was invoiced, use the new pricing regardless of the last invoiced price
        if (!isEmpty(product.priceChanges)) {
            priceChanges.push(...product.priceChanges);
        }

        // if the product has been invoiced before for the current customer, use the pricing from the last invoice
        if (product.lastInvoiceItemForCustomer) {
            qty = product.lastInvoiceItemForCustomer.qty;
            taxable = product.lastInvoiceItemForCustomer.taxable;
            timestamp = product.lastInvoiceItemForCustomer.invoiced_at;

            priceChanges.push({
                ...product.lastInvoiceItemForCustomer,
                effective_date: product.lastInvoiceItemForCustomer.created_at,
            });
        }

        if (priceChanges.length > 0) {
            const lastPriceChange = getUpdatedDateFromArrayOfObj(priceChanges, "effective_date");
            price = lastPriceChange.new_price || lastPriceChange.price;
        }
    }

    // if product is new to store, it won't have an id and when that happens, we set the price to 0 so that the user sets their own price
    if (!product.id) {
        price = 0;
    }

    return { ...product, price, qty, timestamp, taxable };
};

export const buildProductIdKey = (prod, ydkProduct = null) => {
    if (ydkProduct) {
        return prod.id ? `product_${prod.id}` : `source_${prod.product_id}`;
    } else {
        return !prod.id ? `source_${prod.product_id}` : `product_${prod.id}`;
    }
};
/**
 * Given an array of objects with a date property, returns the object with the latest date
 * @param array {Array}  Array of objects
 * @param field {String} Name of the date property
 * @return {*}
 */
export const getUpdatedDateFromArrayOfObj = (array, field) => {
    const newArray = [...array];
    newArray.sort((a, b) => {
        return (
            new Date(parseServerTimestampForClient(b[field])) -
            new Date(parseServerTimestampForClient(a[field]))
        );
    });
    return newArray[0];
};
