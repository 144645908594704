import { keyBy } from "lodash";
import { format } from "date-fns";

import { DateTimeFormat } from "utilities/dist/invoice/constants";
import { formatAmount } from "utilities/dist/helpers";
import { formatItemWithUnits } from "../helpers";

export const allGridColumns = keyBy(
    [
        {
            filter: false,
            sortable: false,
            field: "history",
            headerName: "History",
            cellRenderer: "OrderHistoryRenderer",
        },
        {
            minWidth: 25,
            field: "status",
            headerName: "Status",
            filter: "agTextColumnFilter",
            // filterParams: { suppressMiniFilter: true }
            // filterParams: {
            //     filterOptions: ["contains", "startsWith", "endsWith"],
            //     defaultOption: "startsWith",
            // },
            valueGetter: function ({ data }) {
                return data ? data.status : null;
            },
            cellRenderer: "OrderStatusRenderer",
        },
        {
            minWidth: 25,
            field: "order_number",
            headerName: "Number",
            filter: "agNumberColumnFilter",
            valueGetter: function ({ data }) {
                return data ? parseInt(data.order_number) : null;
            },
            cellRenderer: "OrderLinkRenderer",
        },
        {
            field: "created_at",
            headerName: "Date",
            filter: "date",
            valueGetter: ({ data }) =>
                data && data.created_at ? format(new Date(data.created_at), DateTimeFormat) : null,
        },
        {
            minWidth: 260,
            field: "customer",
            headerName: "Customer",
            filter: "agTextColumnFilter",
            valueGetter: function ({ data }) {
                return data
                    ? data.customerUser?.customer?.name +
                          ", " +
                          data.customerUser?.customer?.address
                    : null;
            },
            cellRenderer: function ({ data }) {
                return data
                    ? data.customerUser?.customer?.name +
                          "<br/>" +
                          data.customerUser?.customer?.address
                    : null;
            },
        },
        {
            filter: false,
            field: "items",
            sortable: false,
            suppressMenu: true,
            headerName: "Items",
            valueGetter: formatItemWithUnits,
        },
        {
            field: "total",
            headerName: "Total",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            valueGetter: function ({ data }) {
                return data ? formatAmount(data.total) : null;
            },
        },

        {
            headerName: "Actions",
            cellRenderer: "orderComponentGridActions",
            filter: false,
            minWidth: 120,
        },
    ],
    "field"
);
