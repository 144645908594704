import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Grid, Header, Menu, Confirm, Dropdown, Icon } from "semantic-ui-react";

import { authSignout } from "../../auth/actions";
import { toggleSideNav, minimizeSideNav, maximizeSideNav } from "../actions";

class TopNav extends React.Component {
    state = {
        showSignoutConfirm: false,
    };

    showSignoutConfirm = () => this.setState({ showSignoutConfirm: true });
    handleSignoutCancel = () => this.setState({ showSignoutConfirm: false });

    handleSignoutConfirm = () => {
        this.setState({ showSignoutConfirm: false }, () => this.props.authSignout());
    };

    render() {
        return (
            <Grid.Row className="page-topbar" verticalAlign="middle">
                <Confirm
                    onConfirm={this.handleSignoutConfirm}
                    open={this.state.showSignoutConfirm}
                    onCancel={this.handleSignoutCancel}
                    content="Sure you want to logout?"
                    confirmButton="Log Me Out"
                    cancelButton="Nevermind"
                />

                <Grid.Column mobile={14} tablet={14} computer={8}>
                    <div className="hp-15">
                        <Header as="h3" onClick={this.props.toggleSideNav}>
                            <Icon name={this.props.headerIcon} />
                            <Header.Content>
                                {this.props.headerContent}
                                {/* This might come back to bite me in the ass but we need to hide it on print because users dont want to see it when they print their invoice page out */}
                                <Header.Subheader className="no-print">
                                    {this.props.subheader}
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </div>
                </Grid.Column>

                <Grid.Column only="mobile tablet" textAlign="right" mobile={2} className="no-print">
                    <Dropdown icon="bars" direction="left">
                        <Dropdown.Menu>
                            {this.props.extraButtons && this.props.extraButtons()}
                            <Menu.Item
                                onClick={this.showSignoutConfirm}
                                icon="sign out"
                                name="logout"
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </Grid.Column>

                <Grid.Column computer={8} only="computer" className="no-print">
                    <Menu secondary floated="right" className="tp-10">
                        {this.props.extraButtons && this.props.extraButtons()}
                        <Menu.Item
                            data-cy="logout"
                            onClick={this.showSignoutConfirm}
                            icon="sign out"
                            name="logout"
                        />
                    </Menu>
                </Grid.Column>
            </Grid.Row>
        );
    }
}

TopNav.propTypes = {
    headerContent: PropTypes.string.isRequired,
    headerIcon: PropTypes.string.isRequired,
    subheader: PropTypes.string.isRequired,
    authSignout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    extraButtons: PropTypes.func,
};

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            authSignout,
            toggleSideNav,
            minimizeSideNav,
            maximizeSideNav,
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNav));
