import React from "react";
import { connect } from "react-redux";
import { isEqual, isEmpty } from "lodash";
import { bindActionCreators } from "redux";
import { AgGridReact } from "ag-grid-react";
import format from "date-fns/format";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import { Header, Button, Dropdown, Icon } from "semantic-ui-react";

import { loadInvoiceGridPage, changeInvoiceGridPage, changeInvoiceGridState } from "./actions";
import {
    InvoiceActionsRenderer,
    InvoiceStatusRenderer,
    InvoiceLinkRenderer,
    InvoiceHistoryRenderer,
} from "./cellRenderers.component";
import GridPageSizePicker from "../../common/components/grid-page-size-picker";
import { DateFormat } from "utilities/dist/invoice/constants";
import { handleBottlePosJsonExport, handleExportInvoice, handleFintechCsvExport } from "../helpers";
import { CompanyFeatures } from "utilities/dist/company/constants";

const NoInvoicesOverlay = () => {
    return <Header>You have not created any invoice yet.</Header>;
};

const frameworkComponents = {
    invoiceActionsRenderer: InvoiceActionsRenderer,
    invoiceStatusRenderer: InvoiceStatusRenderer,
    invoiceLinkRenderer: InvoiceLinkRenderer,
    invoiceHistoryRenderer: InvoiceHistoryRenderer,
    noInvoicesOverlay: NoInvoicesOverlay,
};

class DataGrid extends React.Component {
    state = {
        dateRange: "",
    };

    grid = null;
    gridDataSource = {
        getRows: params => {
            this.props.loadInvoiceGridPage(this.props, params);
        },
    };

    handleDateRangeChange = (e, { value }) => {
        const filterModel = { ...this.props.filterModel };
        const [start, end] = value?.split(" - ");

        if (start && end && this.grid) {
            filterModel.invoiced_at = {
                type: "inRange",
                filterType: "date",
                dateTo: format(new Date(end), "yyyy-MM-dd"),
                dateFrom: format(new Date(start), "yyyy-MM-dd"),
            };
        } else if (filterModel.invoiced_at) {
            delete filterModel["invoiced_at"];
        }

        this.props.changeInvoiceGridState({ filterModel });
        this.setState({ dateRange: value });
    };

    componentWillUnmount() {
        this.grid = null;
    }

    onGridReady = grid => {
        this.grid = grid;

        this.grid.api.sizeColumnsToFit();

        // this.grid.api.paginationGoToPage(this.props.currentPage);
        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.setDatasource(this.gridDataSource);

        this.grid.api.addEventListener("sortChanged", () => {
            this.props.changeInvoiceGridState({
                sortModel: this.grid.api.getSortModel(),
            });
        });

        this.grid.api.addEventListener("filterChanged", () => {
            this.props.changeInvoiceGridState({
                filterModel: this.grid.api.getFilterModel(),
            });
        });
    };

    reloadGridData = () => {
        this.grid && this.grid.api.refreshInfiniteCache();
    };

    changePage = () => {
        if (this.grid) {
            const page = this.grid.api.paginationGetCurrentPage() + 1;

            if (page === this.props.currentPage) return;

            this.props.changeInvoiceGridPage(page);
        }
    };

    componentDidUpdate(prevProps) {
        if (!this.grid) return;

        if (prevProps.isLoading && !this.props.isLoading) {
            this.grid.api.hideOverlay();
        }

        if (!prevProps.isLoading && this.props.isLoading) {
            this.grid.api.showLoadingOverlay();
        }

        if (!this.props.isLoading && this.props.invoices.length < 1) {
            this.grid.api.showNoRowsOverlay();
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.grid.api.paginationSetPageSize(this.props.pageSize);
        }

        if (!isEqual(prevProps.filterModel, this.props.filterModel)) {
            this.grid.api.setFilterModel(this.props.filterModel);
        }
    }

    render() {
        const shouldShowExportTxt = !!this.props.invoices?.find(
            invoice => !!invoice.customer.fintech_option
        );
        const shouldShowExportBottlePos = !!this.props.company?.companyFeatures?.find(
            ({ name }) => name === CompanyFeatures.BOTTLE_POS
        );

        const options = [
            {
                key: 1,
                text: "Summary(.csv)",
                onClick: () => handleExportInvoice(this.props.invoices, "summary"),
            },
            {
                key: 2,
                text: "Detailed(.csv)",
                onClick: () => handleExportInvoice(this.props.invoices, "detailed"),
            },
        ];
        if (shouldShowExportTxt) {
            options.push({
                key: 3,
                text: "Fintech(.csv)",
                onClick: () =>
                    handleFintechCsvExport(this.props.invoices, this.props.company?.fintech_id),
            });
        }
        if (shouldShowExportBottlePos) {
            options.push({
                key: 4,
                text: "Bottle POS(.json)",
                onClick: () => 
                    handleBottlePosJsonExport(this.props.filterModel),
            });
        }

        return (
            <>
                <div className="clear bm-10">
                    <GridPageSizePicker
                        button
                        compact
                        pointing="top"
                        pageSize={this.props.pageSize}
                        className="right floated basic"
                        text={`${this.props.pageSize}/page`}
                        onChange={this.props.changeInvoiceGridState}
                    />

                    {!isEmpty(this.props.filterModel) && (
                        <Button
                            size="mini"
                            icon="filter"
                            floated="right"
                            content="Clear Filters"
                            onClick={() => this.grid.api.setFilterModel({})}
                        />
                    )}

                    <Dropdown
                        options={options}
                        button
                        className="ui mini right floated button"
                        placeholder="Export"
                        floated="right"
                        content="Export"
                        trigger={
                            <span>
                                <Icon name="download" />
                                Export
                            </span>
                        }
                    />

                    <DatesRangeInput
                        size="mini"
                        className="fr"
                        clearable={true}
                        animation={null}
                        maxDate={new Date()}
                        placeholder="From - To"
                        value={this.state.dateRange}
                        dateFormat={DateFormat.toUpperCase()}
                        onChange={this.handleDateRangeChange}
                    />
                </div>
                <div className="ag-theme-balham bm-10 clear">
                    <AgGridReact
                        pagination
                        rowHeight={50}
                        reactNext={true}
                        floatingFilter={true}
                        rowSelection="single"
                        domLayout="autoHeight"
                        rowModelType="infinite"
                        onGridReady={this.onGridReady}
                        columnDefs={this.props.columnDefs}
                        cacheBlockSize={this.props.pageSize}
                        onPaginationChanged={this.changePage}
                        frameworkComponents={frameworkComponents}
                        noRowsOverlayComponent="noInvoicesOverlay"
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            filter: true,
                        }}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ invoiceGrid, auth }) => ({
    invoices: invoiceGrid.invoices,
    pageSize: invoiceGrid.pageSize,
    isLoading: invoiceGrid.isLoading,
    sortModel: invoiceGrid.sortModel,
    columnDefs: invoiceGrid.columnDefs,
    filterModel: invoiceGrid.filterModel,
    currentPage: invoiceGrid.currentPage,
    company: auth.user.company,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadInvoiceGridPage,
            changeInvoiceGridState,
            changeInvoiceGridPage: page => dispatch(changeInvoiceGridPage(page)),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DataGrid);
