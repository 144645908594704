import pluralize from "pluralize";
import { format } from "date-fns";
import downloadFile from "js-file-download";
import { toast } from "react-semantic-toasts";
import { lowerCase, isEqual, get, isEmpty } from "lodash";

import { makePrivateApiCall } from "utilities/dist/api";
import { ProductTypes } from "../product/constants";
import { FilenameDateFormat } from "utilities/dist/invoice/constants";
import { trackDownloadPdfEvent } from "../common/analytics";
import { isIos } from "api/src/common/helpers";
import { computeTaxedTotal } from "utilities/dist/invoice/helpers";
import { formatAmount } from "utilities/src/helpers";

const showStateTax = item => {
    const itemPrice = item.price * item.qty;
    const totalTax = item.total - itemPrice;
    return formatAmount(totalTax / item.qty).replace("$", "");
};

export const computeTotalFromItems = items => {
    let subTotal = 0,
        taxTotal = 0,
        total = 0,
        totalItems = 0,
        totalUnits = 0,
        beerItem = 0,
        naItem = 0,
        liquorItem = 0,
        positiveLiquorCount = 0,
        wineItem = 0,
        beerCount = 0,
        naCount = 0,
        liquorCount = 0,
        wineCount = 0;

    items.forEach(item => {
        // item total includes the tax but since in the summary, we're showing the split-up calculation
        // we need to use the total without tax for next computations
        const totalWithoutTax = item.price * item.qty;
        const tax = computeTaxedTotal({ ...item, total: totalWithoutTax });

        subTotal += totalWithoutTax;
        taxTotal += tax;
        total += item.total;
        totalItems += 1;
        totalUnits += item.qty;

        if (isBeer(item)) {
            beerCount += item.qty;
            beerItem += 1;
        } else if (isLiquor(item)) {
            liquorCount += item.qty;
            liquorItem += 1;
            if (item.qty > 0) positiveLiquorCount += item.qty;
        } else if (isWine(item)) {
            wineCount += item.qty;
            wineItem += 1;
        } else if (isNa(item)) {
            naCount += item.qty;
            naItem += 1;
        }
    });

    const counts = { subTotal, taxTotal, total, totalItems, totalUnits };

    if (beerItem > 0) {
        counts.beerCount = beerCount;
        counts.beerItem = beerItem;
    }

    if (liquorItem > 0) {
        counts.liquorCount = liquorCount;
        counts.liquorItem = liquorItem;
        counts.positiveLiquorCount = positiveLiquorCount;
    }

    if (wineItem > 0) {
        counts.wineCount = wineCount;
        counts.wineItem = wineItem;
    }

    if (naItem > 0) {
        counts.naCount = naCount;
        counts.naItem = naItem;
    }

    return counts;
};

export const getNewInvoiceNumber = () =>
    makePrivateApiCall({
        url: "/invoice/new-number",
    }).then(res => res.data);

/**
 * Given an invoice entry, it computes the total amount of all the payment entries added to the invoice
 * @function computePaymentTotal
 *
 * @param {Object} invoice invoice data
 *
 * @returns {Number} Total summation of all the payment entries logged
 */
export const computePaymentTotal = invoice => {
    if (!invoice || isEmpty(invoice.payments)) {
        return 0;
    }

    return invoice.payments.reduce((total, payment) => {
        return total + Number(payment.amount);
    }, 0);
};

/**
 * Finds out how many stamps are being used in an invoice by looping through all serialized stamps
 * Used in the invoice builder reducer and the summary component
 */
export const computeStampTotal = (stamps = []) => {
    const total = stamps.reduce((previousTotal, { from, to }) => {
        return to - from + previousTotal + 1;
    }, 0);

    return total;
};

/**
 * Finds out how many stamps are needed by counting total number of liquor bottles
 * Used in the invoice builder reducer
 */
export const countStampsNeeded = ({ items = {} }) => {
    const liquorBottleCount = Object.values(items).reduce((total, item) => {
        if (isLiquor(item) && item.qty > 0) return item.qty + total;

        return total;
    }, 0);

    return liquorBottleCount;
};

export const DateFormat = "dd MMM, yyyy";
export const DateFormatForTxt = "MM/dd/yyyy";

export const getItemType = item => lowerCase(get(item, "product.product_type", null));
export const isItemType = (item, type) => isEqual(getItemType(item), lowerCase(type));

export const isBeer = item => isItemType(item, ProductTypes.BEER);
export const isLiquor = item => isItemType(item, ProductTypes.LIQUOR);
export const isWine = item => isItemType(item, ProductTypes.WINE);
export const isNa = item => isItemType(item, ProductTypes.NA);
export const isBeerOrNa = item => isBeer(item) || isNa(item);

export const isQtyComputable = qty => qty !== "";

/**
 * @function generatePdf
 *
 * @param {String} number invoice number
 * @param {Function} setGeneratingPdfUrl Function that will be called to set the sate of generation process, true will be passed when the process starts and false will be passed when the process ends un/successfully
 * @param {Boolean} shouldDownload if invoice should be immediately downloaded
 */
export const generatePdf = (number, setGeneratingPdfUrl, shouldDownload = true) => {
    trackDownloadPdfEvent(number, {});
    setGeneratingPdfUrl(true);

    return makePrivateApiCall({
        method: "POST",
        url: "/invoice/pdf",
        data: { number },
        // only when download is needed, we need the file as blob, otherwise json /default is fine
        ...(shouldDownload ? { responseType: "blob" } : {}),
    })
        .then(res => {
            trackDownloadPdfEvent(number, { success: true });
            setGeneratingPdfUrl(false);
            if (shouldDownload) {
                if (isIos()) {
                    const file = new Blob([res.data], { type: "application/pdf" });
                    const fileURL = URL.createObjectURL(file);
                    return window.open(fileURL, "_blank");
                }
                return downloadFile(res.data, `inv_${number}.pdf`);
            }
            // when not downloading, data will contain url
            return res.data?.url;
        })
        .catch(err => {
            trackDownloadPdfEvent(number, { error: true });
            setGeneratingPdfUrl(false);
            toast({
                icon: "file",
                type: "error",
                title: "Error generating pdf",
                description: `${err.message}`,
            });
        });
};

export const getProductFullName = (product = {}) => `${product.product} (${product.product_size})`;

// TODO: Change the func def

export const formatItemWithUnits = ({ data: invoice }) => {
    if (!invoice) return null;
    const qtyCount = invoice.items.reduce((count, { qty }) => count + qty, 0);
    return `${pluralize("unit", qtyCount, true)} of ${pluralize(
        "item",
        invoice.items.length,
        true
    )}`;
};

const buildDetailedRows = invoice => {
    const rows = [];
    const invoiceColumns = {
        invoice_status: invoice.status || "", // empty if no value found
        invoice_number: buildInvoiceNumberWithBeer(invoice) || "",
        customer_name: invoice.customer?.name || "",
        fintech_id: invoice.customer.fintech_id || "",
        customer_email: invoice.customer?.contact_email || "",
        address: invoice.customer?.address || "",
        city: invoice.customer?.city || "",
        state: invoice.customer?.state || "",
        zip: invoice.customer?.zip || "",
        phone_number: invoice.customer?.contact_phone || "",
        cod: invoice.customer.cash_on_delivery ? "Yes" : "No",
    };

    invoice.items.forEach(item => {
        const itemColumn = {
            product_name: item.product?.product || "",
            product_type: item.product?.product_type || "",
            upc: item.product?.upc || "",
            last_purchase_price: formatAmount(item.product?.last_purchase_price) || "",
            unit_price: formatAmount(item.price) || "",
            quantity: item.qty || "",
            state_tax: item.taxable ? showStateTax(item) : 0,
            total: formatAmount(item.total) || "",
        };

        rows.push({ ...invoiceColumns, ...itemColumn });
    });

    return rows;
};
const buildSummaryRows = invoice => {
    const rows = [
        {
            invoice_status: invoice.status || "", // empty if no value found
            invoice_number: buildInvoiceNumberWithBeer(invoice) || "",
            total: formatAmount(invoice.total) || "",
            tax: formatAmount(invoice.tax_total) || 0,
            date: invoice.invoiced_at || "",
            due_on: invoice.due_at || "",
            customer: invoice.customer?.name || "",
            fintech_id: invoice.customer.fintech_id || "",
            items: formatItemWithUnits({ data: invoice }) || "",
            cod: invoice.customer.cash_on_delivery ? "Yes" : "No",
        },
    ];

    return rows;
};

export const handleExportInvoice = (invoices, exportType) => {
    if (!invoices?.length) return null;
    const rows = [];
    const buildRows = exportType === "summary" ? buildSummaryRows : buildDetailedRows;
    // structure data with each row for one items
    // invoice_status invoice_number customer_name customer_email address city state zip phone_number product_name unit_price buying_price quantity total
    invoices.forEach((invoice, i) => {
        const invoiceRows = buildRows(invoice, i);
        if (i === 0) {
            const headers = Object.keys(invoiceRows[0]).map(key =>
                key.toLowerCase().replaceAll("_", " ")
            );
            rows.push(`"${headers.join('","')}"`);
        }

        invoiceRows.forEach(invoiceRow => {
            let columnValues = Object.values(invoiceRow);

            const parsedRow = columnValues.join('","');
            rows.push(`"${parsedRow}"`);
        });
    });

    const aTag = document.createElement("a");
    const textFile = new Blob([rows.join("\r\n").toLocaleUpperCase()], {
        type: "text/csv;charset=utf-8;",
    });
    aTag.href = URL.createObjectURL(textFile);
    aTag.download = `invoices_${exportType === "summary" ? "summary" : "detailed"}_${format(
        new Date(),
        FilenameDateFormat
    )}${".csv"}`;
    aTag.click();
};

const showEmptyStringIfNull = prod => {
    return prod ? format(new Date(prod), DateFormatForTxt) : "";
};

const buildFintechRows = (invoice, fintechId) => {
    const fintechRows = [];
    const fintechInvoiceColumns = {
        Division_id: fintechId || "",
        invoice_number: buildInvoiceNumberWithBeer(invoice) || "",
        invoice_date: format(new Date(invoice.invoiced_at), DateFormatForTxt) || "",
        Vendor_store_id: invoice.customer?.fintech_id || "",
        invoice_due_date: showEmptyStringIfNull(invoice?.due_at) || "",
    };

    invoice.items.forEach(item => {
        const fintechItemColumn = {
            quantity_shipped: item.qty ? item.qty : "",
            Quantity_uom: "EA",
            item_number: item.product?.upc || item.product?.ydk_product_id || item.product_id || "",
            product_description: item.product?.product + " " + item.product?.product_size || "",
            unit_price: `"${formatAmount(item.price)?.replace("$", "")}"` || "",
            state_tax: item.taxable ? showStateTax(item) : 0,
        };

        fintechRows.push({
            ...fintechInvoiceColumns,
            ...fintechItemColumn,
        });
    });

    return fintechRows;
};

const createAllCapsWithoutFirstElement = rows => {
    const newRows = [];
    const firstRow = rows[0] + "\n";
    const restRows = [rows.slice(1).join("\r\n").toLocaleUpperCase()];
    newRows.push(firstRow + restRows);
    return newRows;
};

export const handleFintechCsvExport = (invoices, fintechId) => {
    if (!invoices?.length) return null;
    const rows = [];

    // structure data with each row for one items
    // only export invoices for customers that have fintech option enabled
    invoices.forEach((invoice, i) => {
        if (!invoice.customer?.fintech_option) return;
        const invoiceRows = buildFintechRows(invoice, fintechId, i);
        if (rows.length === 0) {
            const headers = Object.keys(invoiceRows[0]).map(key => key);
            rows.push(`${headers.join(",")}`);
        }

        invoiceRows.forEach(invoiceRow => {
            let columnValues = Object.values(invoiceRow);

            const parsedRow = columnValues.join(",");
            rows.push(`${parsedRow}`);
        });
    });
    const modifiedRows = createAllCapsWithoutFirstElement(rows);
    const aTag = document.createElement("a");
    const textFile = new Blob([modifiedRows], {
        type: "text/csv;charset=utf-8;",
    });
    aTag.href = URL.createObjectURL(textFile);
    aTag.download = `invoices_fintech_${format(new Date(), FilenameDateFormat)}${".csv"}`;
    aTag.click();
};

export const buildInvoiceNumberWithBeer = (invoice = {}) =>
    !!invoice.beer ? `${invoice.number}B` : invoice.number;

export const createItemLog = items => {
    return (
        items.map(item => ({
            ...item,
            product: item.product ? item.product : item.ydk_product,
            isRejected: false,
            rejectionNote: "",
            previousPrice: item.price,
            newPrice: "",
            priceChangeNote: "",
            previousQty: item.qty,
            newQty: "",
            qtyChangeNote: "",
        })) || []
    );
};

export const handleBottlePosJsonExport = filterModel => {
    makePrivateApiCall({
        url: "/bottle-pos/export-invoices",
        method: "GET",
        params: { filterModel },
    })
        .then(res => {
            if (!res.data) {
                return toast({
                    description: `Error exporting bottle pos sales report`,
                    time: 30000,
                    type: "error",
                    icon: "warning sign",
                    title: "Error exporting",
                });
            }

            const aTag = document.createElement("a");
            const jsonFile = new Blob([JSON.stringify(res.data)], {
                type: "application/json;charset=utf-8;",
            });
            aTag.href = URL.createObjectURL(jsonFile);
            aTag.download = `sales_import_bottle_pos_${format(
                new Date(),
                FilenameDateFormat
            )}${".json"}`;
            aTag.click();
        })
        .catch(err => {
            toast({
                description: err.message,
                time: 30000,
                type: "error",
                icon: "warning sign",
                title: "Error exporting",
            });
        });
};
