import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Label, Message } from "semantic-ui-react";

import { uploadInvoiceFile } from "./actions";

const InvoiceConverterUploaderContainer = () => {
    let hiddenInput = null;
    const dispatch = useDispatch();
    const { uploadError, uploadingInvoice } = useSelector(
        ({ invoiceConverter }) => invoiceConverter
    );

    return (
        <>
            {!!uploadError && (
                <Message error content={uploadError} header="Error Uploading Invoice" />
            )}

            <Button
                icon="upload"
                labelPosition="right"
                loading={!!uploadingInvoice}
                onClick={() => hiddenInput.click()}
                label={
                    <Label
                        data-cy="upload_fintech"
                        basic
                        as="label"
                        className="clickable"
                        children={uploadingInvoice ? "Uploading..." : "Upload Fintech Invoice"}
                    />
                }
            />
            <input
                hidden
                type="file"
                ref={el => (hiddenInput = el)}
                onChange={e => {
                    dispatch(uploadInvoiceFile(e.target.files[0]));
                    e.target.value = "";
                }}
            />
        </>
    );
};

export default InvoiceConverterUploaderContainer;
