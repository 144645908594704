import { PRODUCT_VIEW_DATA, PRODUCT_VIEW_ERROR, PRODUCT_VIEW_LOADING } from "../constants";
import { authExpire } from "../../auth/actions";
import { makePrivateApiCall } from "utilities/dist/api";

export const loadProduct =
    ({ id }) =>
    (dispatch, getState) => {
        const { productGrid } = getState();
        const productFromGrid = productGrid.products.find(p => p.id === id);
        // Order of the dispatches is important here because we want to first load any data we can, from the grid
        // then, we want to trigger loading state before querying the server for latest data
        if (productFromGrid) {
            dispatch({
                type: PRODUCT_VIEW_DATA,
                payload: productFromGrid,
            });
        }

        dispatch({
            type: PRODUCT_VIEW_LOADING,
        });

        return makePrivateApiCall({
            url: `/product/${id}`,
            method: "get",
        })
            .then(res => {
                if (res.data) {
                    return dispatch({
                        type: PRODUCT_VIEW_DATA,
                        payload: res.data,
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    return dispatch(authExpire());
                }

                dispatch({
                    type: PRODUCT_VIEW_ERROR,
                    payload: err?.response?.data?.message || err.message,
                });
            });
    };
