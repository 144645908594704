import React from "react";
import { Link } from "react-router-dom";
import { Grid, Menu } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import StampRollContainerList from "../roll/containers/list";
import StampRollContainerEditor from "../roll/containers/editor";

const StampPageRoll = () => {
    return (
        <Grid>
            <TopNav
                headerIcon="ticket"
                headerContent="Stamp Roll"
                subheader="Manage your stamp roll"
                extraButtons={() => (
                    <Menu.Item as={Link} to="/stamp" icon="ticket" name="Stamp Log" />
                )}
            />

            <Grid.Column mobile={16} tablet={10} computer={8}>
                <StampRollContainerEditor />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={10} computer={8}>
                <StampRollContainerList />
            </Grid.Column>
        </Grid>
    );
};

export default StampPageRoll;
