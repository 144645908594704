import React from "react";
import { isEmpty } from "lodash";
import { addDays } from "date-fns";
import { Button, Icon } from "semantic-ui-react";
import { Form, TextArea } from "formik-semantic-ui";
import { DateInput } from "semantic-ui-calendar-react";
import CurrencyInput from "react-currency-masked-input";
import { DateFormat } from "../helpers";

const InvoicePaymentEditorComponent = ({
    setFieldValue,
    setFieldTouched,
    values,

    onCancel,
    ...formProps
}) => {
    return (
        <>
            <Form.Group>
                <Form.Field required width={4}>
                    <label htmlFor="field_input_amount">Amount</label>
                    <div className="ui fluid left icon input">
                        <Icon name="dollar sign" />
                        <CurrencyInput
                            name="amount"
                            value={values.amount}
                            id="field_input_amount"
                            onChange={(e, value) => {
                                setFieldValue("amount", value);
                                setFieldTouched("amount");
                            }}
                        />
                    </div>
                </Form.Field>

                <DateInput
                    closable
                    animation="none"
                    name="received_at"
                    placeholder="Date"
                    iconPosition="left"
                    label="Received On"
                    value={values.received_at}
                    maxDate={addDays(new Date(), 3)}
                    dateFormat={DateFormat.toUpperCase()}
                    onChange={(e, data) => {
                        setFieldValue("received_at", data.value);
                        setFieldTouched("received_at");
                    }}
                />
            </Form.Group>

            <TextArea name="note" label="Payment Note" />

            <br />
            <Button disabled={isEmpty(formProps.touched)} color="teal" type="submit">
                Save Payment
            </Button>
            <Button color="yellow" type="button" onClick={onCancel}>
                Cancel
            </Button>
        </>
    );
};

export default InvoicePaymentEditorComponent;
