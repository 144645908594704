import React from "react";
import { get, isEmpty } from "lodash";
import { Button, Header, Icon, Message } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { DateInput } from "semantic-ui-calendar-react";
import CurrencyInput from "react-currency-masked-input";
import { Form, Input, Dropdown } from "formik-semantic-ui";

import { ProductTypes } from "../constants";
import { DateFormat } from "utilities/dist/invoice/constants";
import CustomerContainerPicker from "../../customer/containers/picker";
import { changeProductGridState } from "../grid/actions";
import {
    isFeatureActive,
    InternalSkuFeature,
    InventoryFeatureDetails,
    TieredPricing,
} from "../../company/feature/helpers";
import { parseAmount } from "../../common/helpers";
import { DistributorSelectorComponent } from "../distributor-selector.component";

const ProductEditorComponent = ({
    setFieldValue,
    setFieldTouched,
    values,
    isBottlePosActive,
    isCloverActive,

    onCancel,
    initialValues,
    ...formProps
}) => {
    const dispatch = useDispatch();
    const { customerId, company } = useSelector(({ productGrid, auth }) => ({
        customerId: productGrid.customerId,
        company: auth.user.company,
    }));
    const isInternalSkuActive = isFeatureActive(company, InternalSkuFeature);
    const isInventoryActive = isFeatureActive(company, InventoryFeatureDetails);
    const isTieredPricingActive = isFeatureActive(company, TieredPricing);

    const handleCustomerChange = customer => {
        const customerId = get(customer, "id", null);

        dispatch(changeProductGridState({ customerId }));
    };

    const hasBlockingIntegration = isBottlePosActive || isCloverActive;

    return (
        <>
            {!isBottlePosActive && !isCloverActive && (
                <Form.Group>
                    <Input
                        disabled={hasBlockingIntegration}
                        name="product"
                        label="Product Name"
                        fieldProps={{ width: 9, required: true }}
                        inputProps={{
                            "data-cy": "product_name",
                        }}
                    />

                    <Dropdown
                        selection
                        disabled={hasBlockingIntegration}
                        name="product_type"
                        label="Product Type"
                        options={Object.values(ProductTypes).map(t => ({
                            key: t,
                            value: t,
                            text: t,
                        }))}
                        fieldProps={{ required: true }}
                        inputProps={{
                            "data-cy": "product_type",
                        }}
                    />

                    <Input
                        label="Product Size"
                        name="product_size"
                        disabled={hasBlockingIntegration}
                        fieldProps={{ width: 3, required: true }}
                        inputProps={{
                            "data-cy": "product_size",
                        }}
                    />
                </Form.Group>
            )}
            <Form.Group>
                <Form.Field required width={4}>
                    <label>{isTieredPricingActive && "Base "}Unit Price</label>
                    <div className="ui fluid left icon input">
                        <Icon name="dollar sign" />
                        <CurrencyInput
                            data-cy="unit_price"
                            name="product_price"
                            value={parseAmount(values.product_price || 0)}
                            onChange={(e, value) => {
                                setFieldValue("product_price", value);
                                setFieldTouched("product_price");
                            }}
                        />
                    </div>
                </Form.Field>

                {!!values.id && values.product_price !== initialValues.product_price && (
                    <DateInput
                        icon={null}
                        closable={true}
                        animation="none"
                        minDate={new Date()}
                        name="effective_date"
                        // popupPosition="top center"
                        label="Price Effective From"
                        value={values.effective_date}
                        onChange={(e, data) => {
                            setFieldTouched("effective_date");
                            setFieldValue("effective_date", data.value);
                        }}
                        dateFormat={DateFormat.toUpperCase()}
                    />
                )}
                {!isBottlePosActive && !isCloverActive && (
                    <Form.Field width={3}>
                        <label>Buying Price</label>
                        <div className="ui fluid left icon input">
                            <Icon name="dollar sign" />
                            <CurrencyInput
                                data-cy="buying_price"
                                name="last_purchase_price"
                                value={parseAmount(values.last_purchase_price || 0)}
                                onChange={(e, value) => {
                                    setFieldValue("last_purchase_price", value);
                                    setFieldTouched("last_purchase_price");
                                }}
                            />
                        </div>
                    </Form.Field>
                )}
                {!isBottlePosActive && !isCloverActive && (
                    <Input
                        disabled={hasBlockingIntegration}
                        name="upc"
                        label="UPC"
                        fieldProps={{ width: 6 }}
                        inputProps={{
                            type: "number",
                            "data-cy": "upc",
                        }}
                    />
                )}
                {!!isInternalSkuActive && !isBottlePosActive && !isCloverActive && (
                    <Input
                        name="internal_sku"
                        label="Internal SKU"
                        fieldProps={{ width: 6 }}
                        inputProps={{
                            type: "text",
                            "data-cy": "internal_sku",
                        }}
                    />
                )}
                {hasBlockingIntegration && (
                    <Message
                        warning
                        header={
                            isBottlePosActive
                                ? "Bottle POS integration is active"
                                : "Clover integration is active"
                        }
                        content={
                            isBottlePosActive
                                ? "Some fields are disabled when bottle POS integration is active since these fields will be synchronized from bottle POS."
                                : "Some fields are disabled when clover integration is active since these fields will be synchronized from clover."
                        }
                    />
                )}
            </Form.Group>
            {!!values.id && values.product_price !== initialValues.product_price && (
                <Form.Group>
                    <Form.Field width={8}>
                        <label>Select customer</label>
                        <div className="ui fluid left icon input">
                            <CustomerContainerPicker
                                dropdownProps={{
                                    size: "mini",
                                    fluid: true,
                                    clearable: true,
                                    placeholder: "Select Customer",
                                }}
                                selectedCustomerId={customerId}
                                onSelect={handleCustomerChange}
                            />
                        </div>
                        <span>
                            {!customerId
                                ? "Price will be updated for all customers"
                                : "Price will be updated only for this customer "}
                        </span>
                    </Form.Field>
                </Form.Group>
            )}

            {isInventoryActive && (
                <>
                    <Header as="h3" content="Inventory Settings" />
                    <Form.Group>
                        <DistributorSelectorComponent
                            onCustomInput={dist => {
                                setFieldTouched("distributor");
                                setFieldValue("distributor", dist);
                            }}
                        />
                        <Input
                            name="reorder_level"
                            label="Reorder Level"
                            fieldProps={{ width: 2 }}
                            inputProps={{
                                type: "number",
                                "data-cy": "reorder_level",
                            }}
                        />
                        <Input
                            name="reorder_qty"
                            label="Reorder Qty"
                            fieldProps={{ width: 2 }}
                            inputProps={{
                                type: "number",
                                "data-cy": "reorder_qty",
                            }}
                        />
                        <Input
                            name="reorder_time_in_days"
                            label="Reorder In Days"
                            fieldProps={{ width: 3 }}
                            inputProps={{
                                type: "number",
                                "data-cy": "reorder_time_in_days",
                            }}
                        />
                    </Form.Group>
                </>
            )}

            <br />
            <Button
                data-cy="save_product"
                disabled={isEmpty(formProps.touched)}
                color="teal"
                type="submit"
            >
                Save Product
            </Button>
            <Button data-cy="cancel" color="yellow" type="button" onClick={onCancel}>
                Cancel
            </Button>
        </>
    );
};

export default ProductEditorComponent;
