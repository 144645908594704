import { get } from "lodash";
import pluralize from "pluralize";
import { format } from "date-fns";
import React, { useEffect } from "react";
import csvDownload from "json-to-csv-export";
import { useSelector, useDispatch } from "react-redux";
import { Statistic, Segment, Header, Dropdown, Divider, Button } from "semantic-ui-react";

import { formatAmount } from "utilities/dist/helpers";
import ProductList from "./products-list.component";
import { DateFormat } from "utilities/dist/invoice/constants";
import { dateRangesDropdownOptions, dateRanges } from "./helpers";
import { loadCompanyStats, setCompanyStatDateRange } from "./actions";

const CompanyStatsContainer = () => {
    const { stats, dateRange, exportableProductStats, isLoading } = useSelector(
        ({ companyStats }) => companyStats
    );
    const currentRange = dateRanges.find(dr => dr.name === dateRange);

    const dispatch = useDispatch();
    const setDateRange = (e, { value }) => dispatch(setCompanyStatDateRange(value));
    const load = () => {
        dispatch(loadCompanyStats(dateRange));
    };

    const exportProductStats = () =>
        csvDownload(exportableProductStats, `inventory_stats_${Date.now()}.csv`);

    useEffect(load, [dateRange]);

    const totalProductQty = get(stats, "productStats", []).reduce(
        (total, stat) => total + stat.total_qty,
        0
    );

    const totalOrderQty = get(stats, "orderStats", []).reduce(
        (total, stat) => total + parseInt(stat.total_qty),
        0
    );

    return (
        <Segment loading={isLoading}>
            <Header dividing>
                Invoice Stats&nbsp;
                <Dropdown
                    inline
                    value={dateRange}
                    onChange={setDateRange}
                    options={dateRangesDropdownOptions}
                />
                <Header.Subheader>
                    Quick overview in numbers from {format(currentRange.from, DateFormat)} -{" "}
                    {format(currentRange.to, DateFormat)}
                </Header.Subheader>
            </Header>
            <Statistic.Group size="tiny" widths={3}>
                <Statistic
                    label={pluralize("Customer", stats.customerCount)}
                    value={stats.customerCount}
                />
                <Statistic
                    label={pluralize("Invoice", stats.invoiceCount)}
                    value={stats.invoiceCount}
                />
                <Statistic label="Total" value={formatAmount(stats.invoiceTotal)} />
            </Statistic.Group>

            {totalProductQty > 0 && (
                <>
                    <Divider hidden />

                    <Statistic.Group size="tiny" widths={3}>
                        <Statistic
                            label={pluralize("Products", stats.productStats.length)}
                            value={stats.productStats.length}
                        />
                        <Statistic
                            label={pluralize("Units", totalProductQty)}
                            value={totalProductQty}
                        />
                    </Statistic.Group>
                    {totalOrderQty > 0 && (
                        <>
                            <Divider section />

                            <Statistic.Group size="tiny" widths={3}>
                                <Statistic
                                    label={pluralize("Orders", stats.orderCount)}
                                    value={stats.orderCount}
                                />
                                <Statistic
                                    label={pluralize("Units", totalOrderQty)}
                                    value={totalOrderQty}
                                />
                                <Statistic label="Total" value={formatAmount(stats.orderTotal)} />
                            </Statistic.Group>
                        </>
                    )}

                    <Divider section />

                    <Header floated="left">
                        Inventory Stats
                        <Header.Subheader>
                            Overview of the products you have invoiced
                        </Header.Subheader>
                    </Header>

                    <Button
                        size="mini"
                        icon="file"
                        floated="right"
                        content="export"
                        onClick={exportProductStats}
                    />
                    <Divider hidden clearing />
                    <ProductList productStats={stats.productStats} />
                </>
            )}
        </Segment>
    );
};

export default CompanyStatsContainer;
