import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { Segment, Modal, Dimmer, Loader } from "semantic-ui-react";

import { authSignout } from "../actions";

class LogoutPage extends React.Component {
    state = {
        redirect: false,
    };

    componentDidMount() {
        setTimeout(() => {
            this.props.authSignout().then(() => this.setState({ redirect: true }));
        }, 2000);
    }

    render() {
        if (this.state.redirect) return <Redirect to="/" />;

        return (
            <Modal open={true} size="small">
                <Modal.Header>Logging you out</Modal.Header>

                <Modal.Content>
                    <Segment>
                        <Dimmer active inverted>
                            <Loader />
                        </Dimmer>
                        <div className="hp-15 sp-15"></div>
                    </Segment>
                </Modal.Content>
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            authSignout,
        },
        dispatch
    );

export default connect(({ nav }) => nav, mapDispatchToProps)(LogoutPage);
