import React from "react";
import { format } from "date-fns";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEqual } from "lodash";
import { bindActionCreators } from "redux";
import { AgGridReact } from "ag-grid-react";
import { Header, Button } from "semantic-ui-react";

import { loadOrderGridPage, changeOrderGridPage, changeOrderGridState } from "./actions";
import GridPageSizePicker from "../../common/components/grid-page-size-picker";
import { allGridColumns } from "./columns";
import { DateFormat } from "utilities/dist/invoice/constants";
import {
    OrderLinkRenderer,
    OrderStatusRenderer,
    OrderHistoryRenderer,
} from "./cellRenderers.component";
import orderComponentGridActions from "../components/grid-actions";
import { DatesRangeInput } from "semantic-ui-calendar-react";

const NoOrdersOverlay = () => {
    return <Header>No Order history found.</Header>;
};

const frameworkComponents = {
    noOrdersOverlay: NoOrdersOverlay,
    orderComponentGridActions: orderComponentGridActions,
    OrderLinkRenderer: OrderLinkRenderer,
    OrderStatusRenderer: OrderStatusRenderer,
    OrderHistoryRenderer: OrderHistoryRenderer,
};

class DataGrid extends React.Component {
    state = {
        dateRange: "",
    };

    grid = null;
    gridDataSource = {
        getRows: params => {
            this.props.loadOrderGridPage(this.props, params);
        },
    };

    handleDateRangeChange = (e, { value }) => {
        const filterModel = { ...this.props.filterModel };
        const [start, end] = value?.split(" - ");

        if (start && end && this.grid) {
            filterModel.created_at = {
                type: "inRange",
                filterType: "date",
                dateTo: format(new Date(end), "yyyy-MM-dd"),
                dateFrom: format(new Date(start), "yyyy-MM-dd"),
            };
        } else if (filterModel.created_at) {
            delete filterModel["created_at"];
        }

        this.props.changeOrderGridState({ filterModel });
        this.setState({ dateRange: value });
    };

    componentWillUnmount() {
        this.grid = null;
    }

    onGridReady = grid => {
        this.grid = grid;

        setTimeout(() => this.grid && this.grid.api.sizeColumnsToFit(), 300);

        // this.grid.api.paginationGoToPage(this.props.currentPage);
        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.setDatasource(this.gridDataSource);

        this.grid.api.addEventListener("sortChanged", () => {
            this.props.changeOrderGridState({
                sortModel: this.grid.api.getSortModel(),
            });
        });

        this.grid.api.addEventListener("filterChanged", () => {
            this.props.changeOrderGridState({
                filterModel: this.grid.api.getFilterModel(),
            });
        });
        this.props.changeOrderGridState({
            filterModel: {
                status: {
                    filter: "placed",
                    filterType: "text",
                    type: "equals",
                },
            },
        });
    };

    reloadGridData = () => {
        this.grid && this.grid.api.refreshInfiniteCache();
    };

    changePage = () => {
        if (this.grid) {
            const page = this.grid.api.paginationGetCurrentPage() + 1;

            if (page === this.props.currentPage) return;

            this.props.changeOrderGridState(page);
        }
    };

    componentDidUpdate(prevProps) {
        if (!this.grid) return;

        if (prevProps.isLoading && !this.props.isLoading) {
            this.grid.api.hideOverlay();
        }

        if (!prevProps.isLoading && this.props.isLoading) {
            this.grid.api.showLoadingOverlay();
        }

        if (!this.props.isLoading && this.props?.orders?.length < 1) {
            this.grid.api.showNoRowsOverlay();
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.grid.api.paginationSetPageSize(this.props.pageSize);
        }

        if (!isEqual(prevProps.filterModel, this.props.filterModel)) {
            this.grid.api.setFilterModel(this.props.filterModel);
        }
        if (
            !isEqual(prevProps.location.search, this.props.location.search) &&
            this.props.location.search.includes("force-update")
        ) {
            this.grid.api.setDatasource(this.gridDataSource);
            this.props.history.replace("/order");
        }
    }
    handleResetGridFilter = e => {
        e.preventDefault();
        this.props.changeOrderGridState({
            filterModel: {},
        });
    };

    render() {
        const { pageSize } = this.props;
        return (
            <>
                <div className="clear bm-10">
                    <GridPageSizePicker
                        button
                        compact
                        pointing="top"
                        pageSize={pageSize}
                        text={`${pageSize}/page`}
                        className="right floated basic"
                        onChange={this.props.changeOrderGridState}
                    />
                    <DatesRangeInput
                        size="mini"
                        className="fr lm-5"
                        clearable={true}
                        animation={null}
                        maxDate={new Date()}
                        placeholder="From - To"
                        value={this.state.dateRange}
                        dateFormat={DateFormat.toUpperCase()}
                        onChange={this.handleDateRangeChange}
                    />
                    <Button
                        className="right floated basic"
                        content="Reset"
                        compact
                        icon="dont"
                        onClick={this.handleResetGridFilter}
                    />
                </div>

                <div className="ag-theme-balham bm-10">
                    <AgGridReact
                        pagination
                        rowHeight={50}
                        reactNext={true}
                        floatingFilter={true}
                        rowSelection="single"
                        domLayout="autoHeight"
                        rowModelType="infinite"
                        onGridReady={this.onGridReady}
                        cacheBlockSize={this.props.pageSize}
                        onPaginationChanged={this.changePage}
                        columnDefs={Object.values(allGridColumns)}
                        frameworkComponents={frameworkComponents}
                        noRowsOverlayComponent="noOrdersOverlay"
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            filter: true,
                            wrapText: true,
                            flex: 1,
                            autoHeight: true,
                        }}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ orderGrid }) => ({
    orders: orderGrid.orders,
    pageSize: orderGrid.pageSize,
    isLoading: orderGrid.isLoading,
    sortModel: orderGrid.sortModel,
    columnDefs: orderGrid.columnDefs,
    filterModel: orderGrid.filterModel,
    currentPage: orderGrid.currentPage,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadOrderGridPage,
            changeOrderGridState,
            changeOrderGridPage: page => dispatch(changeOrderGridPage(page)),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataGrid));
