import { keyBy } from "lodash";
import { format } from "date-fns";

import { DateFormat } from "utilities/dist/invoice/constants";
import { parseServerTimestampForClient } from "../../common/helpers";
import { formatAmount } from "utilities/dist/helpers";

export const allGridColumns = keyBy(
    [
        {
            field: "number",
            headerName: "Invoice",
            filter: "agNumberColumnFilter",
            cellRenderer: "invoiceLinkRenderer",
            valueGetter: function ({ data }) {
                return data ? data.invoice.number : null;
            },
        },
        {
            field: "received_at",
            headerName: "Date",
            filter: "date",
            filterParams: {
                browserDatePicker: true,
            },
            valueGetter: ({ data }) => {
                return data && data.received_at
                    ? format(parseServerTimestampForClient(data.received_at), DateFormat)
                    : null;
            },
        },
        {
            field: "customer",
            headerName: "Customer",
            cellRenderer: function ({ data }) {
                if (!data) return null;
                return data.invoice.customer.name + "<br/>" + data.invoice.customer.address;
            },
            valueGetter: function ({ data }) {
                return data
                    ? `${data.invoice.customer.name}, ${data.invoice.customer.address}`
                    : null;
            },
        },
        {
            field: "amount",
            headerName: "Amount",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            valueGetter: function ({ data }) {
                if (!data) return null;
                return formatAmount(data.amount);
            },
        },
    ],
    "field"
);
