import { isArray, isString, isEmpty, pick, cloneDeep } from "lodash";

import {
    CUSTOMER_EDITOR_INPUT,
    CUSTOMER_EDITOR_ERROR,
    CUSTOMER_EDITOR_SAVING,
    CUSTOMER_EDITOR_SAVED,
    CUSTOMER_EDITOR_CONFIRMING,
    CUSTOMER_EDITOR_CUSTOMER_ERROR,
    CUSTOMER_EDITOR_CUSTOMER_LOADING,
    CUSTOMER_EDITOR_CUSTOMER_DATA,
    CUSTOMER_EDITOR_CUSTOMER_RESET,
    CUSTOMER_EDITOR_REMOVING,
    CUSTOMER_EDITOR_ERROR_REMOVING,
    CUSTOMER_EDITOR_REMOVED,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const defaultInputs = {
    name: "",
    city: "",
    zip: "",
    address: "",
    state: "TX",
    tabc_id: "",
    ydk_est_id: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    cash_on_delivery: false,
    fintech_option: false,
    fintech_id: "",
    product_price_tier_id: null
};

const initialState = {
    inputs: cloneDeep(defaultInputs),
    isConfirming: false,
    isSaving: false,
    errors: [],
    isRemoving: false,
    removeErrors: [],

    customer: null,
    customerError: null,
    isLoadingCustomer: false,
};

const setError = (state, error) => {
    let { errors } = state;

    if (isString(error)) {
        errors.push(error);
    }

    if (isArray(error)) {
        errors = error;
    }

    return { ...state, isSaving: false, errors };
};
const setConfirming = (state, customer) => {
    // if customer is not literally false, then set it to true
    const newState = { ...state, isConfirming: customer !== false };

    if (customer && !isEmpty(customer)) {
        newState.inputs = customer;
    } else {
        newState.isSaving = false;
    }

    return newState;
};
const setSaving = state => ({ ...state, isSaving: true });
const resetState = () => initialState;

const setInput = (state, inputs = {}) => {
    // make sure only the allowed fields are added to inputs by only picking the keys from defaultInputs
    return {
        ...state,
        inputs: { ...state.inputs, ...pick(inputs, Object.keys(defaultInputs)) },
    };
};

const setCustomerLoading = (state, isLoadingCustomer) => ({
    ...state,
    isLoadingCustomer,
    customerError: null,
});
const resetCustomer = state => ({
    ...state,
    customer: null,
    customerError: null,
    errors: [],
    isLoadingCustomer: false,
    inputs: cloneDeep(defaultInputs),
});
const setCustomerData = (state, customer) => {
    const inputs = {
        id: customer.id,
        service_start: customer.service_start,
        ...pick(customer, Object.keys(defaultInputs)),
    };

    return {
        ...state,
        isLoadingCustomer: false,
        customer,
        inputs,
        customerError: null,
    };
};
const setCustomerError = (state, customerError) => ({
    ...state,
    customerError,
    customer: null,
    isLoadingCustomer: false,
    inputs: initialState.inputs,
});

const setCustomerRemoved = (state) => ({
    ...state,
    isRemoving: false,
    removalErrors: [],
});

const setCustomerRemoving = (state) => ({
    ...state,
    isRemoving: true,
    removalErrors: [],
});

const setCustomerErrorRemoving = (state, removalErrors) => ({
    ...state,
    removalErrors,
    isRemoving: false,
});


const reducers = {
    [CUSTOMER_EDITOR_CUSTOMER_LOADING]: setCustomerLoading,
    [CUSTOMER_EDITOR_CUSTOMER_ERROR]: setCustomerError,
    [CUSTOMER_EDITOR_CUSTOMER_DATA]: setCustomerData,
    [CUSTOMER_EDITOR_CUSTOMER_RESET]: resetCustomer,
    [CUSTOMER_EDITOR_REMOVED]: setCustomerRemoved,
    [CUSTOMER_EDITOR_REMOVING]: setCustomerRemoving,
    [CUSTOMER_EDITOR_ERROR_REMOVING]: setCustomerErrorRemoving,

    [CUSTOMER_EDITOR_CONFIRMING]: setConfirming,
    [CUSTOMER_EDITOR_SAVED]: resetState,
    [CUSTOMER_EDITOR_SAVING]: setSaving,
    [CUSTOMER_EDITOR_INPUT]: setInput,
    [CUSTOMER_EDITOR_ERROR]: setError,
};

export default generateReducer(reducers, initialState);
