import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Button } from "semantic-ui-react";
import { Dropdown as FormikDropdown } from "formik-semantic-ui";

import { InvoiceStatuses } from "utilities/dist/invoice/constants";

const statusMapping = {
    [InvoiceStatuses.PAID]: { color: "green", icon: "dollar", text: "Paid" },
    [InvoiceStatuses.DRAFT]: { color: "yellow", icon: "clock", text: "Draft" },
    [InvoiceStatuses.CANCELLED]: {
        color: "red",
        icon: "close",
        text: "Cancelled",
    },
    [InvoiceStatuses.INVOICED]: {
        color: "blue",
        icon: "newspaper",
        text: "Invoiced",
    },
    [InvoiceStatuses.PARTIALLY_PAID]: {
        color: "teal",
        icon: "money",
        text: "Partially Paid",
    },
    [InvoiceStatuses.PAYMENT_DUE]: {
        color: "purple",
        icon: "hourglass end",
        text: "Due",
    },
    [InvoiceStatuses.PAYMENT_DUE_SOON]: {
        color: "orange",
        icon: "hourglass half",
        text: "Due Soon",
    },
    [InvoiceStatuses.DELIVERED]: {
        color: "green",
        icon: "truck",
        text: "Delivered",
    },
};

const InvoiceComponentStatus = ({
    status,
    onChange,
    isFormik = false,
    name,
    label,
    noIcon,
    ...otherProps
}) => {
    if (!status) return null;
    const data = statusMapping[status];

    if (!data) return null;

    if (!onChange && !isFormik) {
        return (
            <Button
                compact
                size="mini"
                color={data.color}
                content={data.text}
                icon={!noIcon && data.icon}
            />
        );
    }

    return isFormik ? (
        <FormikDropdown
            name={name}
            label={label}
            inputProps={{
                icon: !noIcon && data.icon,
                className: `icon  ${data.color} `,
                button: true,
                labeled: true,
                selection: false,
                "data-cy": "default_invoice",
            }}
            options={Object.values(InvoiceStatuses).map(value => ({
                key: value,
                value: value,
                text: statusMapping[value].text,
                icon: statusMapping[value].icon,
            }))}
            {...otherProps}
        />
    ) : (
        <Dropdown
            data-cy="invoice_status"
            button
            labeled
            floating
            value={status}
            text={data.text}
            onChange={onChange}
            icon={!noIcon && data.icon}
            className={`icon mini compact ${data.color}`}
            options={Object.values(InvoiceStatuses).map(is => ({
                key: is,
                value: is,
                text: statusMapping[is].text,
                icon: statusMapping[is].icon,
            }))}
            {...otherProps}
        />
    );
};

InvoiceComponentStatus.propTypes = {
    noIcon: PropTypes.bool,
    status: PropTypes.string,
    onChange: PropTypes.func,
};

export default InvoiceComponentStatus;
