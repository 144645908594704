import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Button, Form, Segment, Message } from "semantic-ui-react";

import { authResetInputChange } from "../actions";

const ResetPasswordForm = ({ inputs, authResetInputChange }) => (
    <Form size="large">
        <Segment stacked>
            <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                value={inputs.email}
                placeholder="E-mail address"
                onChange={e => authResetInputChange("email", e.target.value)}
            />

            <Button color="teal" fluid size="large">
                Reset Password
            </Button>
        </Segment>

        <Message>
            Got your password? <Link to="/auth/signin">Sign In</Link>
        </Message>
    </Form>
);

const mapStateToProps = ({ auth }) => ({
    inputs: auth.resetInputs,
    isSigningIn: auth.signingIn,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            authResetInputChange,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
