import { sortBy } from "lodash";
import React, { useState } from "react";
import { Table, Pagination } from "semantic-ui-react";

import { formatAmount } from "utilities/dist/helpers";

const ProductList = ({ productStats }) => {
    const pageSize = 5,
        rows = productStats.length,
        pageCount = parseInt(rows / pageSize),
        ellipsisItem = pageCount > 10 ? "..." : false,
        totalPages = pageCount + (rows % pageSize > 0 ? 1 : 0);

    const [activePage, setActivePage] = useState(1);
    const [sortColumn, setSortColumn] = useState("total_qty");
    const [sortDirection, setSortDirection] = useState("descending");

    const handleSort = clickedColumn => {
        if (sortColumn !== clickedColumn) {
            setSortColumn(clickedColumn);
            setSortDirection("ascending");
            return;
        }

        setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
    };

    let itemsToShow = sortBy(productStats, [sortColumn]);

    if (sortDirection === "descending") {
        itemsToShow = itemsToShow.reverse();
    }

    itemsToShow = itemsToShow.slice(activePage * pageSize - pageSize, activePage * pageSize);

    return (
        <Table sortable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell
                        sorted={sortColumn === "product.product" ? sortDirection : null}
                        onClick={() => handleSort("product.product")}
                    >
                        Product
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={sortColumn === "total_qty" ? sortDirection : null}
                        onClick={() => handleSort("total_qty")}
                    >
                        Units
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={sortColumn === "total_amount" ? sortDirection : null}
                        onClick={() => handleSort("total_amount")}
                    >
                        Amount
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={sortColumn === "total_invoices" ? sortDirection : null}
                        onClick={() => handleSort("total_invoices")}
                    >
                        Invoiced
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {itemsToShow.map(
                    ({ product, invoice, total_qty, total_amount, total_invoices }) => (
                        <Table.Row key={`${product.product}_${invoice.id}`}>
                            <Table.Cell>
                                {product.product}
                                <br />
                                <span className="text-grey">
                                    {product.product_type}, {product.product_size}
                                </span>
                            </Table.Cell>
                            <Table.Cell>{total_qty}</Table.Cell>
                            <Table.Cell>{formatAmount(total_amount)}</Table.Cell>
                            <Table.Cell>{total_invoices} times</Table.Cell>
                        </Table.Row>
                    )
                )}
            </Table.Body>

            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan="4">
                        <Pagination
                            size="tiny"
                            floated="right"
                            lastItem={null}
                            firstItem={null}
                            defaultActivePage={1}
                            totalPages={totalPages}
                            ellipsisItem={ellipsisItem}
                            onPageChange={(e, { activePage }) => setActivePage(activePage)}
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};

export default ProductList;
