import {
    INVOICE_EMAIL_GRID_DATA,
    INVOICE_EMAIL_GRID_ERROR,
    INVOICE_EMAIL_GRID_LOADING,
    INVOICE_EMAIL_GRID_PAGE_CHANGE,
    INVOICE_EMAIL_GRID_STATE_CHANGE,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";

export const loadEmailGridPage = (state, grid) => dispatch => {
    let { lastRow, sortModel, filterModel } = state;

    dispatch({
        type: INVOICE_EMAIL_GRID_LOADING,
    });

    const params = {
        endRow: grid.endRow,
        filterModel,
        sortModel,
        startRow: grid.startRow,
    };

    return makePrivateApiCall({
        url: "/invoice/email/grid",
        method: "get",
        params,
    })
        .then(res => {
            if (res.data) {
                const Emails = res.data.rows;
                lastRow = res.data.count;

                grid.successCallback(Emails, lastRow);

                dispatch({
                    type: INVOICE_EMAIL_GRID_DATA,
                    payload: { Emails, lastRow },
                });
            }
        })
        .catch(err => {
            grid.failCallback(err);
            dispatch({
                type: INVOICE_EMAIL_GRID_ERROR,
                payload: err.message,
            });
        });
};

export const changeEmailGridPage = page => {
    return {
        type: INVOICE_EMAIL_GRID_PAGE_CHANGE,
        payload: page,
    };
};

export const changeEmailGridState = gridState => dispatch => {
    return dispatch({
        type: INVOICE_EMAIL_GRID_STATE_CHANGE,
        payload: gridState,
    });
};
