import React, { useEffect } from "react";
import { Modal, Segment, Dimmer, Loader } from "semantic-ui-react";

import { persistor } from "../../store";

const CacheClearPage = () => {
    useEffect(() => {
        persistor.purge().then(() => {
            window.location.href = "/dashboard";
        });
    }, []);

    return (
        <Modal open={true} size="small">
            <Modal.Header>Clearing cached data...</Modal.Header>

            <Modal.Content>
                <Segment vertical>
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                    <div className="hp-25 sp-15"></div>
                </Segment>
            </Modal.Content>
        </Modal>
    );
};

export default CacheClearPage;
