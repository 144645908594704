import { toast } from "react-semantic-toasts";

import {
    INVENTORY_RESTOCK_AWAITING_ITEMS_DATA,
    INVENTORY_RESTOCK_AWAITING_ITEMS_ERROR,
    INVENTORY_RESTOCK_AWAITING_ITEMS_LOADING,
    INVENTORY_RESTOCK_SHOW_INLINE_EDITOR,
    INVENTORY_RESTOCK_HIDE_INLINE_EDITOR,
    INVENTORY_RESTOCK_CHANGE_INLINE_EDITOR,
    INVENTORY_RESTOCK_SELECT_PRODUCT,
    INVENTORY_RESTOCK_CHANGE_QTY,
    INVENTORY_RESTOCK_CHANGE_PRICE,
    INVENTORY_RESTOCK_ADD_PRODUCT,
    INVENTORY_RESTOCK_REMOVE_ITEM,
    INVENTORY_RESTOCK_COMPLETE,
    INVENTORY_RESTOCK_SET_DISTRIBUTOR,
    INVENTORY_RESTOCK_CLEAR_SELECTION,
    INVENTORY_RESTOCK_SET_REF_NUMBER,
    INVENTORY_RESTOCK_TOGGLE_ON_DEAL,
} from "../constants";
import { authExpire } from "../../auth/actions";
import { makePrivateApiCall } from "utilities/dist/api";

export const loadAwaitingItems = () => (dispatch, getState) => {
    dispatch({ type: INVENTORY_RESTOCK_AWAITING_ITEMS_LOADING });

    const filterModel = {
        // Custom filter model for the grid filter so that only items that require reordering are shown
        reorder_needed: {
            type: "equals",
            filterType: "number",
            filter: 1,
        },
    };

    const { inventoryRestock } = getState();
    if (inventoryRestock.distributor) {
        filterModel.distributor = {
            type: "equals",
            filterType: "text",
            filter: inventoryRestock.distributor,
        };
    }

    makePrivateApiCall({
        url: "/inventory/grid",
        method: "get",
        params: {
            ignoreLimit: true,
            filterModel,
        },
    })
        .then(res => {
            if (!res.data) {
                return dispatch({
                    type: INVENTORY_RESTOCK_AWAITING_ITEMS_ERROR,
                    payload: "No data returned from server",
                });
            }

            if (!res.data?.rows?.length) {
                toast({
                    time: 10000,
                    type: "warning",
                    icon: "warehouse",
                    description: "No out of stock items found",
                    title: "Restock",
                });
            }

            dispatch({
                type: INVENTORY_RESTOCK_AWAITING_ITEMS_DATA,
                payload: res.data.rows,
            });
        })
        .catch(err => {
            if (err.response && err.response.status === 401) {
                return dispatch(authExpire());
            }

            dispatch({
                type: INVENTORY_RESTOCK_AWAITING_ITEMS_ERROR,
                payload: err.message,
            });
        });
};

export const saveInventory = onSuccess => (dispatch, getState) => {
    dispatch({ type: INVENTORY_RESTOCK_AWAITING_ITEMS_LOADING });
    const { inventoryRestock } = getState();
    makePrivateApiCall({
        url: "/inventory",
        method: "put",
        data: {
            items: Object.values(inventoryRestock.items),
            ref_number: inventoryRestock.ref_number,
        },
    })
        .then(res => {
            dispatch({
                type: INVENTORY_RESTOCK_COMPLETE,
            });
            const restockedItemsCount = res.data?.length;
            const restockedUnitsCount = res.data?.reduce((acc, item) => acc + item.qty, 0);
            toast({
                time: 30000,
                type: "success",
                icon: "checkmark",
                title: "Restocking complete",
                description: `Added ${restockedUnitsCount} units of ${restockedItemsCount} items`,
            });
            onSuccess();
        })
        .catch(err => {
            if (err.response && err.response.status === 401) {
                return dispatch(authExpire());
            }

            // details will be sent back when backend validator throws an error
            if (err.response?.data?.details?.length) {
                return dispatch({
                    type: INVENTORY_RESTOCK_AWAITING_ITEMS_ERROR,
                    payload: err.response.data.details.map(({ message }) => message),
                });
            }

            dispatch({
                type: INVENTORY_RESTOCK_AWAITING_ITEMS_ERROR,
                payload: err.message,
            });
        });
};

export const clearSelection = () => ({
    type: INVENTORY_RESTOCK_CLEAR_SELECTION,
});

export const showInventoryRestockInlineEditor = (item, inlineEditorFields) => ({
    type: INVENTORY_RESTOCK_SHOW_INLINE_EDITOR,
    payload: { item, inlineEditorFields },
});

export const hideInventoryRestockInlineEditor = field => ({
    type: INVENTORY_RESTOCK_HIDE_INLINE_EDITOR,
    payload: field,
});

export const changeInventoryRestockInlineEditor = (field, value) => ({
    type: INVENTORY_RESTOCK_CHANGE_INLINE_EDITOR,
    payload: { field, value },
});

export const addInventoryRestockProduct = () => ({
    type: INVENTORY_RESTOCK_ADD_PRODUCT,
});

export const selectInventoryRestockProduct = product => ({
    type: INVENTORY_RESTOCK_SELECT_PRODUCT,
    payload: product,
});

export const changeInventoryRestockQty = (qty, id) => ({
    type: INVENTORY_RESTOCK_CHANGE_QTY,
    payload: { id, qty },
});

export const toggleInventoryRestockOnDeal = id => ({
    type: INVENTORY_RESTOCK_TOGGLE_ON_DEAL,
    payload: id,
});

export const changeInventoryRestockPrice = (price, id) => ({
    type: INVENTORY_RESTOCK_CHANGE_PRICE,
    payload: { id, price },
});

export const removeInventoryRestockItem = product => ({
    type: INVENTORY_RESTOCK_REMOVE_ITEM,
    payload: product,
});

export const setInventoryRestockDistributor = distributor => ({
    type: INVENTORY_RESTOCK_SET_DISTRIBUTOR,
    payload: distributor,
});

export const setInventoryRestockRefNumber = payload => ({
    type: INVENTORY_RESTOCK_SET_REF_NUMBER,
    payload,
});
