import React from "react";
import { Button, Table } from "semantic-ui-react";

export const InventoryRestockFooterContentComponent = ({ saveInventory, isLoading = false, clearSelection }) => {
    return (
        <Table.Row verticalAlign="top">
            <Table.HeaderCell colSpan={3} verticalAlign="top"></Table.HeaderCell>

            <Table.HeaderCell colSpan={2} textAlign="right" verticalAlign="top">
                <Button
                    data-cy="save_inventory"
                    size="small"
                    icon="check"
                    floated="right"
                    color={"green"}
                    content={"Save"}
                    loading={isLoading}
                    onClick={() => saveInventory()}
                />
                <Button
                    data-cy="clear_selection"
                    size="small"
                    icon="erase"
                    floated="right"
                    color={"yellow"}
                    content={"Reset List"}
                    onClick={clearSelection}
                />
            </Table.HeaderCell>
        </Table.Row>
    );
};
