import {
    COMPANY_FEATURE_TOGGLE_DATA,
    COMPANY_FEATURE_TOGGLE_ERROR,
    COMPANY_FEATURE_TOGGLE_LOADING,
    COMPANY_FEATURE_TOGGLE_CONFIRMING,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    error: null,
    isLoading: false,
    confirming: false,
};

const setFeatureToggleError = (state, error) => ({
    ...state,
    isLoading: false,
    confirming: false,
    error,
});
const setFeatureToggleConfirm = (state, confirming) => ({
    ...state,
    confirming,
});
const setFeatureToggleLoading = state => ({ ...state, isLoading: true });
const setFeatureToggleData = state => {
    return {
        ...state,
        error: null,
        isLoading: false,
        confirming: false,
    };
};

const reducers = {
    [COMPANY_FEATURE_TOGGLE_DATA]: setFeatureToggleData,
    [COMPANY_FEATURE_TOGGLE_ERROR]: setFeatureToggleError,
    [COMPANY_FEATURE_TOGGLE_LOADING]: setFeatureToggleLoading,
    [COMPANY_FEATURE_TOGGLE_CONFIRMING]: setFeatureToggleConfirm,
};

export default generateReducer(reducers, initialState);
