import {
    PRODUCT_GRID_DATA,
    PRODUCT_GRID_ERROR,
    PRODUCT_GRID_LOADING,
    PRODUCT_GRID_PAGE_CHANGE,
    PRODUCT_GRID_STATE_CHANGE,
} from "../constants";
import { authExpire } from "../../auth/actions";
import { makePrivateApiCall } from "utilities/dist/api";

export const loadProductGridPage =
    ({ dateRange, customerId }) =>
    dispatch => {
        dispatch({
            type: PRODUCT_GRID_LOADING,
        });

        const params = {};
        const dateFragments = dateRange.split(" - ");

        if (dateFragments.length > 1) {
            params.dateFrom = dateFragments[0].trim();
            params.dateTo = dateFragments[1].trim();
        }

        if (customerId) {
            params.customerId = customerId;
        }
        return makePrivateApiCall({
            url: "/product",
            method: "get",
            params,
        })
            .then(res => {
                if (res.data) {
                    const products = res.data.rows;

                    dispatch({
                        type: PRODUCT_GRID_DATA,
                        payload: {
                            products,
                        },
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    return dispatch(authExpire());
                }

                dispatch({
                    type: PRODUCT_GRID_ERROR,
                    payload: err.message,
                });
            });
    };

export const changeProductGridPage = page => {
    return {
        type: PRODUCT_GRID_PAGE_CHANGE,
        payload: page,
    };
};

export const changeProductGridState = gridState => dispatch =>
    dispatch({
        type: PRODUCT_GRID_STATE_CHANGE,
        payload: gridState,
    });
