import React from "react";
import PropTypes from "prop-types";
import { Segment, Item, Label, Button, Icon, Header, Divider } from "semantic-ui-react";
import { paidFeatureIds } from "./helpers";

const CompanyFeatureComponentItemSmall = ({ feature, isActive = false, toggleActivation }) => {
    const FeatureActions = () => {
        let btnText = "";
        const isPaidFeature = paidFeatureIds.includes(feature.id);

        if (feature.comingSoon) btnText = "Coming Soon";
        else if (isPaidFeature) btnText = isActive ? "Deactivate" : "Request Access";
        else btnText = isActive ? "Deactivate" : "Activate";

        return (
            <>
                <Button
                    size="small"
                    disabled={feature.comingSoon}
                    onClick={() => toggleActivation(feature)}
                    color={feature.comingSoon ? "grey" : isActive ? "red" : "green"}
                    data-cy={`feature-button-${feature.id}`}
                >
                    {btnText}
                    <Icon name="right chevron" />
                </Button>
                {/*we will use this in future maybe*/}
                {/*{isCloverFeature && isActive ? (*/}
                {/*    <Button*/}
                {/*        size="small"*/}
                {/*        disabled={feature.comingSoon}*/}
                {/*        onClick={() => syncHistory(feature)}*/}
                {/*        color={"green"}*/}
                {/*        data-cy={`feature-button-${feature.id}`}*/}
                {/*    >*/}
                {/*        Sync History*/}
                {/*        <Icon name="right chevron" />*/}
                {/*    </Button>*/}
                {/*) : (*/}
                {/*    ""*/}
                {/*)}*/}
            </>
        );
    };

    return (
        <div>
            <Header as="h4" attached="top">
                {feature.title}
            </Header>
            <Segment attached>
                <Item>
                    <Item.Image size="small" src={`/images/${feature.id}_feature_logo.svg`} />

                    <Divider hidden />
                    <Item.Content>
                        <Item.Meta>
                            <Label.Group size="small">
                                <Label>{feature.pricing}</Label>
                                <Label data-cy={`feature-status-${feature.id}`}>
                                    {isActive ? "Active" : "Inactive"}
                                </Label>
                            </Label.Group>
                        </Item.Meta>
                        <Item.Description>{feature.description}</Item.Description>
                        <Divider hidden />
                        <Item.Extra>
                            <FeatureActions />
                        </Item.Extra>
                    </Item.Content>
                </Item>
            </Segment>
        </div>
    );
};

CompanyFeatureComponentItemSmall.propTypes = {
    isActive: PropTypes.bool,
    feature: PropTypes.object,
    toggleActivation: PropTypes.func,
    syncHistory: PropTypes.func,
};

export default CompanyFeatureComponentItemSmall;
