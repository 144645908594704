import React from "react";
import { Confirm, Button } from "semantic-ui-react";

const InvoiceBuilderContainerConfirmToggle = ({ setShowModal, zeroPricedItems, saveOnConfirm }) => {
    const hideConfirm = () => {
        setShowModal(false);
    };

    const prizeZeroProductName = zeroPricedItems.map(item => {
        return `${item.product.product} (${item.product.product_size}) `;
    });

    const confirmPrizeZeroItem = {
        header: `Confirm create invoice`,
        content: `Are you sure you want to create the invoice with Item Price for these products "${prizeZeroProductName}" set to $0.00?`,
        cancelButton: <Button color="green" content="Fix Price" />,
        confirmButton: <Button negative content="Skip and create" />,
        onConfirm: saveOnConfirm,
        onCancel: hideConfirm,
        open: true,
    };

    const propsToPass = confirmPrizeZeroItem;

    return <Confirm {...propsToPass} />;
};

export default InvoiceBuilderContainerConfirmToggle;
