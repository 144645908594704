import React, { useRef } from "react";
import PropTypes from "prop-types";
import { omit, isFunction } from "lodash";
import CurrencyInput from "react-currency-masked-input";
import { Table, Button, Input, Grid, Popup, TextArea } from "semantic-ui-react";

import { InvoiceItemType } from "../types";
import { formatAmount } from "utilities/dist/helpers";
import { buildProductIdKey } from "../../product/helpers";
import { ProductTypeAcronyms } from "../../product/constants";
import { getProductFullName } from "../helpers";
import { history } from "../../store";

// order of the keys is very important here to make the table look nice and keep the columns in order
const keyHeaderMap = {
    actions: "Actions",
    type: "Type",
    qty: "Item Qty",
    product: "Product",
    price: "Item Price",
    total: "Total",
};
const keyAlignmentMap = {
    type: "left",
    qty: "center",
    product: "left",
    price: "left",
    total: "left",
    actions: "left",
};
const inlineEditableFields = ["qty", "price"];

const ItemCell = ({
    col,
    row,
    onItemEdit,
    onItemRemove,
    showInlineEditor,
    showItemLogInlineEditor,
    orderNumber,
    onShowInlineEditor,
    onShowInItemLogInlineEditor,
    onHideInlineEditor,
    onChangeInlineEditor,
    onChangeItemLogInlineEditor,
    isInternalSkuActive,
}) => {
    const qtyPopup = useRef(null);
    const pricePopup = useRef(null);
    let textAlign = keyAlignmentMap[col];
    let value = row[col];
    const addNoteAndRemoveItem = col => {
        onItemRemove();
        onHideInlineEditor(col);
    };
    const addNote = col => {
        onHideInlineEditor(col);
    };
    const changeInlineEditor = (col, value) => {
        onChangeInlineEditor(col, value);
        if (orderNumber) onChangeItemLogInlineEditor(col, value);
    };

    if (showInlineEditor) {
        if (col === "qty") {
            let qtyChangeNote = row.qtyChangeNote;
            return (
                <Table.Cell textAlign="center">
                    <Input action size="small" type="number" className="hide-number-arrows">
                        <input
                            value={value}
                            step="1"
                            type="number"
                            autoFocus={true}
                            style={{ width: "60px" }}
                            onKeyUp={e => {
                                if (e?.key === "Enter") {
                                    if (qtyPopup.current) {
                                        qtyPopup.current.triggerRef.current.click();
                                    } else {
                                        onHideInlineEditor(col);
                                    }
                                }
                            }}
                            onChange={e => changeInlineEditor(col, e.target.value)}
                        />

                        {orderNumber && row?.previousQty !== row?.newQty ? (
                            <Popup
                                wide
                                trigger={<Button icon="checkmark" />}
                                on="click"
                                ref={qtyPopup}
                            >
                                {showItemLogInlineEditor && (
                                    <Grid divided columns="equal">
                                        <Grid.Column>
                                            <Popup
                                                trigger={
                                                    <div>
                                                        <TextArea
                                                            autoFocus={true}
                                                            value={qtyChangeNote}
                                                            type="text"
                                                            rows={3}
                                                            size="mini"
                                                            className="item-log-note-column"
                                                            placeholder="Add note to change item quantity"
                                                            onChange={e =>
                                                                onChangeItemLogInlineEditor(
                                                                    (col = "qty_change_note"),
                                                                    e.target.value
                                                                )
                                                            }
                                                        />

                                                        <Button
                                                            icon="checkmark"
                                                            color="green"
                                                            content="Change quantity"
                                                            size="mini"
                                                            fluid
                                                            onClick={() => addNote(col)}
                                                        />
                                                    </div>
                                                }
                                                content="Add note as the reason for changing item quantity or leave the field empty"
                                                position="top center"
                                                size="tiny"
                                                inverted
                                            />
                                        </Grid.Column>
                                    </Grid>
                                )}
                            </Popup>
                        ) : (
                            <Button icon="checkmark" onClick={() => onHideInlineEditor(col)} />
                        )}
                    </Input>
                </Table.Cell>
            );
        }
        if (col === "price") {
            let priceChangeNote = row.priceChangeNote;
            return (
                <Table.Cell textAlign="center">
                    <div className="ui fluid small input hide-number-arrows action">
                        <CurrencyInput
                            autoFocus={true}
                            value={parseFloat(value || 0).toFixed(2)}
                            onChange={(e, value) => changeInlineEditor(col, value)}
                            onKeyUp={e => {
                                if (e?.key === "Enter") {
                                    if (pricePopup.current) {
                                        pricePopup.current.triggerRef.current.click();
                                    } else {
                                        onHideInlineEditor(col);
                                    }
                                }
                            }}
                        />

                        {orderNumber &&
                        row?.previousPrice &&
                        row?.previousPrice !== row?.newPrice ? (
                            <Popup wide trigger={<Button icon="checkmark" />} on="click" ref={pricePopup}>
                                {showItemLogInlineEditor && (
                                    <Grid divided columns="equal">
                                        <Grid.Column>
                                            <Popup
                                                trigger={
                                                    <div>
                                                        <TextArea
                                                            value={priceChangeNote}
                                                            autoFocus={true}
                                                            rows={3}
                                                            size="mini"
                                                            className="item-log-note-column"
                                                            placeholder="Add note to change item price"
                                                            onChange={e =>
                                                                onChangeItemLogInlineEditor(
                                                                    (col = "price_change_note"),
                                                                    e.target.value
                                                                )
                                                            }
                                                        />

                                                        <Button
                                                            icon="checkmark"
                                                            color="green"
                                                            content="Change price"
                                                            size="mini"
                                                            fluid
                                                            onClick={() => addNote(col)}
                                                        />
                                                    </div>
                                                }
                                                content="Add note as the reason for changing item price or leave the field empty"
                                                position="top center"
                                                size="tiny"
                                                inverted
                                            />
                                        </Grid.Column>
                                    </Grid>
                                )}
                            </Popup>
                        ) : (
                            <Button icon="checkmark" onClick={() => onHideInlineEditor(col)} />
                        )}
                    </div>
                </Table.Cell>
            );
        }
    }

    if (col === "type") {
        value = <b>{ProductTypeAcronyms[row.product?.product_type.toUpperCase()]}</b>;
    }

    if (col === "product") {
        return (
            <Table.Cell>
                {isInternalSkuActive && value.internal_sku && value.internal_sku !== "0"
                    ? `${value.internal_sku} -- `
                    : ""}
                {getProductFullName(value)}
            </Table.Cell>
        );
    }

    if (col === "actions") {
        return (
            <Table.Cell>
                <Button.Group size="mini">
                    {orderNumber ? (
                        <Popup
                            wide
                            trigger={<Button icon="close" onClick={onItemEdit} />}
                            on="click"
                        >
                            {showItemLogInlineEditor && (
                                <Grid divided columns="equal">
                                    <Grid.Column>
                                        <Popup
                                            trigger={
                                                <div>
                                                    <TextArea
                                                        autoFocus={true}
                                                        rows={3}
                                                        size="mini"
                                                        className="item-log-note-column"
                                                        placeholder="Add note to remove item"
                                                        onChange={e =>
                                                            onChangeItemLogInlineEditor(
                                                                col,
                                                                e.target.value
                                                            )
                                                        }
                                                    />

                                                    <Button
                                                        icon="close"
                                                        color="red"
                                                        content="Remove item"
                                                        size="mini"
                                                        fluid
                                                        onClick={() => addNoteAndRemoveItem(col)}
                                                    />
                                                </div>
                                            }
                                            content="Add note as the reason for removing item or leave the field empty"
                                            position="top center"
                                            size="tiny"
                                            inverted
                                        />
                                    </Grid.Column>
                                </Grid>
                            )}
                        </Popup>
                    ) : (
                        <Button icon="close" onClick={onItemRemove} />
                    )}

                    <Button icon="pencil" onClick={onItemEdit} />
                </Button.Group>
            </Table.Cell>
        );
    }

    if (col === "price" || col === "total") {
        value = formatAmount(value);

        if (row.taxable) {
            value = (
                <>
                    {value}
                    <b className="text-red">*</b>
                </>
            );
        }
    }

    const canInlineEditField =
        inlineEditableFields.indexOf(col) >= 0 && isFunction(onShowInlineEditor);
    const canItemLogInlineEditField =
        inlineEditableFields.indexOf(col) >= 0 && isFunction(onShowInItemLogInlineEditor);
    const canInlineEditAllField = col => {
        onShowInlineEditor([col]);
        onShowInItemLogInlineEditor([col]);
    };
    return (
        <Table.Cell
            textAlign={textAlign}
            onDoubleClick={() =>
                canInlineEditField && canItemLogInlineEditField
                    ? canInlineEditAllField(col)
                    : canInlineEditField
                    ? onShowInlineEditor([col])
                    : null
            }
        >
            {value}
        </Table.Cell>
    );
};

const InvoiceBuilderComponentProductList = ({
    items,
    footerContent,
    orderNumber,
    isEditable,
    onItemEdit,
    onItemRemove,
    isInternalSkuActive,

    inlineEditorFields = [],
    itemLogInlineEditorFields = [],
    inlineEditorItemId = "",
    itemLogInlineEditorItemId = "",
    onShowInlineEditor,
    onShowInItemLogInlineEditor,
    onHideInlineEditor,
    onChangeInlineEditor,
    onChangeItemLogInlineEditor,
}) => {
    let columns = !isEditable ? omit(keyHeaderMap, ["actions"]) : omit(keyHeaderMap, ["type"]);

    const showRedBackground =
        history.location.pathname.includes("create") || history.location.pathname.includes("edit");

    return (
        <Table basic="very" data-testid="invoice-product-list-table">
            <Table.Header>
                <Table.Row>
                    {Object.keys(columns).map(col => (
                        <Table.HeaderCell
                            key={`product_list_header_${col}`}
                            className={`product-list-column-${col}`}
                            textAlign={
                                inlineEditorFields.indexOf(col) !== -1
                                    ? "center"
                                    : keyAlignmentMap[col]
                            }
                        >
                            {columns[col]}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {items.map((item, i) => {
                    return (
                        <Table.Row
                            key={`product_list_row_${i}`}
                            negative={!item.price && showRedBackground ? true : false}
                        >
                            {Object.keys(columns).map(col => (
                                <ItemCell
                                    col={col}
                                    row={item}
                                    key={`product_list_cell_${i}_${col}`}
                                    showInlineEditor={
                                        inlineEditorFields.indexOf(col) !== -1 &&
                                        inlineEditorItemId ===
                                            buildProductIdKey(item.product, item.ydk_product)
                                    }
                                    showItemLogInlineEditor={
                                        itemLogInlineEditorFields.indexOf(col) !== -1 &&
                                        itemLogInlineEditorItemId ===
                                            buildProductIdKey(item.product, item.ydk_product)
                                    }
                                    isInternalSkuActive={isInternalSkuActive}
                                    onItemEdit={() => onItemEdit(item)}
                                    onItemRemove={() => onItemRemove(item)}
                                    onHideInlineEditor={onHideInlineEditor}
                                    onChangeInlineEditor={onChangeInlineEditor}
                                    onChangeItemLogInlineEditor={onChangeItemLogInlineEditor}
                                    orderNumber={orderNumber}
                                    onShowInlineEditor={field => onShowInlineEditor(item, field)}
                                    onShowInItemLogInlineEditor={field =>
                                        onShowInItemLogInlineEditor?.(item, field)
                                    }
                                />
                            ))}
                        </Table.Row>
                    );
                })}
            </Table.Body>

            {/* We need the style for pretty printing */}
            <Table.Footer style={{ display: "table-row-group" }}>{footerContent}</Table.Footer>
        </Table>
    );
};

InvoiceBuilderComponentProductList.propTyps = {
    items: PropTypes.arrayOf(InvoiceItemType),
    isEditable: PropTypes.bool.isRequired,
    inlineEditorFields: PropTypes.array,
    itemLogInlineEditorFields: PropTypes.array,
    footerContent: PropTypes.node,

    onChangeInlineEditor: PropTypes.func,
    onChangeItemLogInlineEditor: PropTypes.func,
    onShowInlineEditor: PropTypes.func,
    onShowInItemLogInlineEditor: PropTypes.func,
    onHideInlineEditor: PropTypes.func,
    onItemRemove: PropTypes.func,
    onItemEdit: PropTypes.func,
};

export default InvoiceBuilderComponentProductList;
