import { isEmpty, omit, pick } from "lodash";
import { InvoiceStatuses } from "utilities/dist/invoice/constants";
import { computeTaxedTotal } from "utilities/dist/invoice/helpers";

import {
    INVOICE_BUILDER_UPDATE_CUSTOMER,
    INVOICE_BUILDER_SET_CUSTOMER,
    INVOICE_BUILDER_SELECT_PRODUCT,
    INVOICE_BUILDER_CHANGE_PRICE,
    INVOICE_BUILDER_CHANGE_QTY,
    INVOICE_BUILDER_TOGGLE_TAXABLE,
    INVOICE_BUILDER_ADD_PRODUCT,
    INVOICE_BUILDER_SAVING,
    INVOICE_BUILDER_SAVED,
    INVOICE_BUILDER_ERROR,
    INVOICE_BUILDER_REMOVE_ITEM,
    INVOICE_BUILDER_INVOICE_LOADING,
    INVOICE_BUILDER_INVOICE_DATA,
    INVOICE_BUILDER_CHANGE_STATUS,
    INVOICE_BUILDER_CHANGE_DATE,
    INVOICE_BUILDER_CHANGE_STAMP,
    INVOICE_BUILDER_CHANGE_NOTE,
    INVOICE_BUILDER_RESET,
    INVOICE_BUILDER_CLONING_LAST,
    INVOICE_BUILDER_CLONE,
    INVOICE_BUILDER_CREATED,
    INVOICE_BUILDER_SHOW_INLINE_EDITOR,
    INVOICE_BUILDER_HIDE_INLINE_EDITOR,
    INVOICE_BUILDER_CHANGE_INLINE_EDITOR,
    INVOICE_BUILDER_CHANGE_LOGO,
    INVOICE_BUILDER_TOGGLE_LOGO_PICKER,
    INVOICE_CONVERTER_UPLOAD_SUCCESS,
    INVOICE_BUILDER_REMOVE_STAMP_ROLL,
    INVOICE_BUILDER_ADD_STAMP_ROLL,
    INVOICE_BUILDER_SELECT_STAMP_ROLL,
    INVOICE_BUILDER_GET_ORDER,
} from "../constants";
import { STAMP_ROLL_DATA } from "../../stamp/constants";
import { buildProductIdKey } from "../../product/helpers";
import { generateReducer, parseCurrency } from "../../common/helpers";
import { countStampsNeeded, computeStampTotal, isQtyComputable } from "../helpers";

const initialState = {
    cloningLast: false,
    order_number: "",

    note: "",
    items: {},
    editor: {},
    customer: {},
    invoice: null,
    logo_id: null,
    invoiced_at: new Date(),
    status: "",

    inlineEditorFields: [],
    inlineEditorItemId: "",

    stamps: [],
    stampRolls: [],
    editingStamp: {},
    availableStampSequences: [],

    showLogoPicker: false,

    errors: [],
    isSaving: false,
    isLoading: false,
};

const assignStamps = ({ availableStampSequences, items }) => {
    const totalStampsNeeded = countStampsNeeded({ items });
    const stamps = [];

    let stampsNeeded = totalStampsNeeded;

    availableStampSequences.forEach(roll => {
        if (stampsNeeded < 1) return;

        const stampData = {
            roll_id: roll.id,
            prefix: roll.prefix,
            from: roll.firstAvailable,
        };

        if (roll.totalAvailable < stampsNeeded) {
            stamps.push({
                ...stampData,
                to: roll.lastAvailable,
            });
            stampsNeeded = stampsNeeded - roll.totalAvailable;
        } else {
            stamps.push({
                ...stampData,
                to: roll.firstAvailable + stampsNeeded - 1,
            });
            stampsNeeded = 0;
        }
    });

    return stamps;
};

const changeNote = (state, note) => ({ ...state, note });
const changeStatus = (state, status) => ({ ...state, status });
const changeLogo = (state, logo_id) => ({ ...state, logo_id });
const removeStampRoll = (state, stampIndex) => {
    const stamps = [...state.stamps];

    stamps.splice(stampIndex, 1);

    return { ...state, stamps };
};

const changeStamp = (state, stamp = {}) => {
    if (isEmpty(Object.keys(stamp))) return { ...state, editingStamp: {} };

    const { stamps, items } = state;
    const editingStamp = { ...state.editingStamp, ...stamp };

    if (stamp?.from) {
        const totalStampsAssigned = computeStampTotal(stamps);
        const totalStampsNeeded = countStampsNeeded({ items });
        const stampsNeeded = totalStampsNeeded - totalStampsAssigned;
        // TODO: check if the entire block is available or not
        editingStamp.to = Number(editingStamp.from) + stampsNeeded - 1;
    }
    return { ...state, editingStamp };
};
const setCustomer = (state, customer) => ({ ...state, customer });

const updateInvoiceWithCustomer = (state, customer) => {
    return {
        ...state,
        customer,
        invoice: { ...state.invoice, customer_id: customer.id, customer },
    };
};

const changeDate = (state, invoiced_at) => ({ ...state, invoiced_at });
const selectProduct = (state, product) => {
    let newState = { ...state, editor: {} };

    if (!isEmpty(product)) {
        newState.editor = {
            product,
            qty: product.qty,
            price: product.price,
            taxable: !!product.taxable,
            total: product.qty * product.price,
            scanned: !!product.scanned,
        };

        newState.editor.total += computeTaxedTotal(newState.editor);
    }

    return newState;
};

const changePrice = (state, { price, id }) => {
    const newState = { ...state };
    let product = newState.editor;

    if (id) {
        product = { ...newState.products[id] };
    }

    product.price = parseFloat(price);
    product.total = parseCurrency(product.qty * product.price);
    product.total += computeTaxedTotal(product);

    if (id) {
        newState.products[id] = product;
    }

    return newState;
};

const changeQty = (state, { qty, id }) => {
    const newState = { ...state };
    let product = newState.editor;

    if (id) {
        product = { ...newState.products[id] };
    }

    product.qty = isQtyComputable(qty) ? Number(qty) : qty;
    const multipliableQty = isQtyComputable(qty) ? product.qty : 0;
    product.total = parseCurrency(product.price * multipliableQty);
    product.total += computeTaxedTotal(product);

    if (id) {
        newState.products[id] = product;
    }

    return newState;
};
const toggleTaxable = (state, id) => {
    const newState = { ...state };
    let product = newState.editor;

    if (id) {
        product = { ...newState.products[id] };
    }

    product.taxable = !product.taxable;
    product.total = parseCurrency(product.price * product.qty);
    product.total += computeTaxedTotal(product);

    if (id) {
        newState.products[id] = product;
    }

    return newState;
};
const addProduct = state => {
    const item = state.editor;
    const key = buildProductIdKey(item.product);
    const items = { ...state.items, [key]: item };

    let newState = {
        ...state,
        items,
        editor: {},
    };

    newState.stamps = assignStamps(newState);

    return newState;
};

const removeItem = (state, item) => {
    const items = omit(state.items, buildProductIdKey(item.product));
    const newState = { ...state, items };

    newState.stamps = assignStamps(newState);

    return newState;
};

const loadInvoice = (state, invoice) => {
    if (!invoice) {
        return {
            ...state,
            note: "",
            items: {},
            stamps: [],
            customer: {},
            logo_id: null,
            invoice: null,
            isLoading: false,
            invoiced_at: new Date(),
            status: InvoiceStatuses.DRAFT,
        };
    }

    // since all items from existing invoice must have an actual product entry
    // we know that the keys can't be source_ so we manually build product_ keys
    const items = {};
    invoice.items.forEach(item => {
        items[`product_${item.product_id}`] = item;
    });

    return {
        ...initialState,
        items,
        invoice,
        isLoading: false,

        note: invoice.note,
        stamps: invoice.stamps,
        status: invoice.status,
        logo_id: invoice.logo_id,
        customer: invoice.customer,
        invoiced_at: invoice.invoiced_at,

        stampRolls: state.stampRolls,
        availableStampSequences: state.availableStampSequences,
    };
};
const reset = state => ({
    ...initialState,
    // these need to be kept as is since they only load on first page load of the editor
    stampRolls: state.stampRolls,
    availableStampSequences: state.availableStampSequences,
});
const setSaving = state => ({ ...state, isSaving: true });
const setError = (state, errors) => ({ ...state, errors, isSaving: false });
const setInvoiceLoading = (state, isLoading) => ({
    ...state,
    isLoading,
    errors: [],
});

const setShowLogoPicker = (state, showLogoPicker) => ({
    ...state,
    showLogoPicker,
});

const setCloning = (state, cloningLast) => ({ ...state, cloningLast });
const setOrderInvoice = (state, orderInvoice) => ({ ...state, orderInvoice });

const cloneLast = (state, invoice) => {
    const newState = {
        ...state,
        items: {},
        order_number: invoice?.order_number,
        ...pick(invoice, ["note", "status", "customer"]),
    };
    invoice.items.forEach(item => {
        newState.items[buildProductIdKey(item.product, item.ydk_product)] = item;
    });
    newState.stamps = assignStamps(newState);

    return newState;
};

const showInlineEditor = (state, { item, inlineEditorFields }) => {
    return {
        ...state,
        inlineEditorFields,
        inlineEditorItemId: buildProductIdKey(item.product, item.ydk_product),
    };
};

const hideInlineEditor = (state, field) => {
    const newState = {
        ...state,
        inlineEditorFields: state.inlineEditorFields.filter(f => f !== field),
    };

    if (newState.inlineEditorFields.length < 1) {
        newState.inlineEditorItemId = "";
    }

    return newState;
};

const updateItemInline = (state, { field, value }) => {
    if (state.inlineEditorFields.length < 1) return state;

    const newState = { ...state },
        item = newState.items[state.inlineEditorItemId];
    if (field === "price") {
        item.price = parseFloat(value);
    } else if (field === "qty") {
        item.qty = isQtyComputable(value) ? Number(value) : value;
    }

    item.total = parseCurrency(item.price * (isQtyComputable(item.qty) ? item.qty : 0));
    item.total += computeTaxedTotal(item);

    newState.items[state.inlineEditorItemId] = item;
    newState.stamps = assignStamps(newState);

    return newState;
};

const addItemsFromConvertedFile = (state, { items }) => {
    const newState = { ...state, items: {} };

    items.forEach(item => {
        newState.items[buildProductIdKey(item.product)] = item;
    });

    newState.stamps = assignStamps(newState);

    return newState;
};

const setStampRolls = (state, rolls) => {
    let availableStampSequences = [];

    /**
     * From [{number: 2, number: 3, number: 5, number:6, number: 7}]
     * Returns [[2, 3], [5,6,7]]
     */
    const buildSequencesOfStamps = (result, { number }) => {
        const lastSubArray = result[result.length - 1];

        if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== number - 1) {
            result.push([]);
        }

        result[result.length - 1].push(number);

        return result;
    };

    /**
     * Goes through every roll, finds out the ones that can be used and
     * builds availableStampSequences array
     */
    const filter = roll => {
        const canBeUsed = roll.active && !roll.finished && roll.stamps.length > 0;

        if (!canBeUsed) return false;

        let sequences = roll.stamps.reduce(buildSequencesOfStamps, []).map(availableStamps => {
            const firstAvailable = availableStamps[0];
            const lastAvailable = availableStamps[availableStamps.length - 1];
            return {
                ...omit(roll, ["stamps"]),
                lastAvailable,
                firstAvailable,
                totalAvailable: lastAvailable - firstAvailable + 1,
            };
        });

        availableStampSequences = [...availableStampSequences, ...sequences];

        return true;
    };

    const stampRolls = rolls.filter(filter);
    const newState = {
        ...state,
        stampRolls,
        availableStampSequences,
    };

    // only update stamps if we are not editing existing invoice
    if (!state.invoice && !state.isLoading)
        newState.stamps = assignStamps({
            availableStampSequences,
            items: state.items,
        });

    return newState;
};

const addStampRoll = state => {
    const { editingStamp } = state;

    return {
        ...state,
        editingStamp: {},
        stamps: [...state.stamps, editingStamp],
    };
};

const selectStampRoll = (state, rollIndex) => {
    const { editingStamp, stamps, items } = state;
    const totalStampsAssigned = computeStampTotal(stamps);
    const totalStampsNeeded = countStampsNeeded({ items });
    const stampsNeeded = totalStampsNeeded - totalStampsAssigned;

    const roll = omit(state.availableStampSequences[rollIndex], ["stamps"]);
    const from = roll.firstAvailable;
    let to = roll.firstAvailable;

    if (stampsNeeded > 0) {
        // if we need more stamps by auto calculation, count how many of the needed stamps we have available in the selected roll
        // if the roll has more than we need, only assign the ones we need
        // otherwise assign all the stamps available in the roll
        if (stampsNeeded > roll.totalAvailable) {
            to += roll.totalAvailable - 1;
        } else {
            to += stampsNeeded - 1;
        }
    } else if (editingStamp.from && editingStamp.to) {
        // ensure that if user was adding SS210-SS220 and then changes roll to RR550 sequence
        // we automatically prefill RR550-RR560
        to += Number(editingStamp.to) - Number(editingStamp.from);
    }

    return { ...state, editingStamp: { ...roll, from, to, roll_id: roll.id } };
};

const reducers = {
    [INVOICE_BUILDER_SET_CUSTOMER]: setCustomer,
    [INVOICE_BUILDER_UPDATE_CUSTOMER]: updateInvoiceWithCustomer,

    [INVOICE_BUILDER_CHANGE_QTY]: changeQty,
    [INVOICE_BUILDER_ADD_PRODUCT]: addProduct,
    [INVOICE_BUILDER_CHANGE_DATE]: changeDate,
    [INVOICE_BUILDER_CHANGE_NOTE]: changeNote,
    [INVOICE_BUILDER_CHANGE_LOGO]: changeLogo,
    [INVOICE_BUILDER_CHANGE_PRICE]: changePrice,
    [INVOICE_BUILDER_CHANGE_STATUS]: changeStatus,
    [INVOICE_BUILDER_TOGGLE_TAXABLE]: toggleTaxable,
    [INVOICE_BUILDER_SELECT_PRODUCT]: selectProduct,

    [INVOICE_BUILDER_CHANGE_STAMP]: changeStamp,
    [INVOICE_BUILDER_ADD_STAMP_ROLL]: addStampRoll,
    [INVOICE_BUILDER_REMOVE_STAMP_ROLL]: removeStampRoll,
    [INVOICE_BUILDER_SELECT_STAMP_ROLL]: selectStampRoll,

    [INVOICE_BUILDER_REMOVE_ITEM]: removeItem,

    [INVOICE_BUILDER_TOGGLE_LOGO_PICKER]: setShowLogoPicker,
    [INVOICE_BUILDER_CLONING_LAST]: setCloning,
    [INVOICE_BUILDER_CLONE]: cloneLast,

    [INVOICE_BUILDER_SHOW_INLINE_EDITOR]: showInlineEditor,
    [INVOICE_BUILDER_HIDE_INLINE_EDITOR]: hideInlineEditor,
    [INVOICE_BUILDER_CHANGE_INLINE_EDITOR]: updateItemInline,

    [INVOICE_BUILDER_RESET]: reset,
    [INVOICE_BUILDER_SAVED]: reset,
    [INVOICE_BUILDER_CREATED]: reset,
    [INVOICE_BUILDER_ERROR]: setError,
    [INVOICE_BUILDER_SAVING]: setSaving,

    [INVOICE_BUILDER_GET_ORDER]: setOrderInvoice,

    [INVOICE_BUILDER_INVOICE_DATA]: loadInvoice,
    [INVOICE_BUILDER_INVOICE_LOADING]: setInvoiceLoading,

    [INVOICE_CONVERTER_UPLOAD_SUCCESS]: addItemsFromConvertedFile,

    [STAMP_ROLL_DATA]: setStampRolls,
};

export default generateReducer(reducers, initialState);
