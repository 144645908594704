import {
    INVOICE_DOCUMENT_DATA,
    INVOICE_DOCUMENT_ERROR,
    INVOICE_DOCUMENT_LOADING,
    INVOICE_DOCUMENT_UPLOADING,
    INVOICE_DOCUMENT_UPLOAD_ERROR,
    INVOICE_DOCUMENT_UPLOAD_SUCCESS,
    INVOICE_DOCUMENT_REMOVING,
    INVOICE_DOCUMENT_REMOVE_ERROR,
    INVOICE_DOCUMENT_REMOVE_SUCCESS,
    INVOICE_DOCUMENT_UPLOAD_WEBCAM,
    INVOICE_DOCUMENT_EDITOR_ID,
    INVOICE_DOCUMENT_EDITOR_IS_SAVING,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    uploadError: null,
    uploadingDocument: null,

    openWebCam: false,
    invoiceId: null,

    removeError: null,
    removingDocumentsId: null,

    editorDocumentId: null,
    editorIsSaving: false,

    documents: [],
    error: null,
    isLoading: false,
};

const setUploadingImage = (state, uploadingDocument) => ({
    ...state,
    uploadingDocument,
    uploadError: null,
});
const setUploadWebCam = (state, data) => ({
    ...state,
    openWebCam: data.openWebCam,
    invoiceId: data.invoiceId,
    uploadError: null,
});
const setUploadError = (state, uploadError) => ({
    ...state,
    uploadingDocument: null,
    uploadError,
});
const setUploadData = (state, document) => ({
    ...state,
    uploadError: null,
    uploadingDocument: null,
    documents: [document, ...state.documents],
});

const setRemovingDocuments = (state, removingDocumentsId) => ({
    ...state,
    removingDocumentsId,
    removeError: null,
});
const setRemovingError = (state, removeError) => ({
    ...state,
    removingDocumentsId: null,
    removeError,
});
const setRemovedDocuments = (state, documentId) => ({
    ...state,
    removeError: null,
    removingDocumentsId: null,
    documents: state.documents.filter(({ id }) => id !== documentId),
});

const setDocuments = (state, { rows }) => ({
    ...state,
    isLoading: false,
    documents: rows.sort((a, z) => new Date(z.updatedAt) - new Date(a.updatedAt)),
});
const setDocumentsError = (state, error) => ({
    ...state,
    error,
    isLoading: false,
});
const setDocumentsLoading = state => ({
    ...state,
    error: null,
    isLoading: true,
});

const setEditorDocumentId = (state, editorDocumentId) => ({
    ...state,
    editorDocumentId,
    editorIsSaving: false,
});

const setEditorDocumentIsSaving = (state, { editorIsSaving, updatedDocument }) => {
    if (!updatedDocument) {
        return {
            ...state,
            editorIsSaving,
        };
    }

    let newDoc = { ...updatedDocument };
    const allDocuments = [...state.documents].filter(doc => {
        if (doc.id !== updatedDocument.id) {
            return true;
        }

        newDoc = { ...doc, ...newDoc };
        return false;
    });

    return {
        ...state,
        editorIsSaving,
        documents: [newDoc, ...allDocuments],
    };
};

const reducers = {
    [INVOICE_DOCUMENT_UPLOADING]: setUploadingImage,
    [INVOICE_DOCUMENT_UPLOAD_ERROR]: setUploadError,
    [INVOICE_DOCUMENT_UPLOAD_SUCCESS]: setUploadData,

    [INVOICE_DOCUMENT_UPLOAD_WEBCAM]: setUploadWebCam,

    [INVOICE_DOCUMENT_DATA]: setDocuments,
    [INVOICE_DOCUMENT_ERROR]: setDocumentsError,
    [INVOICE_DOCUMENT_LOADING]: setDocumentsLoading,

    [INVOICE_DOCUMENT_REMOVING]: setRemovingDocuments,
    [INVOICE_DOCUMENT_REMOVE_SUCCESS]: setRemovedDocuments,
    [INVOICE_DOCUMENT_REMOVE_ERROR]: setRemovingError,

    [INVOICE_DOCUMENT_EDITOR_ID]: setEditorDocumentId,
    [INVOICE_DOCUMENT_EDITOR_IS_SAVING]: setEditorDocumentIsSaving,
};

export default generateReducer(reducers, initialState);
