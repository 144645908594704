import { get } from "lodash";

import {
    COMPANY_EDITOR_SAVING,
    COMPANY_EDITOR_SAVED,
    COMPANY_EDITOR_INPUT,
    COMPANY_EDITOR_ERROR,
} from "../constants";
import { makePrivateApiCall } from "utilities/dist/api";
import { loadLastStamp } from "../../stamp/warning/actions";

export const saveCompany =
    (company = {}) =>
    dispatch => {
        dispatch({
            type: COMPANY_EDITOR_SAVING,
            payload: true,
        });

        const request = {
            url: `/company/${company.id}`,
            method: "put",
            data: company,
        };

        return makePrivateApiCall(request)
            .then(res => {
                if (res.data) {
                    dispatch({ type: COMPANY_EDITOR_SAVED, payload: res.data });
                    dispatch(loadLastStamp());
                    return res.data;
                }
            })
            .catch(err => {
                let messages = [];
                const data = get(err, "response.data", null);

                if (!data) {
                    messages.push(err.message);
                } else if (data.details) {
                    data.details.forEach(({ message }) => messages.push(message));
                } else if (data.message) {
                    messages.push(data.message);
                }

                dispatch(setCompanyEditorError(messages));
                return null;
            });
    };

export const setCompanyEditorInput =
    (inputs = {}) =>
    dispatch => {
        return dispatch({
            type: COMPANY_EDITOR_INPUT,
            payload: inputs,
        });
    };

export const setCompanyEditorError = error => dispatch => {
    return dispatch({
        type: COMPANY_EDITOR_ERROR,
        payload: error,
    });
};
