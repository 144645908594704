import { keyBy } from "lodash";
import { format } from "date-fns";

import { DateTimeFormat } from "utilities/dist/invoice/constants";
import { ACTIVITY_MODULES, USER_ACTIVITY_TYPES } from "utilities/dist/user/constants";
import { SYSTEM_ACTIVITY_TYPES } from "utilities/dist/user/constants";

export const allGridColumns = keyBy(
    [
        {
            filter: false,
            sortable: false,
            field: "status",
            headerName: "Status",
            valueGetter: ({ data }) => (data?.success ? "Success" : "Error"),
        },
        {
            minWidth: 25,
            field: "activity",
            headerName: "Activity",
            filter: false,
            sortable: false,
            valueGetter: ({ data }) => {
                if (data.module === ACTIVITY_MODULES.AUTH) {
                    if (data.type === USER_ACTIVITY_TYPES.SIGN_IN) {
                        return "Signed In";
                    }
                }
                if (data.module === ACTIVITY_MODULES.INVOICE) {
                    const map = {
                        [USER_ACTIVITY_TYPES.CREATE]: "Created",
                        [USER_ACTIVITY_TYPES.UPDATE]: "Updated",
                        [USER_ACTIVITY_TYPES.DELETE]: "Deleted",
                    };

                    const info = data.info ? JSON.parse(data.info) : {};

                    const invoiceNumber = `#${info?.invoiceNumber}${info.beer ? "B" : ""}`;
                    return `${map[data.type]} Invoice ${invoiceNumber}`;
                }

                if (data.module === ACTIVITY_MODULES.PAYMENT) {
                    const map = {
                        [USER_ACTIVITY_TYPES.CREATE]: "Created",
                        [USER_ACTIVITY_TYPES.DELETE]: "Deleted",
                    };

                    return `${map[data.type]} Payment`;
                }

                if (data.module === ACTIVITY_MODULES.BOTTLE_POS) {
                    const map = {
                        [SYSTEM_ACTIVITY_TYPES.INVOICE_SYNC]: "Synced invoice to",
                        [SYSTEM_ACTIVITY_TYPES.PRODUCT_SYNC]: "Synced products from",
                    };

                    return `${map[data.type]} Bottle POS`;
                }
            },
        },
        {
            field: "created_at",
            headerName: "Date",
            filter: "date",
            valueGetter: ({ data }) =>
                data && data.created_at ? format(new Date(data.created_at), DateTimeFormat) : null,
        },
    ],
    "field"
);
