import {
    PRODUCT_VIEW_DATA,
    PRODUCT_VIEW_ERROR,
    PRODUCT_VIEW_LOADING,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    errors: {},
    products: {},
    isLoading: {},
};

const setViewData = (state, product) => ({
    ...state,
    products: { ...state.products, [product.id]: product },
    isLoading: { ...state.isLoading, [product.id]: false },
    errors: { ...state.errors, [product.id]: null },
});
const setViewError = (state, id, error) => ({
    ...state,
    isLoading: { ...state.isLoading, [id]: false },
    errors: { ...state.errors, [id]: error },
});
const setViewLoading = (state, id) => ({
    ...state,
    isLoading: { ...state.isLoading, [id]: true },
    errors: { ...state.errors, [id]: null },
});

const reducers = {
    [PRODUCT_VIEW_DATA]: setViewData,
    [PRODUCT_VIEW_ERROR]: setViewError,
    [PRODUCT_VIEW_LOADING]: setViewLoading,
};

export default generateReducer(reducers, initialState);
