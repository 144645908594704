import React from "react";
import { Dropdown } from "semantic-ui-react";

export const ProductPriceTierSelector = ({ priceTiers = [], value, onChange, ...rest }) => {
    const options = priceTiers.map(tier => ({
        key: tier.id,
        text: tier.name,
        value: tier.id,
        description: `${tier.percentage}% of base price`,
    }));
    
    return (
        <Dropdown
            clearable
            placeholder="Select a price tier"
            fluid
            selection
            {...{ options, value, onChange, ...rest }}
        />
    );
};
