import { shape, number, string } from "prop-types";

export const CompanyType = shape({
    id: number,
    zip: string,
    name: string,
    city: string,
    state: string,
    address: string,
    tabc_id: string,
});
