import { isEmpty } from "lodash";
import { format } from "date-fns";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Confirm, Button, Table } from "semantic-ui-react";

import InvoicePaymentEditorContainer from "./editor.container";
import { DateFormat } from "../helpers";
import { InvoiceStatuses } from "utilities/dist/invoice/constants";
import { computePaymentTotal } from "../helpers";
import { parseCurrency, parseServerTimestampForClient } from "../../common/helpers";
import { formatAmount } from "utilities/dist/helpers";
import { isFeatureActive, PaymentFeatureDetails } from "../../company/feature/helpers";
import {
    showInvoicePaymentEditorModal,
    removeInvoicePayment,
    setInvoicePaymentEditorInput,
} from "./actions";

const InvoicePaymentListContainer = ({ invoice, handlePaymentChange, user }) => {
    const [deletingPaymentId, setDeletingPaymentId] = useState(0);
    const {
        removing,
        company,
        // removeError
    } = useSelector(({ invoicePayment, auth }) => ({
        removing: invoicePayment.removing,
        company: auth.user.company,
        // removeError: invoicePayment.removeError,
    }));
    const hasPaymentEntries = !!invoice.payments && !isEmpty(invoice.payments);
    const hasPaymentFeature = isFeatureActive(company, PaymentFeatureDetails);

    const dispatch = useDispatch();
    const showPaymentEditor = () => {
        const paymentRemaining = parseCurrency(invoice.total - computePaymentTotal(invoice));

        dispatch(
            setInvoicePaymentEditorInput({
                amount: paymentRemaining > 0 ? paymentRemaining : 0,
            })
        );
        dispatch(showInvoicePaymentEditorModal(true));
    };
    const handleRemove = () => {
        dispatch(removeInvoicePayment(invoice.id, deletingPaymentId))
            .then(handlePaymentChange)
            .then(() => {
                setDeletingPaymentId(0);
            });
    };

    return (
        <Table striped className={!hasPaymentEntries || !hasPaymentFeature ? "no-print" : ""}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan={user.role === "owner" ? "3" : "2"}>
                        Payments
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <Button
                            data-cy="add_payment"
                            basic
                            positive
                            size="tiny"
                            icon="dollar"
                            floated="right"
                            labelPosition="left"
                            className="no-print"
                            content="Add Payment"
                            onClick={showPaymentEditor}
                            disabled={invoice.status === InvoiceStatuses.CANCELLED}
                        />

                        <InvoicePaymentEditorContainer
                            invoice={invoice}
                            onComplete={handlePaymentChange}
                        />

                        <Confirm
                            header="Remove Payment?"
                            onConfirm={handleRemove}
                            open={deletingPaymentId > 0}
                            content="Sure you want to remove this payment entry? Invoice status might be changed as a result of this action."
                            confirmButton={
                                <Button
                                    negative
                                    loading={removing}
                                    disabled={removing}
                                    content="Remove Payment"
                                />
                            }
                            onCancel={() => setDeletingPaymentId(0)}
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            {!hasPaymentEntries ? (
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>No payment logged for this invoice</Table.Cell>
                    </Table.Row>
                </Table.Body>
            ) : (
                <>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.HeaderCell>Received On</Table.HeaderCell>
                            <Table.HeaderCell>Note</Table.HeaderCell>
                            {user.role === "owner" && (
                                <Table.HeaderCell textAlign="right">
                                    <span className="no-print">Actions</span>
                                </Table.HeaderCell>
                            )}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {invoice.payments.map(payment => (
                            <Table.Row key={`invoice_payment_row_${payment.id}`}>
                                <Table.Cell>{formatAmount(payment.amount)}</Table.Cell>
                                <Table.Cell>
                                    {format(
                                        parseServerTimestampForClient(payment.received_at),
                                        DateFormat
                                    )}
                                </Table.Cell>
                                <Table.Cell>{payment.note}</Table.Cell>
                                {/* here is the trash bin to delete payment */}
                                {user.role === "owner" && (
                                    <Table.Cell textAlign="right">
                                        <Button
                                            basic
                                            icon="trash"
                                            className="no-print"
                                            onClick={() => setDeletingPaymentId(payment.id)}
                                            size="mini"
                                            color="red"
                                        />
                                    </Table.Cell>
                                )}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </>
            )}
        </Table>
    );
};

export default InvoicePaymentListContainer;
