import { isArray, isString, pick, cloneDeep } from "lodash";

import {
    COMPANY_EDITOR_INPUT,
    COMPANY_EDITOR_ERROR,
    COMPANY_EDITOR_SAVING,
    COMPANY_EDITOR_SAVED,
} from "../constants";
import { generateReducer } from "../../common/helpers";
import { AUTH_SIGNIN_SUCCESS } from "../../auth/constants";

const defaultInputs = {
    id: "",
    name: "",
    city: "",
    zip: "",
    address: "",
    state: "TX",
    tabc_id: "",
    has_fintech: "",
    fintech_id: "",
    show_prices: "",
    default_invoice_note: "",
    starting_invoice_number: 1,
    subdomain: "",
    default_invoice_status: "",
};

const initialState = {
    inputs: cloneDeep(defaultInputs),
    isSaving: false,
    errors: [],
};

const setError = (state, error) => {
    let { errors } = state;

    if (isString(error)) {
        errors.push(error);
    }

    if (isArray(error)) {
        errors = error;
    }

    return { ...state, isSaving: false, errors };
};
const setSaving = state => ({ ...state, isSaving: true });
const setSaved = (state, company) => ({
    ...state,
    ...setInput(state, company),
    isSaving: false,
    errors: [],
});

const setInput = (state, inputs = {}) => {
    // make sure only the allowed fields are added to inputs by only picking the keys from defaultInputs
    return {
        ...state,
        inputs: { ...state.inputs, ...pick(inputs, Object.keys(defaultInputs)) },
    };
};

const setInputFromUser = (state, user) => setInput(state, user.company);

const reducers = {
    [COMPANY_EDITOR_SAVING]: setSaving,
    [COMPANY_EDITOR_SAVED]: setSaved,
    [COMPANY_EDITOR_INPUT]: setInput,
    [COMPANY_EDITOR_ERROR]: setError,

    [AUTH_SIGNIN_SUCCESS]: setInputFromUser,
};

export default generateReducer(reducers, initialState);
