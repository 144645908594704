export const AUTH_SIGNIN_REQUEST = "AUTH_SIGNIN_REQUEST";
export const AUTH_SIGNIN_SUCCESS = "AUTH_SIGNIN_SUCCESS";
export const AUTH_SIGNIN_INPUT_ERROR = "AUTH_SIGNIN_INPUT_ERROR";
export const AUTH_SIGNIN_INPUT_CHANGE = "AUTH_SIGNIN_INPUT_CHANGE";

export const AUTH_SIGNUP_REQUEST = "AUTH_SIGNUP_REQUEST";
export const AUTH_SIGNUP_SUCCESS = "AUTH_SIGNUP_SUCCESS";
export const AUTH_SIGNUP_INPUT_ERROR = "AUTH_SIGNUP_INPUT_ERROR";
export const AUTH_SIGNUP_INPUT_CHANGE = "AUTH_SIGNUP_INPUT_CHANGE";

export const AUTH_RESET_INPUT_CHANGE = "AUTH_RESET_INPUT_CHANGE";
export const AUTH_TOGGLE_PASSWORD_FIELD = "AUTH_TOGGLE_PASSWORD_FIELD";

export const AUTH_PROFILE_UPDATED = "AUTH_PROFILE_UPDATED";
export const AUTH_PROFILE_UPDATING = "AUTH_PROFILE_UPDATING";
export const AUTH_PROFILE_UPDATE_ERROR = "AUTH_PROFILE_UPDATE_ERROR";

export const AUTH_EXPIRE = "AUTH_EXPIRE";
export const AUTH_SIGNOUT = "AUTH_SIGNOUT";
