export const PRODUCT_SEARCH_CLEAR = "PRODUCT_SEARCH_CLEAR";
export const PRODUCT_SEARCH_SELECT = "PRODUCT_SEARCH_SELECT";
export const PRODUCT_SEARCH_CHANGE_QUERY = "PRODUCT_SEARCH_CHANGE_QUERY";
export const PRODUCT_SEARCH_SUGGESTIONS_DATA = "PRODUCT_SEARCH_SUGGESTIONS_DATA";
export const PRODUCT_SEARCH_SUGGESTIONS_ERROR = "PRODUCT_SEARCH_SUGGESTIONS_ERROR";
export const PRODUCT_SEARCH_SUGGESTIONS_LOADING = "PRODUCT_SEARCH_SUGGESTIONS_LOADING";

export const PRODUCT_EDITOR_INPUT = "PRODUCT_EDITOR_INPUT";
export const PRODUCT_EDITOR_ERROR = "PRODUCT_EDITOR_ERROR";
export const PRODUCT_EDITOR_SAVING = "PRODUCT_EDITOR_SAVING";
export const PRODUCT_EDITOR_SAVED = "PRODUCT_EDITOR_SAVED";
export const PRODUCT_EDITOR_CONFIRMING = "PRODUCT_EDITOR_CONFIRMING";
export const PRODUCT_EDITOR_ARCHIVE_LOADING = "PRODUCT_EDITOR_ARCHIVE_LOADING";
export const PRODUCT_EDITOR_ARCHIVE_SUCCESS = "PRODUCT_EDITOR_ARCHIVE_SUCCESS";
export const PRODUCT_EDITOR_ARCHIVE_ERROR = "PRODUCT_EDITOR_ARCHIVE_ERROR";
export const PRODUCT_EDITOR_DELETE_LOADING = "PRODUCT_EDITOR_DELETE_LOADING";
export const PRODUCT_EDITOR_DELETE_SUCCESS = "PRODUCT_EDITOR_DELETE_SUCCESS";
export const PRODUCT_EDITOR_DELETE_ERROR = "PRODUCT_EDITOR_DELETE_ERROR";
export const PRODUCT_EDITOR_PRODUCT_LOADING = "PRODUCT_EDITOR_PRODUCT_LOADING";
export const PRODUCT_EDITOR_PRODUCT_DATA = "PRODUCT_EDITOR_PRODUCT_DATA";
export const PRODUCT_EDITOR_PRODUCT_ERROR = "PRODUCT_EDITOR_PRODUCT_ERROR";
export const PRODUCT_EDITOR_PRODUCT_RESET = "PRODUCT_EDITOR_PRODUCT_RESET";

export const PRODUCT_EDITOR_MODAL_TOGGLE = "PRODUCT_EDITOR_MODAL_TOGGLE";

export const PRODUCT_EDITOR_DISTRIBUTOR_LIST_LOADING = "PRODUCT_EDITOR_DISTRIBUTOR_LIST_LOADING";
export const PRODUCT_EDITOR_DISTRIBUTOR_LIST_SUCCESS = "PRODUCT_EDITOR_DISTRIBUTOR_LIST_SUCCESS";
export const PRODUCT_EDITOR_DISTRIBUTOR_LIST_ERROR = "PRODUCT_EDITOR_DISTRIBUTOR_LIST_ERROR";
export const PRODUCT_EDITOR_DISTRIBUTOR_LIST_ADD = "PRODUCT_EDITOR_DISTRIBUTOR_LIST_ADD";

export const PRODUCT_GRID_DATA = "PRODUCT_GRID_DATA";
export const PRODUCT_GRID_ERROR = "PRODUCT_GRID_ERROR";
export const PRODUCT_GRID_LOADING = "PRODUCT_GRID_LOADING";
export const PRODUCT_GRID_PAGE_CHANGE = "PRODUCT_GRID_PAGE_CHANGE";
export const PRODUCT_GRID_STATE_CHANGE = "PRODUCT_GRID_STATE_CHANGE";

export const PRODUCT_VIEW_LOADING = "PRODUCT_VIEW_LOADING";
export const PRODUCT_VIEW_DATA = "PRODUCT_VIEW_DATA";
export const PRODUCT_VIEW_ERROR = "PRODUCT_VIEW_ERROR";

export const ProductTypes = {
    LIQUOR: "LIQUOR",
    WINE: "WINE",
    BEER: "BEER",
    NA: "N/A",
};

export const ProductTypeAcronyms = {
    LIQUOR: "DS",
    WINE: "W",
    BEER: "B",
    NA: "N",
};
