import { keyBy } from "lodash";
import { format } from "date-fns";
import { DateTimeFormat } from "utilities/dist/invoice/constants";

export const allGridColumns = keyBy(
   
    [
        {
            field: "invoice_id",
            headerName: "Invoice Number",
            filter: "agNumberColumnFilter",
            valueGetter: function ({ data }) {
                return data ? data.invoice.number : null;
            },
            cellRenderer: "invoiceLinkRenderer",
        },
        {
            field: "emailed_to",
            headerName: "Emailed To",
            filter: "agTextColumnFilter",
            valueGetter: function ({ data }) {
                return data ? data.emailed_to : null;
            },
        },
        {
            field: "notes",
            headerName: "Notes",
            filter: "agTextColumnFilter",
            cellStyle: { "white-space": "normal" },
            valueGetter: function ({ data }) {
                return data ? data.notes : null;
            },
        },
        {
            field: "created_at",
            headerName: "Sent At",
            filter: false,
            valueGetter: ({ data }) =>
                data && data.created_at ? format(new Date(data.created_at), DateTimeFormat) : null,
        },
    ],
    "field"
);
