import React from "react";
import { Link } from "react-router-dom";
import { Grid, Menu } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import IndexContainer from "../email-grid/index.container";

const EmailPageHome = () => {
    return (
        <Grid>
            <TopNav
                headerIcon="envelope"
                headerContent="Email Log"
                subheader="All your past emails"
                extraButtons={() => (
                    <Menu.Item as={Link} icon="plus" name="New Invoice" to="/invoice/create" />
                )}
            />

            <Grid.Column mobile={16}>
                <IndexContainer />
            </Grid.Column>
        </Grid>
    );
};

export default EmailPageHome;
