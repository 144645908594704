import { get } from "lodash";

import {
    COMPANY_LOGO_DATA,
    COMPANY_LOGO_ERROR,
    COMPANY_LOGO_LOADING,
    COMPANY_LOGO_UPLOADING,
    COMPANY_LOGO_UPLOAD_ERROR,
    COMPANY_LOGO_UPLOAD_SUCCESS,
    COMPANY_LOGO_REMOVING,
    COMPANY_LOGO_REMOVE_ERROR,
    COMPANY_LOGO_REMOVE_SUCCESS,
} from "../constants";

import { makePrivateApiCall } from "utilities/dist/api";

export const loadCompanyLogos = () => dispatch => {
    dispatch({
        type: COMPANY_LOGO_LOADING,
    });

    return makePrivateApiCall({
        url: "/company/logo",
    })
        .then(res => {
            dispatch({
                type: COMPANY_LOGO_DATA,
                payload: res.data,
            });
        })
        .catch(err => {
            dispatch({
                type: COMPANY_LOGO_ERROR,
                payload: err.message,
            });
        });
};

export const uploadCompanyLogo = file => dispatch => {
    let imageFormObj = new FormData();

    imageFormObj.append("logo", file);

    dispatch({
        type: COMPANY_LOGO_UPLOADING,
        payload: URL.createObjectURL(file),
    });

    makePrivateApiCall({
        method: "POST",
        data: imageFormObj,
        url: "/company/logo",
    })
        .then(res => {
            dispatch({
                type: COMPANY_LOGO_UPLOAD_SUCCESS,
                payload: res.data,
            });
        })
        .catch(err => {
            const message = get(err, "response.data.message", err.message);
            dispatch({
                type: COMPANY_LOGO_UPLOAD_ERROR,
                payload: message,
            });
        });
};

export const removeCompanyLogo = logoId => dispatch => {
    dispatch({
        type: COMPANY_LOGO_REMOVING,
        payload: logoId,
    });

    makePrivateApiCall({
        method: "DELETE",
        url: `/company/logo/${logoId}`,
    })
        .then(() => {
            dispatch({
                type: COMPANY_LOGO_REMOVE_SUCCESS,
                payload: logoId,
            });
        })
        .catch(err => {
            dispatch({
                type: COMPANY_LOGO_REMOVE_ERROR,
                payload: err.message,
            });
        });
};
