import React from "react";
import { format } from "date-fns";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { isEmpty, escapeRegExp } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Header, Label } from "semantic-ui-react";

import { selectProductSearch, changeProductSearchQuery } from "./actions";
import { DateFormat } from "utilities/dist/invoice/constants";
import { CustomerType } from "../../customer/types";
import { parseServerTimestampForClient } from "../../common/helpers";
import { formatAmount } from "utilities/dist/helpers";
import { transformProductToItem, buildProductIdKey } from "../helpers";
import { selectInvoiceBuilderProduct } from "../../invoice/builder/actions";
import { showProductEditorModal, setProductEditorInput } from "../editor/actions";
import ProductEditorContainer from "../editor/index.container";
import { isCompanyFeatureActive } from "utilities/dist/company/helpers";
import { TieredPricing } from "../../company/feature/helpers";

const DropdownContent = ({
    id,
    product,
    qty,
    product_size,
    price,
    product_type,
    timestamp,
    upc,
    internal_sku,
    inventory,
    hideOrderHistory = false,
}) => {
    return (
        <div className="clear" data-cy={`product_search_result_${id}`}>
            <Header as="h4" floated="left">
                {product}
                <Header.Subheader>
                    {product_type}, {product_size} | {formatAmount(price)}/Unit | {upc}{" "}
                    {internal_sku && internal_sku !== "0" ? `| ${internal_sku}` : ""}
                </Header.Subheader>
                {!!id && !hideOrderHistory && (
                    <Header.Subheader>
                        {timestamp
                            ? `Last Ordered: ${format(
                                  parseServerTimestampForClient(timestamp),
                                  DateFormat
                              )} | ${pluralize("Units", qty, true)}`
                            : "Never Ordered"}
                    </Header.Subheader>
                )}
            </Header>
            {inventory && (
                <div className="fr">
                    <Label
                        basic
                        size="mini"
                        color={
                            inventory.reorder_needed
                                ? "red"
                                : inventory.qty < inventory.reorder_level * 2
                                ? "orange"
                                : undefined
                        }
                    >
                        {inventory.qty} in stock
                    </Label>
                </div>
            )}
            {!id && (
                <div className="fr">
                    <Label size="small" color="blue" content="Add to store" />
                </div>
            )}
        </div>
    );
};

const AdditionLabel = (
    <>
        Can't find what you're looking for? Quickly create your own product &nbsp;
        <div className="fr">
            <Label basic color="red" size="small" pointing="left" content="Create Product" />
        </div>
    </>
);

const ProductSearchContainer = ({
    onSelect,
    customer = {},
    dropdownRef,
    hideOrderHistory = false,
}) => {
    const { hasInitiatedSearch, suggestions, inputQuery, isLoading, selected, error } = useSelector(
        ({ productSearch }) => productSearch
    );
    const { isTieredPricingActive, productPriceTiers } = useSelector(({ auth }) => ({
        isTieredPricingActive: isCompanyFeatureActive(auth.user.company, TieredPricing.id),
        productPriceTiers: auth.user.company.productPriceTiers,
    }));
    const customerPriceTier = isTieredPricingActive
        ? productPriceTiers?.find(tier => tier.id === customer?.product_price_tier_id)
        : null;

    const dispatch = useDispatch();
    const handleChange = (e, data) => {
        let prod = {};

        if (data.value.length !== 0) {
            // if there is a value but there is not _ in it,
            // means they are trying to create new product so exit early from here
            if (data.value.indexOf("_") < 0) {
                return null;
            }

            if (data.value) {
                const idKey = data.value.indexOf("source") === 0 ? "product_id" : "id";
                const finder = prod => prod[idKey] === parseInt(data.value.split("_")[1]);
                prod = transformProductToItem(suggestions.find(finder), {
                    customerPriceTier,
                    isTieredPricingActive,
                });
            }
        }

        dispatch(selectProductSearch(prod));
        onSelect(prod);
    };
    const handleSearchChange = (e, { searchQuery }) =>
        dispatch(changeProductSearchQuery(searchQuery, customer ? customer.id : null));
    const handleAddition = (e, { value }) => {
        const productInputs = {};
        // only look at UPC if search query is full of number and longer than 5 digits
        if (/^[0-9]+$/.test(value) && value.length > 5) {
            productInputs.upc = value;
        } else {
            productInputs.product = value;
        }
        dispatch(setProductEditorInput(productInputs));
        dispatch(showProductEditorModal(true));
    };
    const handleProductCreate = product => {
        dispatch(selectProductSearch(product));
        dispatch(
            selectInvoiceBuilderProduct(
                transformProductToItem(product, { customerPriceTier, isTieredPricingActive })
            )
        );
    };

    // when finding results from the returned results, ignore looking into sizes
    const finder = (opts, sq) => {
        const shouldSearchByUpc = /^\d*$/.test(sq.trim()) && sq.trim()?.length > 5;
        const queryFragments = sq?.split(" ");
        const hasSizeInQuery =
            queryFragments?.length > 1 && /^\d.*$/.test(queryFragments[queryFragments.length - 1]);
        // Using .pop() so that later on, when we join the fragments back to rebuild the query string, we don't include the size fragment
        const sizeFragment =
            hasSizeInQuery && !shouldSearchByUpc ? queryFragments.pop().trim() : "";
        // we don't need to match sizes in product names so let's get rid of those
        const queryWithoutSize = sizeFragment ? queryFragments.join(" ") : sq;
        const sqWithoutSizeAndWildcard = queryWithoutSize
            .replace(new RegExp("%", "g"), "")
            .replace(new RegExp("_", "g"), "")
            .trim();

        let regexQuery = escapeRegExp(sqWithoutSizeAndWildcard);
        // if the query is `amsterdam vodka` then match for `amsterdam vodka` and (`amsterdam` or `vodka`)
        if (regexQuery?.includes(" ")) {
            regexQuery = `${regexQuery}|${regexQuery.replace(new RegExp(" ", "g"), "|")}`;
        }
        const matcher = new RegExp(regexQuery, "i");
        return opts.filter(opt => matcher.test(opt.text));
    };

    const options = suggestions.map(prod => {
        let searchedWithSku = false;
        if (!!prod.searchByInternalSku && prod.internal_sku) {
            const query = prod.query.toUpperCase();
            searchedWithSku = prod.internal_sku.startsWith(query);
        }

        const productName = !!searchedWithSku ? prod.internal_sku : prod.product;
        return {
            key: buildProductIdKey(prod),
            value: buildProductIdKey(prod),
            text: `${productName} ${prod.product_size}`,
            content: (
                <DropdownContent
                    {...transformProductToItem(prod, {
                        customerPriceTier,
                        isTieredPricingActive,
                    })}
                    hideOrderHistory={hideOrderHistory}
                />
            ),
        };
    });
    return (
        <div className="product-search-container">
            <ProductEditorContainer onComplete={handleProductCreate} />

            <Dropdown
                data-cy="product_search"
                fluid
                selection
                clearable
                search={finder}
                error={!!error}
                options={options}
                minCharacters={3}
                ref={dropdownRef}
                autoComplete="off"
                loading={isLoading}
                selectOnBlur={false}
                onChange={handleChange}
                searchQuery={inputQuery}
                additionPosition="bottom"
                selectOnNavigation={false}
                onAddItem={handleAddition}
                additionLabel={AdditionLabel}
                onSearchChange={handleSearchChange}
                icon={hasInitiatedSearch ? "dropdown" : null}
                allowAdditions={hasInitiatedSearch && !isLoading}
                placeholder={`Start typing to see suggestions...`}
                value={isEmpty(selected) ? null : selected.id || selected.ydk_product_id}
                noResultsMessage={
                    hasInitiatedSearch && !isLoading
                        ? `Sorry, no results found for "${inputQuery}"`
                        : null
                }
            />
        </div>
    );
};

ProductSearchContainer.propTypes = {
    onSelect: PropTypes.func.isRequired,
    hideOrderHistory: PropTypes.bool,
    dropdownRef: PropTypes.any,
    customer: CustomerType,
};

export default ProductSearchContainer;
