import React from "react";
import { Link } from "react-router-dom";
import { Grid, Menu } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import InventoryContainerRestock from "../restock/index.container";

const InventoryPageRestock = ({ history }) => {
    const goHome = () => history.push("/inventory");
    return (
        <Grid>
            <TopNav
                headerIcon="warehouse"
                headerContent="Inventory"
                subheader="Restock inventory items"
                extraButtons={() => (
                    <>
                        <Menu.Item as={Link} to="/inventory" icon="chevron left" name="Inventory" />
                    </>
                )}
            />

            <Grid.Column mobile={16} computer={16}>
                <InventoryContainerRestock onComplete={goHome} />
            </Grid.Column>
        </Grid>
    );
};

export default InventoryPageRestock;
