import React from "react";
import { connect } from "react-redux";
import { Form } from "formik-semantic-ui";
import { bindActionCreators } from "redux";
import { isArray, isObject } from "lodash";
import { toast } from "react-semantic-toasts";
import { withRouter } from "react-router-dom";
import { Segment, Message } from "semantic-ui-react";

import { validateCustomerCreate, validateCustomerUpdate } from "../services/validators";
import {
    requestSaveCustomer,
    resetEditingCustomer,
    setCustomerEditorError,
    setLoadingCustomerForEdit,
} from "../actions";

import CustomerContainerEditorConfirm from "./editor-confirm";
import CustomerComponentEditorForm from "../components/editor";
import { isCompanyFeatureActive } from "utilities/dist/company/helpers";
import { TieredPricing } from "../../company/feature/helpers";
import { ProductPriceTierCreatorContainer } from "../../product/price-tier/containers/creator";
import {
    showProductPriceTierModal,
    setProductPriceTierInput,
} from "../../product/price-tier/actions";

class CustomerContainerEditor extends React.Component {
    formikApi = null;

    handleSubmit = async (customerData, formik) => {
        this.formikApi = formik;

        try {
            let customer;

            if (customerData.id) customer = await validateCustomerUpdate(customerData);
            else customer = await validateCustomerCreate(customerData);

            this.props.requestSaveCustomer(customer);
        } catch (err) {
            const messages = [];
            err.details.forEach(errDet => {
                this.formikApi.setFieldError(errDet.context.key, errDet.message);
                messages.push(errDet.message);
            });

            this.formikApi.setSubmitting(false);
            this.props.setCustomerEditorError(messages);
        }
    };

    handleConfirm = (data, options = {}) => {
        this.formikApi && this.formikApi.setSubmitting(false);

        if (data === null) {
            return;
        }

        if (isArray(data)) {
            data.forEach(errDet => {
                this.formikApi.setFieldError(errDet.context.key, errDet.message);
            });
            return;
        }

        if (isObject(data)) {
            let description = ["Customer saved in database."];

            toast({
                description: <Message.List items={description} />,
                time: 6000,
                type: "success",
                icon: "checkmark",
                title: options?.isUpdate ? "Updated Customer" : "Created Customer",
            });

            this.props.history.push("/customer");
        }
    };

    handleReset = (_, { resetForm }) => {
        this.props.setLoadingCustomerForEdit(true);
        resetForm();
        setTimeout(() => {
            this.props.resetEditingCustomer();
            this.props.setLoadingCustomerForEdit(false);
        }, 100);
    };

    render() {
        const {
            errors,
            inputs,
            isSaving,
            isLoadingCustomer,
            customerError,
            hasFintech,
            hasTieredPricingEnabled,
            productPriceTiers,
        } = this.props;

        return (
            <Segment loading={isLoadingCustomer} disabled={!!customerError}>
                <CustomerContainerEditorConfirm onClose={this.handleConfirm} />
                <ProductPriceTierCreatorContainer />

                {!isLoadingCustomer ? (
                    <Form
                        initialValues={inputs}
                        isSubmitting={isSaving}
                        onReset={this.handleReset}
                        onSubmit={this.handleSubmit}
                        render={props => (
                            <CustomerComponentEditorForm
                                {...{
                                    ...props,
                                    hasFintech,
                                    hasTieredPricingEnabled,
                                    productPriceTiers,
                                    onAddNewTier: (_, { value }) => {
                                        this.props.setProductPriceTierInput({ name: value });
                                        this.props.showProductPriceTierModal(true);
                                    },
                                }}
                            />
                        )}
                    />
                ) : (
                    <div className="hp-40"></div>
                )}

                {errors.length > 0 && (
                    <Message
                        error
                        content={errors}
                        icon="warning sign"
                        header="Error saving customer!"
                    />
                )}
            </Segment>
        );
    }
}

const mapStateToProps = ({ customerEditor, auth }) => ({
    errors: customerEditor.errors,
    inputs: customerEditor.inputs,
    customer: customerEditor.customer,
    isSaving: customerEditor.isSaving,
    customerError: customerEditor.customerError,
    isLoadingCustomer: customerEditor.isLoadingCustomer,

    hasFintech: auth.user?.company?.has_fintech,
    productPriceTiers: auth.user?.company?.productPriceTiers || [],
    hasTieredPricingEnabled: isCompanyFeatureActive(auth.user?.company || {}, TieredPricing.id),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            requestSaveCustomer,
            resetEditingCustomer,
            setCustomerEditorError,
            setLoadingCustomerForEdit,

            showProductPriceTierModal,
            setProductPriceTierInput,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerContainerEditor));
