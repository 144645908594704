export const convertObjectToPlainArray = obj => Object.keys(obj).map(key => obj[key]);

export const getTotalAmount = productArray =>
    productArray
        .map(item => item.quantity * item.last_invoiced_price || item.product_price)
        .reduce((acc, val) => acc + val, 0)
        .toFixed(2);

export const getTotalUnits = productArray =>
    productArray.map(item => item.quantity).reduce((acc, val) => acc + val, 0);

export const getOrderTotalAmount = productArray =>
    productArray
        .map(item => parseInt(item.qty) * item.price)
        .reduce((acc, val) => acc + val, 0)
        .toFixed(2);

export const getOrderTotalUnits = (productArray = []) => {
    return productArray
        .map(item => (item.new_qty > 0 ? parseInt(item.new_qty) : parseInt(item.qty)))
        .reduce((acc, val) => acc + val, 0);
};

const moneyFormatter = new Intl.NumberFormat("en-US", {
    currency: "USD",
    style: "currency",
    minimumFractionDigits: 2,
});

export const getFilteredPriceTotal = productArray => {
    const totalPrice = productArray
        .map(item => parseInt(item.total))
        .reduce((acc, val) => acc + val, 0)
        .toFixed(2);
    return moneyFormatter.format(totalPrice);
};
export const formatAmount = amount => moneyFormatter.format(amount);
