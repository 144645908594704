import React from "react";
import { Grid } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import { BottlePosContainerHistory } from "../containers/history";

const BottlePosPageHome = () => {
    return (
        <Grid>
            <TopNav
                headerIcon="flask"
                subheader="Manage Bottle POS Integration"
                headerContent="Bottle POS"
            />

            <Grid.Column mobile={16} computer={16}>
                  <BottlePosContainerHistory />
            </Grid.Column>
        </Grid>
    );
};

export default BottlePosPageHome;
