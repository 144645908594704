import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Message } from "semantic-ui-react";

import { features, isFeatureActive } from "./helpers";
import CompanyFeatureComponentItemSmall from "./item-small.component";
import { showCompanyFeatureToggleConfirmation, syncHistoryFromClover } from "./actions";
import CompanyFeatureContainerConfirmToggle from "./confirm-toggle.container";

const CompanyFeatureContainerList = () => {
    const { company, error } = useSelector(({ auth, companyFeature }) => ({
        company: auth.user.company,
        error: companyFeature.error,
    }));

    const dispatch = useDispatch();
    const confirmActivationToggle = feat => {
        dispatch(showCompanyFeatureToggleConfirmation(feat));
    };
    const onClickSyncHistory = () => {
        dispatch(syncHistoryFromClover());
    };

    return (
        <>
            <CompanyFeatureContainerConfirmToggle />
            <Grid stretched>
                <Grid.Row>
                    {features.map(feature => (
                        <Grid.Column
                            key={feature.id}
                            mobile={16}
                            tablet={8}
                            computer={5}
                            className="bp-15"
                        >
                            <CompanyFeatureComponentItemSmall
                                feature={feature}
                                toggleActivation={confirmActivationToggle}
                                syncHistory={onClickSyncHistory}
                                isActive={isFeatureActive(company, feature)}
                            />
                        </Grid.Column>
                    ))}
                    {error && (
                        <Message error content={error} icon="warning sign" header="Error !" />
                    )}
                </Grid.Row>
            </Grid>
        </>
    );
};

export default CompanyFeatureContainerList;
