import React from "react";
import { Dropdown, Header } from "semantic-ui-react";
import { useCustomerAutoFillFinder } from "../hooks/auto-fill-finder";

const CustomerContainerAutoFillFinder = () => {
    const {
        error,
        query,
        isLoading,
        customers,
        handleChange,
        handleSearchChange,
    } = useCustomerAutoFillFinder();

    const options = customers.map(cust => ({
        key: cust.establishment_id,
        value: cust.establishment_id,
        text: `${cust.name}, ${cust.address}, ${cust.permitCodes[0].permit_code}`,
        content: (
            <div className="clear">
                <Header as="h4" floated="left">
                    {cust.name}
                    <Header.Subheader>
                        {[cust.address, cust.county, cust.city, cust.state]
                            .filter(Boolean)
                            .join(", ")}
                    </Header.Subheader>
                    <Header.Subheader>TABC ID #{cust.permitCodes[0].permit_code}</Header.Subheader>
                </Header>
            </div>
        ),
    }));

    return (
        <>
            <Header as="h5">Find your customer</Header>
            <Dropdown
                fluid
                search
                selection
                clearable
                error={!!error}
                minCharacters={3}
                autoComplete="off"
                options={options}
                loading={isLoading}
                onChange={handleChange}
                onSearchChange={handleSearchChange}
                placeholder={`Address/Establishment Name etc.`}
                icon={query.length > 2 ? "dropdown" : null}
                noResultsMessage={
                    query.length > 2 ? `Sorry, no results found for "${query}"` : null
                }
            />
        </>
    );
};

export default CustomerContainerAutoFillFinder;
