"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SOCKET_EVENTS = void 0;
const SOCKET_EVENTS = {
  USER_ACCESS: "user:access",
  ORDER_INVOICED: "order:invoiced",
  ORDER_PLACED: "order:placed",
  ORDER_STATUS: "order:status",
  COMPANY_UPDATE: "company:update",
  PRODUCT_CACHE_UPDATE: "product:cache-update",
  ORDER_FEATURE: "order:feature",
  BOTTLE_POS_INVOICE_SYNC_COMPLETED: "bottle-pos:invoice-sync-completed",
  BOTTLE_POS_INVOICE_SYNC_ERROR: "bottle-pos:invoice-sync-error",
  CLOVER_INVENTORY_PUSH_ERROR: "clover:inventory-push-error"
};
exports.SOCKET_EVENTS = SOCKET_EVENTS;