import { shape, number, string } from "prop-types";

export const ProductType = shape({
    id: number,
    ydk_product_id: number,
    product: string.isRequired,
    product_size: string.isRequired,
    product_type: string.isRequired,
    product_price: number.isRequired,
});
