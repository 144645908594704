import { Link } from "react-router-dom";
import { isEmpty, startCase, isEqual } from "lodash";
import { format, isBefore, addDays } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { Grid, Menu, Message, Confirm, Modal, Table, Button } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import InvoiceComponentView from "../components/view";

import { DateTimeFormat, InvoiceStatuses } from "utilities/dist/invoice/constants";
import { buildInvoiceNumberWithBeer, DateFormat, generatePdf } from "../helpers";
import { makePrivateApiCall } from "utilities/dist/api";
import { saveInvoiceBuilderInvoice } from "../builder/actions";
import InvoicePaymentListContainer from "../payment/list.container";
import {
    EmailFeatureDetails,
    isFeatureActive,
    PaymentFeatureDetails,
} from "../../company/feature/helpers";
import DocumentContainer from "../document-upload/document.container";
import { toast } from "react-semantic-toasts";
import InvoiceEmailModal from "../builder/invoice-email.component";
const isChangeNeeded = ({ field, previousValue, newValue }) => {
    let from = previousValue,
        to = newValue;

    if (field === "invoiced_at") {
        field = "Invoice Date";
        to = format(new Date(newValue), DateFormat);
        from = format(new Date(previousValue), DateFormat);
    }

    if (field === "due_at") {
        field = "Due Date";
        from = !!previousValue ? format(new Date(previousValue), DateFormat) : "Due on delivery";

        to = isBefore(newValue, addDays(new Date(), 1))
            ? "Due on delivery"
            : format(new Date(newValue), DateFormat);
    }

    if (from === to) {
        return false;
    }

    return { from, to };
};

const ConfirmContent = ({ field, previousValue, newValue }) => {
    const changeValues = isChangeNeeded({ field, previousValue, newValue });

    if (!changeValues) {
        return (
            <Modal.Content>
                {field} is not different than the current one, so there's nothing to update.
            </Modal.Content>
        );
    }

    return (
        <Modal.Content>
            Sure you want to change <b>{startCase(field)}</b> from{" "}
            <b>{startCase(changeValues.from)}</b> to <b>{startCase(changeValues.to)}</b>?
        </Modal.Content>
    );
};

const InvoicePageView = ({ computedMatch, history }) => {
    const { number } = computedMatch.params;

    const [generatingPdfUrl, setGeneratingPdfUrl] = useState(false);

    const [error, setError] = useState(null);
    const [invoice, setInvoice] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [emailModalOpen, setEmailModalOpen] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [isDeleteConfirming, setDeleteConfirming] = useState(false);

    const [confirmChange, setConfirmChange] = useState(null);

    const dispatch = useDispatch();
    // see if we already have the data we need from grid, if not, load from server
    const { fromGrid, isSaving, company, user } = useSelector(
        ({ invoiceBuilder, invoiceGrid, auth }) => ({
            fromGrid: invoiceGrid.invoices.find(inv =>
                isEqual(buildInvoiceNumberWithBeer(inv), number)
            ),
            isSaving: invoiceBuilder.isSaving,
            company: auth.user.company,
            user: auth.user,
        })
    );

    if (!!fromGrid && isEmpty(invoice)) {
        setInvoice(fromGrid);
    }
    const canEdit = !!invoice && [InvoiceStatuses.CANCELLED].indexOf(invoice.status) < 0;
    const canDelete =
        !!invoice && [InvoiceStatuses.CANCELLED].includes(invoice.status) && user.role === "owner";
    const hasPaymentFeature = isFeatureActive(company, PaymentFeatureDetails);

    const handleDelete = useCallback(() => {
        setDeleting(true);
        makePrivateApiCall({
            url: `/invoice/${number}`,
            method: "delete",
        })
            .then(() => {
                setDeleting(false);
                toast({
                    icon: "trash",
                    type: "success",
                    title: `Invoice deleted`,
                });
                history.push("/invoice");
            })
            .catch(err => {
                setDeleting(false);
                toast({ icon: "trash", type: "error", title: err.message });
            });
    }, [number]);

    const handlePdfGeneration = useCallback(
        ({ onSuccess }) => {
            generatePdf(number, setGeneratingPdfUrl).then(pdfUrl => {
                if (onSuccess) {
                    onSuccess(pdfUrl);
                }
            });
        },
        [number]
    );

    const loadInvoice = useCallback(() => {
        makePrivateApiCall({
            url: `/invoice/${number}`,
        })
            .then(res => {
                setLoading(false);
                if (res.data) {
                    setInvoice(res.data);
                    setError(null);
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
            });
    }, [number]);

    const saveChange = () => {
        dispatch(
            saveInvoiceBuilderInvoice({
                ...invoice,
                [confirmChange.field]: confirmChange.newValue,
            })
        ).then(inv => {
            setInvoice({ ...invoice, ...inv });
            setConfirmChange(null);
        });
    };
    const handlePaymentChange = () => loadInvoice();

    // when a value change is requested, let's first make sure that the value is actually changing from the current value
    // if not, no point showing the modal window asking for confirmation, right?
    //
    const handleChangeRequest =
        field =>
        (e, { value }) => {
            const previousValue = invoice[field],
                newValue = value,
                changeData = { field, previousValue, newValue },
                changedValues = isChangeNeeded(changeData);

            if (!changedValues) return null;

            setConfirmChange(changeData);
        };

    useEffect(() => {
        setLoading(true);
        loadInvoice();
    }, []);

    if (!isLoading && error && !invoice) {
        return <Message content={error} header="Sorry, something went wrong!" />;
    }

    const hasEmailFeature = isFeatureActive(company, EmailFeatureDetails);

    return (
        <>
            <Grid className="invoice-view-page">
                <TopNav
                    headerIcon="newspaper"
                    subheader="Details of your invoice"
                    headerContent={`Invoice #${number}`}
                    extraButtons={() => (
                        <>
                            <Menu.Item
                                name="All Invoices"
                                icon="chevron left"
                                to="/invoice"
                                as={Link}
                            />
                            {canEdit && (
                                <Menu.Item
                                    as={Link}
                                    name="Edit"
                                    icon="pencil"
                                    to={`/invoice/${number}/edit`}
                                />
                            )}
                            {canDelete && (
                                <Menu.Item
                                    name="Delete"
                                    disabled={isDeleting}
                                    icon={{
                                        name: isDeleting ? "spinner" : "trash",
                                        loading: isDeleting,
                                    }}
                                    onClick={() => setDeleteConfirming(true)}
                                />
                            )}
                            <Menu.Item
                                name="Pdf"
                                onClick={handlePdfGeneration}
                                disabled={!invoice || generatingPdfUrl}
                                icon={{
                                    name: generatingPdfUrl ? "spinner" : "file",
                                    loading: generatingPdfUrl,
                                }}
                            />
                        </>
                    )}
                />

                <Confirm
                    size="tiny"
                    open={!!confirmChange}
                    onConfirm={saveChange}
                    onCancel={() => setConfirmChange(null)}
                    confirmButton={{ content: "Yes, Change It", loading: isSaving }}
                    content={!!confirmChange && <ConfirmContent {...confirmChange} />}
                />

                <Confirm
                    size="tiny"
                    open={!!isDeleteConfirming}
                    onConfirm={handleDelete}
                    cancelButton="No, Keep It"
                    header="Are you absolutely sure?"
                    confirmButton={{ negative: true, content: "Yes, Delete It" }}
                    onCancel={() => setDeleteConfirming(false)}
                    content="This action is irreversible. You will lose all the data associated with this invoice once deleted."
                />

                <Grid.Column mobile={16}>
                    <InvoiceComponentView
                        invoice={invoice}
                        company={company}
                        isLoading={isLoading || isSaving}
                        onStatusChange={handleChangeRequest("status")}
                        onDueDateChange={handleChangeRequest("due_at")}
                        onDateChange={handleChangeRequest("invoiced_at")}
                    />
                </Grid.Column>

                {hasPaymentFeature && (
                    <Grid.Column mobile={16}>
                        <InvoicePaymentListContainer
                            invoice={invoice}
                            handlePaymentChange={handlePaymentChange}
                            user={user}
                        />
                    </Grid.Column>
                )}

                <Grid.Column mobile={16}>
                    <DocumentContainer invoiceId={invoice.id} />
                </Grid.Column>

                <Grid.Column mobile={16}>
                    <Table fixed className="no-print">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Email log</Table.HeaderCell>
                                <Table.HeaderCell colSpan="2" textAlign="right">
                                    {hasEmailFeature && (
                                        <Button
                                            component={Link}
                                            basic
                                            positive
                                            size="tiny"
                                            icon="envelope"
                                            floated="right"
                                            labelPosition="left"
                                            className="no-print"
                                            content="Send Email"
                                            onClick={() => setEmailModalOpen(true)}
                                        />
                                    )}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Emailed To</Table.HeaderCell>
                                <Table.HeaderCell>Notes</Table.HeaderCell>
                                <Table.HeaderCell>Sent at</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {invoice?.emails?.length ? (
                                invoice.emails.reverse().map(email => (
                                    <Table.Row key="email">
                                        <Table.Cell>{email.emailed_to}</Table.Cell>
                                        <Table.Cell>{email.notes}</Table.Cell>
                                        <Table.Cell>
                                            {format(new Date(email.created_at), DateTimeFormat)}
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                            ) : (
                                <Table.Row>
                                    <Table.Cell>
                                        You haven't sent this invoice to anyone yet
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid>

            <InvoiceEmailModal
                invoice={invoice}
                open={emailModalOpen}
                setOpen={setEmailModalOpen}
            />
        </>
    );
};

export default InvoicePageView;
