import {
    INVENTORY_GRID_CELL_EDIT_CONFIRMATION_TOGGLE,
    INVENTORY_GRID_CELL_UPDATE_SUCCESS,
    INVENTORY_GRID_DATA,
    INVENTORY_GRID_ERROR,
    INVENTORY_GRID_LOADING,
    INVENTORY_GRID_PAGE_CHANGE,
    INVENTORY_GRID_STATE_CHANGE,
} from "../constants";
import { allGridColumns } from "./columns";
import { generateReducer } from "../../common/helpers";

const initialState = {
    error: null,
    inventories: [],
    isLoading: false,
    currentPage: 1,
    lastRow: null,
    pageSize: 25,
    sortModel: [
        {
            colId: "updated_at",
            sort: "desc",
        },
    ],
    filterModel: {},
    columnDefs: Object.values(allGridColumns),

    showCellEditConfirmation: true,
};

const setGridData = (state, { inventories, lastRow }) => {
    return {
        ...state,
        error: null,
        isLoading: false,
        lastRow,
        inventories,
    };
};

const changeGridPage = (state, currentPage) => ({ ...state, currentPage });
const changeGridState = (state, payload) => ({ ...state, ...payload });
const setGridLoading = state => ({ ...state, isLoading: true });
const setGridError = (state, error) => ({ ...state, isLoading: false, error });
const setGridCellEditConfirmation = (state, showCellEditConfirmation) => ({
    ...state,
    showCellEditConfirmation,
});
const updateGridRow = (state, row) => {
    const inventories = state.inventories.map(inventory => {
        if (inventory.id === row.id) {
            return { ...inventory, ...row };
        }

        return inventory;
    });

    return {
        ...state,
        inventories,
    };
};

const reducers = {
    [INVENTORY_GRID_DATA]: setGridData,
    [INVENTORY_GRID_ERROR]: setGridError,
    [INVENTORY_GRID_LOADING]: setGridLoading,
    [INVENTORY_GRID_CELL_EDIT_CONFIRMATION_TOGGLE]: setGridCellEditConfirmation,
    [INVENTORY_GRID_CELL_UPDATE_SUCCESS]: updateGridRow,

    [INVENTORY_GRID_PAGE_CHANGE]: changeGridPage,
    [INVENTORY_GRID_STATE_CHANGE]: changeGridState,
};

export default generateReducer(reducers, initialState);
