import { get } from "lodash";

import {
    INVOICE_CONVERTER_UPLOADING,
    INVOICE_CONVERTER_UPLOAD_ERROR,
    INVOICE_CONVERTER_UPLOAD_SUCCESS,
} from "../constants";

import { makePrivateApiCall } from "utilities/dist/api";

export const uploadInvoiceFile = file => dispatch => {
    if (!file) return;

    let invoiceForm = new FormData();

    invoiceForm.append("invoice", file);

    dispatch({
        type: INVOICE_CONVERTER_UPLOADING,
        payload: URL.createObjectURL(file),
    });

    return makePrivateApiCall({
        method: "POST",
        data: invoiceForm,
        url: "/invoice/convert",
    })
        .then(res => {
            dispatch({
                type: INVOICE_CONVERTER_UPLOAD_SUCCESS,
                payload: res.data,
            });
        })
        .catch(err => {
            const message = get(err, "response.data.message", err.message);
            dispatch({
                type: INVOICE_CONVERTER_UPLOAD_ERROR,
                payload: message,
            });
        });
};
