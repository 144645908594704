import React, { useState, useEffect, useCallback } from "react";
import { Grid, Message } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";

import { makePrivateApiCall } from "utilities/dist/api";
import OrderComponentView from "../components/view";

const OrderPagePdf = ({ match }) => {
    const { token } = match.params;

    const [error, setError] = useState(null);
    const [order, setOrder] = useState({});
    const [isLoading, setLoading] = useState(false);

    const loadOrder = useCallback(() => {
        makePrivateApiCall({
            url: `/pdf/order/${token}`,
        })
            .then(res => {
                setLoading(false);
                if (res.data) {
                    const { modifiedOrderItems, order } = res.data;
                    if (modifiedOrderItems?.length) {
                        order.orderItems = modifiedOrderItems;
                    }
                    setOrder(order);
                    window.READY_FOR_PDF_EXPORT = true;
                    setError(null);
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
            });
    }, [token]);

    // when a value change is requested, let's first make sure that the value is actually changing from the current value
    // if not, no point showing the modal window asking for confirmation, right?
    //

    useEffect(() => {
        setLoading(true);
        loadOrder();
    }, []);

    if (!isLoading && error) {
        return <Message content={error} header="Sorry, something went wrong!" />;
    }

    return (
        <>
            <Grid className="invoice-view-page">
                <TopNav
                    headerIcon="newspaper"
                    subheader="Details of your invoice"
                    headerContent={`Order #${order?.order_number || ""}`}
                />

                <Grid.Column mobile={16}>
                    <OrderComponentView
                        order={order}
                        company={order?.company}
                        isLoading={isLoading}
                    />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default OrderPagePdf;
