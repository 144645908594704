import { omit } from "lodash";
import Joi from "joi";

const schema = {
    id: Joi.number().min(1).required(),
    prefix: Joi.string().allow("").uppercase().trim(),
    received_at: Joi.date().label("Receive Date").required(),
    from: Joi.number().min(1).label("Stamp Start").required(),
    to: Joi.number().min(Joi.ref("from")).label("Stamp End").required(),
};

export const validateStampCreate = stamp => {
    return Joi.object(omit(schema, ["id"])).validateAsync(stamp, {
        convert: true,
        stripUnknown: true,
    });
};

export const validateStampUpdate = stamp => {
    return Joi.object(schema).validateAsync(stamp, {
        convert: true,
        stripUnknown: true,
    });
};
