import { keyBy, isEmpty, omit } from "lodash";
import { generateReducer, parseCurrency } from "../../common/helpers";
import { isQtyComputable } from "../../invoice/helpers";
import { buildProductIdKey } from "../../product/helpers";
import {
    INVENTORY_RESTOCK_AWAITING_ITEMS_DATA,
    INVENTORY_RESTOCK_AWAITING_ITEMS_ERROR,
    INVENTORY_RESTOCK_AWAITING_ITEMS_LOADING,
    INVENTORY_RESTOCK_SHOW_INLINE_EDITOR,
    INVENTORY_RESTOCK_HIDE_INLINE_EDITOR,
    INVENTORY_RESTOCK_CHANGE_INLINE_EDITOR,
    INVENTORY_RESTOCK_SELECT_PRODUCT,
    INVENTORY_RESTOCK_CHANGE_QTY,
    INVENTORY_RESTOCK_CHANGE_PRICE,
    INVENTORY_RESTOCK_ADD_PRODUCT,
    INVENTORY_RESTOCK_REMOVE_ITEM,
    INVENTORY_RESTOCK_SET_DISTRIBUTOR,
    INVENTORY_RESTOCK_CLEAR_SELECTION,
    INVENTORY_RESTOCK_SET_REF_NUMBER,
    INVENTORY_RESTOCK_COMPLETE,
    INVENTORY_RESTOCK_TOGGLE_ON_DEAL,
} from "../constants";

const initialState = {
    items: {},
    error: null,
    isLoading: false,

    ref_number: "",
    distributor: "",

    editor: {},
    inlineEditorFields: [],
    inlineEditorItemId: "",
};

const setLoading = state => ({ ...state, isLoading: true, error: null });
const setError = (state, error) => ({ ...state, error, isLoading: false });
const setItems = (state, items) => {
    if (!items?.length) {
        return { ...state, isLoading: false };
    }
    return {
        ...state,
        items: keyBy(
            items.map(item => {
                const transformedItem = { ...item, qty: item.reorder_qty || 1 };
                transformedItem.price = parseCurrency(item.product?.last_purchase_price || 0);
                transformedItem.total = parseCurrency(
                    transformedItem.price * item.reorder_qty || 1
                );
                return transformedItem;
            }),
            item => buildProductIdKey(item.product)
        ),
        isLoading: false,
    };
};

// Inline editor related reducers
// It's mostly duplicated from invoice creator's inline editor reducer
const showInlineEditor = (state, { item, inlineEditorFields }) => {
    return {
        ...state,
        inlineEditorFields,
        inlineEditorItemId: buildProductIdKey(item.product),
    };
};

const hideInlineEditor = (state, field) => {
    const newState = {
        ...state,
        inlineEditorFields: state.inlineEditorFields.filter(f => f !== field),
    };

    if (newState.inlineEditorFields.length < 1) {
        newState.inlineEditorItemId = "";
    }

    return newState;
};

const updateItemInline = (state, { field, value }) => {
    if (state.inlineEditorFields.length < 1) return state;

    const newState = { ...state },
        item = newState.items[state.inlineEditorItemId];

    if (field === "on_deal") {
        item[field] = value;
    } else {
        if (field === "price") {
            item.price = parseFloat(value);
        } else if (field === "qty") {
            item.qty = isQtyComputable(value) ? Number(value) : value;
        }

        item.total = parseCurrency(item.price * (isQtyComputable(item.qty) ? item.qty : 0));
    }

    newState.items[state.inlineEditorItemId] = item;

    return newState;
};

// Search and select product related reducers
const selectProduct = (state, product) => {
    let newState = { ...state, editor: {} };

    if (!isEmpty(product)) {
        const price = parseCurrency(product.last_purchase_price || 0);
        const qty = product.inventory?.qty || 0;
        newState.editor = {
            product,
            qty,
            price,
            product_id: product.id,
            on_deal: false,
            total: parseCurrency(qty * price),
        };
    }

    return newState;
};

const changePrice = (state, { price, id }) => {
    const newState = { ...state };
    let item = newState.editor;

    if (id) {
        item = { ...newState.items[id] };
    }

    item.price = parseFloat(price);
    item.total = parseCurrency(item.qty * item.price);

    if (id) {
        newState.items[id] = item;
    }

    return newState;
};

const changeQty = (state, { qty, id }) => {
    const newState = { ...state };
    let item = newState.editor;

    if (id) {
        item = { ...newState.items[id] };
    }

    item.qty = isQtyComputable(qty) ? Number(qty) : qty;
    const multipliableQty = isQtyComputable(qty) ? item.qty : 0;
    item.total = parseCurrency(item.price * multipliableQty);

    if (id) {
        newState.products[id] = item;
    }

    return newState;
};

const toggleOnDeal = (state, id) => {
    const newState = { ...state };
    let item = newState.editor;

    if (id) {
        item = { ...newState.items[id] };
    }

    item.on_deal = !item.on_deal;

    if (id) {
        newState.products[id] = item;
    }

    return newState;
};

const addProduct = state => {
    const item = state.editor;
    const key = buildProductIdKey(item.product);
    const items = { ...state.items, [key]: item };

    let newState = {
        ...state,
        items,
        editor: {},
    };

    return newState;
};

const removeItem = (state, item) => {
    const items = omit(state.items, buildProductIdKey(item.product));
    const newState = { ...state, items };

    return newState;
};

const setDistributor = (state, distributor) => {
    if (!distributor) return { ...state, distributor };

    // If distributor is being set, filter items by distributor to make sure that previously selected items that don't belong to the selected distributor is cleaned
    const items = {};
    Object.keys(state.items).forEach(key => {
        if (state.items[key].distributor === distributor) {
            items[key] = { ...state.items[key] };
        }
    });

    return { ...state, distributor, items };
};
const setRefNumber = (state, ref_number) => ({ ...state, ref_number });
const clearSelection = state => ({ ...state, items: {} });
const resetAfterComplete = () => initialState;

const reducers = {
    [INVENTORY_RESTOCK_AWAITING_ITEMS_DATA]: setItems,
    [INVENTORY_RESTOCK_AWAITING_ITEMS_ERROR]: setError,
    [INVENTORY_RESTOCK_AWAITING_ITEMS_LOADING]: setLoading,

    [INVENTORY_RESTOCK_SHOW_INLINE_EDITOR]: showInlineEditor,
    [INVENTORY_RESTOCK_HIDE_INLINE_EDITOR]: hideInlineEditor,
    [INVENTORY_RESTOCK_CHANGE_INLINE_EDITOR]: updateItemInline,

    [INVENTORY_RESTOCK_ADD_PRODUCT]: addProduct,
    [INVENTORY_RESTOCK_SELECT_PRODUCT]: selectProduct,
    [INVENTORY_RESTOCK_CHANGE_QTY]: changeQty,
    [INVENTORY_RESTOCK_TOGGLE_ON_DEAL]: toggleOnDeal,
    [INVENTORY_RESTOCK_CHANGE_PRICE]: changePrice,
    [INVENTORY_RESTOCK_REMOVE_ITEM]: removeItem,
    [INVENTORY_RESTOCK_SET_DISTRIBUTOR]: setDistributor,
    [INVENTORY_RESTOCK_SET_REF_NUMBER]: setRefNumber,
    [INVENTORY_RESTOCK_CLEAR_SELECTION]: clearSelection,
    [INVENTORY_RESTOCK_COMPLETE]: resetAfterComplete,
};

export default generateReducer(reducers, initialState);
