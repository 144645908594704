import React from "react";
import { Grid, Menu, Divider } from "semantic-ui-react";

import TopNav from "../../common/containers/top-nav";
import CustomerContainerEditor from "../containers/editor";
import CustomerContainerAutoFillFinder from "../containers/auto-fill-finder";

const CustomerPageCreate = ({ history }) => {
    return (
        <Grid>
            <TopNav
                headerIcon="briefcase"
                subheader="Add new customer"
                headerContent="Create Customer"
                extraButtons={() => (
                    <Menu.Item
                        icon="chevron left"
                        name="All Customers"
                        onClick={() => history.goBack()}
                    />
                )}
            />

            <Grid.Column mobile={16} computer={8}>
                <CustomerContainerAutoFillFinder />
            </Grid.Column>

            <Grid.Column mobile={16}>
                <Divider horizontal>Or Fill Out Manually</Divider>
                <CustomerContainerEditor />
            </Grid.Column>
        </Grid>
    );
};

export default CustomerPageCreate;
