import { exec } from "child_process";

export const parseCurrency = amount => Number(parseFloat(amount).toFixed(2));

export const getRoomNameWithCompanyId = (roomName, id) => `${roomName}-${id}`;

export const isIos = () => /(iPad|iPhone|iPod).*WebKit/.test(window.navigator.userAgent);

// eslint-disable-next-line no-console
export const prettyPrint = data => console.log(JSON.stringify(data, null, 4));

export const prettyPrintDbData = data => {
    if (Array.isArray(data)) {
        // eslint-disable-next-line no-console
        return console.log(
            JSON.stringify(
                data.map(d => d.dataValues),
                null,
                4
            )
        );
    }

    // eslint-disable-next-line no-console
    console.log(JSON.stringify(data, null, 4));
};

/**
 * Executes a shell command and return it as a Promise.
 * @param cmd {string}
 * @return {Promise<string>}
 */
export function execShellCommand(cmd) {
    return new Promise((resolve, reject) => {
        exec(cmd, (error, stdout, stderr) => {
            if (error) {
                reject(error);
            }
            resolve(stdout ? stdout : stderr);
        });
    });
}
