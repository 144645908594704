import React from "react";
import { Form } from "formik-semantic-ui";

import { ProductPriceTierCreatorComponent } from "../components/creator";
import { Message, Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { saveProductPriceTier, showProductPriceTierModal } from "../actions";

export const ProductPriceTierCreatorContainer = () => {
    const dispatch = useDispatch();
    const { inputs, showModal, isSaving, errors } = useSelector(
        ({ productPriceTier }) => productPriceTier
    );

    return (
        <Modal open={showModal}>
            <Modal.Header>
                {inputs?.id ? "Update price tier" : "Create new price tier"}
            </Modal.Header>
            <Modal.Content>
                <Form
                    onSubmit={(data, formik) => {
                        dispatch(saveProductPriceTier(data)).finally(() =>
                            formik.setSubmitting(false)
                        );
                    }}
                    isSubmitting={isSaving}
                    initialValues={inputs}
                >
                    <ProductPriceTierCreatorComponent
                        onCancel={() => dispatch(showProductPriceTierModal(false))}
                    />
                </Form>
                {!!errors?.length && <Message error header="Something went wrong" list={errors} />}
            </Modal.Content>
        </Modal>
    );
};
