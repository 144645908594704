export const CustomerGridColumns = [
    {
        headerName: "Name",
        field: "name",
        minWidth: 150,
    },
    {
        headerName: "TABC ID",
        field: "tabc_id",
        minWidth: 100,
    },
    {
        headerName: "Address",
        field: "address",
        minWidth: 200,
        cellRenderer: function ({ data }) {
            if (!data) return null;

            return `${data.address}<br/>${data.city}, ${data.zip}`;
        },
    },
    {
        headerName: "Tier",
        field: "product_price_tier_id",
        minWidth: 100,
        filter: false,
        sortable: false,
        cellRenderer: function ({ data }) {
            if (!data) return null;
            return data.productPriceTier?.name || "Base Tier";
        },
    },
    {
        headerName: "COD",
        field: "cash_on_delivery",
        minWidth: 50,
        cellRenderer: function ({ data }) {
            if (!data) return null;

            return data.cash_on_delivery ? "Yes" : "No";
        },
    },
    {
        headerName: "Contact Name",
        field: "contact_name",
        minWidth: 100,
    },
    {
        headerName: "Contact Info",
        field: "contact_email",
        minWidth: 100,
        cellRenderer: function ({ data }) {
            if (!data) return null;

            return `${data.contact_email || ""}<br/>${data.contact_phone || ""}`;
        },
    },
    {
        headerName: "Actions",
        cellRenderer: "customerGridActions",
        minWidth: 120,
    },
    {
        headerName: "Users",
        field: "customer_users",
        minWidth: 50,
        cellRenderer: function ({ data }) {
            if (!data) return null;
            return `${data.customerUser?.length || 0}`;
        },
    },
];
