import React, { useState } from "react";
import Webcam from "react-webcam";
import { useDispatch, useSelector } from "react-redux";
import { uploadDocuments, webCamUpload } from "../actions";
import { Modal, Input, Button, Message, Grid } from "semantic-ui-react";

const dataURItoBlob = dataURI => {
    const binary = atob(dataURI.split(",")[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
};

const WebcamUpload = () => {
    const [imageName, setImageName] = useState(`camera_image_${Date.now()}`);
    const [image, setImage] = useState(null);
    const dispatch = useDispatch();
    const { invoiceId, openWebCam } = useSelector(({ invoiceDocument }) => invoiceDocument);
    const webcamRef = React.useRef(null);
    const handleUpload = () => {
        if (image && imageName && invoiceId) {
            dispatch(uploadDocuments(image, invoiceId, `${imageName}.jpeg`));
            dispatch(webCamUpload({ openWebCam: false }));
        }
    };
    const capture = React.useCallback(() => {
        const imageSrc = dataURItoBlob(webcamRef.current.getScreenshot());
        setImage(imageSrc);
    }, [webcamRef]);

    return (
        <Modal
            closeIcon
            size="large"
            open={!!openWebCam}
            onClose={() => dispatch(webCamUpload({ openWebCam: false }))}
        >
            <Modal.Header>{image ? "Set a file name" : "Take photo"}</Modal.Header>
            <Modal.Content>
                {!!image ? (
                    <>
                        <Grid>
                            <Grid.Column computer={8} mobile={16}>
                                <Input
                                    fluid
                                    autoFocus
                                    size="large"
                                    value={imageName}
                                    error={!imageName}
                                    labelPosition="right"
                                    label={{ basic: true, content: ".jpeg" }}
                                    placeholder="Set a descriptive file name"
                                    onChange={e => setImageName(e.target.value)}
                                />
                                {!imageName && (
                                    <Message error content="File name can not be empty" />
                                )}
                            </Grid.Column>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Webcam
                            mirrored={true}
                            audio={false}
                            ref={webcamRef}
                            className="webcam"
                            screenshotFormat="image/png"
                        />
                        <div className="photo-button" onClick={capture}>
                            <div id="circle"></div>
                            <div id="ring"></div>
                        </div>
                    </>
                )}
            </Modal.Content>

            <Modal.Actions>
                <Button
                    negative
                    content="Cancel"
                    onClick={() => dispatch(webCamUpload({ openWebCam: false }))}
                />
                {!!image && <Button content="Retake Photo" onClick={() => setImage(null)} />}
                <Button
                    positive
                    content="Upload"
                    disabled={!imageName || !image}
                    onClick={handleUpload}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default WebcamUpload;
