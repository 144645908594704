import { keyBy } from "lodash";
import { parseCurrency, parseServerTimestampForClient } from "../../common/helpers";
import { format } from "date-fns";
import { DateFormat } from "utilities/dist/invoice/constants";

export const allGridColumns = keyBy(
    [
        {
            field: "distributor",
            headerName: "Distributor",
            editable: true,
            valueGetter: function ({ data }) {
                return !data ? null : data.distributor;
            },
        },
        {
            filter: "agNumberColumnFilter",
            field: "reorder_needed",
            headerName: "To Reorder",
            headerTooltip: "To Reorder",
            valueGetter: function ({ data }) {
                return data?.reorder_needed ? "Yes" : "No";
            },
        },
        {
            field: "product.upc",
            headerName: "UPC",
            valueGetter: function ({ data }) {
                return !data ? null : data?.product.upc;
            },
        },
        {
            field: "product.product",
            headerName: "Product",
            minWidth: 250,
            cellRenderer: "productLinkRenderer",
            valueGetter: function ({ data }) {
                return !data ? null : data.product?.product;
            },
        },
        {
            field: "product.product_size",
            headerName: "Size",
            valueGetter: function ({ data }) {
                return !data ? null : data.product?.product_size;
            },
        },
        {
            field: "product.product_price",
            headerName: "Unit Price",
            headerTooltip: "Unit Price",
            valueGetter: function ({ data }) {
                return !data ? null : parseCurrency(data.product?.product_price);
            },
        },
        {
            field: "qty",
            headerName: "In Stock",
            headerTooltip: "In Stock",
            editable: true,
            filter: "agNumberColumnFilter",
            valueGetter: function ({ data }) {
                return !data ? null : data.qty;
            },
        },
        {
            field: "reorder_level",
            headerName: "Min Reorder",
            headerTooltip: "Min Reorder",
            editable: true,
            filter: "agNumberColumnFilter",
            valueGetter: function ({ data }) {
                return !data ? null : data.reorder_level;
            },
        },
        {
            field: "reorder_time_in_days",
            headerName: "Days To Reorder",
            headerTooltip: "Days To Reorder",
            editable: true,
            filter: "agNumberColumnFilter",
            valueGetter: function ({ data }) {
                return !data ? null : data.reorder_time_in_days;
            },
        },
        {
            field: "reorder_qty",
            headerName: "Qty To Reorder",
            headerTooltip: "Qty To Reorder",
            editable: true,
            filter: "agNumberColumnFilter",
            valueGetter: function ({ data }) {
                return !data ? null : data.reorder_qty;
            },
        },
        {
            field: "inventory_value",
            headerName: "Inventory Value",
            headerTooltip: "Inventory Value",
            filter: "agNumberColumnFilter",
            valueGetter: function ({ data }) {
                return !data ? null : `$${parseCurrency(data.product.last_purchase_price * data.qty)}`;
            },
        },
        {
            field: "updated_at",
            headerName: "Last Updated",
            headerTooltip: "Last Updated",
            filter: "agDateColumnFilter",
            valueGetter: function ({ data }) {
                return !data ? null : format(parseServerTimestampForClient(data.updated_at), DateFormat);
            },
        },
    ],
    "field"
);
