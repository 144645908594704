import {
    ITEM_LOG_ADD_PRODUCT,
    ITEM_LOG_CHANGE_INLINE_EDITOR,
    ITEM_LOG_HIDE_INLINE_EDITOR,
    ITEM_LOG_REMOVE_ITEM,
    ITEM_LOG_SHOW_INLINE_EDITOR,
    RESET_ITEM_LOG,
} from "../constants";

export const itemLogRemoveItem = product => ({
    type: ITEM_LOG_REMOVE_ITEM,
    payload: product,
});

export const resetItemLog = () => ({
    type: RESET_ITEM_LOG,
});

export const changeItemLogInlineEditor = (field, value) => ({
    type: ITEM_LOG_CHANGE_INLINE_EDITOR,
    payload: { field, value },
});

export const showItemLogInlineEditor = (item, inlineEditorFields) => ({
    type: ITEM_LOG_SHOW_INLINE_EDITOR,
    payload: { item, inlineEditorFields },
});

export const hideItemLogInlineEditor = field => ({
    type: ITEM_LOG_HIDE_INLINE_EDITOR,
    payload: field,
});
export const addItemLogProduct = () => ({
    type: ITEM_LOG_ADD_PRODUCT,
});
