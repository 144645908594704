import { get } from "lodash";
import { makePrivateApiCall } from "utilities/dist/api";
import {
    PRODUCT_PRICE_TIER_ERROR,
    PRODUCT_PRICE_TIER_MODAL_TOGGLE,
    PRODUCT_PRICE_TIER_REMOVED,
    PRODUCT_PRICE_TIER_REMOVE_ERROR,
    PRODUCT_PRICE_TIER_REMOVING,
    PRODUCT_PRICE_TIER_SAVED,
    PRODUCT_PRICE_TIER_SAVING,
    PRODUCT_PRICE_TIER_REMOVE_MODAL_TOGGLE,
    PRODUCT_PRICE_TIER_INPUT,
} from "./constants";
import { loadCustomerList } from "../../customer/actions";

export const setProductPriceTierInput = payload => ({
    type: PRODUCT_PRICE_TIER_INPUT,
    payload,
});

export const showProductPriceTierModal = payload => ({
    type: PRODUCT_PRICE_TIER_MODAL_TOGGLE,
    payload,
});

export const setPriceTierError = error => dispatch => {
    return dispatch({
        type: PRODUCT_PRICE_TIER_ERROR,
        payload: error,
    });
};

export const setPriceTierRemoveError = error => dispatch => {
    return dispatch({
        type: PRODUCT_PRICE_TIER_REMOVE_ERROR,
        payload: error,
    });
};

export const setPriceTierRemoveId = id => dispatch => {
    return dispatch({
        type: PRODUCT_PRICE_TIER_REMOVE_MODAL_TOGGLE,
        payload: id,
    });
};

export const saveProductPriceTier = (priceTier = {}) => {
    return dispatch => {
        dispatch({
            type: PRODUCT_PRICE_TIER_SAVING,
            payload: true,
        });

        const request = {
            url: `/product/price-tier`,
            method: "post",
            data: priceTier,
        };

        if (priceTier.id) {
            request.url = `/product/price-tier/${priceTier.id}`;
            request.method = "put";
            request.data = { ...priceTier };
        }

        return makePrivateApiCall(request)
            .then(res => {
                if (res.data) {
                    dispatch({ type: PRODUCT_PRICE_TIER_SAVED, payload: res.data });
                }

                return res.data;
            })
            .catch(err => {
                const details = get(err, "response.data.details", []);
                if (details.length) {
                    dispatch(setPriceTierError(details.map(errDet => errDet?.message)));
                    return;
                }
                const message = get(err, "response.data.message", null) || err.message;
                dispatch(setPriceTierError([message]));
            });
    };
};

export const removeProductPriceTier = (id, migrating_price_tier_id = null) => {
    return dispatch => {
        dispatch({
            type: PRODUCT_PRICE_TIER_REMOVING,
        });

        let url = `/product/price-tier/${id}`;

        if (migrating_price_tier_id) {
            url += `?migrating_price_tier_id=${migrating_price_tier_id}`;
        }

        return makePrivateApiCall({
            url,
            method: "DELETE",
        })
            .then(() => {
                dispatch({ type: PRODUCT_PRICE_TIER_REMOVED, payload: id });
                dispatch(loadCustomerList());
                return true;
            })
            .catch(err => {
                const details = get(err, "response.data.details", []);
                if (details.length) {
                    dispatch(setPriceTierRemoveError(details.map(errDet => errDet?.message)));
                    return;
                }
                const message = get(err, "response.data.message", null) || err.message;
                dispatch(setPriceTierRemoveError([message]));
            });
    };
};
