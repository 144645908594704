import React from "react";
import { Link } from "react-router-dom";
import { Header } from "semantic-ui-react";
import { format } from "date-fns";

import { DateTimeFormat } from "utilities/dist/invoice/constants";

const InvoiceComponentOrderInfo = ({ order = {} }) => {
    const orderedAt = order[0]?.ordered_at;
    return (
        <>
            <Header textAlign="right">
                <Header.Subheader className="no-print">
                    Order{" "}
                    <Link title="Order info" to={`/order/${order[0]?.order_number}`}>
                        #{order[0]?.order_number}
                    </Link>{" "}
                    Placed by {order[0].customerUser?.name}
                    <br />
                    {format(new Date(orderedAt), DateTimeFormat)}
                </Header.Subheader>
            </Header>
        </>
    );
};

export default InvoiceComponentOrderInfo;
